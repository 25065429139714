import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { setToken } from "../../flux/actions/tokenIndex";
import { setUser } from "../../flux/actions/userIndex";
import api from "../../services/api";

import { makeStyles } from "@material-ui/core/styles";
import MuiPhoneInput from 'material-ui-phone-number'
import { validateTokenUser, getUser, getToken } from '../../utils/utils.js'

function ConfigurarConta(props) {
    let classes = useStyles()


    const [novoCelular, setNovoCelular] = useState('')
    const [celularAtual, setCelularAtual] = useState()


    useEffect(() => {
        const numeroAtual = localStorage.getItem('celular')
        setCelularAtual(numeroAtual)

        async function fetchMyAPI() {
            await validateTokenUser(props)
        }
        fetchMyAPI()
    }, [])

    async function alterarDadosUsuario() {
        const response = await api.get('alterarDadosUsuario', { params: { userId: getUser(), token: getToken(), novoCelular } })
        if (response.data === 'NOK') {
            alert('Erro ao salvar alteração...')
        } else {
            alert('Alteração salva com sucesso!')
            localStorage.setItem('celular', novoCelular)
            window.location.reload()
        }
    }

    return (
        <div style={{ width: '95%', background: 'white', padding: 20, borderRadius: 8 }}>
            <div style={{ margin: 5 }}>
                <h3 style={{ fontWeight: 'bold' }}>CONFIGURAÇÕES</h3>
            </div>
            <div className={classes.tela1}>

                <div style={{ border: '1px solid rgba(0, 0, 0, .4)', borderRadius: 8, padding: 10 }}>
                    <h4>Alterar número do celular</h4>

                    {celularAtual === '' ? <p>Nenhum número cadastrado</p> : <p>Número atual: {celularAtual}</p>}


                    <MuiPhoneInput
                        defaultCountry='br'
                        style={{ width: 300, margin: 5 }}

                        onChange={(e) => setNovoCelular(e)}
                        label='Cadastrar um novo número'
                    />

                    {novoCelular.length !== 18 ? <></> : <div style={{ textAlign: 'center' }}><button onClick={() => alterarDadosUsuario()}>Salvar Alteração</button></div>}
                </div>

            </div>

        </div>
    )
}

const useStyles = makeStyles({
    tela1: {
        padding: 10,
        marginTop: 20,
        background: 'white',
        width: 400,
        borderRadius: 8,
        fontWeight: 'bold',
        boxShadow: 'rgba(0, 0, 0, 0.2) 0px 5px 15px'
    }
});

function mapStateToProps(state) {
    return {
        token: state.token,
        user: state.user,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            setToken: setToken,
            setUser: setUser,
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfigurarConta);