/*import React, { useState, useEffect } from 'react';
import { Grid, Box, Paper } from '@material-ui/core';
import Calcular from './formuladorComponents/Calcular';
import Resultado from './formuladorComponents/Resultado';
import Edicao from './formuladorComponents/Edicao';
import api from 'services/api.js';
import { getUser, getToken, getId } from 'utils/utils.js'
import Loader from "react-loader-spinner";
import axios from 'axios';

const FormularioPrecos = () => {
  const [formData, setFormData] = useState({
    codigo: '',
    nome_produto: '',
    preco_atual: '',
    moeda_atual: 'BRL',
    bom_jesus_atual: '',
    habilitada_bom_jesus: true,
    barcarena_atual: '',
    habilitada_barcarena: true,
    paranagua_atual: '',
    habilitada_paranagua: true,
    preco_futuro: '',
    moeda_futuro: 'BRL',
    bom_jesus_futuro: '',
    habilitada_bom_jesus_futuro: true,
    barcarena_futuro: '',
    habilitada_barcarena_futuro: true,
    paranagua_futuro: '',
    habilitada_paranagua_futuro: true,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post('/api/precos/', formData);
      alert('Preço cadastrado com sucesso!');
    } catch (error) {
      console.error('Erro ao cadastrar preço:', error);
      alert('Erro ao cadastrar preço.');
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <input name="codigo" value={formData.codigo} onChange={handleChange} placeholder="Código" required />
      <input name="nome_produto" value={formData.nome_produto} onChange={handleChange} placeholder="Nome do Produto" required />
      <input name="preco_atual" type="number" value={formData.preco_atual} onChange={handleChange} placeholder="Preço Atual" required />
      <select name="moeda_atual" value={formData.moeda_atual} onChange={handleChange}>
        <option value="BRL">Real</option>
        <option value="USD">Dólar</option>
      </select>
      <input name="bom_jesus_atual" type="number" value={formData.bom_jesus_atual} onChange={handleChange} placeholder="Bom Jesus Atual" required />
      <input name="habilitada_bom_jesus" type="checkbox" checked={formData.habilitada_bom_jesus} onChange={handleChange} />
      <input name="barcarena_atual" type="number" value={formData.barcarena_atual} onChange={handleChange} placeholder="Barcarena Atual" required />
      <input name="habilitada_barcarena" type="checkbox" checked={formData.habilitada_barcarena} onChange={handleChange} />
      <input name="paranagua_atual" type="number" value={formData.paranagua_atual} onChange={handleChange} placeholder="Paranaguá Atual" required />
      <input name="habilitada_paranagua" type="checkbox" checked={formData.habilitada_paranagua} onChange={handleChange} />
      <input name="preco_futuro" type="number" value={formData.preco_futuro} onChange={handleChange} placeholder="Preço Futuro" required />
      <select name="moeda_futuro" value={formData.moeda_futuro} onChange={handleChange}>
        <option value="BRL">Real</option>
        <option value="USD">Dólar</option>
      </select>
      <input name="bom_jesus_futuro" type="number" value={formData.bom_jesus_futuro} onChange={handleChange} placeholder="Bom Jesus Futuro" required />
      <input name="habilitada_bom_jesus_futuro" type="checkbox" checked={formData.habilitada_bom_jesus_futuro} onChange={handleChange} />
      <input name="barcarena_futuro" type="number" value={formData.barcarena_futuro} onChange={handleChange} placeholder="Barcarena Futuro" required />
      <input name="habilitada_barcarena_futuro" type="checkbox" checked={formData.habilitada_barcarena_futuro} onChange={handleChange} />
      <input name="paranagua_futuro" type="number" value={formData.paranagua_futuro} onChange={handleChange} placeholder="Paranaguá Futuro" required />
      <input name="habilitada_paranagua_futuro" type="checkbox" checked={formData.habilitada_paranagua_futuro} onChange={handleChange} />
      <button type="submit">Salvar</button>
    </form>
  );
};

export default FormularioPrecos;*/

// Código antigo dessa linha para baixo:
import React, { useState, useEffect } from 'react';
import { Grid, Box, Paper } from '@material-ui/core';
import Calcular from './formuladorComponents/Calcular';
import Resultado from './formuladorComponents/Resultado';
import Edicao from './formuladorComponents/Edicao';
import api from 'services/api.js';
import { getUser, getToken, getId } from 'utils/utils.js'
import Loader from "react-loader-spinner";

export default function NewFormulador() {
    const [materias, setMaterias] = useState([]);
    const [formulacao, setFormulacao] = useState({});
    const [formulacaoMisc, setFormulacaoMisc] = useState({});
    const [calculando, setCalculando] = useState(false);
    const [editores, setEditores] = useState({ 'lista': [] });

    useEffect(() => {
        FetchMyApi();
    }, []);

    const FetchMyApi = async () => {
        const response = await api.get("retorna_materias_formulas", {
            params: { userId: getUser(), token: getToken() },
        });
        setMaterias(response.data);
        const response_editores = await api.get("retorna_editores_formulador_app", {
            params: { userId: getUser(), token: getToken() },
        });
        setEditores(response_editores.data);
    }

    const updateMaterias = (newMaterias) => {
        setMaterias(newMaterias);
    }

    return (
        <Grid 
            container 
            component={Paper} 
            style={{ padding: 20, borderRadius: 10, marginTop: -40, maxHeight: '90vh', overflow: 'auto' }}
        >
            {/* Coluna da esquerda, ajustando o tamanho conforme a tela */}
            <Grid 
                item 
                xs={12} sm={4} md={3}
            >
                <Grid item xs={12}>
                    <Calcular
                        materias={materias}
                        onFormulacao={(dados) => setFormulacao(dados)}
                        onFormulacaoMisc={(dados) => setFormulacaoMisc(dados)}
                        onFetching={(value) => setCalculando(value)}
                        calculando={calculando}
                    />
                </Grid>

                {/* Renderiza o componente Edicao baseado no editor */}
                {editores.lista.includes(parseInt(getId())) ? (
                    <Grid item xs={12} style={{ marginTop: 20 }}>
                        <Edicao
                            materias={materias}
                            setMaterias={updateMaterias}
                            vendedor={false}
                        />
                    </Grid>
                ) : (
                    <Grid item xs={12} style={{ marginTop: 20 }}>
                        <Edicao
                            materias={materias}
                            setMaterias={updateMaterias}
                            vendedor={true}
                        />
                    </Grid>
                )}
            </Grid>

            {/* Coluna da direita, ajustando o tamanho conforme a tela */}
            <Grid 
                item 
                xs={12} sm={8} md={9}
            >
                <Resultado
                    vendedor={!editores.lista.includes(parseInt(getId()))}
                    data={formulacao}
                    dataMisc={formulacaoMisc}
                    calculando={calculando}
                    dolar={formulacao.dolar}
                />
            </Grid>
        </Grid>
    );
}
