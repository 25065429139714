import React, { useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { setToken } from "../../flux/actions/tokenIndex";
import { setUser } from "../../flux/actions/userIndex";
import api from "../../services/api";

import Loader from "react-loader-spinner";


const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(3),
  },
  button: {
    margin: theme.spacing(1, 1, 0, 0),
  },
}));

function InfoPedido(props) {
  const classes = useStyles();

  const [infoPedido, setInfoPedido] = useState({})
  const [cliente, setCliente] = useState('')

  useEffect(() => {
    async function fetchMyAPI() {
      const response = await api.get("/getDataPedido", {
        params: {
          docEntry: props.match.params.id,
          token: props.match.params.token,
        }
      });

      if ((response.data === 'NOK') || (response.data === 'NOK1') || (response.data === 'NOK2')) {
        alert('Erro ao encontrar informações de faturamento')
      }
      else {
        console.log(response.data)
        setInfoPedido(response.data)
        setCliente(response.data[0].Cliente)
      }
    }
    fetchMyAPI();

  }, [])



  return (
    <div style={{ height: 2000, marginLeft: 25 , overflowX: 'auto'}} >

      <div>

        <GridContainer>
          <GridItem>
            <p> Nome : {cliente}</p>
          </GridItem>
        </GridContainer>
        <GridContainer >
          <GridItem>
            <div style={{  marginTop: 20, borderStyle: 'solid' }} >
              {infoPedido !== '' ? <table id="table-to-xls"  >
                <thead>

                  <tr style={{ textAlign: "center", border: "1px solid " }}>
                    <th style={{ textAlign: "center", border: "1px solid " }}>
                      DocPedido
                    </th>
                    <th style={{ textAlign: "center", border: "1px solid " }}>
                      NumeroPedidoGen
                    </th>
                    <th style={{ textAlign: "center", border: "1px solid " }}>
                      DataFaturamento
                    </th>
                    <th style={{ textAlign: "center", border: "1px solid " }}>
                      Item
                    </th>
                    <th style={{ textAlign: "center", border: "1px solid " }}>
                      QtdPedido
                    </th>
                    <th style={{ textAlign: "center", border: "1px solid " }}>
                      Faturado
                    </th>
                    <th style={{ textAlign: "center", border: "1px solid " }}>
                      Saldo
                    </th>
                    <th style={{ textAlign: "center", border: "1px solid " }}>
                      Placa
                    </th>
                    <th style={{ textAlign: "center", border: "1px solid " }}>
                      Cliente
                    </th>
                    <th style={{ textAlign: "center", border: "1px solid " }}>
                      CidadePedido
                    </th>
                    <th style={{ textAlign: "center", border: "1px solid " }}>
                      EstadoPedido
                    </th>
                    <th style={{ textAlign: "center", border: "1px solid " }}>
                      FazendaPedido
                    </th>
                    <th style={{ textAlign: "center", border: "1px solid " }}>
                      NFVenda
                    </th>
                    <th style={{ textAlign: "center", border: "1px solid " }}>
                      Embalagem
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {infoPedido !== '' ?
                    Object.keys(infoPedido).map((item, index) => (

                      <tr key={index}>
                        <td style={{ width: 300, textAlign: "center", border: "1px solid ", width: 100 }}>
                          {infoPedido[item].DocPedido}
                        </td>
                        <td style={{ width: 300, textAlign: "center", border: "1px solid ", width: 100 }}>
                          {infoPedido[item].NumeroPedidoGen}
                        </td>
                        <td style={{ width: 300, textAlign: "center", border: "1px solid ", width: 100 }}>
                          {infoPedido[item].DataFaturamento}
                        </td>
                        <td style={{ width: 300, textAlign: "center", border: "1px solid ", width: 100 }}>
                          {infoPedido[item].Item}
                        </td>
                        <td style={{ width: 300, textAlign: "center", border: "1px solid ", width: 100 }}>
                          {infoPedido[item].QtdPedido}
                        </td>
                        <td style={{ width: 300, textAlign: "center", border: "1px solid ", width: 100 }}>
                          {infoPedido[item].Faturado}
                        </td>
                        <td style={{ width: 300, textAlign: "center", border: "1px solid ", width: 100 }}>
                          {infoPedido[item].Saldo}
                        </td>
                        <td style={{ width: 300, textAlign: "center", border: "1px solid ", width: 100 }}>
                          {infoPedido[item].Placa}
                        </td>
                        <td style={{ width: 300, textAlign: "center", border: "1px solid ", width: 100 }}>
                          {infoPedido[item].Cliente}
                        </td>
                        <td style={{ width: 300, textAlign: "center", border: "1px solid ", width: 100 }}>
                          {infoPedido[item].CidadePedido}
                        </td>
                        <td style={{ width: 300, textAlign: "center", border: "1px solid ", width: 100 }}>
                          {infoPedido[item].EstadoPedido}
                        </td>
                        <td style={{ width: 300, textAlign: "center", border: "1px solid ", width: 100 }}>
                          {infoPedido[item].FazendaPedido}
                        </td>
                        <td style={{ width: 300, textAlign: "center", border: "1px solid ", width: 100 }}>
                          {infoPedido[item].NFVenda}
                        </td>
                        <td style={{ width: 300, textAlign: "center", border: "1px solid ", width: 100 }}>
                          {infoPedido[item].Embalagem}
                        </td>
                      </tr>

                    )) : <div></div>}
                </tbody>
              </table> : <Loader
                type="Circles"
                color="#00BFFF"
                height={20}
                width={20}
                timeout={20000} //3 secs
              />}

            </div>
          </GridItem>

        </GridContainer>
      </div>
    </div>
  );
}
function mapStateToProps(state) {
  return {
    token: state.token,
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setToken: setToken,
      setUser: setUser,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(InfoPedido);
