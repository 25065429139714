import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { setToken } from "../../flux/actions/tokenIndex";
import { setUser } from "../../flux/actions/userIndex";
import api from "../../services/api";
import { checarPermissao, validateTokenUser, getUser, getToken, orderObjArray } from '../../utils/utils.js'
import { TextField } from "@material-ui/core";
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import useStyles from "./styles";
import Loader from "react-loader-spinner";
import IconButton from '@material-ui/core/IconButton';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { Chart } from 'react-google-charts';
import Button from "components/CustomButtons/Button.js";

var asc = false
var column_sort = 'Estimado'
function Necessidades(props) {
    const [dados, setDados] = useState('')
    const [auxDados, setAuxDados] = useState('')

    const [acao, setAcao] = useState('')
    const [produto, setProduto] = useState('')
    const [qtde, setQtde] = useState('')
    const [enviando, setEnviando] = useState(false)
    const [dadosUpdate, setDadosUpdate] = useState('')
    let slider = document.getElementById('slider')
    const [pressed, setPressed] = useState(false)
    const [startx, setStartx] = useState(0)
    const [scrollLeft, setScrollLeft] = useState(0)
    const [registros, setRegistros] = useState([])
    const [filtro, setFiltro] = useState('')
    const [somatorio, setSomatorio] = useState('')
    const [abrirNegocio, setAbrirNegocio] = useState(false)
    const [plus, setPlus] = useState('')
    const [info, setInfo] = useState('')
    const [abrirNegocioInfo, setAbrirNegocioInfo] = useState(false)
    const [estoques, setEstoques] = useState('')
    const [abrirNegocioEst, setAbrirNegocioEst] = useState(false)
    const [last_update, setLast_update] = useState('')
    const classes = useStyles()
    const [gb, setGB] = useState('')
    const [estoquesP, setEstoquesP] = useState('')
    const [negocioNovo, setNegocioNovo] = useState(false)
    const [tabelaGrupos, setTabelaGrupos] = useState('')
    const [grupoAtual, setGrupoAtual] = useState('')

    const handle = useFullScreenHandle();
    useEffect(() => {
        async function fetchMyAPI() {


            const response2 = await api.get('/get_tabela_grupos', { params: { userId: getUser(), token: getToken() } })
            setTabelaGrupos(response2.data)


            const response = await api.get('/return_necessidades_teste', { params: { userId: getUser(), token: getToken() } })
            setDados(handleOrder(column_sort, response.data))
            setAuxDados(handleOrder(column_sort, response.data))
           
            const response_hora = await api.get('/return_necessidades_last_update', { params: { userId: getUser(), token: getToken() } })
            setLast_update(response_hora.data)
            
            // const response33 = await api.get('/return_necessidades_teste_true_false', { params: { userId: getUser(), token: getToken(), dados: JSON.stringify(response.data) } })
            // setDadosUpdate(response33.data)

            // const response_registro = await api.get('/return_registro_teste', { params: { userId: getUser(), token: getToken() } })
            // setRegistros(response_registro.data)

            // let count = 0
            // let falcatrua_dados = response.data
            // setInterval(async function () {
            //     const response = await api.get('/return_necessidades_teste', { params: { userId: getUser(), token: getToken() } })

            //     const respose_somatorio = await api.get('/return_necessidades_sum', { params: { userId: getUser(), token: getToken() } })
            //     setSomatorio(respose_somatorio.data)
            //     console.log(respose_somatorio.data)
            //     const response_registro = await api.get('/return_registro_teste', { params: { userId: getUser(), token: getToken() } })
            //     setRegistros(response_registro.data)

            //     if (count !== 0) {
            //         const response33 = await api.get('/return_necessidades_teste_true_false', { params: { userId: getUser(), token: getToken(), dados: JSON.stringify(falcatrua_dados) } })
            //         setDadosUpdate(response33.data)
            //     } else {
            //         const response33 = await api.get('/return_necessidades_teste_true_false', { params: { userId: getUser(), token: getToken(), dados: JSON.stringify(response.data) } })
            //         setDadosUpdate(response33.data)
            //     }

            //     count = 1
            //     falcatrua_dados = response.data
            //     setDados(handleOrder(column_sort, response.data))
            // }, 5000);
        }
        fetchMyAPI();
    }, [])

    const sendAction = async () => {
        setEnviando(true)
        const response = await api.get('/modifica_necessidades_teste', { params: { 'operacao': acao, produto, qtde, userId: getUser(), token: getToken() } })
        setEnviando(false)
    }

    function mouseDown(e) {
        if (slider !== null) {
            setPressed(true)
            setStartx(e.pageX - slider.offsetLeft)
            setScrollLeft(slider.scrollLeft)
        }
    }

    function mouseMove(e) {
        if (!pressed) { return }
        if (slider !== null) {
            e.preventDefault()
            let x = e.pageX - slider.offsetLeft
            let walk = x - startx
            slider.scrollLeft = scrollLeft - walk * 1
        }
    }
    function handleOrder(column, place = 0) {
        if (place != 0) {
            return orderObjArray(place, column, asc)

        }
        else {
            if (column === column_sort) {
                asc = !asc

            } else {
                asc = false
            }
            column_sort = column
            let objString = JSON.stringify((orderObjArray(dados, column, asc)));
            let objAgain = JSON.parse(objString);
            setDados(objAgain)
            setAuxDados(objAgain)

        }

    }


    async function handleClickOpenInfo(docPedido, item) {

        const response = await api.get('/return_dados_pedido', { params: { docPedido, item, userId: getUser(), token: getToken() } })
        setInfo(response.data)

        setAbrirNegocioInfo(true);
    };
    const handleClickCloseInfo = () => {
        setAbrirNegocioInfo(false);
    };
    async function handleClickOpenEst(item) {

        const response = await api.get('/estoques_tempo_real_teste', { params: { item, userId: getUser(), token: getToken() } })
        setEstoques(response.data)

        setAbrirNegocioEst(true);
    };
    const handleClickCloseEst = () => {
        setAbrirNegocioEst(false);
    };

    async function handleClickOpen(item, tipo) {
        if (tipo === 'vendas') {
            const response = await api.get('/return_pedidos_abertos', { params: { item, userId: getUser(), token: getToken() } })
            setPlus(response.data)
        }

        setAbrirNegocio(true);
    };

    async function returnEstoques(item) {

        const response = await api.get('/return_estoques_cidades', { params: { item, userId: getUser(), token: getToken() } })
        setEstoquesP(response.data)
        const response_gb = await api.get('/return_estoques_cidades_gb', { params: { item, userId: getUser(), token: getToken() } })
        let array_formulas = [["Produto", "Kg"]]
        Object.keys(response_gb.data).map((item_produto, index_produto) =>

            array_formulas.push([response_gb.data[item_produto].Cidade, response_gb.data[item_produto].qtde])
        )
        setGB(array_formulas)


        setNegocioNovo(true);
    };

    async function getTabelaGrupos() {
        const response_data = await api.get('/get_tabela_grupos', { params: { token: getToken() } })
        setTabelaGrupos(response_data)
    }

    async function atualizar() {
        const response_hora = await api.get('/return_necessidades_last_update', { params: { userId: getUser(), token: getToken() } })

        if (response_hora.data === last_update) {
            alert('Sem novas atualizações')
            return
        }

        setDados('')
        const response = await api.get('/return_necessidades_teste', { params: { userId: getUser(), token: getToken() } })
        setDados(handleOrder(column_sort, response.data))
        setAuxDados(handleOrder(column_sort, response.data))
        setLast_update(response_hora.data)

        // const response33 = await api.get('/return_necessidades_teste_true_false', { params: { userId: getUser(), token: getToken(), dados: JSON.stringify(response.data) } })
        // setDadosUpdate(response33.data)

        // const response_registro = await api.get('/return_registro_teste', { params: { userId: getUser(), token: getToken() } })
        // setRegistros(response_registro.data)

        // let count = 0
        // let falcatrua_dados = response.data
        // setInterval(async function () {
        //     const response = await api.get('/return_necessidades_teste', { params: { userId: getUser(), token: getToken() } })

        //     const respose_somatorio = await api.get('/return_necessidades_sum', { params: { userId: getUser(), token: getToken() } })
        //     setSomatorio(respose_somatorio.data)
        //     console.log(respose_somatorio.data)
        //     const response_registro = await api.get('/return_registro_teste', { params: { userId: getUser(), token: getToken() } })
        //     setRegistros(response_registro.data)

        //     if (count !== 0) {
        //         const response33 = await api.get('/return_necessidades_teste_true_false', { params: { userId: getUser(), token: getToken(), dados: JSON.stringify(falcatrua_dados) } })
        //         setDadosUpdate(response33.data)
        //     } else {
        //         const response33 = await api.get('/return_necessidades_teste_true_false', { params: { userId: getUser(), token: getToken(), dados: JSON.stringify(response.data) } })
        //         setDadosUpdate(response33.data)
        //     }

        //     count = 1
        //     falcatrua_dados = response.data
        //     setDados(handleOrder(column_sort, response.data))
        // }, 5000);
    }
    const filterByKey = (key, str) => {

        setDados(auxDados.filter(item => item[key].includes(str)))
    };

    const handleClickClose = () => {
        setNegocioNovo(false);
    };

    const handleFilter = (str) => {
        let array = []
        const tabelaGruposFiltrada = tabelaGrupos.filter((row) => row.Grupo === str);
        tabelaGruposFiltrada.forEach(item => {
            const itemCode = item.ItemCode;
            array = array.concat(auxDados.filter(item => item["ItemCode"].includes(itemCode.toString())))
            //setDados(auxDados.filter(item => item["ItemCode"].includes(itemCode.toString())))
        })
        setDados(array)
    };
    const grupoAtivo = (str) => {
        setGrupoAtual(str)
        console.log(grupoAtual)
    };


    return (
        <div>
            <Paper className={classes.bigPaper}>


                <Paper elevation={3} >
                    <div>
                        <p> Ultimo Update da base de dados:{last_update}</p>
                        {dados === '' ? <Loader></Loader> :
                            <div>
                                {/* Div todos os botoes da pagina, atualizar, filtro por botoes, filtro por texto... */}
                                {/* funcao grupoAtivo adicionada para alterar as cores dos botoes quando selecionado... */}
                                <div style={{ marginBottom: 10 }}>

                                    {/* Div botoes de filtro fosfatado, cloreto, nitrogenados... */}
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <div>
                                            <Button style={{ marginLeft: 10, width: '150px', background: grupoAtual === 'Aditivos' ? '#A3CC51' : 'linear-gradient(45deg, #2196F3 10%, #21CBF3 90%)' }} onClick={() => (handleFilter('Aditivos'), grupoAtivo('Aditivos'))} variant="contained" > Aditivos</Button>
                                            <Button style={{ marginLeft: 10, width: '150px', background: grupoAtual === 'Formulados' ? '#A3CC51' : 'linear-gradient(45deg, #2196F3 10%, #21CBF3 90%)' }} onClick={() => (handleFilter('Formulados'), grupoAtivo('Formulados'))} variant="contained" > Formulados</Button>
                                            <Button style={{ marginLeft: 10, width: '150px', background: grupoAtual === 'Micro' ? '#A3CC51' : 'linear-gradient(45deg, #2196F3 10%, #21CBF3 90%)' }} onClick={() => (handleFilter('Micro'), grupoAtivo('Micro'))} variant="contained" > Micro</Button>
                                        </div>
                                        <div>

                                            <Button style={{ marginLeft: 10, width: '150px', background: grupoAtual === 'NPK' ? '#A3CC51' : 'linear-gradient(45deg, #2196F3 10%, #21CBF3 90%)' }} onClick={() => (handleFilter('NPK'), grupoAtivo('NPK'))} variant="contained" > NPK</Button>
                                            <Button style={{ marginLeft: 10, width: '150px', background: grupoAtual === 'NPS' ? '#A3CC51' : 'linear-gradient(45deg, #2196F3 10%, #21CBF3 90%)' }} onClick={() => (handleFilter('NPS'), grupoAtivo('NPS'))} variant="contained" > NPS</Button>
                                       
                                       

                                            <Button style={{ marginLeft: 10, width: '150px', background: grupoAtual === 'Fosfatados' ? '#A3CC51' : 'linear-gradient(45deg, #2196F3 10%, #21CBF3 90%)' }} onClick={() => (handleFilter('Fosfatados'), grupoAtivo('Fosfatados'))} variant="contained" > Fosfatados</Button>
                                            </div>

                                        <div>
                                            <Button style={{ marginLeft: 10, width: '150px', background: grupoAtual === 'Nitrogenados' ? '#A3CC51' : 'linear-gradient(45deg, #2196F3 10%, #21CBF3 90%)' }} onClick={() => (handleFilter('Nitrogenados'), grupoAtivo('Nitrogenados'))} variant="contained" > Nitrogenados</Button>
                                            <Button style={{ marginLeft: 10, width: '150px', background: grupoAtual === 'NPKS' ? '#A3CC51' : 'linear-gradient(45deg, #2196F3 10%, #21CBF3 90%)' }} onClick={() => (handleFilter('NPKS'), grupoAtivo('NPKS'))} variant="contained" > NPKS</Button>
                                            <Button style={{ marginLeft: 10, width: '150px', background: grupoAtual === 'Cloreto' ? '#A3CC51' : 'linear-gradient(45deg, #2196F3 10%, #21CBF3 90%)' }} onClick={() => (handleFilter('Cloreto'), grupoAtivo('Cloreto'))} variant="contained" > Cloreto</Button>
                                        </div>
                                    </div>
                                    {/* Fim da Div botoes de filtro fosfatado, cloreto, nitrogenados... */}

                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <div>
                                            <Button style={{ marginLeft: 10, height: '55px', width: '150px' }} onClick={() => (atualizar())}> Atualizar</Button>
                                        </div>
                                        <div >
                                            <TextField
                                                variant='outlined'
                                                label='Filtro Produto'
                                                style={{ marginTop: 5, marginLeft: 10, width: '310px' }}
                                                onChange={(e) => (filterByKey("ItemName", e.target.value))}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {/* Fim da DIV de botoesda pagina, atualizar, filtro por botoes, filtro por texto...  */}

                                <TableContainer id="slider"
                                    style={{ height: 400 }}>
                                    <Table stickyHeader sx={{ minWidth: 650 }} size="small" >
                                        <TableHead >
                                            <TableRow >

                                                <TableCell style={{ backgroundColor: 'black', color: 'white' }} width={70} onClick={() => (handleOrder('ItemCode'))}>Produto</TableCell>
                                                <TableCell style={{ backgroundColor: 'black', color: 'white' }} width={100} onClick={() => (handleOrder('ItemName'))}>Nome Produto</TableCell>

                                                <TableCell style={{ backgroundColor: 'black', color: 'white' }} width={100} onClick={() => (handleOrder('estoque3'))}>Fertiman</TableCell>
                                                <TableCell style={{ backgroundColor: 'black', color: 'white' }} width={100} onClick={() => (handleOrder('estoque110'))}>Fabrica BJ</TableCell>
                                                <TableCell style={{ backgroundColor: 'black', color: 'white' }} width={100} onClick={() => (handleOrder('estoqued'))}>Estoque Disponível</TableCell>
                                                <TableCell style={{ backgroundColor: 'black', color: 'white' }} width={100} onClick={() => (handleOrder('estoquet'))}>Estoque Transito</TableCell>
                                                <TableCell style={{ backgroundColor: 'black', color: 'white' }} width={100} onClick={() => (handleOrder('Carteira'))}>Carteira</TableCell>
                                                <TableCell style={{ backgroundColor: 'black', color: 'white' }} width={100} onClick={() => (handleOrder('compra'))}>Compras</TableCell>
                                                <TableCell style={{ backgroundColor: 'black', color: 'white' }} width={100} onClick={() => (handleOrder('Estimado'))}>Estimado</TableCell>
                                                <TableCell style={{ backgroundColor: 'black', color: 'white' }} width={100} >Mais informações</TableCell>

                                            </TableRow>
                                        </TableHead>

                                        <TableBody >
                                            {Object.keys(dados).map((row, index) => (
                                                <TableRow id={index} key={index} style={{ backgroundColor: index % 2 === 0 ? 'white' : 'rgb(233, 233, 233)' }} >
                                                    <TableCell >{dados[index].ItemCode} </TableCell>

                                                    <TableCell >{dados[index].ItemName}</TableCell>

                                                    <TableCell
                                                    >
                                                        {dados[index].estoque3}

                                                    </TableCell>
                                                    <TableCell
                                                    >
                                                        {dados[index].estoque110}
                                                    </TableCell>

                                                    <TableCell
                                                    >
                                                        <div style={{ display: 'flex', flexDirection: 'row' }}>

                                                            {dados[index].estoqued}

                                                        </div>

                                                    </TableCell>
                                                    <TableCell
                                                    >
                                                        {dados[index].estoquet}
                                                    </TableCell>

                                                    <TableCell
                                                    >
                                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                            {dados[index].Carteira}

                                                        </div>
                                                    </TableCell>

                                                    <TableCell
                                                    >
                                                        {dados[index].compra}
                                                    </TableCell>



                                                    <TableCell
                                                        style={{ backgroundColor: dados[index].Estimado >= 0 ? 'lightgreen' : 'lightsalmon' }}>
                                                        {dados[index].Estimado}
                                                    </TableCell>
                                                    <TableCell >
                                                        <IconButton
                                                            color="inherit"
                                                            aria-label="open drawer"
                                                            onClick={() => returnEstoques(dados[index].ItemCode)}
                                                        >
                                                            <AddCircleOutlineIcon />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>




                                {/* <TableContainer id="slider" style={{ borderWidth: 1, borderStyle: 'double' }}>
                                    <Table stickyHeader sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                                        <TableHead>
                                            <TableRow>

                                                <TableCell width={60}> Somatório</TableCell>
                                                <TableCell width={150}><div style={{ width: 150 }}></div></TableCell>
                                                <TableCell width={100} align='left'>{somatorio.fertiman}</TableCell>
                                                <TableCell width={100} align='left'>{somatorio.fabricaBJ}</TableCell>
                                                <TableCell width={100} align='left'>{somatorio.estoqueD}</TableCell>
                                                <TableCell width={100} align='left'>{somatorio.transito}</TableCell>
                                                <TableCell width={100} align='left'>{(somatorio.vendas + somatorio.vendasDesc).toFixed(3)}</TableCell>
                                                <TableCell width={100} align='left'>{somatorio.compras_vista}</TableCell>
                                                <TableCell width={100} align='left'>{somatorio.compras_prazo}</TableCell>
                                                <TableCell width={100} align='left'>{somatorio.Estimado}</TableCell>

                                            </TableRow>
                                        </TableHead>

                                    </Table>
                                </TableContainer> */}
                            </div>}
                    </div>
                </Paper>
            </Paper>
            {/* <div style={{ height: 400, display: 'flex', flexDirection: 'row' }}>
                <Paper elevation={3} className={classes.topPaper}>
                    <TextField
                        style={{ width: 300, marginTop: 20 }}

                        variant='outlined'
                        label='Filtro'
                        onChange={(e) => setFiltro(e.target.value)}
                    />
                </Paper>

                <Paper elevation={3} className={classes.histPaper}>
                    <h4>Histórico</h4>
                    {registros.map((reg, index) =>
                        reg.operacao.includes(filtro) ?
                            <Paper key={index} elevation={3} className={classes.reg}>
                                {reg.data}
                                <div>{reg.operacao}</div>
                            </Paper> : null)}
                </Paper>
            </div> */}
            {/* <FullScreen handle={handle}>
                <Paper style={{

                    padding: 20,
                    width: '90vw',
                    height: '800vh',

                }}>
                    <button onClick={handle.exit}>
                        exit fullscreen
                    </button>
                    <Paper elevation={3} >
                        <div>
                            {somatorio === '' ? <Loader></Loader> :
                                <div>

                                    <TableContainer id="slider"
                                        style={{ height: '60vh' }}>
                                        <Table stickyHeader sx={{ minWidth: 650 }} size="small" >
                                            <TableHead >
                                                <TableRow >

                                                    <TableCell style={{ backgroundColor: 'black', color: 'white' }} width={70} onClick={() => (handleOrder('produto'))}>Produto</TableCell>
                                                    <TableCell style={{ backgroundColor: 'black', color: 'white' }} width={150} onClick={() => (handleOrder('nome_produto'))}>Nome Produto</TableCell>
                                                    <TableCell style={{ backgroundColor: 'black', color: 'white' }} width={100} onClick={() => (handleOrder('fertiman'))}>Fertiman</TableCell>
                                                    <TableCell style={{ backgroundColor: 'black', color: 'white' }} width={100} onClick={() => (handleOrder('fabricaBJ'))}>Fabrica BJ</TableCell>
                                                    <TableCell style={{ backgroundColor: 'black', color: 'white' }} width={100} onClick={() => (handleOrder('estoqueD'))}>Estoque Disponível</TableCell>
                                                    <TableCell style={{ backgroundColor: 'black', color: 'white' }} width={100} onClick={() => (handleOrder('transito'))}>Estoque Transito</TableCell>
                                                    <TableCell style={{ backgroundColor: 'black', color: 'white' }} width={100} onClick={() => (handleOrder('vendas'))}>Carteira</TableCell>
                                                    <TableCell style={{ backgroundColor: 'black', color: 'white' }} width={100} onClick={() => (handleOrder('compras_vista'))}>Compras vista</TableCell>
                                                    <TableCell style={{ backgroundColor: 'black', color: 'white' }} width={100} onClick={() => (handleOrder('compras_prazo'))}>Compras Prazo</TableCell>
                                                    <TableCell style={{ backgroundColor: 'black', color: 'white' }} width={100} onClick={() => (handleOrder('Estimado'))}>Estimado</TableCell>

                                                </TableRow>
                                            </TableHead>

                                            <TableBody >
                                                {Object.keys(dados).map((row, index) => (
                                                    <TableRow id={index} key={index} style={{ backgroundColor: index % 2 === 0 ? 'white' : 'rgb(233, 233, 233)' }} >

                                                        <TableCell >{dados[index].produto}</TableCell>
                                                        <TableCell>{dados[index].nome_produto}</TableCell>
                                                        <TableCell
                                                        >
                                                            {dados[index].fertiman}
                                                        </TableCell>
                                                        <TableCell
                                                        >
                                                            {dados[index].fabricaBJ}
                                                        </TableCell>

                                                        <TableCell
                                                        >
                                                            {dados[index].estoqueD}
                                                        </TableCell>
                                                        <TableCell
                                                        >
                                                            {dados[index].transito}
                                                        </TableCell>
                                                        <TableCell
                                                        >
                                                            {(dados[index].vendas + dados[index].vendasDesc).toFixed(3)}
                                                        </TableCell>

                                                        <TableCell
                                                        >
                                                            {dados[index].compras_vista}
                                                        </TableCell>

                                                        <TableCell
                                                        >
                                                            {dados[index].compras_prazo}
                                                        </TableCell>


                                                        <TableCell
                                                            style={{ backgroundColor: dados[index].Estimado >= 0 ? 'lightgreen' : 'lightsalmon' }}>
                                                            {dados[index].Estimado}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>




                                    <TableContainer id="slider" style={{ borderWidth: 1, borderStyle: 'double' }}>
                                        <Table stickyHeader sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                                            <TableHead>
                                                <TableRow>

                                                    <TableCell width={60}> Somatório</TableCell>
                                                    <TableCell width={150}><div style={{ width: 150 }}></div></TableCell>
                                                    <TableCell width={100} align='left'>{somatorio.fertiman}</TableCell>
                                                    <TableCell width={100} align='left'>{somatorio.fabricaBJ}</TableCell>
                                                    <TableCell width={100} align='left'>{somatorio.estoqueD}</TableCell>
                                                    <TableCell width={100} align='left'>{somatorio.transito}</TableCell>
                                                    <TableCell width={100} align='left'>{(somatorio.vendas + somatorio.vendasDesc).toFixed(3)}</TableCell>
                                                    <TableCell width={100} align='left'>{somatorio.compras_vista}</TableCell>
                                                    <TableCell width={100} align='left'>{somatorio.compras_prazo}</TableCell>
                                                    <TableCell width={100} align='left'>{somatorio.Estimado}</TableCell>

                                                </TableRow>
                                            </TableHead>

                                        </Table>
                                    </TableContainer> </div>}
                        </div>
                    </Paper>
                </Paper>
            </FullScreen> */}

            {negocioNovo !== false ?


                <Dialog fullScreen open={negocioNovo} onClose={handleClickClose} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Mais informações</DialogTitle>
                    <DialogContent >
                        <Chart chartType="PieChart" data={gb} options={{
                            backgroundColor: '#FAFCF6',
                            fontName: 'Poppins',
                            fontSize: 18,
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            title: 'Locais Estoques:',
                            is3D: true
                        }} width={"70vw"} height={"70vh"} />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClickClose} color="primary">
                            Voltar
                        </Button>

                    </DialogActions>
                </Dialog>
                : null}


        </div>
    )
}


function mapStateToProps(state) {
    return {
        token: state.token,
        user: state.user,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            setToken: setToken,
            setUser: setUser,
        },
        dispatch
    );
}


export default connect(mapStateToProps, mapDispatchToProps)(Necessidades);