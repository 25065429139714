import React, { useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { setToken } from "../../flux/actions/tokenIndex";
import { setUser } from "../../flux/actions/userIndex";
import api from "../../services/api";

import Button from '@material-ui/core/Button';
import { TextField } from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(3),
    },
    button: {
        margin: theme.spacing(1, 1, 0, 0),
    },
}));

function ResetPasword(props) {
    const classes = useStyles();
    const [password, setPassword] = useState('');
    const [valid, setValid] = useState(true)

    useEffect(() => {
        async function fetchMyAPI() {
            const response = await api.get("/validatePassChange", {
                params: {
                    token: props.match.params.token,
                    codUser: props.match.params.uid
                }
            });

            if ((response.data === 'NOK') || (response.data === 'NOK1') || (response.data === 'NOK2')) {
                alert('Erro ao encontrar solicitação')
                setValid(false)
            }
            else if ((response.data === 'NOK3')) {
                alert('Alteração de senha já realizada')
                setValid(false)
            }
            else {
                alert('Digite sua nova senha ' + response.data.nome)
                setUser(response.data.nome)
            }
        }
        fetchMyAPI();

    }, [])

    async function alteraSenha() {
        const response = await api.get("/AlteraSenha", {
            params: {
                codUser: props.match.params.uid, password
            }
        });
        if (response.data === 'OK') {
            alert('Senha alterada com sucesso')
            window.location.reload()
        }
    }

    return (
        <div style={{ height: 500, marginLeft: 2, width: 600, overflowX: 'auto', backgroundColor: 'white', borderStyle: 'solid', borderRadius: 20, padding: 5, margin: 'auto',alignItems:'center' }}>
            {valid === true ?
                <div style = {{display:'flex',flexDirection:'column',marginTop:30,alignItems:'center'}}>
                    <TextField outlined placeholder='digite aqui sua nova senha' type="password" style={{ width: 200, marginBottom: 50 }} onChange={(e) => setPassword(e.target.value)} />
                    <Button style={{ backgroundColor: '#6699ff' }} variant="contained" onClick={() => alteraSenha()}>Alterar Senha</Button>
                </div>
                : <h3> Solicitação já realizada</h3>}

        </div >
    );
}
function mapStateToProps(state) {
    return {
        token: state.token,
        user: state.user,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            setToken: setToken,
            setUser: setUser,
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasword);
