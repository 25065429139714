import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { setToken } from "../../flux/actions/tokenIndex";
import { setUser } from "../../flux/actions/userIndex";
import api from "../../services/api";
import { checarPermissao, validateTokenUser, getUser, getToken } from '../../utils/utils.js'
import { TextField } from "@material-ui/core";
import GrupoEmail from "./GrupoEmail";
import { makeStyles } from "@material-ui/core/styles";

function ConfigurarLogistica(props) {
    const [permitido, setPermitido] = useState(false)
    const [listaArmazens, setListaArmazens] = useState([])

    const [armazem, setArmazem] = useState('')

    const [adicionando, setAdicionando] = useState(false)
    const [removendo, setRemovendo] = useState(false)
    const classes = useStyles()

    useEffect(() => {
        async function fetchMyAPI() {
            await validateTokenUser(props)
            setPermitido(await checarPermissao('configurarLogistica', props))

            const armazens = await api.get('/listaArmazens', { params: { userId: getUser(), token: getToken() } })
            setListaArmazens(armazens.data)
        }
        fetchMyAPI();
    }, [])


    async function adicionarArmazem() {
        setAdicionando(true)
        const response = await api.get('/adicionarArmazem', { params: { armazem, userId: getUser(), token: getToken() } })
        if (response.data === 'NOK') {
            alert('Erro ao adicionar...')
        } else {
            alert('Armazém adicionado!')
            window.location.reload()
        }
        setAdicionando(false)
    }

    async function removerArmazem(id) {
        setRemovendo(true)
        const response = await api.get('/removerArmazem', { params: { id, userId: getUser(), token: getToken() } })
        if (response.data === 'NOK') {
            alert('Erro ao remover...')
        } else {
            alert('Armazém removido!')
            window.location.reload()
        }
        setRemovendo(false)
    }

    return (
        permitido ?
            <div className={classes.container}>
                <h3 style={{ fontWeight: 'bold' }}>
                    Lista de Armazéns
                </h3>
                <div className={classes.screen}>

                    <h4 style={{ fontWeight: 'bold' }}>Armazéms</h4>
                    <div style={{ display: 'flex', flexDirection: 'row', width: 'fit-content' }}>
                        <div style={{ overflow: 'scroll', height: 400, fontWeight: 'bold' }}>
                            <table>
                                <tbody>
                                    {Object.keys(listaArmazens).map((item, index) =>
                                        <tr key={index}>
                                            <td><button disabled={removendo} onClick={() => removerArmazem(listaArmazens[item].id)}>Remover</button></td>
                                            <td>{listaArmazens[item].armazem}</td>
                                        </tr>)}
                                </tbody>
                            </table>
                        </div>


                        <div className={classes.armazem}>
                            <h4 style={{ fontWeight: 'bold' }}>Adicionar Armazém</h4>
                            <TextField label='Nome do armazém' variant="outlined" onChange={(e) => setArmazem(e.target.value)}>Nome do armazém</TextField>

                            <div style={{ marginTop: 5, textAlign: 'center' }}>
                                {armazem === '' ? <></> : <button disabled={adicionando} onClick={() => adicionarArmazem()}>Adicionar</button>}
                            </div>

                        </div>
                    </div>
                </div>


                <GrupoEmail />
            </div>
            : <></>
    )
}


const useStyles = makeStyles({
    container: {
        padding: '20px 20px 150px 20px'
    },

    screen: {
        padding: 10,
        background: 'white',
        boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px;'
    },

    armazem: {
        padding: 10,
        display: 'flex',
        flexDirection: 'column'
    }
})



function mapStateToProps(state) {
    return {
        token: state.token,
        user: state.user,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            setToken: setToken,
            setUser: setUser,
        },
        dispatch
    );
}


export default connect(mapStateToProps, mapDispatchToProps)(ConfigurarLogistica);