import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { setToken } from "../../flux/actions/tokenIndex";
import { setUser } from "../../flux/actions/userIndex";
import api from "../../services/api";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from "components/CustomButtons/Button.js";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { TextField } from "@material-ui/core";
import { checarPermissao, getUser, getToken, validateTokenUser } from '../../utils/utils.js'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Loader from 'react-loader-spinner';
import { Person as PersonIcon, AccessTime as AccessTimeIcon, CheckCircle as CheckCircleIcon, Cancel as CancelIcon, DateRange, CallSplit as CallSplitIcon } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(14),
        flexBasis: '25%',
        flexShrink: 0,
        display: 'flex',
        alignItems: 'center',
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(14),
        color: theme.palette.text.secondary,
    },
    statusText: {
        fontWeight: 'bold',
        display: 'flex',
        alignItems: 'center',
    },
    statusIcon: {
        marginRight: 8,
    },
    detailsSection: {
        display: 'flex',
        flexDirection: 'column',
        margin: '10px 0',
    },
    iconStyle: {
        fontSize: 20,
        marginRight: 8,
    },
    actionButton: {
        marginTop: 10,
    },
    dateAndDesmembramento: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
    },
    desmembramentoText: {
        textAlign: 'center',
        marginRight: '20px',
    },
    pendenteAprovacao: {
        color: '#878689',
        marginRight: '20px',
    },
    clientText: {
        marginRight: '20px',
    },
}));

function ValidaVenda(props) {
    const [pedidos, setPedidos] = useState('')
    const [wait, setWait] = useState(false)
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [indexR, setIndexR] = useState(0)
    const [msg, setMsg] = useState('')
    const [permitido, setPermitido] = useState(false)
    const [addFiador, setAddFiador] = useState(false)
    const [isLoading, setIsLoading] = useState(true);
    const [radioValues, setRadioValues] = useState([])

    useEffect(() => {
        async function fetchMyAPI() {
            setIsLoading(true); // Ativa o carregamento
            try {
                await validateTokenUser(props);
                setPermitido(await checarPermissao('validaVendas', props));
        
                const response_aguardando = await api.get("/PedidosAprovacaoHierarquia", { 
                    params: { userId: getUser(), token: getToken() } 
                });
        
                // Organize os pedidos como necessário
                const pedidosPendentes = response_aguardando.data.filter(pedido => pedido.addSAP === 0);
                const outrosPedidos = response_aguardando.data.filter(pedido => pedido.addSAP !== 0);
                const pedidosOrdenados = [...pedidosPendentes, ...outrosPedidos];
        
                setPedidos(pedidosOrdenados);
                setRadioValues(response_aguardando.data.map(() => ({ value: 0, options: [], addFiador: false })));
            } catch (error) {
                console.error("Erro ao buscar dados:", error);
            } finally {
                setIsLoading(false); // Desativa o carregamento após completar
            }
        }
        fetchMyAPI();
    }, []);

    async function inserePedido(pedido, aprov) {
        setWait(true)
        const response_aprova = await api.get("/AprovaPedidoHierarquia", { params: { pedido, aprov, msg, userId: getUser(), token: getToken() } });

        if (response_aprova.data === 'NOK') {
            alert('Erro ao aprovar pedido')
        } else if (response_aprova.data == 'OK') {
            alert('Pedido enviado para aprovação com sucesso!')
            window.location.reload();
        } else if (response_aprova.data == 'OKN') {
            alert('Pedido reprovado com sucesso!')
            window.location.reload();
        }
        setWait(false)
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    const handleRadioChange = (index, value) => {
        const newValues = [...radioValues];
        newValues[index] = { ...newValues[index], value: value, addFiador: true, fiadores: [] };
        setRadioValues(newValues);
    };

    const fetchOptions = async (index, codCliente) => {
        try {
            const response = await api.get('/get_fiador', { params: { cod_cliente: codCliente } })
            console.log(response.data)
            const updatedRadioValues = [...radioValues];
            updatedRadioValues[index] = {
                ...updatedRadioValues[index],
                options: response.data
            };
            setRadioValues(updatedRadioValues);
        } catch (error) {
            console.error("Error fetching options:", error);
        }
    };

    const handleAutocompleteChange = (index, fiador) => {
        let aux = { ...radioValues }
        aux[index].fiadores = [...aux[index].fiadores, fiador]
        console.log(aux)
    };

    const handleSaveFiador = async (argsVenda, index, idVenda) => {
        let fiadores = JSON.stringify(radioValues[index].fiadores)

        const updatedArgsVenda = {
            ...argsVenda,
            fiadores
        };

        try {
            const response = await api.post('/update_fiador', { idVenda, argsVenda: updatedArgsVenda });

            if (response.status === 200) {
                alert('Fiadores salvos com sucesso')
                console.log("Data successfully saved", response.data);
            } else {
                console.error("Error saving data:", response.data);
            }
        } catch (err) {
            alert('Erro ao salvar fiadores')
            console.error("API call failed:", err);
        }
    }


    const toggleAddFiador = (index) => {
        const updatedStates = [...radioValues];
        updatedStates[index] = { ...updatedStates[index], addFiador: !updatedStates[index].addFiador };
        setRadioValues(updatedStates);
    };

    return (
        isLoading ? (
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <Loader
                    type="Watch"
                    color="#00BFFF"
                    height={40}
                    width={40}
                />
                <p style={{ marginTop: '15x', fontSize: '16px', fontWeight: 'bold', color: '#555' }}>
                    Carregando...
                </p>
            </div>        ) : (
            permitido === true && (
            <div>
                <GridContainer style={{ padding: '20px', backgroundColor: '#f9f9f9', borderRadius: '8px' }}>
                {Object.keys(pedidos).map((index) =>

                        <Accordion style={{ width: '100%', marginBlock: '5px', border: '1px solid #e0e0e0', borderRadius: '8px' }} key={index} onChange={() => fetchOptions(index, pedidos[index].argsVenda.codCliente)}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id={index}>

                               <div className={classes.heading} style={{ display: 'flex', alignItems: 'center' }}>
                                    <PersonIcon className={classes.iconStyle} style={{ marginRight: '8px' }} />
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <Typography style={{ fontSize: '12px', opacity: 0.5 }}><strong>Cliente:</strong></Typography>
                                        <Typography className={classes.clientText} >{pedidos[index].argsVenda.cliente}</Typography>
                                    </div>
                                </div>
                                <div className={classes.statusText}>
                                    {pedidos[index].addSAP === 0 && (
                                        <>
                                            <AccessTimeIcon className={classes.statusIcon} style={{ fontSize: '17px', color: '#A9A9A9' }} />
                                            <Typography className={classes.pendenteAprovacao} style={{ color: 'A9A9A9' }}>Pedido Pendente de Aprovação</Typography>
                                        </>
                                    )}
                                    {pedidos[index].addSAP === 1 && (
                                    <>
                                        <CheckCircleIcon className={classes.statusIcon} style={{ fontSize: '17px', color: '#12824C' }} />
                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '5px' }}>
                                        <Typography className={classes.desmembramentoText} style={{ color: '#12824C' }}>
                                            Pedido Aprovado
                                        </Typography>
                                        {pedidos[index].numPedidoSAP !== 0 && (
                                            <Typography style={{ fontSize: '11px', color: '#4a4a4a', marginTop: '2px' }}>
                                            <strong>Nro. Pedido:</strong> {pedidos[index].numPedidoSAP}
                                            </Typography>
                                        )}
                                        </div>
                                    </>
                                    )}
                                    {pedidos[index].addSAP === 2 && (
                                        <>
                                            <AccessTimeIcon className={classes.statusIcon} style={{ fontSize: '17px', color: '#3e1078' }} />
                                            <Typography className={classes.desmembramentoText} style={{ color: '#3e1078' }}>Pendente de Aprov. Diretoria</Typography>
                                        </>
                                    )}
                                    {pedidos[index].addSAP === 3 && (
                                        <>
                                            <AccessTimeIcon className={classes.statusIcon} style={{ fontSize: '17px', color: 'skyblue' }} />
                                            <Typography className={classes.desmembramentoText} style={{ color: 'skyblue' }}>Pendente de Aprovação Logística</Typography>
                                        </>
                                    )}
                                    {pedidos[index].addSAP === -1 && (
                                        <>
                                            <CancelIcon className={classes.statusIcon} style={{ fontSize: '17px', color: '#DE3163' }} />
                                            <Typography className={classes.desmembramentoText} style={{ color: '#DE3163' }}>Pedido Recusado</Typography>
                                        </>
                                    )}
                                </div>

                                

                                <div className={classes.heading}>
                                    <DateRange className={classes.iconStyle} />
                                    <Typography><strong>Inserção:</strong> {pedidos[index].argsVenda.dataInsercao}</Typography>
                                </div>
                                <div className={classes.heading}>
                                   
                                    {pedidos[index].argsVenda.desmembramento === 'true' && (
                                    <div style={{ alignContent: 'center', textAlign: 'center', position: 'absolute', right: '7%' }}> <CallSplitIcon className={classes.iconStyle} /> Desmembramento do pedido {pedidos[index].argsVenda.pedidoMae}</div>

                                    )}
                                </div>
                            </AccordionSummary>
                            <div
                                style={{
                                margin: '10px',
                                border: '1px solid rgba(0, 0, 0, 0.1)',
                                padding: '10px',
                                borderRadius: '8px',
                                backgroundColor: '#fafafa',
                                }}
                            >                            
                            <AccordionDetails style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                            <Typography>
                                        Produto : {pedidos[index].argsVenda.nomeProduto}
                                    </Typography>
                                    <Typography>
                                        Quantidade: {pedidos[index].argsVenda.qtde}
                                    </Typography>
                                    <Typography>
                                        Preço : {pedidos[index].argsVenda.uni}{pedidos[index].argsVenda.moeda}
                                    </Typography>
                                    <Typography>
                                        Embalagem : {pedidos[index].argsVenda.emb}
                                    </Typography>
                                    <Typography>
                                        Total {pedidos[index].argsVenda.moeda}: {pedidos[index].argsVenda.totProd1}
                                    </Typography>
                                </AccordionDetails>
                                {pedidos[index].argsVenda.nomeProduto2 !== '' ?
                                    <AccordionDetails style={{ display: 'flex', flexDirection: 'column' }}>

                                        <Typography>
                                            Produto : {pedidos[index].argsVenda.nomeProduto2}
                                        </Typography>
                                        <Typography>
                                            Quantidade: {pedidos[index].argsVenda.qtde2}
                                        </Typography>
                                        <Typography>
                                            Preço : {pedidos[index].argsVenda.uni2} {pedidos[index].argsVenda.moeda}
                                        </Typography>
                                        <Typography>
                                            Embalagem : {pedidos[index].argsVenda.emb2}
                                        </Typography>
                                        <Typography>
                                            Total {pedidos[index].argsVenda.moeda}: {pedidos[index].argsVenda.totProd2}
                                        </Typography>
                                    </AccordionDetails> : null}
                                {pedidos[index].argsVenda.nomeProduto3 !== '' ?
                                    <AccordionDetails style={{ display: 'flex', flexDirection: 'column' }}>

                                        <Typography>
                                            Produto : {pedidos[index].argsVenda.nomeProduto3}
                                        </Typography>
                                        <Typography>
                                            Quantidade: {pedidos[index].argsVenda.qtde3}
                                        </Typography>
                                        <Typography>
                                            Preço : {pedidos[index].argsVenda.uni3}{pedidos[index].argsVenda.moeda}
                                        </Typography>
                                        <Typography>
                                            Embalagem : {pedidos[index].argsVenda.emb3}
                                        </Typography>
                                        <Typography>
                                            Total {pedidos[index].argsVenda.moeda}: {pedidos[index].argsVenda.totProd3}
                                        </Typography>
                                    </AccordionDetails> : null}

                                <AccordionDetails style={{ display: 'flex', flexDirection: 'column' }}>


                                    <Typography>
                                        Origem : {pedidos[index].argsVenda.selOrigem}
                                    </Typography>
                                    <Typography>
                                        Destino: {pedidos[index].argsVenda.selDestino}
                                    </Typography>
                                    <Typography>
                                        Inicio Entrega : {pedidos[index].argsVenda.iniDate}
                                    </Typography>
                                    <Typography>
                                        Fim Entrega: {pedidos[index].argsVenda.endDate}
                                    </Typography>
                                    <Typography>
                                        Tipo Frete : {pedidos[index].argsVenda.frete}
                                    </Typography>

                                </AccordionDetails>
                                <AccordionDetails style={{ display: 'flex', flexDirection: 'column' }}>

                                    <Typography>
                                        Cidade Entrega : {pedidos[index].argsVenda.cidadeEntrega}
                                    </Typography>
                                    <Typography>
                                        Estado Entrega : {pedidos[index].argsVenda.estadoEntrega}
                                    </Typography>
                                    {pedidos[index].argsVenda['insProd[]'] === undefined ?
                                        <Typography>
                                            Inscrição Estadual: {pedidos[index].argsVenda['insProdVal']}
                                        </Typography> :
                                        <Typography>
                                            Inscrição Estadual: {JSON.parse(pedidos[index].argsVenda['insProd[]']).TaxId1}
                                        </Typography>}
                                    <Typography>
                                        Cep Entrega : {pedidos[index].argsVenda.CEPEntrega}
                                    </Typography>
                                    <Typography>
                                        Endereço Entrega : {pedidos[index].argsVenda.endEntrega}
                                    </Typography>
                                    <Typography>
                                        Local Entrega : {pedidos[index].argsVenda.local}
                                    </Typography>
                                    <Typography>
                                        Valor Frete: {pedidos[index].argsVenda.valFreteVendido}
                                    </Typography>
                                    <Typography>
                                        Roteiro: {pedidos[index].argsVenda.roteiro}
                                    </Typography>
                                </AccordionDetails>
                                {/* {[...Array(10)].map((x, i) =>
                                    i === 0 ?
                                        <AccordionDetails style={{ display: 'flex', flexDirection: 'column' }}>
                                            <Typography>
                                                Cadência {(i + 1).toString()}: {pedidos[index].argsVenda['manCad']}
                                            </Typography>
                                            <Typography>
                                                Quantidade Cad {(i + 1).toString()}: {pedidos[index].argsVenda['manCadQtd']}
                                            </Typography>
                                        </AccordionDetails> : pedidos[index].argsVenda['manCad' + i.toString()] === '' ? console.log('no mancad') :
                                            <AccordionDetails style={{ display: 'flex', flexDirection: 'column' }}>
                                                <Typography>
                                                    Cadência {(i + 1).toString()}: {pedidos[index].argsVenda['manCad' + i.toString()]}
                                                </Typography>
                                                <Typography>
                                                    Quantidade Cad {(i + 1).toString()}: {pedidos[index].argsVenda['manCadQtd' + i.toString()]}
                                                </Typography>
                                            </AccordionDetails>
                                )} */}
                                <AccordionDetails style={{ display: 'flex', flexDirection: 'column' }}>

                                    <Typography>
                                        Filial : {pedidos[index].argsVenda.filial}
                                    </Typography>
                                    <Typography>
                                        Forma de Pagamento : {pedidos[index].argsVenda.forma}
                                    </Typography>
                                    {pedidos[index].argsVenda.forma === 'À Vista' ? null :
                                        <div>
                                            <Typography>
                                                Parcela 1: {pedidos[index].argsVenda.par1}
                                            </Typography>
                                            <Typography>
                                                Parcela 2: {pedidos[index].argsVenda.par2}
                                            </Typography>
                                            <Typography>
                                                Parcela 3: {pedidos[index].argsVenda.par3}
                                            </Typography>
                                            <Typography>
                                                Parcela 4: {pedidos[index].argsVenda.par4}
                                            </Typography>
                                        </div>
                                    }
                                    <Typography>
                                        Banco:{pedidos[index].argsVenda.banco}/ Agência : {pedidos[index].argsVenda.agencia}/ Conta:{pedidos[index].argsVenda.conta}
                                    </Typography>
                                    <Typography>
                                        Vencimento: {pedidos[index].argsVenda.dataCond}
                                    </Typography>
                                    <Typography>
                                        Comprador: {pedidos[index].argsVenda.comprador}
                                    </Typography>
                                    <Typography>
                                        Moeda: {pedidos[index].argsVenda.moeda} {pedidos[index].argsVenda.moeda !== 'R$' ? ': ' + pedidos[index].argsVenda.docRate : ''}
                                    </Typography>
                                </AccordionDetails>
                                <AccordionDetails style={{ display: 'flex', flexDirection: 'column' }}>

                                    <Typography>
                                        Cliente : {pedidos[index].argsVenda.cliente}
                                    </Typography>
                                    <Typography>
                                        RG : {pedidos[index].argsVenda.rg}
                                    </Typography>
                                    <Typography>
                                        CPF : {pedidos[index].argsVenda.cpf}
                                    </Typography>

                                    <Typography>
                                        Telefone: {pedidos[index].argsVenda.tel}
                                    </Typography>
                                    <Typography>
                                        Observações: {pedidos[index].argsVenda.observ}
                                    </Typography>

                                </AccordionDetails>
                                <AccordionDetails style={{ display: 'flex', flexDirection: 'column' }}>
                                    {console.log(pedidos[index]['argsVenda'].fiadores)}
                                    {(pedidos[index]['argsVenda'].fiadores !== undefined) & (pedidos[index]['argsVenda'].fiadores !== '') ?
                                        <>
                                            <Typography>Fiadores</Typography>
                                            {JSON.parse(pedidos[index]['argsVenda'].fiadores).map((fiador, index) =>
                                                fiador !== null ?
                                                    <div key={index} style={{ border: '1px solid rgba(0, 0, 0, .2)', margin: 5, padding: 10 }}>
                                                        <Typography>Nome: {fiador.nome}</Typography>
                                                        <Typography>  CPF: {fiador.cpf}</Typography>
                                                        <Typography>Endereço: {fiador.rua}, {fiador.numeroCasa}, {fiador.bairro}, {fiador.numeroCasa}, {fiador.cep}</Typography>
                                                    </div> : null)}
                                        </> : null}
                                </AccordionDetails>
                                <AccordionDetails style={{ display: 'flex', flexDirection: 'column' }}>
                                    <Button color='primary' variant='contained' onClick={() => toggleAddFiador(index)}>Adicionar Fiador</Button>

                                    {radioValues.length > 0 && radioValues[index].addFiador &&
                                        <div style={{ border: '1px solid lightgray', padding: 10, borderRadius: 10 }}>
                                            <RadioGroup row onChange={(e) => handleRadioChange(index, e.target.value)}>
                                                <FormControlLabel value='2' control={<Radio />} label="2 Fiadores" />
                                                <FormControlLabel value='4' control={<Radio />} label="4 Fiadores" />
                                            </RadioGroup>


                                            <div>
                                                {
                                                    radioValues[index] && Number(radioValues[index].value) > 0
                                                        ? Array(Number(radioValues[index].value)).fill().map((_, autoIndex) => (
                                                            <Autocomplete
                                                                style={{ marginTop: 5 }}
                                                                key={autoIndex}
                                                                options={radioValues[index].options || []}
                                                                getOptionLabel={(option) => option.nome}
                                                                onChange={(event, newValue) => handleAutocompleteChange(index, newValue)}
                                                                renderInput={(params) => (
                                                                    <TextField {...params} label='Fiador' variant="outlined" />
                                                                )}
                                                            />
                                                        ))
                                                        : null
                                                }
                                                <button style={{ marginTop: 5 }} onClick={() => handleSaveFiador(pedidos[index].argsVenda, index, pedidos[index].id)}>Salvar Fiadores</button>
                                            </div>
                                        </div>
                                    }
                                </AccordionDetails>
                                {pedidos[index].addSAP === 0 ? <div>
                                    <Button disabled={wait} style={{ backgroundColor: '#12824C', color: '#FFFFFF' }} onClick={() => inserePedido(pedidos[index].id, 1)}>   Aprovar Pedido e enviar para próxima etapa</Button>
                                    <Button disabled={wait} style={{ backgroundColor: '#DE3163', color: '#FFFFFF' }} onClick={() => (handleClickOpen(), setIndexR(pedidos[index].id))}>   Reprovar Pedido</Button></div> : <div />}
                            </div>

                        </Accordion>
                    )}
                    <div>

                        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                            <DialogTitle id="form-dialog-title">Reprovar Pedido</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    Descrição do motivo da reprovação da venda:
                                </DialogContentText>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="name"
                                    fullWidth
                                    onChange={(e) => setMsg(e.target.value)}
                                    multiline
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose} color="primary">
                                    Cancelar
                                </Button>
                                <Button onClick={() => (inserePedido(indexR, -1), handleClose)} color="primary">
                                    Reprovar Pedido
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                </GridContainer>
            </div>
        )
        )
    );
}
function mapStateToProps(state) {
    return {
        token: state.token,
        user: state.user,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            setToken: setToken,
            setUser: setUser,
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(ValidaVenda);
