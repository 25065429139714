import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { setToken } from "../../flux/actions/tokenIndex";
import { setUser } from "../../flux/actions/userIndex";
import api from "../../services/api";
import { getUser, getToken } from '../../utils/utils.js'
import { TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Loader from "react-loader-spinner";
import Button from "components/CustomButtons/Button.js"

function GrupoEmail(props) {

    const [emails, setEmails] = useState([''])
    const [nomeGrupo, setNomeGrupo] = useState('')

    const [groupEmails, setGroupEmails] = useState([])
    const [groupNames, setGroupNames] = useState([])

    const [salvandoGrupo, setSalvandoGrupo] = useState(false)
    const [removendoGrupo, setRemovendoGrupo] = useState(false)

    const classes = useStyles()

    useEffect(() => {
        fetchMyApi()
    }, [])

    const fetchMyApi = async () => {
        const response_groups = await api.get('/return_email_groups_agendamento', { params: { userId: getUser(), token: getToken() } })
        setGroupEmails(response_groups.data)

        let aux = []
        response_groups.data.map(group => aux.push(group.nome_grupo))
        setGroupNames(aux)
    }

    const changeInput = (value, position) => {
        let aux = [...emails]
        aux[position] = value
        setEmails(aux)
    }

    const removeEmail = (position) => {
        if (emails.length > 1) {
            let aux = [...emails]
            aux.splice(position, 1)
            setEmails(aux)
        }
    }

    const saveChanges = async () => {
        if (nomeGrupo === '') {
            alert('Adicione um nome para o grupo')
            return
        }
        if (groupNames.includes(nomeGrupo)) {
            alert('Esse nome já existe, escolha outro nome')
            return
        }
        if (emails.includes('')) {
            alert('Preencha todos os emails')
            return
        }

        setSalvandoGrupo(true)
        const grupoEmail = { nomeGrupo: nomeGrupo, emails: emails }
        const response = await api.get('/save_email_group', { params: { grupoEmail, userId: getUser(), token: getToken() } })
        if (response.data === 'OK') {
            alert('Grupo adicionado com sucesso!')
            window.location.reload()
        } else {
            alert('Erro ao adicionar o grupo...')
        }
        setSalvandoGrupo(false)
    }

    const removeGroup = async (id_grupo) => {
        setRemovendoGrupo(true)
        const response = await api.get('/delete_group_email', { params: { id_grupo, userId: getUser(), token: getToken() } })
        if (response.data === 'OK') {
            alert('Grupo removido com sucesso!')
            window.location.reload()
        } else {
            alert('Erro ao deletar o grupo...')
        }
        setRemovendoGrupo(false)
    }

    return (
        <div>
            <h3 style={{ fontWeight: 'bold' }}>
                Grupos de Email
            </h3>
            <div className={classes.mainDiv}>


                <div style={{ padding: 20, overflow: 'auto', height: 500 }}>
                    <h4 style={{ fontWeight: 'bold' }}>Grupos</h4>

                    {groupEmails.map((grupo, index) =>
                        <div key={index} className={classes.gpBox}>
                            <div style={{ fontWeight: 'bold' }}>{grupo.nome_grupo}</div>

                            {grupo.emails.map((email, index) => <div key={index}>{email}</div>)}

                            {removendoGrupo ?
                                <Loader
                                    type="Circles"
                                    color="#00BFFF"
                                    height={20}
                                    width={20}
                                    timeout={2000000}
                                />
                                : <Button onClick={() => removeGroup(grupo.id_grupo)}>Remover grupo</Button>}

                        </div>)}
                </div>

                <div className={classes.addGroup}>
                    <div style={{ margin: 5 }}>
                        <h4 style={{ fontWeight: 'bold' }}>Adicionar Grupo de Email</h4>
                        <TextField variant="outlined" label='Nome do grupo' onChange={(e) => setNomeGrupo(e.target.value)}>Nome do grupo</TextField>
                    </div>

                    <div style={{ padding: 10 }}>
                        <div>
                            <Button onClick={() => setEmails([...emails, ''])}>Adicionar email</Button>
                        </div>

                        {emails.map((email, index) =>
                            <div key={index} className={classes.group}>
                                <div>
                                    <Button onClick={() => removeEmail(index)}>Remover</Button>
                                </div>
                                <div>
                                    <TextField label='Email' variant='outlined' onChange={(e) => changeInput(e.target.value, index)}>Nome do grupo</TextField>
                                </div>

                            </div>
                        )}
                    </div >

                    {salvandoGrupo ?
                        <Loader
                            type="Circles"
                            color="#00BFFF"
                            height={20}
                            width={20}
                            timeout={2000000}
                        />
                        : <Button onClick={() => saveChanges()}>Salvar Grupo</Button>}
                </div>
            </div>
        </div>


    )
}


const useStyles = makeStyles({
    mainDiv: {
        boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px;',
        padding: 20,
        background: 'white',
        display: 'flex',
        flexDirection: 'row',
    },
    group: {
        margin: 10,
        width: 'fit-content',
        padding: 10,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        alignContent: 'center',
        textAlign: 'center'
    },

    addGroup: {
        width: 'fit-content',
        padding: 20,
        background: 'white',
        overflow: 'scroll',
        height: 500,

    },

    gpBox: {
        width: 300,
        boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px;',
        background: 'white',
        border: '1px solid rgba(0, 0, 0, .1)',
        marginTop: 10,
        padding: 10
    },

})



function mapStateToProps(state) {
    return {
        token: state.token,
        user: state.user,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            setToken: setToken,
            setUser: setUser,
        },
        dispatch
    );
}


export default connect(mapStateToProps, mapDispatchToProps)(GrupoEmail);