import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { setToken } from "../../flux/actions/tokenIndex";
import { setUser } from "../../flux/actions/userIndex";
import api from "../../services/api";
import { checarPermissao, validateTokenUser, getUser, getToken } from '../../utils/utils.js'
import { makeStyles } from "@material-ui/core/styles";
import Loader from "react-loader-spinner";
import Table from '@material-ui/core/Table';

import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from "components/CustomButtons/Button.js";
import { Checkbox } from '@material-ui/core';


function PricingView(props) {
    const [permitido, setPermitido] = useState(false)
    const [materias, setMaterias] = useState('')
    const [materia, setMateria] = useState('')
    const [destinos, setDestinos] = useState('')
    const [destino, setDestino] = useState('')
    const [icms, setIcms] = useState(0)
    const [dolar, setDolar] = useState(0)
    const [fob, setFob] = useState(false)
    const [custosPorto, setCustosPorto] = useState('')
    const [wait, setWait] = useState(false)
    const [val, setVal] = useState(0)
    const [custos, setCustos] = useState(0)
    const [envase, setEnvase] = useState(0)
    const [bag, setBag] = useState(0)
    const classes = useStyles()


    useEffect(() => {
        async function fetchMyAPI() {
            await validateTokenUser(props)
            setPermitido(await checarPermissao('pricingView', props))

            const response_precos = await api.get('/retornaTabelaPrecos', { params: { userId: getUser(), token: getToken() } })
            setMaterias(response_precos.data)

            const respose_dest2 = await api.get("/returnDes", { params: { userId: getUser(), token: getToken() } })
            setDestinos(respose_dest2.data)
        }

        fetchMyAPI();
    }, [])


    async function retornaPricing() {
        if (val === 0 || destino === '' || dolar === '' || icms === '') {
            alert('Preencha todos os campos!')
            return
        }

        setWait(true)
        const response_pricing = await api.get("/retornaCalculoPricing", {
            params: { bagEnvase: false, dolar, destino, icms, materia, userId: getUser(), token: getToken(), val, 'custo_porto_fix': custos, fob, bag, envase }
        })
        if (response_pricing.data === 'NOK') {
            setWait(false)
            setCustosPorto('')
            alert('Erro: destino não encontrado. Entre em contato com o time da logística para inserção da rota')
            return
        }
        setCustosPorto(response_pricing.data)
        setWait(false)
    }
    function precoMaterias(materia) {
        let aux = ''
        aux = materias.filter(function (e) {
            return e.cod === materia.split(' - ')[0]
        })
        return aux[0].preco
    }
    return (
        permitido === true ?
            <div>
                <div style={{ background: 'white', padding: 20 }}>

                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        {/* <div >
                            {materias !== '' ? <Autocomplete
                                id="combo-box-demo"
                                options={Object.keys(materias).map((item) => (materias[item].cod + ' - ' + materias[item].produto))}
                                getOptionSelected={(option, value) => option === value || value === ''}
                                style={{ width: 300 }}
                                value={materia}

                                onChange={(e, v) => v !== null ? setMateria(v) : setMateria('')}
                                renderInput={(params) => (
                                    <TextField

                                        {...params}
                                        value={materia}
                                        label="Produto"
                                        variant='outlined'
                                    />
                                )}
                            /> : <Loader
                                type="Circles"
                                color="#00BFFF"
                                height={20}
                                width={20}
                                timeout={2000000} //3 secs
                            />}


                            <div style={{ borderLeft: '1px solid rgba(0, 0, 0, .2)', borderRight: '1px solid rgba(0, 0, 0, .2)', borderBottom: '1px solid rgba(0, 0, 0, .2)', padding: 10, borderRadius: 4 }}>
                                Valor do Produto: ${materia !== '' ? precoMaterias(materia) : ''}
                            </div>
                        </div> */}
                        <div className={classes.inputs}>
                            <TextField
                                variant='outlined'
                                onChange={(e) => setVal(e.target.value)}
                                value={val}
                                type="number"
                                label='Valor Matéria Prima em $'
                            />
                        </div>
                        <div className={classes.inputs}>
                            <TextField
                                variant='outlined'
                                onChange={(e) => setCustos(e.target.value)}
                                value={custos}
                                type="number"
                                label='Custos Portuários $'
                            />
                        </div>
                        <div className={classes.inputs}>
                            {destinos !== '' ? <Autocomplete
                                id="combo-box-demo"
                                options={destinos}
                                getOptionSelected={(option, value) => option === value || value === ''}
                                style={{ width: 200 }}
                                value={destino}
                                onChange={(e, v) => v !== null ? setDestino(v) : setDestino('')}
                                renderInput={(params) => (
                                    <TextField
                                        variant='outlined'
                                        {...params}
                                        value={destino}
                                        label="Destino"

                                    />
                                )}
                            /> : <Loader
                                type="Circles"
                                color="#00BFFF"
                                height={20}
                                width={20}
                                timeout={2000000} //3 secs
                            />}

                        </div>

                        <div className={classes.inputs}>
                            <TextField
                                variant='outlined'
                                onChange={(e) => setDolar(e.target.value)}
                                value={dolar}
                                type="number"
                                label='Dolar'
                            />
                        </div>

                        <div className={classes.inputs}>
                            <TextField
                                variant='outlined'
                                onChange={(e) => setIcms(e.target.value)}
                                value={icms}
                                type="number"
                                label='ICMS'
                            />
                        </div>


                    </div>
                    <div className={classes.inputs} style={{ marginTop: 10 }}>
                        <TextField
                            variant='outlined'
                            onChange={(e) => setBag(e.target.value)}
                            value={bag}
                            type="number"
                            label='Bag'
                        />
                    </div>

                    <div className={classes.inputs} style={{ marginTop: 10 }}>
                        <TextField
                            variant='outlined'
                            onChange={(e) => setEnvase(e.target.value)}
                            value={envase}
                            type="number"
                            label='Envase'
                        />
                    </div>
                    <div style={{ marginTop: 5 }}>
                        FOB
                        <Checkbox
                            checked={fob}
                            onChange={() => setFob(!fob)}
                            name="checkBag"
                            color="primary"
                        />
                    </div>

                    <div>
                        {wait ?
                            <div>
                                <Loader
                                    type="Circles"
                                    color="#00BFFF"
                                    height={20}
                                    width={20}
                                    timeout={2000000} //3 secs
                                />
                                Gerando tabela...
                            </div> :
                            <div>
                                <Button style={{ backgroundColor: '#6699ff', width: 400, marginTop: 12, marginBottom: 12 }} variant="contained" onClick={() => retornaPricing()}>Calcular Pricing</Button>
                            </div>}

                    </div>

                </div>



                <div>

                    {custosPorto !== '' && !wait ?
                        <TableContainer style={{ marginTop: 20, height: 666, borderCollapse: 'collapse', background: 'white' }} >
                            <Table id="table-to-xls" >
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.headCell}>
                                            Porto
                                        </TableCell>
                                        <TableCell className={classes.headCell}>

                                        </TableCell>
                                        <TableCell className={classes.headCell}>
                                            Janeiro
                                        </TableCell>
                                        <TableCell className={classes.headCell}>
                                            Fevereiro
                                        </TableCell>
                                        <TableCell className={classes.headCell}>
                                            Março
                                        </TableCell>
                                        <TableCell className={classes.headCell}>
                                            Abril
                                        </TableCell>
                                        <TableCell className={classes.headCell}>
                                            Maio
                                        </TableCell>
                                        <TableCell className={classes.headCell}>
                                            Junho
                                        </TableCell>
                                        <TableCell className={classes.headCell}>
                                            Julho
                                        </TableCell>
                                        <TableCell className={classes.headCell}>
                                            Agosto
                                        </TableCell>
                                        <TableCell className={classes.headCell}>
                                            Setembro
                                        </TableCell>
                                        <TableCell className={classes.headCell}>
                                            Outubro
                                        </TableCell>
                                        <TableCell className={classes.headCell}>
                                            Novembro
                                        </TableCell>
                                        <TableCell className={classes.headCell}>
                                            Dezembro
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <tbody>

                                    {custosPorto !== '' ?
                                        Object.keys(custosPorto).map((item, index) => (
                                            <TableRow key={index}>

                                                <TableCell className={classes.divPorto}>
                                                    <div >
                                                        <p className={classes.portoCell}>{custosPorto[item].porto}</p>
                                                        <p className={classes.portoCell}></p>
                                                    </div>
                                                </TableCell>
                                                <TableCell className={classes.divPorto}>
                                                    <div style={{ display: 'flex', flexDirection: 'column' }}>

                                                        <p className={classes.divCell}> Frete R$: </p>
                                                        <p className={classes.divCell}> Demurrage R$: </p>
                                                        <p className={classes.divCell}> Total Custos R$:</p>
                                                        <p className={classes.divCell}> Preço Total CIF $:</p>

                                                    </div>
                                                </TableCell>
                                                <TableCell className={classes.tableCell}>
                                                    <div style={{ display: 'flex', flexDirection: 'column' }}>

                                                        <p className={classes.divCell}> R$: {custosPorto[item].U_Jan} </p>
                                                        <p className={classes.divCell}>  R$: {custosPorto[item].U_Jan_dem}</p>
                                                        <p className={classes.divCell}>  R$: {custosPorto[item].custoTotal + custosPorto[item].U_Jan + custosPorto[item].U_Jan_dem} </p>
                                                        <p className={classes.divCell}> $ {custosPorto[item].U_Jan_custo_dem_dol_materia}</p>
                                                    </div>

                                                </TableCell>
                                                <TableCell className={classes.tableCell}>

                                                    <div style={{ display: 'flex', flexDirection: 'column' }}>

                                                        <p className={classes.divCell}> R$: {custosPorto[item].U_Fev}</p>
                                                        <p className={classes.divCell}>  R$: {custosPorto[item].U_Fev_dem}</p>
                                                        <p className={classes.divCell}>  R$: {custosPorto[item].custoTotal + custosPorto[item].U_Fev + custosPorto[item].U_Fev_dem} </p>
                                                        <p className={classes.divCell}> $ {custosPorto[item].U_Fev_custo_dem_dol_materia}</p>
                                                    </div>
                                                </TableCell>
                                                <TableCell className={classes.tableCell}>

                                                    <div style={{ display: 'flex', flexDirection: 'column' }}>

                                                        <p className={classes.divCell}> R$: {custosPorto[item].U_Mar}</p>
                                                        <p className={classes.divCell}>  R$: {custosPorto[item].U_Mar_dem}</p>
                                                        <p className={classes.divCell}>  R$: {custosPorto[item].custoTotal + custosPorto[item].U_Mar + custosPorto[item].U_Mar_dem} </p>
                                                        <p className={classes.divCell}> $ {custosPorto[item].U_Mar_custo_dem_dol_materia}</p>
                                                    </div>
                                                </TableCell>
                                                <TableCell className={classes.tableCell}>

                                                    <div style={{ display: 'flex', flexDirection: 'column' }}>

                                                        <p className={classes.divCell}> R$: {custosPorto[item].U_Abr}</p>
                                                        <p className={classes.divCell}>  R$: {custosPorto[item].U_Abr_dem}</p>
                                                        <p className={classes.divCell}>  R$: {custosPorto[item].custoTotal + custosPorto[item].U_Abr + custosPorto[item].U_Abr_dem} </p>
                                                        <p className={classes.divCell}> $ {custosPorto[item].U_Abr_custo_dem_dol_materia}</p>
                                                    </div>
                                                </TableCell>
                                                <TableCell className={classes.tableCell}>

                                                    <div style={{ display: 'flex', flexDirection: 'column' }}>

                                                        <p className={classes.divCell}> R$: {custosPorto[item].U_Mai}</p>
                                                        <p className={classes.divCell}>  R$: {custosPorto[item].U_Mai_dem}</p>
                                                        <p className={classes.divCell}>  R$: {custosPorto[item].custoTotal + custosPorto[item].U_Mai + custosPorto[item].U_Mai_dem} </p>
                                                        <p className={classes.divCell}> $ {custosPorto[item].U_Mai_custo_dem_dol_materia}</p>
                                                    </div>
                                                </TableCell>
                                                <TableCell className={classes.tableCell}>

                                                    <div style={{ display: 'flex', flexDirection: 'column' }}>

                                                        <p className={classes.divCell}> R$: {custosPorto[item].U_Jun}</p>
                                                        <p className={classes.divCell}>  R$: {custosPorto[item].U_Jun_dem}</p>
                                                        <p className={classes.divCell}>  R$: {custosPorto[item].custoTotal + custosPorto[item].U_Jun + custosPorto[item].U_Jun_dem} </p>
                                                        <p className={classes.divCell}> $ {custosPorto[item].U_Jun_custo_dem_dol_materia}</p>
                                                    </div>
                                                </TableCell>
                                                <TableCell className={classes.tableCell}>

                                                    <div style={{ display: 'flex', flexDirection: 'column' }}>

                                                        <p className={classes.divCell}> R$: {custosPorto[item].U_Jul}</p>
                                                        <p className={classes.divCell}>  R$: {custosPorto[item].U_Jul_dem}</p>
                                                        <p className={classes.divCell}>  R$: {custosPorto[item].custoTotal + custosPorto[item].U_Jul + custosPorto[item].U_Jul_dem} </p>
                                                        <p className={classes.divCell}> $ {custosPorto[item].U_Jul_custo_dem_dol_materia}</p>
                                                    </div>
                                                </TableCell>
                                                <TableCell className={classes.tableCell}>

                                                    <div style={{ display: 'flex', flexDirection: 'column' }}>

                                                        <p className={classes.divCell}> R$: {custosPorto[item].U_Ago}</p>
                                                        <p className={classes.divCell}>  R$: {custosPorto[item].U_Ago_dem}</p>
                                                        <p className={classes.divCell}>  R$: {custosPorto[item].custoTotal + custosPorto[item].U_Ago + custosPorto[item].U_Ago_dem} </p>
                                                        <p className={classes.divCell}> $ {custosPorto[item].U_Ago_custo_dem_dol_materia}</p>
                                                    </div>
                                                </TableCell>
                                                <TableCell className={classes.tableCell}>

                                                    <div style={{ display: 'flex', flexDirection: 'column' }}>

                                                        <p className={classes.divCell}> R$: {custosPorto[item].U_Set}</p>
                                                        <p className={classes.divCell}>  R$: {custosPorto[item].U_Set_dem}</p>
                                                        <p className={classes.divCell}>  R$: {custosPorto[item].custoTotal + custosPorto[item].U_Set + custosPorto[item].U_Set_dem} </p>
                                                        <p className={classes.divCell}> $ {custosPorto[item].U_Set_custo_dem_dol_materia}</p>
                                                    </div>
                                                </TableCell>
                                                <TableCell className={classes.tableCell}>

                                                    <div style={{ display: 'flex', flexDirection: 'column' }}>

                                                        <p className={classes.divCell}> R$: {custosPorto[item].U_Out}</p>
                                                        <p className={classes.divCell}>  R$: {custosPorto[item].U_Out_dem}</p>
                                                        <p className={classes.divCell}>  R$: {custosPorto[item].custoTotal + custosPorto[item].U_Out + custosPorto[item].U_Out_dem} </p>
                                                        <p className={classes.divCell}> $ {custosPorto[item].U_Out_custo_dem_dol_materia}</p>
                                                    </div>

                                                </TableCell>
                                                <TableCell className={classes.tableCell}>

                                                    <div style={{ display: 'flex', flexDirection: 'column' }}>

                                                        <p className={classes.divCell}> R$: {custosPorto[item].U_Nov}</p>
                                                        <p className={classes.divCell}>  R$: {custosPorto[item].U_Nov_dem}</p>
                                                        <p className={classes.divCell}>  R$: {custosPorto[item].custoTotal + custosPorto[item].U_Nov + custosPorto[item].U_Nov_dem} </p>
                                                        <p className={classes.divCell}> $ {custosPorto[item].U_Nov_custo_dem_dol_materia}</p>
                                                    </div>

                                                </TableCell>
                                                <TableCell className={classes.tableCell}>

                                                    <div style={{ display: 'flex', flexDirection: 'column' }}>

                                                        <p className={classes.divCell}> R$: {custosPorto[item].U_Dez}</p>
                                                        <p className={classes.divCell}>  R$: {custosPorto[item].U_Dez_dem}</p>
                                                        <p className={classes.divCell}>  R$: {custosPorto[item].custoTotal + custosPorto[item].U_Dez + custosPorto[item].U_Dez_dem} </p>
                                                        <p className={classes.divCell}> $ {custosPorto[item].U_Dez_custo_dem_dol_materia}</p>
                                                    </div>
                                                </TableCell>

                                                {/* 

                                                <TableCell style={{ width: 300, textAlign: "center", border: "1px solid " }}>
                                                    {demurrage[item].daysUpdate}

                                                </TableCell> */}

                                            </TableRow>

                                        )) : <div></div>}
                                </tbody>
                            </Table>
                        </TableContainer> : <></>
                    }


                </div>
            </div> : <></>
    )
}


const useStyles = makeStyles({
    table: {
        borderCollapse: 'collapse'
    },

    tableCell: {
        fontFamily: "Calibri, sans-serif",
        fontSize: 14,
        border: '1px solid black',
        padding: 0,
        margin: 0
    },

    divCell: {
        whiteSpace: 'nowrap',
        margin: 0,
        paddingLeft: 4,
        borderCollapse: 'collapse'
    },

    portoCell: {
        whiteSpace: 'nowrap',
        margin: 0,
        paddingLeft: 4,
        borderCollapse: 'collapse',
    },

    headCell: {
        fontFamily: "Calibri, sans-serif",
        fontSize: 16,
        border: '1px solid black',
        padding: 0,
        margin: 0,
        textAlign: 'center',
        background: 'lightblue'
    },

    divPorto: {
        fontFamily: "Calibri, sans-serif",
        fontSize: 14,
        border: '1px solid black',
        padding: 0,
        margin: 0,
    },

    inputs: {
        marginLeft: 10
    }
})


function mapStateToProps(state) {
    return {
        token: state.token,
        user: state.user,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            setToken: setToken,
            setUser: setUser,
        },
        dispatch
    );
}


export default connect(mapStateToProps, mapDispatchToProps)(PricingView);