import React, { useState, useEffect } from 'react';
import api from 'services/api.js';
import { getUser, getToken } from 'utils/utils.js'
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Paper,
    TextField,
    Button,
    TableContainer,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogContent
} from "@material-ui/core";

export default function EditorPreco({ materias, setMaterias, close, vendedor }) {
    const [loading, setLoading] = useState(false);

    async function update_materia(value, cod, isAtivoUpdate = false, centro_custo = 'bom_jesus') {
        if (isAtivoUpdate) {
            setLoading(true);
        }
        console.log(centro_custo)
        const params = {
            'code': cod,
            centro_custo: centro_custo,
            userId: getUser(),
            token: getToken()
        };

        if (isAtivoUpdate) {
            params.habilitada = value ? 1 : 0;
        } else {
            params.preco = value;
        }

        const response_update = await api.get("/update_precos_materias", { params });

        if ((response_update.data === "NOK") || (response_update.data === "NOS")) {
            alert('Erro ao atualizar');
        } else {
            const response = await api.get("retorna_materias_formulas", {
                params: { userId: getUser(), token: getToken() },
            });
            setMaterias(response.data);
        }

        if (isAtivoUpdate) {
            setLoading(false); // Reset loading state at the end
        }
    }

    return (
        <div style={{ padding: 20, overflow: 'auto' }}>
            <div>
                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 20 }}>
                    <Button variant='contained' color='primary' size="small" onClick={() => close()}>Fechar</Button>
                </div>
                <TableContainer component={Paper} style={{
                    maxHeight: '700px',
                    overflowY: 'auto',
                }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Código do Produto</TableCell>
                                <TableCell>Nome do Produto</TableCell>
                                <TableCell>Bom Jesus:Preço </TableCell>
                                {vendedor === false ? <TableCell>Habilitada </TableCell> : null}
                                <TableCell>Barcarena:Preço </TableCell>
                                {vendedor === false ? <TableCell>Habilitada </TableCell> : null}
                                <TableCell>Paranaguá:Preço</TableCell>
                                {vendedor === false ? <TableCell>Habilitada </TableCell> : null}

                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {materias.map((item, index) => (
                                <TableRow key={item['Código do Produto']}>
                                    <TableCell>{item['Código do Produto']}</TableCell>
                                    <TableCell>{item['Nome do Produto']}</TableCell>
                                    {vendedor === false ?
                                        <TableCell>
                                            <TextField
                                                type="number"
                                                defaultValue={item.preco}
                                                onChange={(e) => update_materia(e.target.value, item['Código do Produto'], false, 'bom_jesus')}
                                            />
                                        </TableCell> :
                                        <TableCell>
                                            <TextField
                                                type="number"
                                                defaultValue={item.preco_v}
                                            />
                                        </TableCell>}
                                    {vendedor === false ? <TableCell>
                                        <Checkbox
                                            checked={item.habilitada === 1}
                                            onChange={(e) => update_materia(e.target.checked, item['Código do Produto'], true)}
                                        />
                                    </TableCell> : null}
                                    {vendedor === false ?
                                        <TableCell>
                                            <TextField
                                                type="number"
                                                defaultValue={item['Preço de Venda Barcarena']}
                                                onChange={(e) => update_materia(e.target.value, item['Código do Produto'], false, 'barcarena')}

                                            />
                                        </TableCell>
                                        :
                                        <TableCell>
                                            <TextField
                                                type="number"
                                                defaultValue={item['Preço de Venda Barcarena_v']}
                                            />
                                        </TableCell>}
                                    {vendedor === false ? <TableCell>
                                        <Checkbox
                                            checked={item.habilitadade_barcarena === 1}
                                            onChange={(e) => update_materia(e.target.checked, item['Código do Produto'], true, 'barcarena')}
                                        />
                                    </TableCell> : null}
                                    {vendedor === false ?
                                        <TableCell>
                                            <TextField
                                                type="number"
                                                defaultValue={item['Preço de Venda Paranaguá']}
                                                onChange={(e) => update_materia(e.target.value, item['Código do Produto'], false, 'paranagua')}
                                            />
                                        </TableCell>
                                        :
                                        <TableCell>
                                            <TextField
                                                type="number"
                                                defaultValue={item['Preço de Venda Paranaguá_v']}
                                            />
                                        </TableCell>}
                                    {vendedor === false ? <TableCell>
                                        <Checkbox
                                            checked={item.habilitadade_paranagua === 1}
                                            onChange={(e) => update_materia(e.target.checked, item['Código do Produto'], true, 'paranagua')}
                                        />
                                    </TableCell> : null}

                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>



            {/* Loading dialog */}
            <Dialog open={loading} onClose={() => { }}>
                <DialogContent style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '20px' }}>
                    <CircularProgress />
                </DialogContent>
            </Dialog>
        </div>
    );
}
