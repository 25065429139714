import React, { useEffect, useState } from "react";
import api from "../../services/api";
import { checarPermissao, validateTokenUser, getUser, getToken } from '../../utils/utils.js'
import {
  Card,
  CardContent,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Grid,
  Button,
  TableContainer
} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  paper: {
    marginBottom: 16,
    width: '100%',
    overflowX: 'auto',
    marginTop: 16,
  },
  table: {
    minWidth: 700,
  }, paper: {
    padding: 16,
    marginBottom: 16,
  },
});

const formatValue = (key, value) => {
  if (key.includes('peso')) {
    return `${value} kg`;
  } else if (key.includes('preco')) {
    return `$${value}`;
  } else {
    return value;
  }
};




function CustosTable({ itens, setItens }) {

  async function update_custos(price, cod) {

    const response_update = await api.get("/update_custos_fixos", {
      params: {
        'preco': cod,
        'code': price,
        userId: getUser(), token: getToken(),

      },
    });
    if ((response_update.data === "NOK") | (response_update.data === "NOS")) {
      alert('Erro ao atualizar')

    }
    else {
      const response = await api.get("retorna_custos_fixos", {
        params: { userId: getUser(), token: getToken() },
      });
      setItens(response.data);

      return
    }

  }

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Valor Bag $</TableCell>
            <TableCell>Envase $</TableCell>
            <TableCell>Desembaraco $</TableCell>
            <TableCell>Margem $</TableCell>
            <TableCell>Frete $</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {itens.map((item, index) => (
            <TableRow >

              <TableCell>
                <TextField
                  type="number"
                  defaultValue={item.valor_bag}
                  onChange={(e) => update_custos('valor_bag', e.target.value, item['Código do Produto'])}
                />
              </TableCell>
              <TableCell>
                <TextField
                  type="number"
                  defaultValue={item.envase}
                  onChange={(e) => update_custos('envase', e.target.value, item['Código do Produto'])}
                />
              </TableCell>
              <TableCell>
                <TextField
                  type="number"
                  defaultValue={item.desembaraco}
                  onChange={(e) => update_custos('desembaraco', e.target.value, item['Código do Produto'])}
                />
              </TableCell>
              <TableCell>
                <TextField
                  type="number"
                  defaultValue={item.margem}
                  onChange={(e) => update_custos('margem', e.target.value, item['Código do Produto'])}
                />
              </TableCell>
              <TableCell>
                <TextField
                  type="number"
                  defaultValue={item.frete}
                  onChange={(e) => update_custos('frete', e.target.value, item['Código do Produto'])}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function MateriasTable({ materias, setMaterias }) {

  async function update_materia(price, cod) {

    const response_update = await api.get("/update_precos_materias", {
      params: {
        'preco': price,
        'code': cod,
        userId: getUser(), token: getToken(),

      },
    });
    if ((response_update.data === "NOK") | (response_update.data === "NOS")) {
      alert('Erro ao atualizar')

    }
    else {
      const response = await api.get("retorna_materias_formulas", {
        params: { userId: getUser(), token: getToken() },
      });
      setMaterias(response.data);

      return
    }

  }

  return (

    <TableContainer component={Paper} >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Código do Produto</TableCell>
            <TableCell>Nome do Produto</TableCell>
            <TableCell>preco</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {materias.map((item, index) => (
            <TableRow key={item['Código do Produto']}>
              <TableCell>{item['Código do Produto']}</TableCell>
              <TableCell>{item['Nome do Produto']}</TableCell>
              <TableCell>
                <TextField

                  type="number"
                  defaultValue={item.preco}
                  onChange={(e) => update_materia(e.target.value, item['Código do Produto'])}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
const Totais = ({ totais }) => {
  const orderedKeys = ['total_N', 'total_P', 'total_K', 'total_S', 'total_Ca', 'total_Zn', 'total_B', 'total_Cu', 'total_Fe', 'total_Mg', 'total_Mn', 'total_S-SO4'];
  console.log(totais)
  return (
    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
      {orderedKeys.map(key => {
        const value = totais[key];
        console.log(value, totais[key], key)
        if (value !== 0) {
          return (
            <div key={key} style={{ margin: '0 10px' }}>
              <strong>{key.replace('total_', '')}:</strong> {(value)} %
            </div>
          );
        }
        return null;
      })}
    </div>
  );
};


const DataCard = ({ composicao, totais, fixo }) => {
  const classes = useStyles();

  return (
    <Paper className={classes.paper}>
      <div>
        <Totais totais={totais} />
      </div>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell colSpan={2}>
              <Typography variant="h6">Composicao</Typography>
            </TableCell>
            <TableCell colSpan={2}>
              <Typography variant="h6">Totais</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.entries(composicao).map(([key, value], index) => {


            return (
              formatValue(key, value) !== '0.00' ?
                <TableRow key={key}>
                  <TableCell>{key}</TableCell>
                  <TableCell>{formatValue(key, value)} KG</TableCell>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    {index == 0 ? <TableCell>Preço Formulação :  {(totais.preco_total - fixo).toFixed(2)} $  </TableCell> : null}
                    {index == 0 ? <TableCell>Custos fixos :  {fixo.toFixed(2)} $  </TableCell> : null}
                    {index == 0 ? <TableCell><b>Preço Total :  {totais.preco_total.toFixed(2)} $ </b> </TableCell> : null}
                  </div>
                </TableRow> : null
            );
          })}
        </TableBody>
      </Table>

    </Paper>
  );
};
const InputForm = ({ onGetData, setFech }) => {
  const [n, setN] = useState(0);
  const [p, setP] = useState(0);
  const [k, setK] = useState(0);
  const [call, setCall] = useState(false);

  const classes = useStyles();

  const handleInputChange = (e, setter) => {
    setter(e.target.value);
  };

  async function handleClick() {
    setCall(true)
    setFech("")
    await onGetData(n, p, k);
    setCall(false)
  };

  return (
    <Paper className={classes.paper}>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <TextField
            label="N"
            value={n}
            onChange={(e) => handleInputChange(e, setN)}
            fullWidth
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            label="P"
            value={p}
            onChange={(e) => handleInputChange(e, setP)}
            fullWidth
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            label="K"
            value={k}
            onChange={(e) => handleInputChange(e, setK)}
            fullWidth
          />
        </Grid>

        <Grid item xs={3}>
          <Button disabled={call} variant="contained" color="primary" onClick={handleClick}>
            Calcular Formulação
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};
const MateriasAutocomplete = ({ label, onSelect, materials }) => {
  return (
    <Autocomplete
      options={materials}
      getOptionLabel={(option) => option.cod_total}
      onChange={(event, value) => onSelect(value)}
      renderInput={(params) => (
        <TextField {...params} label={label} fullWidth />
      )}
      multiple
    />
  );
};
function Formulador(props) {
  const [fech, setFech] = useState("");
  const [materias, setMaterias] = useState([]);
  const [materiasMustUse, setMateriasMustUse] = useState([]);
  const [materiasMustNotUse, setMateriasMustNotUse] = useState([]);
  const [editPrices, setEditPrices] = useState(false)
  const [editCustos, setEditCustos] = useState(false)

  const [elementValues, setElementValues] = useState({
    'S': 0,
    'Ca': 0,
    'Zn': 0,
    'B': 0,
    'Cu': 0,
    'Fe': 0,
    'Mg': 0,
    'Mo': 0,
    'Mn': 0,
    'S-SO4': 0,
  });
  const [itens, setItens] = useState([])

  const handleElementInputChange = (e, element) => {
    setElementValues({
      ...elementValues,
      [element]: e.target.value,
    });
  };
  useEffect(() => {
    async function fetchMaterials() {
      const response = await api.get("retorna_materias_formulas", {
        params: { userId: getUser(), token: getToken() },
      });
      setMaterias(response.data);
      const response_custos = await api.get("retorna_custos_fixos", {
        params: { userId: getUser(), token: getToken() },
      });
      setItens(response_custos.data)
    }
    fetchMaterials();
  }, []);


  async function get_data(n, p, k) {

    const materiasToUse = materiasMustUse.map(material => material.cod_total);
    const materiasToAvoid = materiasMustNotUse.map(material => material.cod_total);

    const response_pedidos = await api.get("/retorna_formulacoes_teste", {
      params: {
        n,
        p,
        k,
        userId: getUser(), token: getToken(),
        materiasToUse: JSON.stringify(materiasToUse),
        materiasToAvoid: JSON.stringify(materiasToAvoid),
        elementValues: JSON.stringify(elementValues),

      },
    });
    if ((response_pedidos.data === "NOK") | (response_pedidos.data === "NOS")) {
      alert('Não foi possível encontrar uma formulação para os valores informados')
    }
    else {
      setFech(response_pedidos.data);
    }
  }

  return (
    <div >
      {editPrices === true & materias.length !== 0 ?
        <div >
          <Button variant="contained" color="warning" onClick={() => setEditPrices(!editPrices)}>
            Fechar edição Preços Matérias
          </Button>
          <MateriasTable materias={materias} setMaterias={setMaterias} />

        </div> :
        <Button variant="contained" color="primary" onClick={() => setEditPrices(!editPrices)}>
          Editar preços
        </Button>}
      {editCustos === true & itens.length !== 0 ?
        <div >
          <Button variant="contained" color="warning" onClick={() => setEditCustos(!editCustos)}>
            Fechar edição Custos
          </Button>
          <CustosTable itens={itens} setItens={setItens} />

        </div> :
        <Button variant="contained" color="primary" onClick={() => setEditCustos(!editCustos)}>
          Editar Custos
        </Button>}
      <InputForm onGetData={get_data} setFech={setFech} />
      <Grid item xs={3}>
        <Autocomplete
          multiple
          options={materias}
          getOptionLabel={(option) => option.cod_total}
          onChange={(event, value) => setMateriasMustUse(value)}
          renderInput={(params) => (
            <TextField {...params} label="Incluir Matérias Primas" />
          )}
        />
      </Grid>
      <Grid item xs={3}>
        <Autocomplete
          multiple
          options={materias}
          getOptionLabel={(option) => option.cod_total}
          onChange={(event, value) => setMateriasMustNotUse(value)}
          renderInput={(params) => (
            <TextField {...params} label="Excluir Matérias Primas" />
          )}
        />
      </Grid>
      <Grid container spacing={2}>
        {Object.keys(elementValues).map((element) => (
          <Grid item xs={2} key={element}>
            <TextField
              label={element}
              type='number'
              value={elementValues[element]}
              onChange={(e) => handleElementInputChange(e, element)}
            />
          </Grid>
        ))}
      </Grid>

      {console.log(fech)}
      {fech !== "" ?
        fech.map((item, index) => (
          <DataCard key={index} composicao={item.composicao} totais={item.totais} fixo={item.fixos} />
        ))
        : null}
    </div>
  )
}

export default Formulador;