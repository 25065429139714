import React, { useEffect, useState } from "react";
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { setToken } from "../../flux/actions/tokenIndex";
import { setUser } from "../../flux/actions/userIndex";
import api from "../../services/api";
import Loader from "react-loader-spinner";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';

import { checarPermissao, validateTokenUser, getUser, getToken } from '../../utils/utils.js'

function ClientesFiadores(props) {
  const [clientes, setClientes] = useState();
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [guarantor, setGuarantor] = useState({
    nome: '',
    cpf: '',
    rua: '',
    bairro: '',
    cep: '',
    numeroCasa: '',
    cidadeEstado: ''
  });
  const [saving, setSaving] = useState(false)
  const [deleting, setDeleting] = useState(false)

  const [vinculoFiadores, setVinculoFiadores] = useState([])

  const handleCustomerChange = async (e) => {
    setSelectedCustomer(e.CardCode);

    const response = await api.get("/get_fiador", {
      params: {
        cod_cliente: e.CardCode
      }
    })
    setVinculoFiadores(response.data)
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setGuarantor(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSave = async () => {
    setSaving(true)
    try {
      const response = await api.post("/insert_fiador", {
        cliente: selectedCustomer,
        fiador: guarantor,
      });

      const responseGet = await api.get("/get_fiador", {
        params: {
          cod_cliente: selectedCustomer
        }
      })

      setVinculoFiadores(responseGet.data)
    } catch (error) {
      setSaving(false)
      console.error("Error saving data", error);
    }
    setSaving(false)
  };

  const [permitido, setPermitido] = useState(true)


  useEffect(() => {
    async function fetchMyAPI() {
      setPermitido(await checarPermissao('clientes_fiadores', props))
      const response_clientes = await api.get("/Clientes", { params: { userId: getUser(), token: getToken(), token2: '63cbse4yu21fw24f2v2' } });
      setClientes(response_clientes.data)

    }
    fetchMyAPI();
  }, [])

  const handleDelete = async (cod_fiador) => {
    setDeleting(true)
    const responseDelete = await api.post("/delete_fiador", { cod_fiador })

    const responseGet = await api.get("/get_fiador", {
      params: {
        cod_cliente: selectedCustomer
      }
    })

    setVinculoFiadores(responseGet.data)
    setDeleting(false)
  }

  if (!permitido || !clientes) {
    return <></>
  }

  return (
    <Grid container component={Paper} style={{ padding: 20 }}>
      <Grid item xs={12}>
        <Autocomplete
          disableClearable={true}
          id="customer-combo-box"
          options={clientes}
          getOptionLabel={(option) => option.identAutoComplete}
          style={{ width: 300 }}
          onChange={(event, newValue) => handleCustomerChange(newValue)}
          renderInput={(params) => <TextField {...params} label="Selecionar Cliente" variant="outlined" />}
        />
      </Grid>

      <Grid item xs={12} component={Paper} style={{ padding: 10, marginTop: 20 }}>
        <Typography variant="subtitle1" color="textSecondary">Informações do Fiador</Typography>
        {selectedCustomer && (
          <div>
            <TextField
              size='small'
              label="Nome"
              name="nome"
              inputProps={{ maxLength: 255 }}
              value={guarantor.name}
              onChange={handleInputChange}
              style={{ marginBottom: '1rem' }}
              fullWidth
            />
            <TextField
              size='small'
              label="CPF"
              name="cpf"
              inputProps={{ maxLength: 20 }}
              value={guarantor.cpf}
              onChange={handleInputChange}
              style={{ marginBottom: '1rem' }}
              fullWidth
            />

            <TextField
              size='small'
              label="Rua"
              name="rua"
              inputProps={{ maxLength: 255 }}
              value={guarantor.endereco}
              onChange={handleInputChange}
              style={{ marginBottom: '1rem' }}
              fullWidth
            />

            <TextField
              size='small'
              label="Bairro"
              name="bairro"
              inputProps={{ maxLength: 255 }}
              value={guarantor.endereco}
              onChange={handleInputChange}
              style={{ marginBottom: '1rem' }}
              fullWidth
            />

            <TextField
              size='small'
              label="Cep"
              name="cep"
              inputProps={{ maxLength: 255 }}
              value={guarantor.endereco}
              onChange={handleInputChange}
              style={{ marginBottom: '1rem' }}
              fullWidth
            />

            <TextField
              size='small'
              label="Número Casa"
              name="numeroCasa"
              inputProps={{ maxLength: 255 }}
              value={guarantor.endereco}
              onChange={handleInputChange}
              style={{ marginBottom: '1rem' }}
              fullWidth
            />

            <TextField
              size='small'
              label="Cidade Estado"
              name="cidadeEstado"
              inputProps={{ maxLength: 255 }}
              value={guarantor.endereco}
              onChange={handleInputChange}
              style={{ marginBottom: '1rem' }}
              fullWidth
            />

            <Button variant="contained" color="primary" onClick={handleSave} disabled={saving}>
              Salvar
            </Button>
          </div>
        )}
      </Grid>

      <Grid item xs={12} component={Paper} style={{ marginTop: 20, padding: 10 }}>
        <Typography variant="subtitle1" color="textSecondary">Lista de fiadores</Typography>
        {selectedCustomer &&
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Cod. Cliente</TableCell>
                <TableCell>Nome Fiador</TableCell>
                <TableCell>CPF Fiador</TableCell>
                <TableCell>Rua</TableCell>
                <TableCell>Bairro</TableCell>
                <TableCell>CEP</TableCell>
                <TableCell>Numero</TableCell>
                <TableCell>Cidade Estado</TableCell>
                <TableCell>Ações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {vinculoFiadores.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{row.cod_cliente}</TableCell>
                  <TableCell>{row.nome}</TableCell>
                  <TableCell>{row.cpf}</TableCell>
                  <TableCell>{row.rua}</TableCell>
                  <TableCell>{row.bairro}</TableCell>
                  <TableCell>{row.cep}</TableCell>
                  <TableCell>{row.numeroCasa}</TableCell>
                  <TableCell>{row.cidadeEstado}</TableCell>
                  <TableCell>
                    <Button disabled={deleting} color="secondary" variant="contained" size="small" onClick={() => handleDelete(row.cod_fiador)}>Deletar</Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>}
      </Grid>
    </Grid>
  )
}

function mapStateToProps(state) {
  return {
    token: state.token,
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setToken: setToken,
      setUser: setUser,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientesFiadores);
