import React, { useState, useEffect } from 'react';
import api from 'services/api.js';
import { getUser, getToken } from 'utils/utils.js'
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Paper,
    TextField,
    Button,
    TableContainer
} from "@material-ui/core";

export default function EditorCusto({ itens, setItens, close, itensBarcarena, setItensBarcarena, itensParangua, setItensParanagua }) {

    async function update_custos(key, price) {

        const response_update = await api.get("/update_custos_fixos", {
            params: {
                'preco': price,
                'code': key,
                userId: getUser(), token: getToken(),

            },
        });
        if ((response_update.data === "NOK") | (response_update.data === "NOS")) {
            alert('Erro ao atualizar')

        }
        else {
            const response = await api.get("retorna_custos_fixos", {
                params: { userId: getUser(), token: getToken() },
            });
            setItens(response.data);

            return
        }

    }

    async function update_custos_barcarena(key, price) {

        const response_update = await api.get("/update_custos_fixos_barcarena", {
            params: {
                'preco': price,
                'code': key,
                userId: getUser(), token: getToken(),

            },
        });
        if ((response_update.data === "NOK") | (response_update.data === "NOS")) {
            alert('Erro ao atualizar')

        }
        else {
            const response = await api.get("retorna_custos_fixos_barcarena", {
                params: { userId: getUser(), token: getToken() },
            });
            setItensBarcarena(response.data);

            return
        }

    }


    async function update_custos_parangua(key, price) {

        const response_update = await api.get("/update_custos_fixos_paranagua", {
            params: {
                'preco': price,
                'code': key,
                userId: getUser(), token: getToken(),

            },
        });
        if ((response_update.data === "NOK") | (response_update.data === "NOS")) {
            alert('Erro ao atualizar')

        }
        else {
            const response = await api.get("retorna_custos_fixos_parangua", {
                params: { userId: getUser(), token: getToken() },
            });
            setItensParanagua(response.data);

            return
        }

    }

    return (
        <div style={{ padding: 20 }}>
            <div>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                            <TableCell>Centro de Custo</TableCell>

                                <TableCell>Valor Bag $</TableCell>
                                <TableCell>Envase $</TableCell>
                                <TableCell>Desembaraco $</TableCell>
                                <TableCell>Margem $</TableCell>
                                <TableCell>Frete $</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {itens.map((item, index) => (
                                <TableRow key={index}>
                                    <TableCell>
                                       Bom Jesus
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            type="number"
                                            defaultValue={item.valor_bag}
                                            onChange={(e) => update_custos('valor_bag', e.target.value)}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            type="number"
                                            defaultValue={item.envase}
                                            onChange={(e) => update_custos('envase', e.target.value)}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            type="number"
                                            defaultValue={item.desembaraco}
                                            onChange={(e) => update_custos('desembaraco', e.target.value)}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            type="number"
                                            defaultValue={item.margem}
                                            onChange={(e) => update_custos('margem', e.target.value)}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            type="number"
                                            defaultValue={item.frete}
                                            onChange={(e) => update_custos('frete', e.target.value)}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                        <TableBody>
                            {itensBarcarena.map((item, index) => (
                                <TableRow key={index}>
                                     <TableCell>
                                       Barcarena
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            type="number"
                                            defaultValue={item.valor_bag}
                                            onChange={(e) => update_custos_barcarena('valor_bag', e.target.value)}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            type="number"
                                            defaultValue={item.envase}
                                            onChange={(e) => update_custos_barcarena('envase', e.target.value)}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            type="number"
                                            defaultValue={item.desembaraco}
                                            onChange={(e) => update_custos_barcarena('desembaraco', e.target.value)}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            type="number"
                                            defaultValue={item.margem}
                                            onChange={(e) => update_custos_barcarena('margem', e.target.value)}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            type="number"
                                            defaultValue={item.frete}
                                            onChange={(e) => update_custos_barcarena('frete', e.target.value)}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                        <TableBody>
                            {itensParangua.map((item, index) => (
                                <TableRow key={index}>
                                     <TableCell>
                                       Paranaguá
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            type="number"
                                            defaultValue={item.valor_bag}
                                            onChange={(e) => update_custos_parangua('valor_bag', e.target.value)}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            type="number"
                                            defaultValue={item.envase}
                                            onChange={(e) => update_custos_parangua('envase', e.target.value)}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            type="number"
                                            defaultValue={item.desembaraco}
                                            onChange={(e) => update_custos_parangua('desembaraco', e.target.value)}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            type="number"
                                            defaultValue={item.margem}
                                            onChange={(e) => update_custos_parangua('margem', e.target.value)}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            type="number"
                                            defaultValue={item.frete}
                                            onChange={(e) => update_custos_parangua('frete', e.target.value)}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 20 }}>
                <Button variant='contained' color='primary' size="small" onClick={() => close()}>Salvar</Button>
            </div>
        </div>

    );
}