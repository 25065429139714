import React, { useEffect, useState } from "react";

// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { setToken } from "../../flux/actions/tokenIndex";
import { setUser } from "../../flux/actions/userIndex";
import { TextField } from "@material-ui/core";

import api2 from "../../services/api2";
import Button from "components/CustomButtons/Button.js";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Loader from "react-loader-spinner";
import MuiPhoneInput from 'material-ui-phone-number';
import { checarPermissao, validateTokenUser, getUser, getToken } from '../../utils/utils.js'

function getList() {
    const list = localStorage.getItem('list')
    if (list === null) {
        return []
    }
    else {
        return JSON.parse(list)
    }

}
const useStyles = makeStyles(styles);

function SmsSend(props) {
    const [message, setMessage] = useState('');
    const [prod, setProd] = useState('');
    const [pedido, setPedido] = useState('')
    const [placa, setPlaca] = useState('')
    const [number, setNumber] = useState('')
    const [nome, setNome] = useState('')
    const [list, setList] = useState([])
    const [po, setPo] = useState('')
    const classes = useStyles();
    const [permitido, setPermitido] = useState(false)

    useEffect(() => {
        setList(getList())
        async function fetchMyAPI() {
            await validateTokenUser(props)
            setPermitido(await checarPermissao('sms', props))

            const response_po = await api2.get('/returnProductionOrders', { params: { userId: getUser(), token: getToken() } })
            setPo(response_po.data)
        }
        fetchMyAPI();

    }, []);

    async function smsLista(index, num, placa, pedido, produto, nome) {
        console.log(123)
        let msg = nome + ' , caminhão de placa ' + placa + ' favor se dirigir para a área de carregamento'
        console.log(msg)
        const response_materias = await api2.get("/sendMessage", {
            params: {
                message: msg,
                number: num,
                userId: getUser(),
                token: getToken()
            },
        });
        if (response_materias.data === 'NOK') {
            alert('Erro ao enviar SMS!')
        } else {
            let aux = list
            aux.splice(index, 1)
            localStorage.setItem('list', JSON.stringify(aux))
            alert('SMS enviado com sucesso!')
            window.location.reload();
        }
    }
    async function removeLista(index) {
        let aux = list
        aux.splice(index, 1)
        console.log(aux, list, index)
        localStorage.setItem('list', JSON.stringify(aux))
        alert('SMS removido com sucesso!')
        window.location.reload();
    }
    async function fetchapi2() {
        console.log(123)
        let msg = nome + ' , caminhão de placa ' + placa + ' favor se dirigir para a área de carregamento'
        console.log(msg)
        if (placa === '' || number === '') {
            alert('preencha todos os campos!')
            return
        }
        const response_materias = await api2.get("/sendMessage", {
            params: {
                message: msg,
                number: number,
                userId: getUser(),
                token: getToken()
            },
        });

        if (response_materias.data === 'NOK') {
            alert('Erro ao enviar SMS!')
        } else {
            alert('SMS enviado com sucesso!')
            window.location.reload();
        }
    }

    async function sendSmsPO(nome, telefone, produto, placa, qtd, docEntry, countSms) {
        console.log(123)
        let msg = nome + ' , caminhão de placa ' + placa + ' favor se dirigir para a área de carregamento, Produto: ' + produto + ' Quantidade: ' + qtd + ' ton'
        console.log(msg)
        if (placa === '' || telefone === '') {
            alert('preencha todos os campos!')
            return
        }
        const response_materias = await api2.get("/sendMessage", {
            params: {
                message: msg,
                number: telefone,
                userId: getUser(),
                token: getToken()
            },
        });

        if (response_materias.data === 'NOK') {
            alert('Erro ao enviar SMS!')
        } else {
            alert('SMS enviado com sucesso!')
            const response_increment = await api2.get("/incrementaContSmsPO", {
                params: {
                    docEntry: docEntry,
                    countSms: countSms,
                    cellphone: telefone,
                    userId: getUser(),
                    token: getToken()
                },
            });

            window.location.reload();
        }
    }
    async function ignoraPO(docEntry, telefone) {


        const response_ignora = await api2.get("/ignoraPO", {
            params: {
                docEntry,
                cellphone: telefone,
                userId: getUser(),
                token: getToken()
            },
        });

        if (response_ignora.data === 'NOK') {
            alert('Erro ao ignorar OP!')
        } else {
            alert('OP ignorada com sucesso!')

            window.location.reload();
        }
    }

    function insertList() {
        let aux = []
        if (placa === '' || number === '') {
            alert('preencha tudo')
            return
        }

        aux = [...list, JSON.stringify({ 'pedido': pedido, 'placa': placa, 'produto': prod, 'numero': number, 'nome': nome })]

        alert('Adicionado a lista com sucesso!')
        localStorage.setItem('list', JSON.stringify(aux))
        window.location.reload();

    }
    return (
        permitido === true ?
            <div>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <div>
                            <h1> SmsSend </h1>
                            <div>
                                <TextField style={{ width: 600 }} label={'Nome Motorista'} onChange={(e) => setNome(e.target.value)} />
                            </div>
                            <div>
                                <TextField style={{ width: 600 }} label={'Produto'} onChange={(e) => setProd(e.target.value)} />
                            </div>
                            <div>

                                <TextField style={{ width: 600 }} label={'Placa Caminhão'} onChange={(e) => setPlaca(e.target.value)} />
                            </div>
                            <div>

                                <TextField style={{ width: 600 }} label={'Numero Pedido'} onChange={(e) => setPedido(e.target.value)} />
                            </div>
                            <MuiPhoneInput
                                defaultCountry='br'
                                style={{ width: 333, marginTop: 15 }}

                                onChange={(e) => setNumber(e)}
                                value={number}
                                required
                                label='Telefone'
                            />


                        </div>
                    </GridItem>
                    <Button style={{ backgroundColor: '#6699ff' }} variant="contained" onClick={() => fetchapi2()}> Enviar SMS Agora</Button>
                    <Button style={{ backgroundColor: '#6699ff' }} variant="contained" onClick={() => insertList()}> Adicionar à programação</Button>
                </GridContainer>
                <List style={{
                    width: '100%',


                }}>
                    {list.length !== 0 ?
                        Object.keys(list).map((index, item) =>
                            <ListItem key={index}>
                                <ListItemText style={{ width: 700 }} primary={'Número: ' + JSON.parse(list[index]).numero} secondary={'Placa: ' + JSON.parse(list[index]).placa} />
                                <ListItemText style={{ width: 700 }} primary={'Número Pedido: ' + JSON.parse(list[index]).nome} secondary={'Produto: ' + JSON.parse(list[index]).produto} />

                                <Button style={{ backgroundColor: '#6699ff' }} variant="contained" onClick={() => smsLista(index, JSON.parse(list[index]).numero, JSON.parse(list[index]).placa, JSON.parse(list[index]).pedido, JSON.parse(list[index]).produto, JSON.parse(list[index]).nome)}> Enviar sms</Button>
                                <Button style={{ backgroundColor: '#6699ff' }} variant="contained" onClick={() => removeLista(index)}> Remover da lista</Button>


                            </ListItem>
                        ) : console.log('falcatrua')}
                </List>
                {/*                  */}
                <h3> Ordens de produção</h3>
                <List style={{
                    width: '100%',


                }}>
                    {po.length !== 0 ?
                        Object.keys(po).map((index, item) =>
                            <ListItem key={index} style={{ borderStyle: 'solid' }}>
                                <ListItemText style={{ width: 700 }} primary={'Produto: ' + po[index].ProdName} secondary={'Placa: ' + po[index].placa} />
                                <ListItemText style={{ width: 700 }} primary={'Motorista: ' + po[index].codMotorista} secondary={'Telefone: ' + po[index].telefone} />
                                <ListItemText style={{ width: 700 }} primary={'Quantidade: ' + po[index].U_next_qtdetonelada + ' ton'} secondary={'Sms enviados: ' + po[index].smsCount} />
                                <div style={{ width: 500 }}>
                                    {po[index].ProdName !== '' & po[index].placa !== '' & po[index].codMotorista !== '' & po[index].telefone !== '' ?
                                        <div>
                                            <Button style={{ backgroundColor: '#6699ff' }} variant="contained" onClick={() => sendSmsPO(po[index].codMotorista, po[index].telefone, po[index].ProdName, po[index].placa, po[index].U_next_qtdetonelada, po[index].docEntry, po[index].smsCount)}> Chamar motorista</Button>
                                            <Button style={{ backgroundColor: '#6699ff' }} variant="contained" onClick={() => ignoraPO(po[index].docEntry, po[index].telefone)}> Remover OP</Button>
                                        </div>
                                        :
                                        <p style={{ fontSize: 12 }}> Campos não encontrados no sistema:<br /> {po[index].ProdName === '' ? <p>Nome do produto <br /></p> : ''} {po[index].codMotorista === '' ? <p>Nome do motorista <br /></p> : ''}
                                            {po[index].placa === '' ? <p>Placa do caminhã<br /></p> : ''}{po[index].telefone === '' ? <p>Telefone do motorista <br /></p> : ''}
                                            {po[index].U_next_qtdetonelada === '' ? <p>Quantidade a ser carregada <br /></p> : ''} </p>}
                                </div>
                            </ListItem>
                        ) : <Loader
                            type="Circles"
                            color="#00BFFF"
                            height={20}
                            width={20}
                            timeout={20000} //3 secs
                        />}
                </List>
            </div> : <></>
    );
}
function mapStateToProps(state) {
    return {
        token: state.token,
        user: state.user,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            setToken: setToken,
            setUser: setUser,
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(SmsSend);
