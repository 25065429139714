import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { setToken } from "../../flux/actions/tokenIndex";
import { setUser } from "../../flux/actions/userIndex";
import api from "../../services/api";
import InfiniteScroll from 'react-infinite-scroll-component';
import { Carousel } from 'react-responsive-carousel';

import carouselStyle from "../../../node_modules/react-responsive-carousel/lib/styles/carousel.min.css"

import { makeStyles } from "@material-ui/core/styles";
import { TextField } from "@material-ui/core";
import Button from "components/CustomButtons/Button.js"
import Autocomplete from "@material-ui/lab/Autocomplete";

import {
    filesToBase64,
    checarPermissao,
    validateTokenUser, getUser, getToken
} from '../../utils/utils.js'


function JornalGen(props) {
    // Valores pra criar publicação
    const [text, setText] = useState('')
    const [header, setHeader] = useState('')
    const [images, setImages] = useState([])
    const [fontes, setFontes] = useState([])
    const [formVisible, setFormVisible] = useState(false);

    // Valores da edição
    const [textEdit, setTextEdit] = useState('')
    const [headerEdit, setHeaderEdit] = useState('')
    const [imagesEdit, setImagesEdit] = useState([])
    const [fontesEdit, setFontesEdit] = useState([])

    // Objetos do back
    const [posts, setPosts] = useState('')
    const [fixado, setFixado] = useState('')


    // Validação de usuario
    const [userAllowed, setUserAllowed] = useState(false)

    // Impedir que violem nossos botões
    const [postando, setPostando] = useState(false)
    const [fixando, setFixando] = useState(false)
    const [desafixando, setDesafixando] = useState(false)
    const [deletando, setDeletando] = useState(false)
    const [salvando, setSalvando] = useState(false)

    const classes = useStyles();
    const [permitido, setPermitido] = useState(false)

    // state infinite scroll
    const [morePosts, setMorePosts] = useState(0)
    const [hasMore, setHasMore] = useState(true)

    const [postBeingEditedId, setPostBeingEditedId] = useState('')

    useEffect(() => {
        async function fetchMyApi() {
            await validateTokenUser(props)
            setPermitido(await checarPermissao('jornalGen', props))

            const getPosts = await api.get('retornaPosts', { params: { userId: getUser(), token: getToken(), morePosts } }) // resposta 0
            const getFixado = await api.get('retornaFixado', { params: { userId: getUser(), token: getToken() } })
            let aux = getFixado.data.concat(getPosts.data)
            setPosts(aux)

            if (getFixado.data.length !== 0) {
                setFixado(getFixado.data[0])
            }
        }
        fetchMyApi()
        let id = JSON.parse(localStorage.getItem('permissoes'))
        if (id !== null) {
            id = id[0].idUsuario
            // gleice, unity, thais, marcio, sandoval, fabio nunes, gabrielle rodrigues, flaviane morgana //
            if (id === 5 || id === 59 || id === 72 || id === 58 || id === 80 || id === 254 || id === 264 || id === 267 || id === 284) {
                setUserAllowed(true)
            }
        }
    }, [])

    async function insertPost() {
        setPostando(true)
        const insert = await api.get('insertPost', {
            params: {
                userId: getUser(),
                token: getToken(),
                text,
                header,
                'images': images.join('identEndOfFile'),
                'fontes': fontes.join('identEndOfFile'),
            }
        })
        if (insert.data === 'OK') {
            alert('Postado')
            window.location.reload()
        } else {
            alert('Erro ao inserir publicação, entre em contato com o time de desenvolvimento')
        }
        setPostando(false)
    }

    async function setImagesBase64(ref, edit) {
        const aux = await filesToBase64(ref)
        if (edit === true) {
            setImagesEdit(aux)
        } else {
            setImages(aux)
        }
    }

    async function deletarPost(id) {
        setDeletando(true)
        const deletar = await api.get('deletarPost', { params: { valor: 1, id, userId: getUser(), token: getToken() } })
        if (deletar.data === 'OK') {
            alert('Deletado!')
            window.location.reload()
        } else {
            alert('Erro ao deletar publicação, entre em contato com o time de desenvolvimento')
        }
        setDeletando(false)
    }

    function editarPost(item, index) {
        setPostBeingEditedId(item.id)
        setHeaderEdit(item.header)
        setTextEdit(item.text)
        setImagesEdit(item.images.split('identEndOfFile'))
        setFontesEdit(item.fontes.split('identEndOfFile'))
    }

    async function salvarEdicao(postId) {
        setSalvando(true)
        const saveEdit = await api.get('saveEdit', {
            params: {
                id: postId,
                'text': textEdit,
                'header': headerEdit,
                'images': imagesEdit.join('identEndOfFile'),
                'fontes': fontesEdit.join('identEndOfFile'),
                valor: 2,
                userId: getUser(),
                token: getToken()
            }
        })
        if (saveEdit.data === 'OK') {
            alert('Edição salva com sucesso!')
            window.location.reload()
        } else {
            alert('Erro ao editar publicação, entre em contato com o time de desenvolvimento')
        }
        setSalvando(false)
    }

    function cancelarEdicao() {
        setPostBeingEditedId('')
        setTextEdit('')
        setHeaderEdit('')
        setImagesEdit([])
        setFontesEdit([])
    }

    async function fixarPost(id) {
        setFixando(true)
        const fix = await api.get('/fixarPost', {
            params: {
                id,
                userId: getUser(),
                token: getToken()
            }
        })

        if (fix.data === 'OK') {
            alert('Post fixado!')
            window.location.reload()
        } else {
            alert('Erro ao fixar publicação, entre em contato com o time de desenvolvimento')
        }
        setFixando(false)
    }

    async function desafixarPost() {
        setDesafixando(true)
        const fix = await api.get('/desafixarPost', { params: { userId: getUser(), token: getToken() } })

        if (fix.data === 'OK') {
            alert('Post desafixado!')
            window.location.reload()
        } else {
            alert('Erro ao desfixar publicação, entre em contato com o time de desenvolvimento')
        }
        setDesafixando(false)
    }

    async function getMorePosts() {
        let valor = morePosts + 1
        setMorePosts(valor)
        const response = await api.get('retornaPosts', { params: { userId: getUser(), token: getToken(), 'morePosts': valor } })
        if (response.data.length !== 0) {
            let aux = [...posts]
            aux = aux.concat(response.data)
            setPosts(aux)
            setHasMore(true)
            return aux
        } else {
            setHasMore(false)
        }
    }

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    return ( // return principal
        permitido === true ?
            <div className={classes.tudoBackground}>
                {userAllowed ?
                
                <div>
                {/* Botão Criar Publicação */}
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setFormVisible(!formVisible)}  // Alterna a visibilidade do formulário
                >
                    Criar Publicação
                </Button>
    
                {/* Formulário de criação de publicação */}
                {formVisible ? (
                    <div className={classes.createPublication}>
                        <h3>Criar Publicação</h3>
                        <div className={classes.divTitulo}>
                            <TextField
                                multiline
                                className={classes.headerInput}
                                inputProps={{ maxLength: 100 }}
                                variant='outlined'
                                onChange={(e) => setHeader(e.target.value)}
                                label='Título'
                            />
                        </div>
                        <div style={{ marginBottom: 10 }}>
                            <TextField
                                multiline
                                variant='outlined'
                                className={classes.text}
                                onChange={(e) => setText(e.target.value)}
                                label='Texto'
                            />
                        </div>
                        <div>
                            {images.length === 1 && images.length !== 0 ? (
                                <div style={{ margin: 10 }}>
                                    <img src={images[0]} className={classes.image} />
                                </div>
                            ) : (
                                <Carousel
                                    dynamicHeight={true}
                                    width='90%'
                                    showStatus={false}
                                    showIndicators={false}
                                >
                                    {images.map((item, index) => (
                                        <div key={index}>
                                            <img src={item} className={carouselStyle} />
                                        </div>
                                    ))}
                                </Carousel>
                            )}
                        </div>
                        <Button
                            className={classes.buttonImagem}
                            variant="contained"
                            component="label"
                        >
                            Escolher Imagens
                            <input
                                multiple
                                accept="image/png, image/gif, image/jpeg, image/jpg"
                                type="file"
                                id="myfileinput"
                                onChange={(e) => setImagesBase64(e.target.files, false)}
                                hidden
                            />
                        </Button>
                        <div style={{ marginTop: 5 }}>
                            <Autocomplete
                                freeSolo
                                autoSelect
                                multiple
                                options={[]}
                                onChange={(index, valor) => setFontes(valor)}
                                variant='outlined'
                                renderInput={(params) => (
                                    <TextField variant='outlined' {...params} variant='outlined' label='Fontes' />
                                )}
                            />
                        </div>
                        <Button
                            disabled={postando}
                            className={classes.buttonSalvar}
                            onClick={insertPost}
                        >
                            Publicar
                        </Button>
                    </div>
                ) : null}
            </div>
                    : null
                }

                {fixado !== '' ?
                    <div className={classes.postFixadoBackground}>
                        {
                            postBeingEditedId !== fixado.id ?
                                <div>
                                    <div>Publicação fixada por: {capitalizeFirstLetter(fixado.user)}</div>
                                    <div>{fixado.data}</div>
                                    {fixado.postState === 2 ? <div>Editado</div> : ''}
                                    {userAllowed && postBeingEditedId === "" ?
                                        <div>
                                            <Button className={classes.buttonEditar} onClick={() => editarPost(fixado, 0)}>Editar</Button>
                                        </div> : null
                                    }
                                    <div className={classes.borderHeader}>
                                        <h2 className={classes.header}>{fixado.header}</h2>
                                    </div>
                                    <p className={classes.texto}>{fixado.text}</p>
                                    {fixado.images.split('identEndOfFile').length === 1 && fixado.images.split('identEndOfFile').length !== 0 ?
                                        <div style={{ margin: 10 }}>
                                            <img src={fixado.images.split('identEndOfFile')[0]} className={classes.image} />
                                        </div> :
                                        <Carousel
                                            dynamicHeight={true}
                                            width='90%'
                                            showStatus={false}
                                            emulateTouch={true}
                                            showIndicators={false}
                                        >
                                            {fixado.images.split('identEndOfFile').map((item, index) =>
                                                item === '' ? null :
                                                    <div key={index}>
                                                        <img src={item} className={carouselStyle} />
                                                    </div>)
                                            }
                                        </Carousel>
                                    }
                                    <div>
                                        {String(fixado.fontes).split('identEndOfFile').map((item, index) => {
                                            if (item !== '') {
                                                return (<a key={index} target="_blank" href={item}> Fonte {index + 1} </a>)
                                            }
                                        })}
                                    </div>
                                </div>
                                :
                                <div>
                                    <Button disabled={desafixando} className={classes.buttonFixar} onClick={() => desafixarPost()}>Desafixar Post</Button>
                                    <div className={classes.divTitulo}>
                                        <TextField multiline className={classes.headerInput} defaultValue={fixado.header} inputProps={{ maxLength: 80 }} onChange={(e) => setHeaderEdit(e.target.value)} variant='outlined' label='Título' />
                                    </div>
                                    <div className={classes.divTextEdit}>
                                        <TextField defaultValue={fixado.text} multiline variant='outlined' className={classes.text} onChange={(e) => setTextEdit(e.target.value)} label='Texto' />
                                    </div>
                                    {/*Carousel no modo edição*/}
                                    {imagesEdit.length > 1 ?
                                        <Carousel
                                            dynamicHeight={true}
                                            width='90%'
                                            showStatus={false}
                                            emulateTouch={true}
                                            showIndicators={false}
                                        >
                                            {imagesEdit.map((item2, index) => <div>
                                                <img key={index} src={item2} className={carouselStyle} />
                                            </div>)}
                                        </Carousel> : <div style={{ margin: 10 }}>
                                            <img src={imagesEdit[0]} className={classes.image} />
                                        </div>}
                                    {fixado.images.split('identEndOfFile').length > 1 && imagesEdit.length === 0 ?
                                        <Carousel
                                            dynamicHeight={true}
                                            width='90%'
                                            showStatus={false}
                                            emulateTouch={true}
                                            showIndicators={false}
                                        >
                                            {fixado.images.split('identEndOfFile').map((item2, index) => <div>
                                                <img key={index} src={item2} className={carouselStyle} />
                                            </div>)}
                                        </Carousel> : fixado.images.split('identEndOfFile').length === 1 && imagesEdit.length === 0 ? <div style={{ margin: 10 }}>
                                            <img src={fixado.images.split('identEndOfFile')[0]} className={classes.image} />
                                        </div> : null}
                                    <Button
                                        className={classes.buttonImagem}
                                        variant="contained"
                                        component="label" >
                                        Escolher Imagens
                                        <input
                                            multiple
                                            accept="image/png, image/gif, image/jpeg, image/jpg"
                                            type="file" id="myfileinput"
                                            onChange={(e) => { setImagesBase64(e.target.files, true) }} hidden />
                                    </Button>
                                    <div className={classes.divFontes}>
                                        <Autocomplete
                                            defaultValue={fixado.fontes === '' ? [] : fixado.fontes.split('identEndOfFile')}
                                            freeSolo
                                            autoSelect
                                            multiple
                                            options={[]}
                                            onChange={(index, valor) => setFontesEdit(valor)}
                                            variant='outlined'
                                            renderInput={(params) => (<TextField variant='outlined' {...params} variant='outlined' label='Fontes' />)}
                                        />
                                    </div>
                                    <div>
                                        <Button disabled={salvando} className={classes.buttonSalvar} onClick={() => salvarEdicao(fixado.id)}>Salvar Edição</Button>
                                        <Button onClick={() => cancelarEdicao()}>Cancelar Edição</Button>
                                        <Button disabled={deletando} className={classes.buttonDeletar} className={classes.buttonDeletar} onClick={() => deletarPost(fixado.id)}>Deletar</Button>
                                    </div>
                                </div>
                        }
                    </div>
                    : null
                }

                <div>
                <img src={require('../../assets/img/jornal-gen.png')} alt="Jornal GeN" className={classes.headerImage} />
                </div>
                {posts === '' ? 
                    <div className={classes.loadingContainer}>
                        <div className={classes.spinner}></div>
                        <span className={classes.loadingText}>Carregando...</span>
                    </div> 
                : null}

                {posts === '' ? null :
                    <InfiniteScroll
                        dataLength={posts.length}
                        next={() => getMorePosts()}
                        hasMore={hasMore}
                        loader={
                        <div className={classes.loadingContainer}>
                            <div className={classes.spinner}></div>
                            <span className={classes.loadingText}>Carregando...</span>
                        </div> 
                        }
                    >
                        <div className={classes.postsContainer}>
                            {Object.keys(posts).map((item, index) => {
                                if (posts[item].fixado !== 1) {
                                    return (
                                        <div key={posts[item].id} className={classes.postBackground}>
                                            {postBeingEditedId !== posts[item].id ?
                                                <div>
                                                    <div className={classes.userDataContainer}>
                                                        <div className={classes.authorSection}>
                                                            <div className={classes.authorText}>Autor(a)</div>
                                                            <div className={classes.userName}>{capitalizeFirstLetter(posts[item].user)}</div>
                                                        </div>
                                                        <div className={classes.postDate}>Publicado em {posts[item].data}</div>
                                                    </div>
                                                    {posts[item].postState === 2 ? <div>Editado</div> : ''}
                                                    {userAllowed && postBeingEditedId === "" ?
                                                        <Button className={classes.buttonEditar} onClick={() => editarPost(posts[item], index)}>Editar</Button>
                                                        : null}
                                                    <div className={classes.borderHeader}>
                                                        <h2 className={classes.header}>{posts[item].header}</h2>
                                                    </div>
                                                    <p className={classes.texto}>{posts[item].text}</p>

                                                    {posts[item].images.split('identEndOfFile').length === 1 && posts[item].images.split('identEndOfFile').length !== 0 ?
                                                        <div style={{ margin: 10 }}>
                                                            <img src={posts[item].images.split('identEndOfFile')[0]} className={classes.image} />
                                                        </div> :
                                                        <Carousel
                                                            dynamicHeight={true}
                                                            width='90%'
                                                            showStatus={false}
                                                            emulateTouch={true}
                                                            showIndicators={false}
                                                        >
                                                            {posts[item].images.split('identEndOfFile').map((item, index) =>
                                                                item === '' ? null :
                                                                    <div key={index}>
                                                                        <img src={item} className={carouselStyle} />
                                                                    </div>)
                                                            }
                                                        </Carousel>
                                                    }

                                                    <div>
                                                        {posts[item].fontes.split('identEndOfFile').map((item, index) =>
                                                            item === '' ? null :
                                                                <a key={index} target="_blank" href={item}> Fonte {index + 1} </a>
                                                        )}
                                                    </div>
                                                </div>
                                                :
                                                <div>
                                                    <Button disabled={fixando} className={classes.buttonFixar} onClick={() => fixarPost(posts[item].id)}>Fixar Post</Button>
                                                    <div className={classes.divTitulo}>
                                                        <TextField multiline className={classes.headerInput} defaultValue={posts[item].header} inputProps={{ maxLength: 80 }} onChange={(e) => setHeaderEdit(e.target.value)} variant='outlined' label='Título' />
                                                    </div>
                                                    <div className={classes.divTextEdit}>
                                                        <TextField defaultValue={posts[item].text} multiline variant='outlined' className={classes.text} onChange={(e) => setTextEdit(e.target.value)} label='Texto' />
                                                    </div>
                                                    {/*Carousel no modo edição*/}
                                                    {imagesEdit.length > 1 ?
                                                        <Carousel
                                                            dynamicHeight={true}
                                                            width='90%'
                                                            showStatus={false}
                                                            emulateTouch={true}
                                                            showIndicators={false}
                                                        >
                                                            {imagesEdit.map((item2, index) => <div>
                                                                <img key={index} src={item2} className={carouselStyle} />
                                                            </div>)}
                                                        </Carousel> : <div style={{ margin: 10 }}>
                                                            <img src={imagesEdit[0]} className={classes.image} />
                                                        </div>}

                                                    {posts[item].images.split('identEndOfFile').length > 1 && imagesEdit.length === 0 ?
                                                        <Carousel
                                                            dynamicHeight={true}
                                                            width='90%'
                                                            showStatus={false}
                                                            emulateTouch={true}
                                                            showIndicators={false}
                                                        >
                                                            {posts[item].images.split('identEndOfFile').map((item2, index) => <div>
                                                                <img key={index} src={item2} className={carouselStyle} />
                                                            </div>)}
                                                        </Carousel> : posts[item].images.split('identEndOfFile').length === 1 && imagesEdit.length === 0 ? <div style={{ margin: 10 }}>
                                                            <img src={posts[item].images.split('identEndOfFile')[0]} className={classes.image} />
                                                        </div> : null
                                                    }
                                                    <Button
                                                        className={classes.buttonImagem}
                                                        variant="contained"
                                                        component="label" >
                                                        Escolher Imagens
                                                        <input
                                                            multiple
                                                            accept="image/png, image/gif, image/jpeg, image/jpg"
                                                            type="file" id="myfileinput"
                                                            onChange={(e) => { setImagesBase64(e.target.files, true) }} hidden />
                                                    </Button>
                                                    <div className={classes.divFontes}>
                                                        <Autocomplete
                                                            defaultValue={posts[item].fontes === '' ? [] : posts[item].fontes.split('identEndOfFile')}
                                                            freeSolo
                                                            autoSelect
                                                            multiple
                                                            options={[]}
                                                            onChange={(index, valor) => setFontesEdit(valor)}
                                                            variant='outlined'
                                                            renderInput={(params) => (<TextField variant='outlined' {...params} variant='outlined' label='Fontes' />)}
                                                        />
                                                    </div>
                                                    <div>
                                                        <Button disabled={salvando} className={classes.buttonSalvar} onClick={() => salvarEdicao(posts[item].id)}>Salvar Edição</Button>
                                                        <Button onClick={() => cancelarEdicao()}>Cancelar Edição</Button>
                                                        <Button disabled={deletando} className={classes.buttonDeletar} onClick={() => deletarPost(posts[item].id)}>Deletar</Button>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    )
                                }
                            })}
                        </div>
                    </InfiniteScroll>
                }
            </div> : null
    )
}

const useStyles = makeStyles({
    editBackground: {
        border: 'solid 1px blue',
        borderRadius: 5,
        padding: '30px',
        background: 'white',

    },
    createPublication: {
        border: 'solid 1px blue',
        borderRadius: 5,
        padding: '30px',
        marginTop: 20,
        marginBottom: 30,
        background: 'white',

    },

    postBackground: {
        borderRadius: 5,
        padding: '30px',
        marginTop: 40,
        background: 'white',
        margin: 'auto',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',  // Alinha o conteúdo verticalmente
        justifyContent: 'space-between',  // Justifica o conteúdo
        boxSizing: 'border-box', // Garante que o padding não aumente o tamanho total
        width: '100%',  // O post vai ocupar 100% da largura da coluna
    },

    postsContainer: {
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)', // Dividir a tela em duas colunas de 50% cada
        gap: '20px', // Espaçamento entre os posts
        width: '100%',
        justifyItems: 'center', // Justifica os itens dentro das colunas
    },

    tudoBackground: {
        background: 'transparent',
        borderRadius: 5,
    },

    userDataContainer: {
        display: 'flex',
        justifyContent: 'space-between',  // Coloca a seção do autor à esquerda e a data à direita
        alignItems: 'flex-start',  // Alinha o conteúdo no topo
        marginBottom: '10px',  // Adiciona um pequeno espaçamento abaixo, se necessário
        fontSize: '14px',  // Ajuste o tamanho da fonte conforme necessário
        color: '#555',  // Cor do texto, ajuste conforme necessário
    },

    authorSection: {
        display: 'flex',
        flexDirection: 'column',  // Organiza o autor e nome do usuário em coluna
        alignItems: 'flex-start',  // Alinha os elementos à esquerda
    },

    authorText: {
        fontWeight: 'bold',
        fontSize: '12px',  // Tamanho da fonte para o "Autor"
        color: '#888',  // Cor mais suave para o texto "Autor"
        marginBottom: '-10px',  // Espaço entre "Autor" e o nome do usuário
    },

    userName: {
        fontWeight: 'bold',
        fontSize: '14px',  // Tamanho da fonte para o nome do usuário
        color: '#333',  // Cor do nome do usuário
    },

    postDate: {
        fontSize: '12px',  // Tamanho da fonte para a data
        color: '#888',  // Cor da data
        alignSelf: 'flex-end',  // Alinha a data à direita
        marginTop: '4px',  // Espaço entre o nome do usuário e a data
    },    // Estilo para o "Carregando..."
    loadingContainer: {
        display: 'flex',
        justifyContent: 'center', // Centraliza o spinner horizontalmente
        alignItems: 'center', // Centraliza o spinner verticalmente
        minHeight: '100vh', // Garante que ocupe toda a altura da tela
    },

    loadingText: {
        fontSize: '24px',
        color: '#333',
        marginLeft: '10px',
    },

    // Spinner (círculo girando)
    spinner: {
        border: '6px solid #f3f3f3', /* Cor do fundo */
        borderTop: '6px solid #3498db', /* Cor do topo */
        borderRadius: '50%',
        width: '40px',
        height: '40px',
        animation: '$spin 2s linear infinite',  // Animação do giro
    },

    '@keyframes spin': {
        '0%': {
            transform: 'rotate(0deg)',
        },
        '100%': {
            transform: 'rotate(360deg)',
        },
    },

    text: {
        width: '100%'  // Garante que o texto ocupe 100% da largura do post
    },

    headerInput: {
        width: '100%'  // Garante que o título ocupe toda a largura disponível
    },

    image: {
        width: '100%',  // Garante que as imagens ocupem toda a largura do post
        maxWidth: '100%',
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
    },

    header: {
        fontWeight: 'bold',
        fontSize: '24px',
        color: 'black',
        fontFamily: 'Arial',
        margin: 0,
        marginBottom: '10px',  
        padding: 0,
        lineHeight: '1.0', 
        wordBreak: 'break-word', 
    },

    headerImage: {
        maxWidth: '80%',  // Garante que a imagem ocupe até 100% da largura do contêiner
        height: 'auto',  // Mantém a proporção da imagem
        maxHeight: '80px',  // Limita a altura máxima da imagem (ajuste conforme necessário)
        margin: '0 auto',  // Centraliza a imagem
        display: 'block',  // Garante que a imagem seja exibida como um bloco
    },

    texto: {
        fontWeight: 'bold',
        whiteSpace: 'pre-wrap'
    },

    buttonDeletar: {
        marginLeft: 800,
        background: '#ED3237',
        '&:hover': {
            background: '#ED3237'
        }
    },

    buttonEditar: {
        position: 'static',
        margin: 10,
        background: 'linear-gradient(45deg, #2196F3 10%, #21CBF3 90%)'
    },

    buttonPublicar: {
        background: 'linear-gradient(45deg, #2196F3 10%, #21CBF3 90%)'
    },

    borderHeader: {
        content: '',
        display: 'flex',
        borderBottom: '1px solid black'
    },

    buttonImagem: {
        background: 'linear-gradient(45deg, #2196F3 10%, #21CBF3 90%)'
    },

    divImagem: {
        marginTop: 10
    },

    buttonFixar: {
        background: 'linear-gradient(45deg, #2196F3 10%, #21CBF3 90%)'
    },

    postFixadoBackground: {
        border: 'solid 1px yellow',
        borderRadius: 5,
        boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .5)',
        padding: '30px',
        marginTop: 40,
        background: 'white',
        position: 'relative',
        margin: 'auto',
        width: '100%',  // O post fixado ocupa toda a largura disponível
        boxSizing: 'border-box',  // Garante que o padding não ultrapasse a largura
    },

    buttonSalvar: {
        background: '#4bcc5a',
        '&:hover': {
            background: '#4bcc5a'
        }
    },

    divTextEdit: {
        marginTop: 10,
        marginBottom: 10
    },

    divTitulo: {
        marginTop: 10,
        marginBottom: 10
    },

    divFontes: {
        marginTop: 10
    },

    // Responsividade para telas menores
    '@media (max-width: 768px)': {
        postsContainer: {
            gridTemplateColumns: '1fr',  // Exibe um post por vez em telas pequenas
            gap: '10px',  // Menor espaçamento entre os posts
        },
        postBackground: {
            width: '100%',  // Os posts ocupam 100% da largura da tela
        }
    },

    // Estilos para o cabeçalho "Jornal GeN"
    headerContainer: {
        backgroundColor: '#2196F3',  // Cor de fundo azul
        color: 'white',
        padding: '20px',
        textAlign: 'center',
        fontSize: '32px',
        fontWeight: 'bold',
        borderRadius: '5px',
        marginTop: '20px',
        marginBottom: '40px',
    }
});

function mapStateToProps(state) {
    return {
        token: state.token,
        user: state.user,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            setToken: setToken,
            setUser: setUser,
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(JornalGen);