import React, { useEffect, useState } from "react";

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { setToken } from "../../flux/actions/tokenIndex";
import { setUser } from "../../flux/actions/userIndex";
import './Dashboard.css'
import api2 from "../../services/api2";
import Loader from "react-loader-spinner";

import exportFromJSON from 'export-from-json'
import { checarPermissao, validateTokenUser, getUser, getToken } from '../../utils/utils.js'

function getPrio() {
  const userString = localStorage.getItem('Prio')
  if (userString === null) {
    return ''
  }
  else {
    return userString
  }
}


function Dashboard(props) {

  // const [token, setToken] = useState('')
  const [rel, setRel] = useState('');// relatório de saldos
  const [prio, setPrio] = useState('')
  const [compArm, setCompArm] = useState('')//relatório de comprar armazenagem
  const [estoques, setEstoques] = useState('')
  const [fat, setFat] = useState('')
  const [consumo, setConsumo] = useState('')
  const [permitido, setPermitido] = useState(false)


  useEffect(() => {
    setPrio(getPrio())

    async function fetchMyAPI() {
      await validateTokenUser(props)
      setPermitido(await checarPermissao('dashboard', props))

      const response_rel = await api2.get("/retornaRelSaldos", { params: { prio: getPrio(), userId: getUser(), token: getToken() } });
      setRel(response_rel.data)
      const response_rel_arm = await api2.get("/retornaRelComprasArmazenagem", { params: { userId: getUser(), token: getToken() } });
      setCompArm(response_rel_arm.data)
      const response_rel_estoques = await api2.get("/relEstoquesVictor", { params: { userId: getUser(), token: getToken() } });
      setEstoques(response_rel_estoques.data)
      const response_rel_fat = await api2.get("/relFatVictor", { params: { userId: getUser(), token: getToken() } });
      setFat(response_rel_fat.data)
      const response_rel_consumo = await api2.get("/relConsumo", { params: { userId: getUser(), token: getToken() } });
      setConsumo(response_rel_consumo.data)
    }
    fetchMyAPI();

  }, [])
  const armazenagem = () => {
    const data = compArm
    const fileName = 'Relatório de Armazenagem'
    const exportType = exportFromJSON.types.xls

    exportFromJSON({ data, fileName, exportType })
  }
  const saldos = () => {
    const data = rel
    const fileName = 'Relatórios de Saldos'
    const exportType = exportFromJSON.types.xls

    exportFromJSON({ data, fileName, exportType })
  }
  const fatR = () => {
    const data = fat
    const fileName = 'Análise faturamento'
    const exportType = exportFromJSON.types.xls

    exportFromJSON({ data, fileName, exportType })
  }
  const estoquesR = () => {
    const data = estoques
    const fileName = 'Valores Estoques'
    const exportType = exportFromJSON.types.xls

    exportFromJSON({ data, fileName, exportType })
  }
  const consumoR = () => {
    const data = consumo
    const fileName = 'Relatório Consumo'
    const exportType = exportFromJSON.types.xls

    exportFromJSON({ data, fileName, exportType })
  }


  return (
    permitido === true ?
      <div>

        {/* <div style={{ width: '100%', height: '100%' }}>
        <PowerBIEmbed
          embedConfig={{
            type: 'report',   // Supported types: report, dashboard, tile, visual and qna
            id: '2490a4ac-19e3-4af2-9327-7b153963524d',
            embedUrl: 'https://app.powerbi.com/reportEmbed?reportId=2490a4ac-19e3-4af2-9327-7b153963524d&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLUJSQVpJTC1TT1VUSC1CLVBSSU1BUlktcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQiLCJlbWJlZEZlYXR1cmVzIjp7Im1vZGVybkVtYmVkIjp0cnVlLCJjZXJ0aWZpZWRUZWxlbWV0cnlFbWJlZCI6dHJ1ZX19',
            accessToken: token,
            tokenType: models.TokenType.Aad,
            settings: {
              panes: {
                filters: {
                  expanded: false,
                  visible: false
                }
              },

            }
          }}

          eventHandlers={
            new Map([
              ['loaded', function () { console.log('Report loaded'); }],
              ['rendered', function () { console.log('Report rendered'); }],
              ['error', function (event) { console.log(event.detail); }]
            ])
          }

          cssClassName={"Embed-container"}

          getEmbeddedComponent={(embeddedReport) => {
            window.report = embeddedReport;
          }}
        />
      </div>
      <GridContainer>
        {upd === true ? <GridItem>
          <a href='https://back.macs.dev.br/relSaldos/relatorioFull.xlsx' download>Relatório Saldos</a>
        </GridItem> : <Loader
          type="Circles"
          color="#00BFFF"
          height={20}
          width={20}
          timeout={20000} //3 secs
        />}


      </GridContainer> */}


        <GridContainer>
          <GridItem>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <h1> Comercial</h1>
                  <a href="https://app.powerbi.com/view?r=eyJrIjoiMTNkMzcyNmItMDQ3ZS00MjZjLWJlODAtNDMyZDczMzRlNjBkIiwidCI6Ijc1M2UyM2VlLTE1YTYtNDQ0MS05ZmQxLWNkZmFmMDQ2YjhiYSJ9">
                    Panorama Carteira/Faturamento
                  </a>
                  <a href="https://app.powerbi.com/view?r=eyJrIjoiNjQ3ODA4N2UtNzllNi00ZDk2LWJiYTMtMjEwYTlmY2NjYjlhIiwidCI6Ijc1M2UyM2VlLTE1YTYtNDQ0MS05ZmQxLWNkZmFmMDQ2YjhiYSJ9">
                    Relatório Disponibilidade e localidade de produtos
                  </a>
                  <a href="https://app.powerbi.com/view?r=eyJrIjoiYWY4M2YxN2QtZDJkNi00MzBiLTg1NmEtOWQ1MTExMjU5N2U5IiwidCI6Ijc1M2UyM2VlLTE1YTYtNDQ0MS05ZmQxLWNkZmFmMDQ2YjhiYSJ9">
                    Relatório de Status do pedido
                  </a>
                  <a href="https://app.powerbi.com/view?r=eyJrIjoiOTk2MzMwNjEtYjg3Mi00MjBmLWFjMjMtYjEyZDgwMjg2NzBkIiwidCI6Ijc1M2UyM2VlLTE1YTYtNDQ0MS05ZmQxLWNkZmFmMDQ2YjhiYSJ9">
                    Relatório de Vendas.
                  </a>
                </div>
                <div style={{ display: "flex", flexDirection: "column", width: 600 }}>
                  <h1> Logística</h1>
                  <a href="https://app.powerbi.com/view?r=eyJrIjoiZjcyOTc3MGMtOWM3Ni00YTRkLWIxODItOTcxMDMwM2VjNGY5IiwidCI6Ijc1M2UyM2VlLTE1YTYtNDQ0MS05ZmQxLWNkZmFmMDQ2YjhiYSJ9&pageName=ReportSection">
                    Relatório Necessidades Embalagens
                  </a>
                  
                 
                  {rel !== '' ? <a>
                    <button onClick={() => saldos()}> Baixar Relatório de Saldos</button>
                  </a> : <Loader
                    type="Circles"
                    color="#00BFFF"
                    height={20}
                    width={20}
                    timeout={20000} //3 secs
                  />}
                  {compArm !== '' ? <a>
                    <button onClick={() => armazenagem()}> Baixar Relatório de valores de armazenagem</button>
                  </a> : <Loader
                    type="Circles"
                    color="#00BFFF"
                    height={20}
                    width={20}
                    timeout={20000} //3 secs
                  />}
                  {consumo !== '' ? <a>
                    <button onClick={() => consumoR()}> Baixar Relatório de Consumo</button>
                  </a> : <Loader
                    type="Circles"
                    color="#00BFFF"
                    height={20}
                    width={20}
                    timeout={20000} //3 secs
                  />}
                  {fat !== '' ? <a>
                    <button onClick={() => fatR()}> Baixar Verificação de faturamentos</button>
                  </a> : <Loader
                    type="Circles"
                    color="#00BFFF"
                    height={20}
                    width={20}
                    timeout={20000} //3 secs
                  />}
                  {estoques !== '' ? <a>
                    <button onClick={() => estoquesR()}> Baixar Relatório de valores de estoque</button>
                  </a> : <Loader
                    type="Circles"
                    color="#00BFFF"
                    height={20}
                    width={20}
                    timeout={20000} //3 secs
                  />}
                </div>
                {/* <div style={{ display: "flex", flexDirection: "column" }}>
                  <h1> Clientes</h1>
                  <a href="https://app.powerbi.com/view?r=eyJrIjoiYzc5MGZiMGQtNjA5My00M2Q1LThkYjQtNDdhYTNlM2NiNDQxIiwidCI6ImNlZjY5YmQ0LThmZmQtNGU3ZS1iOTNiLWQzMzY4YWUwYTYxYiJ9&pageName=ReportSection623350995343ca1277d4">
                    Relatório Clientes
                  </a>
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <h1> Financeiro</h1>
                  <a href="https://app.powerbi.com/view?r=eyJrIjoiNGRhYTljOGItNDRhYS00NzEwLWE2YzItMmVmMmZhY2RjMGNlIiwidCI6ImIzYWM4MmE3LTYwNTQtNDNlMS05M2JjLWU2YWY2YzI2NjI0ZiJ9&pageName=ReportSection1e050fa66699947e92dc">
                    Relatório Contas a Receber/ Pagar
                  </a>
                  <a href="https://app.powerbi.com/view?r=eyJrIjoiZjNjMTU0MWUtYzg3NS00MDY1LTliZWEtMTI3NmQ4NjU5OGEwIiwidCI6ImIzYWM4MmE3LTYwNTQtNDNlMS05M2JjLWU2YWY2YzI2NjI0ZiJ9&pageName=ReportSectiond38a8b674619308cd181">
                    Relatório Monitoramento Cambial
                  </a>
                  <a href="https://app.powerbi.com/view?r=eyJrIjoiMmE2Y2I0YzQtMWM3YS00NDNlLTkyOWQtYWQzNDc2N2YyNTM5IiwidCI6ImIzYWM4MmE3LTYwNTQtNDNlMS05M2JjLWU2YWY2YzI2NjI0ZiJ9&pageName=ReportSection">
                    Relatório Resumo Geral
                  </a>
                </div> */}
              </div>

            </div>
          </GridItem>
        </GridContainer>
        {/* <div id="Estruturação relatório de saldos">
        <GridContainer>
          {rel !== '' ?
            <GridItem>
              <div style={{ overflow: 'scroll', height: 0, width: 0 }} >
                <table id="table-to-xls">
                  <thead>
                    <tr style={{ border: "1px solid " }}>
                      <th style={{ border: "1px solid " }}>
                        DataFaturamento
                      </th>
                      <th style={{ border: "1px solid " }}>
                        DocPedido
                      </th>
                      <th style={{ border: "1px solid " }}>
                        DataLancamento
                      </th>
                      <th style={{ border: "1px solid " }}>
                        NomeGrupo
                      </th>
                      <th style={{ border: "1px solid " }}>
                        CidadePedido
                      </th>
                      <th style={{ border: "1px solid " }}>
                        BairroPedido
                      </th>
                      <th style={{ border: "1px solid " }}>
                        EstadoPedido
                      </th>
                      <th style={{ border: "1px solid " }}>
                        FazendaPedido
                      </th>
                      <th style={{ border: "1px solid " }}>
                        InicioEntrega
                      </th>
                      <th style={{ border: "1px solid " }}>
                        FimEntrega
                      </th>
                      <th style={{ border: "1px solid " }}>
                        NFVenda
                      </th>
                      <th style={{ border: "1px solid " }}>
                        Deposito
                      </th>
                      <th style={{ border: "1px solid " }}>
                        Embalagem
                      </th>
                      {prio == '2' ? <th style={{ border: "1px solid " }}>
                        Transportadora
                      </th> : console.log('frete')}
                      <th style={{ border: "1px solid " }}>
                        Cliente
                      </th>
                      <th style={{ border: "1px solid " }}>
                        VendedorPedido
                      </th>
                      <th style={{ border: "1px solid " }}>
                        NomeFilial
                      </th>
                      <th style={{ border: "1px solid " }}>
                        Item
                      </th>
                      <th style={{ border: "1px solid " }}>
                        CodItem
                      </th>
                      <th style={{ border: "1px solid " }}>
                        QtdPedidoPai
                      </th>
                      <th style={{ border: "1px solid " }}>
                        QtdPedido
                      </th>
                      <th style={{ border: "1px solid " }}>
                        Faturado
                      </th>
                      <th style={{ border: "1px solid " }}>
                        Saldo
                      </th>
                      <th style={{ border: "1px solid " }}>
                        Carteira
                      </th>
                      {prio == '2' ? <th style={{ border: "1px solid " }}>
                        U_UFrete
                      </th> : console.log('frete')}
                      {prio == '2' ? <th style={{ border: "1px solid " }}>
                        U_UFrete_Autonomos
                      </th> : console.log('frete')}
                      {prio == '2' ? <th style={{ border: "1px solid " }}>
                        FreteTransp
                      </th> : console.log('frete')}

                      <th style={{ border: "1px solid " }}>
                        TipoFrete
                      </th>
                      <th style={{ border: "1px solid " }}>
                        StatusPedido
                      </th>
                      <th style={{ border: "1px solid " }}>
                        NumeroPedidoGen
                      </th>
                      <th style={{ border: "1px solid " }}>
                        Garantia
                      </th>
                      <th style={{ border: "1px solid " }}>
                        Obs
                      </th>
                      <th style={{ border: "1px solid " }}>
                        CFOP
                      </th>
                      <th style={{ border: "1px solid " }}>
                        UsageName
                      </th>
                      {prio == '2' ? <th style={{ border: "1px solid " }}>
                        Placa
                      </th> : console.log('placa')}
                      {prio == '2' ? <th style={{ border: "1px solid " }}>
                        Notas_CTE
                      </th> : console.log('notas')}
                      {prio == '2' ? <th style={{ border: "1px solid " }}>
                        Total_Pago
                      </th> : console.log('pago')}
                      {prio == '2' ? <th style={{ border: "1px solid " }}>
                        Total_ton
                      </th> : console.log('ton')}
                      <th style={{ border: "1px solid " }}>
                        DataVencimento
                      </th>
                      {prio == '2' ? <th style={{ border: "1px solid " }}>
                        TotalPago
                      </th> : console.log('pago')}
                      <th style={{ border: "1px solid " }}>
                        totalPedido
                      </th>
                      <th style={{ border: "1px solid " }}>
                        Usage
                      </th>

                    </tr>
                  </thead>
                  <tbody>
                    {rel !== '' ?
                      Object.keys(rel).map((item, index) => (
                        <tr key={index} style={{ height: 120 }}>
                          <td style={{ border: "1px solid " }}>
                            {rel[item].DataFaturamento}
                          </td>
                          <td style={{ border: "1px solid " }}>
                            {rel[item].DocPedido}
                          </td>
                          <td style={{ border: "1px solid " }}>
                            {rel[item].DataLancamento}
                          </td>
                          <td style={{ border: "1px solid " }}>
                            {rel[item].NomeGrupo}
                          </td>
                          <td style={{ border: "1px solid " }}>
                            {rel[item].CidadePedido}
                          </td>
                          <td style={{ border: "1px solid " }}>
                            {rel[item].BairroPedido}
                          </td>
                          <td style={{ border: "1px solid " }}>
                            {rel[item].EstadoPedido}
                          </td>
                          <td style={{ border: "1px solid " }}>
                            {rel[item].FazendaPedido}
                          </td>
                          <td style={{ border: "1px solid " }}>
                            {rel[item].InicioEntrega}
                          </td>
                          <td style={{ border: "1px solid " }}>
                            {rel[item].FimEntrega}
                          </td>
                          <td style={{ border: "1px solid " }}>
                            {rel[item].NFVenda}
                          </td>
                          <td style={{ border: "1px solid " }}>
                            {rel[item].Deposito}
                          </td>
                          <td style={{ border: "1px solid " }}>
                            {rel[item].Embalagem}
                          </td>
                          {prio == '2' ? <td style={{ border: "1px solid " }}>
                            {rel[item].Transportadora}
                          </td> : console.log('t')}
                          <td style={{ border: "1px solid " }}>
                            {rel[item].Cliente}
                          </td>
                          <td style={{ border: "1px solid " }}>
                            {rel[item].VendedorPedido}
                          </td>
                          <td style={{ border: "1px solid " }}>
                            {rel[item].NomeFilial}
                          </td>
                          <td style={{ border: "1px solid " }}>
                            {rel[item].Item}
                          </td>
                          <td style={{ border: "1px solid " }}>
                            {rel[item].CodItem}
                          </td>
                          <td style={{ border: "1px solid " }}>
                            {rel[item].QtdPedidoPai}
                          </td>
                          <td style={{ border: "1px solid " }}>
                            {rel[item].QtdPedido}
                          </td>
                          <td style={{ border: "1px solid " }}>
                            {rel[item].Faturado}
                          </td>
                          <td style={{ border: "1px solid " }}>
                            {rel[item].Saldo}
                          </td>
                          <td style={{ border: "1px solid " }}>
                            {rel[item].Carteira}
                          </td>
                          {prio === '2' ? <td style={{ border: "1px solid " }}>
                            {rel[item].U_UFRETE}
                          </td> : console.log('1')}
                          {prio === '2' ? <td style={{ border: "1px solid " }}>
                            {rel[item].U_UFrete_Autonomos}
                          </td> : console.log(1)}
                          {prio === '2' ? <td style={{ border: "1px solid " }}>
                            {rel[item].FreteTransp}
                          </td> : console.log(1)}
                          <td style={{ border: "1px solid " }}>
                            {rel[item].TipoFrete}
                          </td>
                          <td style={{ border: "1px solid " }}>
                            {rel[item].StatusPedido}
                          </td>
                          <td style={{ border: "1px solid " }}>
                            {rel[item].NumeroPedidoGen}
                          </td>
                          <td style={{ border: "1px solid " }}>
                            {rel[item].Garantia}
                          </td>
                          <td style={{ border: "1px solid " }}>
                            {rel[item].Obs}
                          </td>
                          <td style={{ border: "1px solid " }}>
                            {rel[item].CFOP}
                          </td>
                          <td style={{ border: "1px solid " }}>
                            {rel[item].UsageName}
                          </td>
                          {prio === '2' ? <td style={{ border: "1px solid " }}>
                            {rel[item].Placa}
                          </td> : console.log('1')}
                          {prio === '2' ? <td style={{ border: "1px solid " }}>
                            {rel[item].Notas_CTE}
                          </td> : console.log('1')}
                          {prio === '2' ? <td style={{ border: "1px solid " }}>
                            {rel[item].Total_Pago}
                          </td> : console.log('1')}
                          {prio === '2' ? <td style={{ border: "1px solid " }}>
                            {rel[item].Total_ton}
                          </td> : console.log('1')}
                          <td style={{ border: "1px solid " }}>
                            {rel[item].DataVencimento}
                          </td>
                          {prio === '2' ? <td style={{ border: "1px solid " }}>
                            {rel[item].TotalPago}
                          </td> : console.log(1)}
                          <td style={{ border: "1px solid " }}>
                            {rel[item].totalPedido}
                          </td>
                          <td style={{ border: "1px solid " }}>
                            {rel[item].Usage}
                          </td>
                        </tr>

                      )) : <div></div>}
                  </tbody>
                </table>
              </div>
            </GridItem>
            : <Loader
              type="Circles"
              color="#00BFFF"
              height={20}
              width={20}
              timeout={20000} //3 secs
            />}
        </GridContainer>

      </div>
      <div id="Estruturação relatório de compra armazenagem">
        <GridContainer>
          {compArm !== '' ?
            <GridItem>
              <div style={{ overflow: 'scroll', height: 0, width: 0 }} >
                <table id="comprasarm">
                  <thead>
                    <tr style={{ border: "1px solid " }}>
                      <th style={{ border: "1px solid " }}>
                        docentry
                      </th>
                      <th style={{ border: "1px solid " }}>
                        cardname
                      </th>
                      <th style={{ border: "1px solid " }}>
                        NomeFilial
                      </th>
                      <th style={{ border: "1px solid " }}>
                        docdate
                      </th>
                      <th style={{ border: "1px solid " }}>
                        pymntgroup
                      </th>
                      <th style={{ border: "1px solid " }}>
                        usage
                      </th>
                      <th style={{ border: "1px solid " }}>
                        Code
                      </th>
                      <th style={{ border: "1px solid " }}>
                        Tipo de Frete
                      </th>
                      <th style={{ border: "1px solid " }}>
                        serial
                      </th>
                      <th style={{ border: "1px solid " }}>
                        pricebefdi
                      </th>
                      <th style={{ border: "1px solid " }}>
                        DocRate
                      </th>
                      <th style={{ border: "1px solid " }}>
                        Status
                      </th>
                      <th style={{ border: "1px solid " }}>
                        State
                      </th>

                      <th style={{ border: "1px solid " }}>
                        Qtd. devolvida
                      </th>
                      <th style={{ border: "1px solid " }}>
                        quantity
                      </th>
                      <th style={{ border: "1px solid " }}>
                        doctotal
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {compArm !== '' ?
                      Object.keys(compArm).map((item, index) => (
                        <tr key={index} style={{ height: 120 }}>
                          <td style={{ border: "1px solid " }}>
                            {compArm[item].docentry}
                          </td>
                          <td style={{ border: "1px solid " }}>
                            {compArm[item].cardname}
                          </td>
                          <td style={{ border: "1px solid " }}>
                            {compArm[item].NomeFilial}
                          </td>
                          <td style={{ border: "1px solid " }}>
                            {compArm[item].docdate}
                          </td>
                          <td style={{ border: "1px solid " }}>
                            {compArm[item].pymntgroup}
                          </td>
                          <td style={{ border: "1px solid " }}>
                            {compArm[item].usage}
                          </td>
                          <td style={{ border: "1px solid " }}>
                            {compArm[item].Code}
                          </td>
                          <td style={{ border: "1px solid " }}>
                            {compArm[item]['Tipo de Frete']}
                          </td>
                          <td style={{ border: "1px solid " }}>
                            {compArm[item].serial}
                          </td>
                          <td style={{ border: "1px solid " }}>
                            {compArm[item].pricebedfi}
                          </td>
                          <td style={{ border: "1px solid " }}>
                            {compArm[item].DocRate}
                          </td>
                          <td style={{ border: "1px solid " }}>
                            {compArm[item].Status}
                          </td>
                          <td style={{ border: "1px solid " }}>
                            {compArm[item].State}
                          </td>

                          <td style={{ border: "1px solid " }}>
                            {compArm[item]['Qtd. devolvida']}
                          </td>
                          <td style={{ border: "1px solid " }}>
                            {compArm[item].quantity}
                          </td>
                          <td style={{ border: "1px solid " }}>
                            {compArm[item].doctotal}
                          </td>

                        </tr>

                      )) : <div></div>}
                  </tbody>
                </table>
              </div>
            </GridItem>
            : <Loader
              type="Circles"
              color="#00BFFF"
              height={20}
              width={20}
              timeout={20000} //3 secs
            />}
        </GridContainer>

      </div> */}
      </div>
      : <></>
  );
}
function mapStateToProps(state) {
  return {
    token: state.token,
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setToken: setToken,
      setUser: setUser,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
