import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal, Button, Table } from 'react-bootstrap';
import api from '../../services/api'; // Verifique o caminho correto para a API

function TabelaValorPauta() {
  const [agendamentos, setAgendamentos] = useState([]);
  const [novoAgendamento, setNovoAgendamento] = useState({
    produto: '',
    mp: '',
    pa: '',
    valor_pauta_atualizado: ''
  });
  const [showModal, setShowModal] = useState(false);
  const [agendamentoAtual, setAgendamentoAtual] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false); // Modal de confirmação
  const [agendamentoParaDeletar, setAgendamentoParaDeletar] = useState(null); // Guarda o agendamento a ser deletado

  // Buscar lista de agendamentos
  const fetchAgendamentos = async () => {
    try {
      const response = await api.get('/agendamentos_valor_pauta');
      setAgendamentos(response.data); // Atualiza o estado com os dados da resposta
    } catch (error) {
      console.error("Erro ao buscar agendamentos:", error);
    }
  };

  // Criar um novo agendamento
  const criarAgendamento = async () => {
    if (!novoAgendamento.produto || !novoAgendamento.mp || !novoAgendamento.pa || !novoAgendamento.valor_pauta_atualizado) {
      alert('Preencha todos os campos obrigatórios!');
      return;
    }

    try {
      const response = await api.post('/agendamentos_valor_pauta', {
        ...novoAgendamento,
        valor_pauta_atualizado: parseFloat(novoAgendamento.valor_pauta_atualizado) || 0
      });
      if (response.status === 201) {
        fetchAgendamentos(); // Atualiza a lista
        setNovoAgendamento({ produto: '', mp: '', pa: '', valor_pauta_atualizado: '' });
        setShowModal(false); // Fecha o modal
      } else {
        alert('Erro ao criar agendamento. Verifique os dados e tente novamente.');
      }
    } catch (error) {
      console.error('Erro ao criar agendamento:', error);
      alert('Ocorreu um erro ao criar o agendamento. Tente novamente mais tarde.');
    }
  };

  // Abrir modal para atualizar agendamento
  const abrirModalAtualizar = (agendamento) => {
    setAgendamentoAtual(agendamento);
    setShowEditModal(true);
  };

  // Função para formatar o valor como moeda brasileira
const formatarValor = (valor) => {
    if (!valor) return '';
    return parseFloat(valor).toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL'
    });
  };
  
  // Função para tratar a entrada de valor e armazenar o valor puro no estado
  const handleValorChange = (valor) => {
    // Remove todos os caracteres que não são números (incluindo o símbolo de moeda)
    const valorNumerico = valor.replace(/[^\d,]/g, '').replace(',', '.');
    
    // Atualiza o estado com o valor numérico puro
    setAgendamentoAtual({ ...agendamentoAtual, valor_pauta_atualizado: valorNumerico });
  };
  

  // Atualizar um agendamento existente
  const atualizarAgendamento = async () => {
    if (!agendamentoAtual.produto || !agendamentoAtual.mp || !agendamentoAtual.pa || !agendamentoAtual.valor_pauta_atualizado) {
      alert('Preencha todos os campos obrigatórios!');
      return;
    }

    try {
      const response = await api.put(`/agendamentos_valor_pauta/${agendamentoAtual.id}`, {
        ...agendamentoAtual,
        valor_pauta_atualizado: parseFloat(agendamentoAtual.valor_pauta_atualizado) || 0
      });
      if (response.status === 200) {
        fetchAgendamentos(); // Atualiza a lista
        setShowEditModal(false); // Fecha o modal de edição
      } else {
        alert('Erro ao atualizar agendamento. Verifique os dados e tente novamente.');
      }
    } catch (error) {
      console.error('Erro ao atualizar agendamento:', error);
      alert('Ocorreu um erro ao atualizar o agendamento. Tente novamente mais tarde.');
    }
  };

  // Mostrar modal de confirmação de exclusão
  const confirmarDelecao = (agendamento) => {
    setAgendamentoParaDeletar(agendamento);
    setShowConfirmModal(true);
  };

  // Deletar um agendamento existente após confirmação
  const deletarAgendamento = async () => {
    try {
      const response = await api.delete(`/agendamentos_valor_pauta/${agendamentoParaDeletar.id}`);
      if (response.status === 200) {
        fetchAgendamentos(); // Atualiza a lista
        setShowConfirmModal(false); // Fecha o modal de confirmação
      } else {
        alert('Erro ao deletar agendamento.');
      }
    } catch (error) {
      console.error('Erro ao deletar agendamento:', error);
      alert('Ocorreu um erro ao deletar o agendamento. Tente novamente mais tarde.');
    }
  };

  useEffect(() => {
    fetchAgendamentos(); // Carregar agendamentos ao carregar o componente
  }, []);

  return (
    <div className="container mt-4">
      {/* Título menor e botão alinhado à direita */}
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h3>Tabela Valores de Pauta</h3>
        <Button variant="primary" onClick={() => setShowModal(true)}>
        Cadastrar Novo
        </Button>
      </div>

      {/* Modal para criar novo agendamento */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Cadastrar Novo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              placeholder="Produto"
              value={novoAgendamento.produto}
              onChange={(e) => setNovoAgendamento({ ...novoAgendamento, produto: e.target.value })}
            />
          </div>
          <div className="row mt-3">
            <div className="col-md-4">
              <input
                type="text"
                className="form-control"
                placeholder="MP"
                value={novoAgendamento.mp}
                onChange={(e) => setNovoAgendamento({ ...novoAgendamento, mp: e.target.value })}
              />
            </div>
            <div className="col-md-4">
              <input
                type="text"
                className="form-control"
                placeholder="PA"
                value={novoAgendamento.pa}
                onChange={(e) => setNovoAgendamento({ ...novoAgendamento, pa: e.target.value })}
              />
            </div>
            <div className="col-md-4">
              <input
                type="number"
                className="form-control"
                placeholder="Valor Pauta Atualizado"
                value={novoAgendamento.valor_pauta_atualizado}
                onChange={(e) => setNovoAgendamento({ ...novoAgendamento, valor_pauta_atualizado: e.target.value })}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={criarAgendamento}>
            Criar Agendamento
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal para editar agendamento */}
      {agendamentoAtual && (
        <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Editar Agendamento</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder="Produto"
                value={agendamentoAtual.produto}
                onChange={(e) => setAgendamentoAtual({ ...agendamentoAtual, produto: e.target.value })}
              />
            </div>
            <div className="row mt-3">
              <div className="col-md-4">
                <input
                  type="text"
                  className="form-control"
                  placeholder="MP"
                  value={agendamentoAtual.mp}
                  onChange={(e) => setAgendamentoAtual({ ...agendamentoAtual, mp: e.target.value })}
                />
              </div>
              <div className="col-md-4">
                <input
                  type="text"
                  className="form-control"
                  placeholder="PA"
                  value={agendamentoAtual.pa}
                  onChange={(e) => setAgendamentoAtual({ ...agendamentoAtual, pa: e.target.value })}
                />
              </div>
              <div className="col-md-4">
                <input
                    type="text" // Alteramos para "text" para permitir formatação
                    className="form-control"
                    placeholder="Valor Pauta Atualizado"
                    value={formatarValor(agendamentoAtual.valor_pauta_atualizado)} // Função para formatar
                    onChange={(e) => handleValorChange(e.target.value)} // Função para tratar a mudança
                />
                </div>

            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowEditModal(false)}>
              Cancelar
            </Button>
            <Button variant="primary" onClick={atualizarAgendamento}>
              Atualizar Agendamento
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      {/* Modal de confirmação para deletar agendamento */}
      <Modal show={showConfirmModal} onHide={() => setShowConfirmModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmar Exclusão</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Tem certeza de que deseja excluir o agendamento "{agendamentoParaDeletar?.produto}"?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowConfirmModal(false)}>
            Cancelar
          </Button>
          <Button variant="danger" onClick={deletarAgendamento}>
            Deletar
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Tabela de agendamentos */}
      <Table striped bordered hover className="mt-3">
        <thead>
          <tr>
            <th>ID</th>
            <th>Produto</th>
            <th>MP</th>
            <th>PA</th>
            <th>Valor Pauta Atualizado</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {agendamentos.map((agendamento) => (
            <tr className="text-center" key={agendamento.id}>
              <td>{agendamento.id}</td>
              <td>{agendamento.produto}</td>
              <td>{agendamento.mp}</td>
              <td>{agendamento.pa}</td>
              <td>
                {parseFloat(agendamento.valor_pauta_atualizado).toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL'
                })}
                </td>
              <td>
                <Button variant="warning" onClick={() => abrirModalAtualizar(agendamento)}>
                  Editar
                </Button>{' '}
                <Button variant="danger" onClick={() => confirmarDelecao(agendamento)}>
                  Deletar
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}

export default TabelaValorPauta;
