import api from "../services/api";

// Converte arquivos para base64
async function filesToBase64(filesArray) {
    var files = filesArray;
    var fileLocal;

    var aux = []

    for (var i = 0; i < files.length; i++) {

        fileLocal = files[i];
        let base64 = await toBase64(fileLocal)
        aux = [...aux, base64]
    }
    return aux
}
const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});

// Valida a permissão do usuário pra acessar a pagina
async function checarPermissao(pagina, props) {
    if (pagina === 'jornalGen') { return true }
    const permitido = await api.get('checarPermissao', { params: { userId: getUser(), token: getToken(), pagina } })

    if (permitido.data === true) {
        return true
    } else {
        alert('Seu usuário não tem permissão para acessar essa página!')
        props.history.push({ pathname: "/admin/JornalGen" })
        return false
    }

}

// Valida o token / usuario do usuario
async function validateTokenUser(props, link = '') {
    let token = getToken()
    let userId = getUser()

    if (token === '' || userId === '') {
        localStorage.clear()
        props.history.push({ pathname: "/" })
        localStorage.setItem('link', link)
        return
    }

    const response = await api.get('validateToken', { params: { userId, token } })
    if (response.data !== 'OK') {
        alert('Sessão expirada!')
        localStorage.clear()
        props.history.push({ pathname: "/" })
        localStorage.setItem('link', link)
    }
}


function getUser() {
    const userString = localStorage.getItem('id')
    if (userString === null) {
        return ''
    } else {
        return userString
    }
}

function getToken() {
    const token = localStorage.getItem('token')
    if (token === null) {
        return ''
    } else {
        return token
    }
}

function getId() {
    const token = localStorage.getItem('id')
    if (token === null) {
        return ''
    } else {
        return token
    }
}
function formatStringUniversalDateToLocal(data) {
    let aux = data.split('-')
    return aux[2] + '-' + aux[1] + '-' + aux[0]
}

function orderObjArray(obj = [], key = '', ascending = true) { // função que ordena um array de objetos usando uma key que exista em todos os objetos do array
    if (obj.length === 0) { return obj }
    
    if (typeof (obj[0][key]) === 'string') { // por enquanto funciona apenas com strings e números
        if (ascending === true) {
            obj.sort((a, b) => a[key].localeCompare(b[key]))
        } else {
            obj.sort((a, b) => b[key].localeCompare(a[key]))
        }
    }

    if (typeof (obj[0][key]) === 'number') {
        
        if (ascending === true) {
            obj.sort((a, b) => {
                if (a[key] > b[key]) { return 1 }
                if (a[key] < b[key]) { return - 1 }
                return 0
            })
        } else {
            obj.sort((a, b) => {
                if (b[key] > a[key]) { return 1 }
                if (b[key] < a[key]) { return - 1 }
                return 0
            })
        }
    }

    return obj
}


export {
    filesToBase64,
    checarPermissao,
    validateTokenUser,
    getUser,
    getToken,
    formatStringUniversalDateToLocal,
    orderObjArray,
    getId
}
