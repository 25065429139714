import React, { useState, useEffect } from "react";
import api from "services/api";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button } from '@material-ui/core';
import * as XLSX from 'xlsx';

export default function Resultados() {
    const [resultados, setResultados] = useState([]);
    const [resultados2, setResultados2] = useState([]);

    const [isLoading, setIsLoading] = useState(true); // New state variable for loading status
    const [isLoading2, setIsLoading2] = useState(true); // New state variable for loading status

    useEffect(() => {
        FetchMyApi();
    }, []);

    const FetchMyApi = async () => {
        try {
            const retorno = await api.get('/resultados_plr');
            setResultados(retorno.data);
            setIsLoading(false); // Set loading to false after data is fetched
        } catch (error) {
            console.error("Error fetching data:", error);
            setIsLoading(false); // Also set loading to false in case of error
        }
        try {
            const retorno = await api.get('/return_metas_plr_rel');
            setResultados2(retorno.data);
            setIsLoading2(false); // Set loading to false after data is fetched
        } catch (error) {
            console.error("Error fetching data:", error);
            setIsLoading2(false); // Also set loading to false in case of error
        }
    };

    const downloadExcel = () => {
        const ws = XLSX.utils.json_to_sheet(resultados);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Resultados");
        XLSX.writeFile(wb, "resultados_plr.xlsx");
    };
    const downloadExcel2 = () => {
        const ws = XLSX.utils.json_to_sheet(resultados2);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Resultados");
        XLSX.writeFile(wb, "metas_individuais.xlsx");
    };

    return (
        <div>
            <Button
                variant="contained"
                color="primary"
                onClick={downloadExcel}
                style={{ marginBottom: '10px' }}
                disabled={isLoading} // Button is disabled while data is loading
            >
                Baixar Relatório Completo PLR
            </Button>
            <Button
                variant="contained"
                color="primary"
                onClick={downloadExcel2}
                style={{ marginBottom: '10px' }}
                disabled={isLoading2} // Button is disabled while data is loading
            >
                Baixar Relatório de metas individuais
            </Button>
            <div style={{
                backgroundColor: '#f2f2f2', // Light grey background
                border: '1px solid #d1d1d1', // Slight border for definition
                borderRadius: '8px', // Rounded corners
                padding: '15px', // Some padding inside the div
                margin: '10px 0', // Margin for spacing around the div
                textAlign: 'center', // Centering text
                fontFamily: 'Arial, sans-serif', // Font style
            }}>
                <p style={{
                    color: '#333', // Darker text for readability
                    fontSize: '1.1em' // Slightly larger font size
                }}>
                    Setor GEN: {resultados.length > 0 ? resultados[0].multiplicador_gen.toFixed(2) : "Carregando..."}
                </p>
            </div>
            <div>
                <TableContainer component={Paper} style={{ maxHeight: '50vh' }}>
                    <Table>
                        <TableHead style={{ backgroundColor: '#6699ff', color: 'white' }}>
                            <TableRow>
                                <TableCell style={{ color: 'white' }}>Colaborador</TableCell>
                                <TableCell style={{ color: 'white' }}>Unidade</TableCell>
                                <TableCell style={{ color: 'white' }} align="right">Grupo</TableCell>
                                <TableCell style={{ color: 'white' }} align="right">Setor</TableCell>
                                <TableCell style={{ color: 'white' }} align="right">Cargo</TableCell>
                                <TableCell style={{ color: 'white' }} align="right">Dias Trabalhados</TableCell>
                                <TableCell style={{ color: 'white' }} align="right">Multiplicador Individual</TableCell>
                                <TableCell style={{ color: 'white' }} align="right">Multiplicador Final</TableCell>
                                <TableCell style={{ color: 'white' }} align="right">Salário Base</TableCell>
                                <TableCell style={{ color: 'white' }} align="right">Salário PLR</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {resultados.map((row, index) => (
                                <TableRow key={index}>
                                    <TableCell component="th" scope="row" style={{ color: 'black' }}>{row.colaborador}</TableCell>
                                    <TableCell style={{ color: 'black' }}>{row.unidade}</TableCell>
                                    <TableCell align="right" style={{ color: 'black' }}>{row.grupo}</TableCell>
                                    <TableCell align="right" style={{ color: 'black' }}>{row.setor}</TableCell>
                                    <TableCell align="right" style={{ color: 'black' }}>{row.Cargo}</TableCell>
                                    <TableCell align="right" style={{ color: 'black' }}>{row.dias_trab}</TableCell>
                                    <TableCell align="right" style={{ color: 'black' }}>{row.multiplicador_setor ? row.multiplicador_setor.toFixed(4) : ""}</TableCell>
                                    <TableCell align="right" style={{ color: 'black' }}>{row.multiplicador_final ? row.multiplicador_final.toFixed(4) : ""}</TableCell>
                                    <TableCell align="right" style={{ color: 'black' }}>
                                        {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(row["salario"])}
                                    </TableCell>
                                    <TableCell align="right" style={{ color: 'black' }}>
                                        {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(row["salario_plr"])}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    );
}
