import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { setToken } from "../../flux/actions/tokenIndex";
import { setUser } from "../../flux/actions/userIndex";
import api from "../../services/api";
import { Switch } from "@material-ui/core";
import { checarPermissao, validateTokenUser, getUser, getToken } from '../../utils/utils.js'
import { TextField } from "@material-ui/core";
import MuiPhoneInput from 'material-ui-phone-number';
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';


function ConfigurarUsuario(props) {
    // states da tabela de permissões
    const [listaPermissoes, setListaPermissoes] = useState('')
    const [salvando, setSalvando] = useState(false)
    const [update, setUpdate] = useState([])
    const [user, setUser] = useState('')
    const [permitido, setPermitido] = useState(false)

    // states de criar usuario
    const [adicionando, setAdicionando] = useState(false)
    const [nome, setNome] = useState('')
    const [login, setLogin] = useState('')
    const [senha, setSenha] = useState('')
    const [email, setEmail] = useState('')
    const [celular, setCelular] = useState('')
    const [nomesExistentes, setNomesExistentes] = useState([])

    // states de alterar senha
    const [nomeUsuario, setNomeUsuario] = useState('')
    const [novaSenha, setNovaSenha] = useState('')
    const [escolha, setEscolha] = useState('')


    // states de desativar usuário
    const [usuarioDesativar, setUsuarioDesativar] = useState('')
    const [opcao, setOpcao] = useState('')

    // states tabela de visualização de colunas
    const [permissoesColunas, setPermissoesColunas] = useState('')
    const [updateColunas, setUpdateColunas] = useState([])

    // pesquisa
    const [filter1, setFilter1] = useState('')
    const [filter2, setFilter2] = useState('')

    const classes = useStyles()

    useEffect(() => {
        let tempUser = getUser()
        setUser(tempUser)

        async function fetchMyAPI() {
            await validateTokenUser(props)
            setPermitido(await checarPermissao('admin', props))

            const listinha = await api.get('listaPermissoes', { params: { userId: getUser(), token: getToken() } })
            setListaPermissoes(listinha.data)
            listarNomesExistentes(listinha.data)

            const permiColunas = await api.get('/getPermissoesLocais', { params: { userId: getUser(), token: getToken() } })

            setPermissoesColunas(permiColunas.data)
        }
        fetchMyAPI();
    }, [])


    function checarPreenchido() {
        if (nome === '' || email === '' || senha === '' || login === '') {
            return false
        }
        else {
            return true
        }
    }

    function listarNomesExistentes(obj) {
        let aux = []
        obj.map((item) => aux.push(item.nome.toLowerCase()))
        setNomesExistentes(aux)
    }

    async function salvarUpdate(tipoUpdate) {
        setSalvando(true)
        let stringUpdate = ''
        if (tipoUpdate === 'updatePagina') {
            Object.keys(update).forEach((key) => stringUpdate += update[key])
        } else { // else tipoUpdate === updateColunas
            Object.keys(updateColunas).forEach((key) => stringUpdate += updateColunas[key])
        }

        if (stringUpdate === '') {
            alert('Faça alguma modificação antes de salvar...')
        } else {
            const resultado = await api.get('setarPermissoes', {
                params: {
                    userId: getUser(),
                    token: getToken(),
                    stringUpdate,
                    tipoUpdate
                }
            })

            if (resultado.data === 'OK') {
                alert('Modificações salvas com sucesso!')
                window.location.reload()
            } else {
                alert('Erro ao salvar modificações...')
            }
        }
        setSalvando(false)
    }

    function montaUpdate(valor, idUsuario, target, tipoUpdate) {
        // tipoUpdate pode ser "updatePagina" (update da tabela de páginas) ou "updateColunas" (update da tabela de colunas)
        if (tipoUpdate === 'updatePagina') {
            let aux = update
            aux[idUsuario + target] = idUsuario + "_*_" + target + "_*_" + valor + ';'
            setUpdate(aux)
        } else {
            let aux = updateColunas
            aux[idUsuario + target] = idUsuario + "_*_" + target + "_*_" + valor + ';'
            setUpdateColunas(aux)
        }


    }

    async function addUsuario() {
        if (!checarPreenchido()) {
            alert('Preencha todos os campos necessários! (nome, login, senha, email)')
            return
        }

        setAdicionando(true)
        const add = await api.get('adicionarUsuario', {
            params: {
                userId: getUser(),
                token: getToken(),
                nome,
                email,
                senha,
                login,
                celular
            }
        })

        if (add.data === 'NOK') {
            alert('Erro ao adicionar usuário...')
        } else {
            alert('Usuário adicionado!')
            window.location.reload()
        }
        setAdicionando(false)
    }

    async function alterarSenhaUsuario() {
        if (nomeUsuario === '' || novaSenha === '' || escolha === '') {
            alert('Preencha todos os campos antes de alterar a senha!')
        } else {
            const alt = await api.get('alterarSenhaUsuario', { params: { nomeUsuario, novaSenha, escolha, userId: getUser(), token: getToken() } })
            if (alt.data === 'NOK') {
                alert('Erro ao alterar senha...')
            } else {
                if (escolha === 'Sysgen') {
                    alert('Senha do Sysgen alterada com sucesso!')
                } else {
                    alert('Senha do Email alterada com sucesso!')
                }
                window.location.reload()
            }
        }
    }

    async function desativarUsuario() {
        if (usuarioDesativar === '' || opcao === '') {
            alert('Preencha todos os campos antes de salvar a alteração!')
        } else {
            const alt = await api.get('desativarUsuario', { params: { usuarioDesativar, opcao, userId: getUser(), token: getToken() } })
            if (alt.data === 'NOK') {
                alert('Erro ao alterar o estado do usuário...')
            } else {
                if (opcao === 'Ativar') {
                    alert('Usuário ativado!')
                } else {
                    alert('Usuário desativado!')
                }
                window.location.reload()
            }
        }
    }

    return (
        permitido === true ?
            <>
                {/* Tabela de permissões*/}
                {listaPermissoes !== '' ?
                    <div style={{ background: 'white', borderRadius: 8, fontWeight: 'bold', boxShadow: 'rgba(0, 0, 0, 0.2) 0px 5px 15px' }}>
                        <div style={{ margin: 5, textAlign: 'center' }}>
                            <h3 style={{ fontWeight: 'bold' }}>TABELA DE PERMISSÕES DE PÁGINAS</h3>
                        </div>

                        <div style={{ margin: 5 }}>
                            <TextField size='small' label='Pesquisar' variant="outlined" onChange={(e) => setFilter1(e.target.value.toLowerCase())} />
                        </div>

                        <div style={{ overflow: 'auto', height: 450 }}>

                            <table >
                                <thead >
                                    <tr >
                                        {Object.keys(listaPermissoes[0]).map((item) =>
                                            <React.Fragment key={item}>
                                                {item === 'nome' ?
                                                    <th key={item} style={{ position: 'sticky', background: 'black', color: 'white', left: 0, top: 0, zIndex: 3 }}>
                                                        <div style={{ margin: 10, fontSize: 13 }}>
                                                            {item}
                                                        </div>
                                                    </th> :
                                                    <th key={item} style={{ background: 'black', color: 'white', position: 'sticky', zIndex: 1, top: 0 }}>
                                                        <div style={{ margin: 10, fontSize: 13 }}>
                                                            {item}
                                                        </div>
                                                    </th>
                                                }
                                            </React.Fragment>
                                        )}
                                    </tr>
                                </thead>


                                <tbody style={{ background: '#ededed' }}>
                                    {Object.keys(listaPermissoes).map((item, index) =>
                                        listaPermissoes[item].nome.toLowerCase().includes(filter1) || filter1 === '' ?
                                            <tr key={item}>
                                                {Object.keys(listaPermissoes[item]).map((i) =>
                                                    <React.Fragment key={i}>
                                                        {i === 'nome' ?
                                                            <td key={i} style={{ padding: 5, textAlign: 'center', background: 'white', position: 'sticky', left: 0, zIndex: 1, border: '1px solid rgba(0, 0, 0, .4)' }}>
                                                                <div>
                                                                    {listaPermissoes[item][i]}
                                                                </div>
                                                            </td>
                                                            : i === 'idUsuario' || i === 'ativo' ?
                                                                <td key={i} style={{ padding: 5, textAlign: 'center' }}>
                                                                    <div>
                                                                        {listaPermissoes[item][i]}
                                                                    </div>
                                                                </td>
                                                                :
                                                                <td key={i} style={{ textAlign: 'center' }}>
                                                                    <div >
                                                                        <Switch
                                                                            defaultChecked={listaPermissoes[item][i] === 0 ? false : true}
                                                                            size="small"
                                                                            onChange={(e) => montaUpdate(e.target.checked, listaPermissoes[item].idUsuario, i, 'updatePagina')} />
                                                                    </div>
                                                                </td>}
                                                    </React.Fragment>)}
                                            </tr>
                                            : null)}
                                </tbody>


                            </table>

                        </div>
                        <div style={{ textAlign: 'center', height: 35, marginTop: 5 }}>
                            <button disabled={salvando} onClick={() => salvarUpdate('updatePagina')}>Salvar Modificações</button>
                        </div>
                    </div>
                    : null}


                {/* Adicionar Usuário*/}
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ marginTop: 50, background: 'white', width: 400, borderRadius: 8, fontWeight: 'bold', boxShadow: 'rgba(0, 0, 0, 0.2) 0px 5px 15px' }}>


                        <div style={{ display: 'flex', flexDirection: 'column', alignContent: 'center', alignItems: 'center', padding: 10 }}>
                            <div >
                                <h4 style={{ fontWeight: 'bold' }}>ADICIONAR USUÁRIO</h4>
                            </div>
                            {nomesExistentes.includes(nome.toLowerCase()) ?
                                <div>
                                    <p style={{ color: 'red', position: 'absolute' }}>Esse nome já existe!</p></div>
                                : null}
                            <TextField
                                style={{ width: 300, margin: 5 }}
                                onChange={(e) => setNome(e.target.value)}
                                label='Nome'
                            />


                            <TextField
                                style={{ width: 300, margin: 5 }}
                                onChange={(e) => setLogin(e.target.value)}
                                label='Login'
                            />

                            <TextField
                                type="password"
                                style={{ width: 300, margin: 5 }}
                                onChange={(e) => setSenha(e.target.value)}
                                label='Senha'
                            />

                            <TextField
                                style={{ width: 300, margin: 5 }}
                                onChange={(e) => setEmail(e.target.value)}
                                label='Email'
                            />

                            <MuiPhoneInput
                                defaultCountry='br'
                                style={{ width: 300, margin: 5 }}
                                onChange={(e) => setCelular(e)}
                                label='Celular'
                            />

                            {nomesExistentes.includes(nome.toLowerCase()) ? null : <button
                                disabled={adicionando}
                                onClick={() => addUsuario()}>Adicionar</button>}

                        </div>
                    </div>

                    <div>
                        <div className={classes.divSenha}>
                            <div >
                                <h4 style={{ fontWeight: 'bold' }}>ALTERAR SENHA</h4>
                            </div>
                            <Autocomplete
                                style={{ width: 200, margin: 10 }}
                                options={Object.keys(listaPermissoes).map((item) => listaPermissoes[item].nome)}
                                onChange={(event, value) => value !== null ? setNomeUsuario(value) : setNomeUsuario('')}
                                renderInput={(params) => (<TextField {...params} label='Usuário' />)}
                            />

                            <TextField
                                type='password'
                                style={{ width: 200, margin: 10 }}
                                onChange={(event) => setNovaSenha(event.target.value)}
                                label='Nova senha'
                            />
                            <RadioGroup row onChange={(event) => setEscolha(event.target.value)}>
                                <FormControlLabel value="Sysgen" control={<Radio />} label="Sysgen" />
                                <FormControlLabel value="Email" control={<Radio />} label="Email" />
                            </RadioGroup>
                            <div>
                                <button onClick={() => alterarSenhaUsuario()}>Alterar</button>
                            </div>
                        </div>

                    </div>

                    <div>
                        <div className={classes.divDesativar}>
                            <div >
                                <h4 style={{ fontWeight: 'bold' }}>DESATIVAR/ATIVAR USUÁRIO</h4>
                            </div>
                            <Autocomplete
                                style={{ width: 200, margin: 10 }}
                                options={Object.keys(listaPermissoes).map((item) => listaPermissoes[item].nome)}
                                onChange={(event, value) => value !== null ? setUsuarioDesativar(value) : setUsuarioDesativar('')}
                                renderInput={(params) => (<TextField {...params} label='Usuário' />)}
                            />
                            <RadioGroup row onChange={(event) => setOpcao(event.target.value)}>
                                <FormControlLabel value="Ativar" control={<Radio />} label="Ativar" />
                                <FormControlLabel value="Desativar" control={<Radio />} label="Desativar" />
                            </RadioGroup>
                            <div>
                                <button onClick={() => desativarUsuario()}>Alterar</button>
                            </div>
                        </div>
                    </div>
                </div>


                {/* TABELA DE PERMISSÕES LOCAIS*/}
                {permissoesColunas !== '' ?
                    <div style={{ background: 'white', borderRadius: 8, fontWeight: 'bold', boxShadow: 'rgba(0, 0, 0, 0.2) 0px 5px 15px' }}>
                        <div style={{ margin: 5, textAlign: 'center' }}>
                            <h3 style={{ fontWeight: 'bold' }}>TABELA DE PERMISSÕES LOCAIS</h3>
                        </div>

                        <div style={{ margin: 5 }}>
                            <TextField size='small' label='Pesquisar' variant="outlined" onChange={(e) => setFilter2(e.target.value.toLowerCase())} />
                        </div>

                        <div style={{ overflow: 'auto', height: 450 }}>
                            <table>
                                <thead>
                                    <tr>
                                        {Object.keys(permissoesColunas[0]).map((item) =>
                                            item === 'nome' ?
                                                <th key={item} style={{ position: 'sticky', background: 'black', color: 'white', left: 0, top: 0, zIndex: 3 }}>
                                                    <div style={{ margin: 10, fontSize: 13 }}>
                                                        {item}
                                                    </div>
                                                </th>
                                                : item !== 'idUsuario' ? <th key={item} style={{ background: 'black', color: 'white', position: 'sticky', zIndex: 1, top: 0 }}>
                                                    <div style={{ margin: 10, fontSize: 13 }}>
                                                        {item}
                                                    </div>
                                                </th> : null
                                        )}

                                    </tr>
                                </thead>

                                <tbody style={{ background: '#ededed' }}>
                                    {Object.keys(permissoesColunas).map((item, index) =>
                                        permissoesColunas[item].nome.toLowerCase().includes(filter2) ?
                                            <tr key={item}>
                                                {Object.keys(permissoesColunas[item]).map((coluna) =>
                                                    coluna == 'nome' ?
                                                        <td key={coluna} style={{ padding: 5, textAlign: 'center', background: 'white', position: 'sticky', left: 0, zIndex: 1, border: '1px solid rgba(0, 0, 0, .4)' }}>
                                                            <div>
                                                                {permissoesColunas[item][coluna]}
                                                            </div>
                                                        </td> : coluna !== 'idUsuario' ?
                                                            <td key={coluna} style={{ textAlign: 'center' }}>
                                                                <div >
                                                                    <Switch
                                                                        defaultChecked={permissoesColunas[item][coluna] === 0 ? false : true}
                                                                        size="small"
                                                                        onChange={(e) => montaUpdate(e.target.checked, permissoesColunas[item].idUsuario, coluna, 'updateColunas')} />
                                                                </div>
                                                            </td> : null
                                                )}
                                            </tr>
                                            : null

                                    )}
                                </tbody>

                                <tfoot>
                                    <td></td>
                                    <td colSpan={34} style={{ textAlign: 'center', background: 'red', color: 'white' }}> WORKFLOW COMEX</td>
                                </tfoot>
                            </table>

                        </div>
                        <div style={{ textAlign: 'center', height: 35, marginTop: 5 }}>
                            <button disabled={salvando} onClick={() => salvarUpdate('updateColunas')}>Salvar Modificações</button>
                        </div>
                    </div>
                    : null}

            </> : null

    )
}


const useStyles = makeStyles({
    divSenha: {
        marginTop: 50,
        marginLeft: 10,
        background: 'white',
        borderRadius: 8,
        fontWeight: 'bold',
        boxShadow: 'rgba(0, 0, 0, 0.2) 0px 5px 15px',
        display: 'flex',
        flexDirection: 'column',
        padding: 20,
        textAlign: 'center',
        alignContent: 'center',
        alignItems: 'center'

    },

    divDesativar: {
        marginTop: 50,
        marginLeft: 10,
        width: 300,
        background: 'white',
        borderRadius: 8,
        fontWeight: 'bold',
        boxShadow: 'rgba(0, 0, 0, 0.2) 0px 5px 15px',
        display: 'flex',
        flexDirection: 'column',
        padding: 10,
        textAlign: 'center',
        alignContent: 'center',
        alignItems: 'center'
    }
})






function mapStateToProps(state) {
    return {
        token: state.token,
        user: state.user,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            setToken: setToken,
            setUser: setUser,
        },
        dispatch
    );
}


export default connect(mapStateToProps, mapDispatchToProps)(ConfigurarUsuario);