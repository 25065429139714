import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { setToken } from "../../flux/actions/tokenIndex";
import { setUser } from "../../flux/actions/userIndex";
import api from "../../services/api";
import { checarPermissao, validateTokenUser, getUser, getToken } from '../../utils/utils.js'
import { makeStyles } from "@material-ui/core/styles";
import Loader from "react-loader-spinner";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from "components/CustomButtons/Button.js";



function InputsPricing(props) {
    const [permitido, setPermitido] = useState(false)
    const [materias, setMaterias] = useState('')
    const [materia, setMateria] = useState('')
    const [preco, setPreco] = useState('')
    const [alter, setAlter] = useState([])
    const [alterPortos, setAlterPortos] = useState([])
    const [porto, setPorto] = useState('')
    const [pesagem, setPesagem] = useState(0)
    const [Infraestrutura, setInfraestrutura] = useState(0)
    const [acessoAquaviario, setAcessoAquaviario] = useState(0)
    const [operadoresPortuarios, setOperadoresPortuarios] = useState(0)
    const [vira, setVira] = useState(0)
    const [quebra, setQuebra] = useState(0)
    const [afrmm, setAfrmm] = useState(0)
    const [siscomex, setSiscomex] = useState(0)
    const [despachante, setDespachante] = useState(0)
    const [seguro, setSeguro] = useState(0)
    const [armazengagemTransbordo, setArmazengagemTransbordo] = useState(0)
    const [quebraFerrovia, setQuebraFerrovia] = useState(0)
    const [quebraArmazem, setQuebraArmazem] = useState(0)
    const [bag, setBag] = useState(0)
    const [envase, setEnvase] = useState(0)

    const [U_Jan, setU_Jan] = useState(0)
    const [U_Fev, setU_Fev] = useState(0)
    const [U_Mar, setU_Mar] = useState(0)
    const [U_Abr, setU_Abr] = useState(0)
    const [U_Mai, setU_Mai] = useState(0)
    const [U_Jun, setU_Jun] = useState(0)
    const [U_Jul, setU_Jul] = useState(0)
    const [U_Ago, setU_Ago] = useState(0)
    const [U_Set, seU_Set] = useState(0)
    const [U_Out, seU_Out] = useState(0)
    const [U_Nov, setU_Nov] = useState(0)
    const [U_Dez, setU_Dez] = useState(0)

    const [C_Jan, setC_Jan] = useState(true)
    const [C_Fev, setC_Fev] = useState(true)
    const [C_Mar, setC_Mar] = useState(true)
    const [C_Abr, setC_Abr] = useState(true)
    const [C_Mai, setC_Mai] = useState(true)
    const [C_Jun, setC_Jun] = useState(true)
    const [C_Jul, setC_Jul] = useState(true)
    const [C_Ago, setC_Ago] = useState(true)
    const [C_Set, setC_Set] = useState(true)
    const [C_Out, setC_Out] = useState(true)
    const [C_Nov, setC_Nov] = useState(true)
    const [C_Dez, setC_Dez] = useState(true)
    const [fretesFerrov, setFretesFerrov] = useState('')
    const [demurrage, setDemurrage] = useState([])
    const [wait, setWait] = useState(false)
    const [val, setVal] = useState(0);
    const [vet, setVet] = useState([])
    const [vetPorto, setVetPorto] = useState([])
    const [destinosInsert, setDestinosInsert] = useState('')
    const [origem, setOrigem] = useState('')
    const [destino, setDestino] = useState('')
    const classes = useStyles()

    // states adicionar colaborador
    const [precos, setPrecos] = useState('')
    const [custosPort, setCustosPort] = useState('')


    useEffect(() => {
        async function fetchMyAPI() {
            await validateTokenUser(props)
            setPermitido(await checarPermissao('inputsPricing', props))

            const response_precos = await api.get('/retornaTabelaPrecos', { params: { userId: getUser(), token: getToken() } })
            setPrecos(response_precos.data)
            const response_navios = await api.get('/retornaCustosPortuarios', { params: { userId: getUser(), token: getToken() } })
            setCustosPort(response_navios.data)
            const response_materias = await api.get('/materiasPrimas', { params: { userId: getUser(), token: getToken() } })
            setMaterias(response_materias.data)

            const response_demurrage = await api.get('/retornaDemurragePortos', { params: { userId: getUser(), token: getToken() } })
            setDemurrage(response_demurrage.data)

            const fretes_ferrov = await api.get('/retornaFretesFerrov', { params: { userId: getUser(), token: getToken() } })
            setFretesFerrov(fretes_ferrov.data)

            const respose_destinos_insert = await api.get("/returnDestinosInsert", { params: { userId: getUser(), token: getToken() } })
            setDestinosInsert(respose_destinos_insert.data)

        }

        fetchMyAPI();
    }, [])

    async function adicionaMateriaPreco(produto, preco) {
        const response = await api.get('/adicionaMateriaPreco', { params: { userId: getUser(), token: getToken(), produto, preco } })
        if (response.data === 'NOK') {
            alert('Erro ao inserir preço')
        } else {
            alert('Salvo com sucesso!')
            window.location.reload()
        }

    }

    async function montaUpdate(id, valor) {
        let aux = alter
        aux[id] = valor
        setAlter(aux)
    }

    function montaUpdateDemurrage(val, coluna, id) {
        let aux = vet

        aux[id + '+' + coluna] = val
        setVet(aux)
        console.log(aux)

    }
    function montaUpdatefretesFerrov(val, coluna, id) {
        let aux = vetPorto

        aux[id + '+' + coluna] = val
        setVetPorto(aux)
        console.log(aux)

    }

    async function enviaUpdate() {
        let aux = ''
        Object.keys(alter).forEach(
            (item) => (aux = aux + item + '#' + alter[item] + ';')
        )
        const response = await api.get('/atualizaMateriasPreco', { params: { userId: getUser(), token: getToken(), aux } })
        if (response.data === 'NOK') {
            alert('Erro ao atualizar preços')
        } else {
            alert('Atualizado com sucesso!')
            window.location.reload()
        }
    }

    async function adicionaCustosPortuarios() {
        const response = await api.get('/adiconaPortoCustos', {
            params: {
                userId: getUser(), token: getToken(), porto, pesagem, Infraestrutura, acessoAquaviario, operadoresPortuarios, vira,
                quebra, afrmm, siscomex, despachante, seguro, demurrage, armazengagemTransbordo, quebraFerrovia,
                quebraArmazem, bag, envase
            }
        })
        if (response.data === 'NOK') {
            alert('Erro ao inserir preço')
        } else {
            alert('Salvo com sucesso!')
            window.location.reload()
        }

    }

    async function montaUpdatePortos(id, column, valor) {
        let aux = alterPortos
        aux[id + '+' + column] = valor
        setAlterPortos(aux)
    }

    async function enviaUpdateDemurrage() {
        let aux = ''
        Object.keys(alterPortos).forEach(
            (item) => (aux = aux + item + '#' + alterPortos[item] + ';')
        )
        const response = await api.get('/atualizaCustosPortuarios', { params: { userId: getUser(), token: getToken(), aux } })
        console.log(response.data)
        if (response.data === 'NOK') {
            alert('Erro ao atualizar preços')
        } else {
            alert('Atualizado com sucesso!')
            window.location.reload()
        }
    }
    async function enviaUpdatePortos() {
        let aux = ''
        Object.keys(alterPortos).forEach(
            (item) => (aux = aux + item + '#' + alterPortos[item] + ';')
        )
        const response = await api.get('/atualizaCustosPortuarios', { params: { userId: getUser(), token: getToken(), aux } })
        console.log(response.data)
        if (response.data === 'NOK') {
            alert('Erro ao atualizar preços')
        } else {
            alert('Atualizado com sucesso!')
            window.location.reload()
        }
    }
    async function AtualizaPontual() {
        setWait(true)

        let aux = ''
        Object.keys(vet).forEach(
            (item) => (aux = aux + item + '#' + vet[item] + ';')
        )
        const response_demurrage = await api.get("/atualizaDemurrage", {
            params: { vet: aux, userId: getUser(), token: getToken() }
        })

        setWait(false)

    }
    async function deletaDemurrage(or, ds) {
        alert('implementar')
        // const response_delete = await api2.get("/deletaRota", { params: { origem: or, destino: ds } });

        // if (response_delete.data === 'NOK') {
        //     alert('Falha ao remover frete')
        // } else {
        //     alert('Fretes removido com sucesso')
        //     window.location.reload();
        // }
    }
    async function ModificaDemurrageMassa() {
        alert('implementar')
        // let auxO = ''
        // Object.keys(origemF).forEach(
        //     (item) => (auxO = auxO + item + '#' + origemF[item] + ';')
        // )
        // let auxD = ''
        // Object.keys(destinoF).forEach(
        //     (item) => (auxD = auxD + item + '#' + destinoF[item] + ';')
        // )
        // if (auxO === '') { auxO = 'no' }
        // if (auxD === '') { auxD = 'no' }
        // alert('Atualizando fretes!')
        // const response_fretes = await api2.get("/updateAll", {
        //     params: {
        //         val, origens: auxO, destinos: auxD, C_Jan,
        //         C_Fev,
        //         C_Mar,
        //         C_Abr,
        //         C_Mai,
        //         C_Jun,
        //         C_Jul,
        //         C_Ago,
        //         C_Set,
        //         C_Out,
        //         C_Nov,
        //         C_Dez
        //     }
        // });

        // if (response_fretes.data === 'NOK') {
        //     alert('Falha ao atualizar fretes')
        // } else {
        //     alert('Fretes atualizados com sucesso')
        //     const response_fretes = await api2.get("/returnFretes", { params: { origens: auxO, destinos: auxD } });
        //     setFretes(response_fretes.data)
        // }
    }
    async function AdicionaFreteFerrov() {
        const response_fretes = await api.get("/AdicionaFreteFerrov", {
            params: {
                userId: getUser(), token: getToken(),
                U_Origem: origem,
                U_Destino: destino,

                U_Jan,
                U_Fev,
                U_Mar,
                U_Abr,
                U_Mai,
                U_Jun,
                U_Jul,
                U_Ago,
                U_Set,
                U_Out,
                U_Nov,
                U_Dez
            }
        });

        if (response_fretes.data === 'NOK') {
            alert('Falha ao atualizar fretes')
        } else {
            alert('Fretes adicionados com sucesso')
            window.location.reload();
        }
    }
    return (
        permitido === true ?
            <div style={{ height: 4000 }}>
                {/* <div style={{ height: 300 }} >
                    {materias !== '' ? <div style={{ border: '1px solid black', padding: 20, borderRadius: 10, display: 'flex', flexDirection: 'column', width: 400, background: 'white' }}>
                        <Autocomplete
                            id="combo-box-demo2"

                            options={Object.keys(materias).map((item) => materias[item].ItemName)}
                            value={materia}
                            getOptionSelected={(option, value) => option === value || value === ''}

                            style={{ width: 400 }}
                            onChange={(e, v) => setMateria(v)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label='Nome Produto'
                                    multiline
                                />
                            )}

                        />
                        <TextField
                            value={preco}
                            style={{ width: 150, marginTop: 12 }}
                            type="number"
                            label='Preço CFR'
                            onChange={(e) => setPreco(e.target.value)} />
                        <Button style={{ backgroundColor: '#6699ff', width: 400, marginTop: 12, marginBottom: 12 }} variant="contained" onClick={() => adicionaMateriaPreco(materia, preco)}>Salvar Matéria</Button>
                    </div> : <Loader
                        type="Circles"
                        color="#00BFFF"
                        height={20}
                        width={20}
                        timeout={2000000} //3 secs

                    />}

                </div> */}
                {/* <div style={{ height: 600 }}>
                    {precos !== '' ?
                        <TableContainer component={Paper}>
                            <Table size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Item</TableCell>
                                        <TableCell >Preço CFR $</TableCell>

                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {precos.map((item) => (
                                        <TableRow key={item.id}>


                                            <TableCell align="left">{item.cod} - {item.produto}</TableCell>
                                            <TableCell align="left">
                                                <TextField
                                                    defaultValue={item.preco}
                                                    style={{ width: 150, marginTop: 12 }}
                                                    type="number"
                                                    label='Valor Unitário'
                                                    onChange={(e) => montaUpdate(item.id, e.target.value)} /></TableCell>

                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            <Button style={{ backgroundColor: '#6699ff', width: 400, marginTop: 12, marginBottom: 12 }} variant="contained" onClick={() => enviaUpdate()}>Salvar Modificações</Button>
                        </TableContainer> : null}

                </div> */}
                <div style={{ border: '1px solid black', padding: 20, borderRadius: 10, display: 'flex', flexDirection: 'row', width: 900, background: 'white', marginBottom: 10 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', width: 400, padding: 10 }}>
                        <Autocomplete
                            id="combo-box-demo"
                            options={['Vitória - ES', 'Paranaguá - PR', 'Barcarena - PA', 'Itaqui - MA', 'Barcarena - PA', 'Barcarena - PA', 'Barcarena - PA', 'Barcarena - PA']}
                            getOptionSelected={(option, value) => option === value || value === ''}
                            value={porto}
                            style={{ width: 400 }}
                            onInputChange={(e, v) => setPorto(v)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label='Porto'
                                    multiline
                                />
                            )}
                        />
                        <TextField
                            value={pesagem}
                            style={{ width: 150, marginTop: 12 }}
                            type="number"
                            label='Porto'
                            onChange={(e) => setPesagem(e.target.value)} />
                        <TextField
                            value={Infraestrutura}
                            style={{ width: 150, marginTop: 12 }}
                            type="number"
                            label='Infraestrutura'
                            onChange={(e) => setInfraestrutura(e.target.value)} />
                        <TextField
                            value={acessoAquaviario}
                            style={{ width: 150, marginTop: 12 }}
                            type="number"
                            label='Acesso Aquaviario'
                            onChange={(e) => setAcessoAquaviario(e.target.value)} />
                        <TextField
                            value={operadoresPortuarios}
                            style={{ width: 150, marginTop: 12 }}
                            type="number"
                            label='Operadores Portuarios'
                            onChange={(e) => setOperadoresPortuarios(e.target.value)} />
                        <TextField
                            value={vira}
                            style={{ width: 150, marginTop: 12 }}
                            type="number"
                            label='Vira'
                            onChange={(e) => setVira(e.target.value)} />
                        <TextField
                            value={quebra}
                            style={{ width: 150, marginTop: 12 }}
                            type="number"
                            label='Quebra'
                            onChange={(e) => setQuebra(e.target.value)} />
                        <TextField
                            value={afrmm}
                            style={{ width: 150, marginTop: 12 }}
                            type="number"
                            label='AFRMM'
                            onChange={(e) => setAfrmm(e.target.value)} />
                        <TextField
                            value={siscomex}
                            style={{ width: 150, marginTop: 12 }}
                            type="number"
                            label='SISCOMEX'
                            onChange={(e) => setSiscomex(e.target.value)} />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', width: 400, padding: 10 }}>
                        <TextField
                            value={despachante}
                            style={{ width: 150, marginTop: 12 }}
                            type="number"
                            label='Despachante'
                            onChange={(e) => setDespachante(e.target.value)} />
                        <TextField
                            value={seguro}
                            style={{ width: 150, marginTop: 12 }}
                            type="number"
                            label='Seguro'
                            onChange={(e) => setSeguro(e.target.value)} />
                        {/* <TextField
                            value={demurrage}
                            style={{ width: 150, marginTop: 12 }}
                            type="number"
                            label='Demurrage'
                            onChange={(e) => setDemurrage(e.target.value)} /> */}

                        <TextField
                            value={armazengagemTransbordo}
                            style={{ width: 150, marginTop: 12 }}
                            type="number"
                            label='Armazengagem Transbordo'
                            onChange={(e) => setArmazengagemTransbordo(e.target.value)} />
                        <TextField
                            value={quebraFerrovia}
                            style={{ width: 150, marginTop: 12 }}
                            type="number"
                            label='Quebra Ferrovia'
                            onChange={(e) => setQuebraFerrovia(e.target.value)} />
                        <TextField
                            value={quebraArmazem}
                            style={{ width: 150, marginTop: 12 }}
                            type="number"
                            label='Quebra Armazem'
                            onChange={(e) => setQuebraArmazem(e.target.value)} />
                        <TextField
                            value={bag}
                            style={{ width: 150, marginTop: 12 }}
                            type="number"
                            label='Bag'
                            onChange={(e) => setBag(e.target.value)} />
                        <TextField
                            value={envase}
                            style={{ width: 150, marginTop: 12 }}
                            type="number"
                            label='Envase'
                            onChange={(e) => setEnvase(e.target.value)} />
                        <Button style={{ backgroundColor: '#6699ff', width: 150 }} variant="contained" onClick={() => adicionaCustosPortuarios()}>Salvar Custos Porto</Button>

                    </div>
                </div>
                <div style={{ height: 600 }}>
                    {custosPort !== '' ?
                        <TableContainer component={Paper}>
                            <Table size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Porto</TableCell>
                                        <TableCell >Pesagem</TableCell>
                                        <TableCell>Infraestrutura</TableCell>
                                        <TableCell >Acesso aquaviario</TableCell>
                                        <TableCell>Operadores Portuarios</TableCell>
                                        <TableCell >Vira</TableCell>
                                        <TableCell>Quebra</TableCell>
                                        <TableCell >AFRMM</TableCell>
                                        <TableCell>Siscomex</TableCell>
                                        <TableCell >Despachante</TableCell>
                                        <TableCell>Seguro</TableCell>
                                        {/* <TableCell >Demurrage</TableCell> */}
                                        <TableCell>Armazenagem Transbordo</TableCell>
                                        <TableCell >Quebra Ferrovia</TableCell>
                                        <TableCell >Quebra Armazém</TableCell>
                                        <TableCell>Bag</TableCell>
                                        <TableCell >Envase</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {custosPort.map((custosPort) => (
                                        <TableRow key={custosPort.id}>


                                            <TableCell align="left">{custosPort.porto}</TableCell>
                                            <TableCell align="left">
                                                <TextField
                                                    defaultValue={custosPort.pesagem}
                                                    style={{ width: 150, marginTop: 12 }}
                                                    type="number"
                                                    label='Pesagem'
                                                    onChange={(e) => montaUpdatePortos(custosPort.id, 'pesagem', e.target.value)} /></TableCell>
                                            <TableCell align="left">
                                                <TextField
                                                    defaultValue={custosPort.Infraestrutura}
                                                    style={{ width: 150, marginTop: 12 }}
                                                    type="number"
                                                    label='Infra'
                                                    onChange={(e) => montaUpdatePortos(custosPort.id, 'Infraestrutura', e.target.value)} /></TableCell>
                                            <TableCell align="left">
                                                <TextField
                                                    defaultValue={custosPort.acessoAquaviario}
                                                    style={{ width: 150, marginTop: 12 }}
                                                    type="number"
                                                    label='acessoAquaviario'
                                                    onChange={(e) => montaUpdatePortos(custosPort.id, 'acessoAquaviario', e.target.value)} /></TableCell>
                                            <TableCell align="left">
                                                <TextField
                                                    defaultValue={custosPort.operadoresPortuarios}
                                                    style={{ width: 150, marginTop: 12 }}
                                                    type="number"
                                                    label='operadoresPortuarios'
                                                    onChange={(e) => montaUpdatePortos(custosPort.id, 'operadoresPortuarios', e.target.value)} /></TableCell>
                                            <TableCell align="left">
                                                <TextField
                                                    defaultValue={custosPort.vira}
                                                    style={{ width: 150, marginTop: 12 }}
                                                    type="number"
                                                    label='vira'
                                                    onChange={(e) => montaUpdatePortos(custosPort.id, 'vira', e.target.value)} /></TableCell>
                                            <TableCell align="left">
                                                <TextField
                                                    defaultValue={custosPort.quebra}
                                                    style={{ width: 150, marginTop: 12 }}
                                                    type="number"
                                                    label='quebra'
                                                    onChange={(e) => montaUpdatePortos(custosPort.id, 'quebra', e.target.value)} /></TableCell>
                                            <TableCell align="left">
                                                <TextField
                                                    defaultValue={custosPort.afrmm}
                                                    style={{ width: 150, marginTop: 12 }}
                                                    type="number"
                                                    label='afrmm'
                                                    onChange={(e) => montaUpdatePortos(custosPort.id, 'afrmm', e.target.value)} /></TableCell>
                                            <TableCell align="left">
                                                <TextField
                                                    defaultValue={custosPort.siscomex}
                                                    style={{ width: 150, marginTop: 12 }}
                                                    type="number"
                                                    label='siscomex'
                                                    onChange={(e) => montaUpdatePortos(custosPort.id, 'siscomex', e.target.value)} /></TableCell>
                                            <TableCell align="left">
                                                <TextField
                                                    defaultValue={custosPort.despachante}
                                                    style={{ width: 150, marginTop: 12 }}
                                                    type="number"
                                                    label='despachante'
                                                    onChange={(e) => montaUpdatePortos(custosPort.id, 'despachante', e.target.value)} /></TableCell>
                                            <TableCell align="left">
                                                <TextField
                                                    defaultValue={custosPort.seguro}
                                                    style={{ width: 150, marginTop: 12 }}
                                                    type="number"
                                                    label='seguro'
                                                    onChange={(e) => montaUpdatePortos(custosPort.id, 'seguro', e.target.value)} /></TableCell>
                                            {/* <TableCell align="left">
                                                <TextField
                                                    defaultValue={custosPort.demurrage}
                                                    style={{ width: 150, marginTop: 12 }}
                                                    type="number"
                                                    label='demurrage'
                                                    onChange={(e) => montaUpdatePortos(custosPort.id, 'demurrage', e.target.value)} /></TableCell> */}
                                            <TableCell align="left">
                                                <TextField
                                                    defaultValue={custosPort.armazengagemTransbordo}
                                                    style={{ width: 150, marginTop: 12 }}
                                                    type="number"
                                                    label='armazengagemTransbordo'
                                                    onChange={(e) => montaUpdatePortos(custosPort.id, 'armazengagemTransbordo', e.target.value)} /></TableCell>
                                            <TableCell align="left">
                                                <TextField
                                                    defaultValue={custosPort.quebraFerrovia}
                                                    style={{ width: 150, marginTop: 12 }}
                                                    type="number"
                                                    label='quebraFerrovia'
                                                    onChange={(e) => montaUpdatePortos(custosPort.id, 'quebraFerrovia', e.target.value)} /></TableCell>
                                            <TableCell align="left">
                                                <TextField
                                                    defaultValue={custosPort.quebraArmazem}
                                                    style={{ width: 150, marginTop: 12 }}
                                                    type="number"
                                                    label='quebraArmazem'
                                                    onChange={(e) => montaUpdatePortos(custosPort.id, 'quebraArmazem', e.target.value)} /></TableCell>
                                            <TableCell align="left">
                                                <TextField
                                                    defaultValue={custosPort.bag}
                                                    style={{ width: 150, marginTop: 12 }}
                                                    type="number"
                                                    label='bag'
                                                    onChange={(e) => montaUpdatePortos(custosPort.id, 'bag', e.target.value)} /></TableCell>
                                            <TableCell align="left">
                                                <TextField
                                                    defaultValue={custosPort.envase}
                                                    style={{ width: 150, marginTop: 12 }}
                                                    type="number"
                                                    label='envase'
                                                    onChange={(e) => montaUpdatePortos(custosPort.id, 'envase', e.target.value)} /></TableCell>




                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            <Button style={{ backgroundColor: '#6699ff', width: 400, marginTop: 12, marginBottom: 12 }} variant="contained" onClick={() => enviaUpdatePortos()}>Salvar Modificações</Button>
                        </TableContainer> : null}
                    <div >
                        <div xs={12} sm={12} md={12}>
                            <h4> Tabela Demurrage</h4>
                            <TableContainer style={{ marginTop: 20, height: 700, borderStyle: 'solid' }} >
                                {demurrage !== '' ? <Table id="table-to-xls" >
                                    <TableHead>
                                        <TableRow style={{ textAlign: "center", border: "1px solid " }}>
                                            <TableCell style={{ textAlign: "center", border: "1px solid " }}>
                                                Porto
                                            </TableCell>
                                            <TableCell style={{ textAlign: "center", border: "1px solid " }}>
                                                {/* <Checkbox
                                                checked={C_Jan}
                                                onChange={() => setC_Jan(!C_Jan)}
                                                name="checkJan"
                                                color="primary"
                                            /> */}
                                                Janeiro
                                            </TableCell>
                                            <TableCell style={{ textAlign: "center", border: "1px solid " }}>
                                                {/* <Checkbox
                                                checked={C_Fev}
                                                onChange={() => setC_Fev(!C_Fev)}
                                                name="checkFev"
                                                color="primary"
                                            /> */}
                                                Feveiro
                                            </TableCell>
                                            <TableCell style={{ textAlign: "center", border: "1px solid " }}>
                                                {/* <Checkbox
                                                checked={C_Mar}
                                                onChange={() => setC_Mar(!C_Mar)}
                                                name="checkMar"
                                                color="primary"
                                            /> */}
                                                Março
                                            </TableCell>
                                            <TableCell style={{ textAlign: "center", border: "1px solid " }}>
                                                {/* <Checkbox
                                                checked={C_Abr}
                                                onChange={() => setC_Abr(!C_Abr)}
                                                name="checkAbr"
                                                color="primary"
                                            /> */}
                                                Abril
                                            </TableCell>
                                            <TableCell style={{ textAlign: "center", border: "1px solid " }}>
                                                {/* <Checkbox
                                                checked={C_Mai}

                                                onChange={() => setC_Mai(!C_Mai)}
                                                name="checkMai"
                                                color="primary"
                                            /> */}
                                                Maio
                                            </TableCell>
                                            <TableCell style={{ textAlign: "center", border: "1px solid " }}>
                                                {/* <Checkbox
                                                checked={C_Jun}

                                                onChange={() => setC_Jun(!C_Jun)}
                                                name="checkJun"
                                                color="primary"
                                            /> */}
                                                Junho
                                            </TableCell>
                                            <TableCell style={{ textAlign: "center", border: "1px solid " }}>
                                                {/* <Checkbox
                                                checked={C_Jul}

                                                onChange={() => setC_Jul(!C_Jul)}
                                                name="checkJul"
                                                color="primary"
                                            /> */}
                                                Julho
                                            </TableCell>
                                            <TableCell style={{ textAlign: "center", border: "1px solid " }}>
                                                {/* <Checkbox
                                                checked={C_Ago}

                                                onChange={() => setC_Ago(!C_Ago)}
                                                name="checkAgo"
                                                color="primary"
                                            /> */}
                                                Agosto
                                            </TableCell>
                                            <TableCell style={{ textAlign: "center", border: "1px solid " }}>
                                                {/* <Checkbox
                                                checked={C_Set}

                                                onChange={() => setC_Set(!C_Set)}
                                                name="checkSet"
                                                color="primary"
                                            /> */}
                                                Setembro
                                            </TableCell>
                                            <TableCell style={{ textAlign: "center", border: "1px solid " }}>
                                                {/* <Checkbox
                                                checked={C_Out}

                                                onChange={() => setC_Out(!C_Out)}
                                                name="checkOut"
                                                color="primary"
                                            /> */}
                                                Outubro
                                            </TableCell>
                                            <TableCell style={{ textAlign: "center", border: "1px solid " }}>
                                                {/* <Checkbox
                                                checked={C_Nov}

                                                onChange={() => setC_Nov(!C_Nov)}
                                                name="checkNov"
                                                color="primary"
                                            /> */}
                                                Novembro
                                            </TableCell>
                                            <TableCell style={{ textAlign: "center", border: "1px solid " }}>
                                                {/* <Checkbox
                                                checked={C_Dez}

                                                onChange={() => setC_Dez(!C_Dez)}
                                                name="checkDez"
                                                color="primary"
                                            /> */}
                                                Dezembro
                                            </TableCell>
                                            {/* <TableCell style={{ textAlign: "center", border: "1px solid " }}>
                                            Ultima Atualização
                                        </TableCell> */}
                                        </TableRow>
                                    </TableHead>
                                    <tbody>
                                        {demurrage !== '' ?
                                            Object.keys(demurrage.slice(0, 50)).map((item, index) => (
                                                <TableRow key={index}>
                                                    <TableCell style={{ width: 300, textAlign: "center", border: "1px solid " }}>
                                                        {demurrage[item].porto}
                                                    </TableCell>
                                                    <TableCell style={{ width: 300, textAlign: "center", border: "1px solid " }}>

                                                        <TextField type='number' label={demurrage[item].U_Jan} onChange={(e) => montaUpdateDemurrage(e.target.value, 'U_Jan', demurrage[item].id)} />
                                                    </TableCell>
                                                    <TableCell style={{ width: 300, textAlign: "center", border: "1px solid " }}>

                                                        <TextField type='number' label={demurrage[item].U_Fev} onChange={(e) => montaUpdateDemurrage(e.target.value, 'U_Fev', demurrage[item].id)} />
                                                    </TableCell>
                                                    <TableCell style={{ width: 300, textAlign: "center", border: "1px solid " }}>

                                                        <TextField type='number' label={demurrage[item].U_Mar} onChange={(e) => montaUpdateDemurrage(e.target.value, 'U_Mar', demurrage[item].id)} />
                                                    </TableCell>
                                                    <TableCell style={{ width: 300, textAlign: "center", border: "1px solid " }}>

                                                        <TextField type='number' label={demurrage[item].U_Abr} onChange={(e) => montaUpdateDemurrage(e.target.value, 'U_Abr', demurrage[item].id)} />
                                                    </TableCell>
                                                    <TableCell style={{ width: 300, textAlign: "center", border: "1px solid " }}>

                                                        <TextField type='number' label={demurrage[item].U_Mai} onChange={(e) => montaUpdateDemurrage(e.target.value, 'U_Mai', demurrage[item].id)} />
                                                    </TableCell>
                                                    <TableCell style={{ width: 300, textAlign: "center", border: "1px solid " }}>

                                                        <TextField type='number' label={demurrage[item].U_Jun} onChange={(e) => montaUpdateDemurrage(e.target.value, 'U_Jun', demurrage[item].id)} />
                                                    </TableCell>
                                                    <TableCell style={{ width: 300, textAlign: "center", border: "1px solid " }}>

                                                        <TextField type='number' label={demurrage[item].U_Jul} onChange={(e) => montaUpdateDemurrage(e.target.value, 'U_Jul', demurrage[item].id)} />

                                                    </TableCell>
                                                    <TableCell style={{ width: 300, textAlign: "center", border: "1px solid " }}>

                                                        <TextField type='number' label={demurrage[item].U_Ago} onChange={(e) => montaUpdateDemurrage(e.target.value, 'U_Ago', demurrage[item].id)} />
                                                    </TableCell>
                                                    <TableCell style={{ width: 300, textAlign: "center", border: "1px solid " }}>

                                                        <TextField type='number' label={demurrage[item].U_Set} onChange={(e) => montaUpdateDemurrage(e.target.value, 'U_Set', demurrage[item].id)} />
                                                    </TableCell>
                                                    <TableCell style={{ width: 300, textAlign: "center", border: "1px solid " }}>

                                                        <TextField type='number' label={demurrage[item].U_Out} onChange={(e) => montaUpdateDemurrage(e.target.value, 'U_Out', demurrage[item].id)} />


                                                    </TableCell>
                                                    <TableCell style={{ width: 300, textAlign: "center", border: "1px solid " }}>

                                                        <TextField type='number' label={demurrage[item].U_Nov} onChange={(e) => montaUpdateDemurrage(e.target.value, 'U_Nov', demurrage[item].id)} />


                                                    </TableCell>
                                                    <TableCell style={{ width: 300, textAlign: "center", border: "1px solid " }}>

                                                        <TextField type='number' label={demurrage[item].U_Dez} onChange={(e) => montaUpdateDemurrage(e.target.value, 'U_Dez', demurrage[item].id)} />

                                                    </TableCell>

                                                    {/* 

                                                <TableCell style={{ width: 300, textAlign: "center", border: "1px solid " }}>
                                                    {demurrage[item].daysUpdate}

                                                </TableCell> */}

                                                </TableRow>

                                            )) : <div></div>}
                                    </tbody>
                                </Table> : <Loader
                                    type="Circles"
                                    color="#00BFFF"
                                    height={20}
                                    width={20}
                                    timeout={20000} //3 secs
                                />}

                            </TableContainer>

                            <div>
                                <Button disabled={wait} style={{ backgroundColor: '#6699ff' }} variant="contained" onClick={() => AtualizaPontual()}>Atualizar</Button>
                            </div>
                        </div>
                        <div>
                            <TextField type="number" style={{ width: 250, marginTop: 20 }} onChange={(e) => setVal(e.target.value)} />
                        </div>
                        <div>
                            <Button disabled={wait} style={{ backgroundColor: '#6699ff' }} variant="contained" onClick={() => ModificaDemurrageMassa()}>Atualizar Toda a Tabela</Button>
                        </div>
                    </div>
                    <div >
                        <div xs={12} sm={12} md={12}>
                            <h4> Fretes Ferroviarios</h4>
                            <TableContainer style={{ marginTop: 20, height: 700, borderStyle: 'solid' }} >
                                {fretesFerrov !== '' ? <Table id="table-to-xls" >
                                    <TableHead>
                                        <TableRow style={{ textAlign: "center", border: "1px solid " }}>
                                            <TableCell style={{ textAlign: "center", border: "1px solid " }}>
                                                Origem
                                            </TableCell>
                                            <TableCell style={{ textAlign: "center", border: "1px solid " }}>
                                                Destino
                                            </TableCell>
                                            <TableCell style={{ textAlign: "center", border: "1px solid " }}>
                                                {/* <Checkbox
                                                checked={C_Jan}
                                                onChange={() => setC_Jan(!C_Jan)}
                                                name="checkJan"
                                                color="primary"
                                            /> */}
                                                Janeiro
                                            </TableCell>
                                            <TableCell style={{ textAlign: "center", border: "1px solid " }}>
                                                {/* <Checkbox
                                                checked={C_Fev}
                                                onChange={() => setC_Fev(!C_Fev)}
                                                name="checkFev"
                                                color="primary"
                                            /> */}
                                                Feveiro
                                            </TableCell>
                                            <TableCell style={{ textAlign: "center", border: "1px solid " }}>
                                                {/* <Checkbox
                                                checked={C_Mar}
                                                onChange={() => setC_Mar(!C_Mar)}
                                                name="checkMar"
                                                color="primary"
                                            /> */}
                                                Março
                                            </TableCell>
                                            <TableCell style={{ textAlign: "center", border: "1px solid " }}>
                                                {/* <Checkbox
                                                checked={C_Abr}
                                                onChange={() => setC_Abr(!C_Abr)}
                                                name="checkAbr"
                                                color="primary"
                                            /> */}
                                                Abril
                                            </TableCell>
                                            <TableCell style={{ textAlign: "center", border: "1px solid " }}>
                                                {/* <Checkbox
                                                checked={C_Mai}

                                                onChange={() => setC_Mai(!C_Mai)}
                                                name="checkMai"
                                                color="primary"
                                            /> */}
                                                Maio
                                            </TableCell>
                                            <TableCell style={{ textAlign: "center", border: "1px solid " }}>
                                                {/* <Checkbox
                                                checked={C_Jun}

                                                onChange={() => setC_Jun(!C_Jun)}
                                                name="checkJun"
                                                color="primary"
                                            /> */}
                                                Junho
                                            </TableCell>
                                            <TableCell style={{ textAlign: "center", border: "1px solid " }}>
                                                {/* <Checkbox
                                                checked={C_Jul}

                                                onChange={() => setC_Jul(!C_Jul)}
                                                name="checkJul"
                                                color="primary"
                                            /> */}
                                                Julho
                                            </TableCell>
                                            <TableCell style={{ textAlign: "center", border: "1px solid " }}>
                                                {/* <Checkbox
                                                checked={C_Ago}

                                                onChange={() => setC_Ago(!C_Ago)}
                                                name="checkAgo"
                                                color="primary"
                                            /> */}
                                                Agosto
                                            </TableCell>
                                            <TableCell style={{ textAlign: "center", border: "1px solid " }}>
                                                {/* <Checkbox
                                                checked={C_Set}

                                                onChange={() => setC_Set(!C_Set)}
                                                name="checkSet"
                                                color="primary"
                                            /> */}
                                                Setembro
                                            </TableCell>
                                            <TableCell style={{ textAlign: "center", border: "1px solid " }}>
                                                {/* <Checkbox
                                                checked={C_Out}

                                                onChange={() => setC_Out(!C_Out)}
                                                name="checkOut"
                                                color="primary"
                                            /> */}
                                                Outubro
                                            </TableCell>
                                            <TableCell style={{ textAlign: "center", border: "1px solid " }}>
                                                {/* <Checkbox
                                                checked={C_Nov}

                                                onChange={() => setC_Nov(!C_Nov)}
                                                name="checkNov"
                                                color="primary"
                                            /> */}
                                                Novembro
                                            </TableCell>
                                            <TableCell style={{ textAlign: "center", border: "1px solid " }}>
                                                {/* <Checkbox
                                                checked={C_Dez}

                                                onChange={() => setC_Dez(!C_Dez)}
                                                name="checkDez"
                                                color="primary"
                                            /> */}
                                                Dezembro
                                            </TableCell>
                                            {/* <TableCell style={{ textAlign: "center", border: "1px solid " }}>
                                            Ultima Atualização
                                        </TableCell> */}
                                        </TableRow>
                                    </TableHead>
                                    <tbody>
                                        {fretesFerrov !== '' ?
                                            Object.keys(fretesFerrov.slice(0, 50)).map((item, index) => (
                                                <TableRow key={index}>
                                                    <TableCell style={{ width: 300, textAlign: "center", border: "1px solid " }}>
                                                        {fretesFerrov[item].U_Origem + '-' + fretesFerrov[item].U_UF_Origem}
                                                    </TableCell>
                                                    <TableCell style={{ width: 300, textAlign: "center", border: "1px solid " }}>
                                                        {fretesFerrov[item].U_Destino + '-' + fretesFerrov[item].U_UF_Destino}
                                                    </TableCell>
                                                    <TableCell style={{ width: 300, textAlign: "center", border: "1px solid " }}>

                                                        <TextField type='number' label={fretesFerrov[item].U_Jan} onChange={(e) => montaUpdatefretesFerrov(e.target.value, 'U_Jan', fretesFerrov[item].id)} />
                                                    </TableCell>
                                                    <TableCell style={{ width: 300, textAlign: "center", border: "1px solid " }}>

                                                        <TextField type='number' label={fretesFerrov[item].U_Fev} onChange={(e) => montaUpdatefretesFerrov(e.target.value, 'U_Fev', fretesFerrov[item].id)} />
                                                    </TableCell>
                                                    <TableCell style={{ width: 300, textAlign: "center", border: "1px solid " }}>

                                                        <TextField type='number' label={fretesFerrov[item].U_Mar} onChange={(e) => montaUpdatefretesFerrov(e.target.value, 'U_Mar', fretesFerrov[item].id)} />
                                                    </TableCell>
                                                    <TableCell style={{ width: 300, textAlign: "center", border: "1px solid " }}>

                                                        <TextField type='number' label={fretesFerrov[item].U_Abr} onChange={(e) => montaUpdatefretesFerrov(e.target.value, 'U_Abr', fretesFerrov[item].id)} />
                                                    </TableCell>
                                                    <TableCell style={{ width: 300, textAlign: "center", border: "1px solid " }}>

                                                        <TextField type='number' label={fretesFerrov[item].U_Mai} onChange={(e) => montaUpdatefretesFerrov(e.target.value, 'U_Mai', fretesFerrov[item].id)} />
                                                    </TableCell>
                                                    <TableCell style={{ width: 300, textAlign: "center", border: "1px solid " }}>

                                                        <TextField type='number' label={fretesFerrov[item].U_Jun} onChange={(e) => montaUpdatefretesFerrov(e.target.value, 'U_Jun', fretesFerrov[item].id)} />
                                                    </TableCell>
                                                    <TableCell style={{ width: 300, textAlign: "center", border: "1px solid " }}>

                                                        <TextField type='number' label={fretesFerrov[item].U_Jul} onChange={(e) => montaUpdatefretesFerrov(e.target.value, 'U_Jul', fretesFerrov[item].id)} />

                                                    </TableCell>
                                                    <TableCell style={{ width: 300, textAlign: "center", border: "1px solid " }}>

                                                        <TextField type='number' label={fretesFerrov[item].U_Ago} onChange={(e) => montaUpdatefretesFerrov(e.target.value, 'U_Ago', fretesFerrov[item].id)} />
                                                    </TableCell>
                                                    <TableCell style={{ width: 300, textAlign: "center", border: "1px solid " }}>

                                                        <TextField type='number' label={fretesFerrov[item].U_Set} onChange={(e) => montaUpdatefretesFerrov(e.target.value, 'U_Set', fretesFerrov[item].id)} />
                                                    </TableCell>
                                                    <TableCell style={{ width: 300, textAlign: "center", border: "1px solid " }}>

                                                        <TextField type='number' label={fretesFerrov[item].U_Out} onChange={(e) => montaUpdatefretesFerrov(e.target.value, 'U_Out', fretesFerrov[item].id)} />


                                                    </TableCell>
                                                    <TableCell style={{ width: 300, textAlign: "center", border: "1px solid " }}>

                                                        <TextField type='number' label={fretesFerrov[item].U_Nov} onChange={(e) => montaUpdatefretesFerrov(e.target.value, 'U_Nov', fretesFerrov[item].id)} />


                                                    </TableCell>
                                                    <TableCell style={{ width: 300, textAlign: "center", border: "1px solid " }}>

                                                        <TextField type='number' label={fretesFerrov[item].U_Dez} onChange={(e) => montaUpdatefretesFerrov(e.target.value, 'U_Dez', fretesFerrov[item].id)} />

                                                    </TableCell>

                                                    {/* 

                                                <TableCell style={{ width: 300, textAlign: "center", border: "1px solid " }}>
                                                    {demurrage[item].daysUpdate}

                                                </TableCell> */}

                                                </TableRow>

                                            )) : <div></div>}
                                    </tbody>
                                </Table> : <Loader
                                    type="Circles"
                                    color="#00BFFF"
                                    height={20}
                                    width={20}
                                    timeout={20000} //3 secs
                                />}

                            </TableContainer>

                            <div>
                                <Button disabled={wait} style={{ backgroundColor: '#6699ff' }} variant="contained" onClick={() => AtualizaPontual()}>Atualizar</Button>
                            </div>
                        </div>
                        <div>
                            <TextField type="number" style={{ width: 250, marginTop: 20 }} onChange={(e) => setVal(e.target.value)} />
                        </div>
                        <div>
                            <Button disabled={wait} style={{ backgroundColor: '#6699ff' }} variant="contained" onClick={() => ModificaDemurrageMassa()}>Atualizar Toda a Tabela</Button>
                        </div>

                        <div style={{ marginTop: 30 }} >
                            <table >
                                <thead>
                                    <tr style={{ textAlign: "center", border: "1px solid " }}>
                                        <th style={{ textAlign: "center", border: "1px solid " }}>
                                            Origem
                                        </th>
                                        <th style={{ textAlign: "center", border: "1px solid " }}>
                                            Destino
                                        </th>

                                        <th style={{ textAlign: "center", border: "1px solid " }}>
                                            Janeiro
                                        </th>
                                        <th style={{ textAlign: "center", border: "1px solid " }}>
                                            Feveiro
                                        </th>
                                        <th style={{ textAlign: "center", border: "1px solid " }}>
                                            Março
                                        </th>
                                        <th style={{ textAlign: "center", border: "1px solid " }}>
                                            Abril
                                        </th>
                                        <th style={{ textAlign: "center", border: "1px solid " }}>
                                            Maio
                                        </th>
                                        <th style={{ textAlign: "center", border: "1px solid " }}>
                                            Junho
                                        </th>
                                        <th style={{ textAlign: "center", border: "1px solid " }}>
                                            Julho
                                        </th>
                                        <th style={{ textAlign: "center", border: "1px solid " }}>
                                            Agosto
                                        </th>
                                        <th style={{ textAlign: "center", border: "1px solid " }}>
                                            Setembro
                                        </th>
                                        <th style={{ textAlign: "center", border: "1px solid " }}>
                                            Outubro
                                        </th>
                                        <th style={{ textAlign: "center", border: "1px solid " }}>
                                            Novembro
                                        </th>
                                        <th style={{ textAlign: "center", border: "1px solid " }}>
                                            Dezembro
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style={{ width: 200, textAlign: "center", border: "1px solid " }}>
                                            {destinosInsert !== '' ? <Autocomplete

                                                id="combo-box-demo"
                                                options={destinosInsert}
                                                getOptionLabel={(option) => option.City_uf}
                                                style={{ width: 200, marginTop: 36 }}

                                                onChange={(e, v) => setOrigem(v)}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Origem"

                                                    />
                                                )}
                                            /> : <Loader
                                                type="Circles"
                                                color="#00BFFF"
                                                height={20}
                                                width={20}
                                                timeout={20000} //3 secs
                                            />}

                                        </td>

                                        <td style={{ width: 200, textAlign: "center", border: "1px solid " }}>
                                            {destinosInsert !== '' ? <Autocomplete

                                                id="combo-box-demo"
                                                options={destinosInsert}
                                                getOptionLabel={(option) => option.City_uf}
                                                style={{ width: 200, marginTop: 36 }}

                                                onChange={(e, v) => setDestino(v)}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Destino"

                                                    />
                                                )}
                                            /> : <Loader
                                                type="Circles"
                                                color="#00BFFF"
                                                height={20}
                                                width={20}
                                                timeout={20000} //3 secs
                                            />}
                                        </td>
                                        <td style={{ width: 100, textAlign: "center", border: "1px solid " }}>

                                            <TextField type='number' onChange={(e) => setU_Jan(e.target.value)} />
                                        </td>
                                        <td style={{ width: 100, textAlign: "center", border: "1px solid " }}>

                                            <TextField type='number' onChange={(e) => setU_Fev(e.target.value)} />
                                        </td>
                                        <td style={{ width: 100, textAlign: "center", border: "1px solid " }}>

                                            <TextField type='number' onChange={(e) => setU_Mar(e.target.value)} />
                                        </td>
                                        <td style={{ width: 100, textAlign: "center", border: "1px solid " }}>

                                            <TextField type='number' onChange={(e) => setU_Abr(e.target.value)} />
                                        </td>
                                        <td style={{ width: 100, textAlign: "center", border: "1px solid " }}>

                                            <TextField type='number' onChange={(e) => setU_Mai(e.target.value)} />
                                        </td>
                                        <td style={{ width: 100, textAlign: "center", border: "1px solid " }}>

                                            <TextField type='number' onChange={(e) => setU_Jun(e.target.value)} />
                                        </td>
                                        <td style={{ width: 100, textAlign: "center", border: "1px solid " }}>

                                            <TextField type='number' onChange={(e) => setU_Jul(e.target.value)} />
                                        </td>
                                        <td style={{ width: 100, textAlign: "center", border: "1px solid " }}>

                                            <TextField type='number' onChange={(e) => setU_Ago(e.target.value)} />
                                        </td>
                                        <td style={{ width: 100, textAlign: "center", border: "1px solid " }}>

                                            <TextField type='number' onChange={(e) => seU_Set(e.target.value)} />
                                        </td>
                                        <td style={{ width: 100, textAlign: "center", border: "1px solid " }}>

                                            <TextField type='number' onChange={(e) => seU_Out(e.target.value)} />
                                        </td>
                                        <td style={{ width: 100, textAlign: "center", border: "1px solid " }}>

                                            <TextField type='number' onChange={(e) => setU_Nov(e.target.value)} />
                                        </td>
                                        <td style={{ width: 100, textAlign: "center", border: "1px solid " }}>

                                            <TextField type='number' onChange={(e) => setU_Dez(e.target.value)} />
                                        </td>


                                    </tr>


                                </tbody>
                            </table>
                            <div>
                                <Button style={{ backgroundColor: '#6699ff' }} variant="contained" onClick={() => AdicionaFreteFerrov()}>Adiciona Frete Ferroviário</Button>
                            </div>

                        </div>
                    </div>
                </div>
            </div> : <></>
    )
}


const useStyles = makeStyles()


function mapStateToProps(state) {
    return {
        token: state.token,
        user: state.user,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            setToken: setToken,
            setUser: setUser,
        },
        dispatch
    );
}


export default connect(mapStateToProps, mapDispatchToProps)(InputsPricing);