
import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";

// core components
import Admin from "layouts/Admin.js";
import PesquisaGeral from 'views/PesquisaGeral/PesquisaGeral.js'
import InfoPedido from 'views/InfoPedido/InfoPedido.js'
import ResetPasword from "views/ResetPassword/ResetPasword";
import "assets/css/material-dashboard-react.css?v=1.9.0";
import SignInSide from "siginIn/SignInSide";
import { createStore } from "redux";
import { Provider } from "react-redux";
import allReducers from "./flux/reducers/index";
import PedidoFinalizado from "views/PedidoFinalizado/PedidoFinalizado";
import ValidadeQrCode from "views/ValidadeQrCode/ValidadeQrCode";
//import { datadogRum } from '@datadog/browser-rum';
const store = createStore(allReducers);

const hist = createBrowserHistory();


// datadogRum.init({
//     applicationId: '08396d1a-1789-4ae8-b9a5-72824b0f0fbb',
//     clientToken: 'pub3c4e996062032cecfbf5c516e49b3c84',
//     site: 'us5.datadoghq.com',
//     service:'sysgenteste',
//     env:'<ENV_NAME>',
//     // Specify a version number to identify the deployed version of your application in Datadog 
//     // version: '1.0.0', 
//     sessionSampleRate:100,
//     premiumSampleRate: 100,
//     trackUserInteractions: true,
//     defaultPrivacyLevel:'mask-user-input'
// });
    
// datadogRum.startSessionReplayRecording();
ReactDOM.render(
  <Provider store={store}>
    <Router history={hist}>
      <Switch>
        <Route path="/" exact component={SignInSide} />
        <Route path="/Pesquisa/:id/:uid/:token" component={PesquisaGeral} />
        <Route path="/ValidadeQrCode/:id" component={ValidadeQrCode} />
        <Route path="/InfoPedido/:id/:token" component={InfoPedido} />
        <Route path="/ResetPasword/:uid/:token" component={ResetPasword} />
        <Route path="/PedidoFinalizado/:numPedido/:token" component={PedidoFinalizado} />
        <Route path="/admin" component={Admin} />
        <Redirect from="/dashboard" to="/admin/dashboard" />
        <Redirect from="/cards" to="https://truly-tolerant-gobbler.ngrok-free.app/aprova" />
        <Redirect from="/Aprovacao" to="/admin/Aprovacao" />
        <Redirect from="/notas" to="/admin/notas" />
        <Redirect from="/NDF" to="/admin/NDF" />
        <Redirect from="/Emprestimos" to="/admin/Emprestimos" />
        <Redirect from="/Vendas" to="/admin/Vendas" />
        <Redirect from='/SMS' to="/admin/SMS" />
        <Redirect from='/Relatorios' to="/admin/Relatorios" />
        <Redirect from='/NecessidadesPBIX' to="/admin/NecessidadesPBIX" />
        <Redirect from='/Agendamento' to="/admin/Agendamento" />
        <Redirect from='/Inicial' to="/admin/Inicial" />
        <Redirect from='/JornalGen' to="/admin/JornalGen" />
        <Redirect from='/WorkflowComex' to="/admin/WorkflowComex" />
        <Redirect from='/ConfigurarUsuario' to="/admin/ConfigurarUsuario" />
        <Redirect from='/ConfigurarConta' to="/admin/ConfigurarConta" />
        <Redirect from='/ConfigurarLogistica' to="/admin/ConfigurarLogistica" />
        <Redirect from='/HierarquiaVendas' to="/admin/HierarquiaVendas" />
        <Redirect from='/AutoFaturamento' to="/admin/AutoFaturamento" />
        <Redirect from='/ConfigurarComercial' to="/admin/ConfigurarComercial" />
        <Redirect from='/MonitorCarregamentos' to="/admin/MonitorCarregamentos" />
        <Redirect from='/InputsPricing' to="/admin/InputsPricing" />
        <Redirect from='/PricingView' to="/admin/PricingView" />
        <Redirect from='/AprovaVendaLogistica' to="/admin/AprovaVendaLogistica" />
        <Redirect from='/DocumentosColaborador' to="/admin/DocumentosColaborador" />
        <Redirect from='/Pesquisas' to="/admin/Pesquisas" />
        <Redirect from='/PlacasChecklist' to="/admin/PlacasChecklist" />
        <Redirect from='/BalancoGeral' to="/admin/BalancoGeral" />
        <Redirect from='/PreçosDeCompra' to="/admin/PreçosDeCompra" />
        <Redirect from='/ClientesFiadores' to="/admin/ClientesFiadores" />

        
      </Switch>
    </Router>
  </Provider>,
  document.getElementById("root")
);
