import React, { useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { setToken } from "../../flux/actions/tokenIndex";
import { setUser } from "../../flux/actions/userIndex";
import api from "../../services/api";

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Button from '@material-ui/core/Button';
import { TextField } from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(3),
  },
  button: {
    margin: theme.spacing(1, 1, 0, 0),
  },
}));

function PesquisaGeral(props) {
  const classes = useStyles();
  const [question1, setQuestion1] = React.useState('');
  const [question2, setQuestion2] = React.useState('');
  const [question3, setQuestion3] = React.useState('');
  const [question4, setQuestion4] = React.useState('');
  const [question5, setQuestion5] = React.useState('');
  const [valid, setValid] = useState(true)
  const [nome, setNome] = useState('')
  const [produtos, setProdutos] = useState([])

  useEffect(() => {
    async function fetchMyAPI() {
      const response = await api.get("/validatePesquisa", {
        params: {
          docEntry: props.match.params.id,
          token: props.match.params.token,
          codUser: props.match.params.uid
        }
      });

      if ((response.data === 'NOK') || (response.data === 'NOK1') || (response.data === 'NOK2')) {
        alert('Erro ao encontrar pesquisa')
        setValid(false)
      }
      else if ((response.data === 'NOK3')) {
        alert('Pesquisa já realizada!')
        setValid(false)
      }
      else {
        console.log(response.data)
        setValid(true)
        setNome(response.data.nome)
        setProdutos(response.data.produtos)
      }
    }
    fetchMyAPI();

  }, [])

  async function sendPesquisa() {
    const response = await api.get("/terminaPesquisa", {
      params: {
        question1, question2, question3, question4, question5, docEntry: props.match.params.id,
        token: props.match.params.token
      }
    });
    if (response.data === 'OK') {
      alert('Pesquisa respondida com sucesso!')
      window.location.reload()
    }
  }
  const handleChange = (event) => {
    setQuestion1(event.target.value);
  };
  const handleChange2 = (event) => {
    setQuestion2(event.target.value);
  };
  const handleChange3 = (event) => {
    setQuestion3(event.target.value);
  };
  const handleChange4 = (event) => {
    setQuestion4(event.target.value);
  };
  const handleChange5 = (event) => {
    setQuestion5(event);
  };


  return (
    <div style={{ background: 'white' }}>
      {valid === true ?
        <div style={{ overflowWrap: 'break-word', padding: 10, margin: 'auto', width: '90vw' }} >
          <div>
            <GridContainer >
              <GridItem style={{ width: '90vw' }}>
                <p> Nome: {nome}</p>
              </GridItem>
            </GridContainer>

            <GridContainer>
              <GridItem style={{ width: '90vw' }}>
                <p> Produtos: {produtos}</p>
              </GridItem>
            </GridContainer>


            <FormLabel style={{ width: '80vw' }} component="legend">1. Como você avalia o atendimento comercial da Gen Fertilizantes?</FormLabel>
            <RadioGroup aria-label="questão1" name="questão1" value={question1} onChange={handleChange}>
              <FormControlLabel value="Excepcional" control={<Radio />} label="Excepcional" />
              <FormControlLabel value="Muito bom" control={<Radio />} label="Muito bom" />
              <FormControlLabel value="Bom" control={<Radio />} label="Bom" />
              <FormControlLabel value="Ruim" control={<Radio />} label="Ruim" />
              <FormControlLabel value="Péssimo" control={<Radio />} label="Péssimo" />
            </RadioGroup>


            <FormLabel style={{ marginTop: 50, width: '90vw' }} component="legend">2.  Como você avalia nossos serviços: Programação de Entrega, Logística em Geral?
            </FormLabel>
            <RadioGroup aria-label="questão2" name="questão2" value={question2} onChange={handleChange2}>
              <FormControlLabel value="Excepcional" control={<Radio />} label="Excepcional" />
              <FormControlLabel value="Muito bom" control={<Radio />} label="Muito bom" />
              <FormControlLabel value="Bom" control={<Radio />} label="Bom" />
              <FormControlLabel value="Ruim" control={<Radio />} label="Ruim" />
              <FormControlLabel value="Péssimo" control={<Radio />} label="Péssimo" />
            </RadioGroup>


            <FormLabel style={{ marginTop: 50, width: '90vw' }} component="legend">3. Como você avalia a qualidade de nossos produtos e embalagens?
            </FormLabel>
            <RadioGroup aria-label="questão3" name="questão3" value={question3} onChange={handleChange3}>
              <FormControlLabel value="Excepcional" control={<Radio />} label="Excepcional" />
              <FormControlLabel value="Muito bom" control={<Radio />} label="Muito bom" />
              <FormControlLabel value="Bom" control={<Radio />} label="Bom" />
              <FormControlLabel value="Ruim" control={<Radio />} label="Ruim" />
              <FormControlLabel value="Péssimo" control={<Radio />} label="Péssimo" />
            </RadioGroup>

            {/* <FormLabel style={{ marginTop: 50 }} component="legend">4. Qual o nível de satisfação com a Gen Fertilizantes
              </FormLabel>
              <RadioGroup aria-label="questão4" name="questão4" value={question4} onChange={handleChange4}>
                <FormControlLabel value="Excepcional" control={<Radio />} label="Excepcional" />
                <FormControlLabel value="Muito bom" control={<Radio />} label="Muito bom" />
                <FormControlLabel value="Bom" control={<Radio />} label="Bom" />
                <FormControlLabel value="Ruim" control={<Radio />} label="Ruim" />
                <FormControlLabel value="Péssimo" control={<Radio />} label="Péssimo" />
              </RadioGroup> */}

            <FormLabel style={{ marginTop: 50, width: '90vw' }} component="legend">4. Em uma escala de 1 a 10, o quanto você recomendaria a Gen Fertilizantes para outros agricultores?
            </FormLabel>
            <RadioGroup style={{ width: '90vw' }} aria-label="questão4" name="questão4" value={question4} onChange={handleChange4}>
              <FormControlLabel value="1" control={<Radio />} label="1" />
              <FormControlLabel value="2" control={<Radio />} label="2" />
              <FormControlLabel value="3" control={<Radio />} label="3" />
              <FormControlLabel value="4" control={<Radio />} label="4" />
              <FormControlLabel value="5" control={<Radio />} label="5" />
              <FormControlLabel value="6" control={<Radio />} label="6" />
              <FormControlLabel value="7" control={<Radio />} label="7" />
              <FormControlLabel value="8" control={<Radio />} label="8" />
              <FormControlLabel value="9" control={<Radio />} label="9" />
              <FormControlLabel value="10" control={<Radio />} label="10" />
            </RadioGroup>

            <FormLabel style={{ marginTop: 50, width: '90vw' }} component="legend">5. Que sugestão você daria para a GeN (Quanto a produto, serviço, inovação, etc)?
            </FormLabel>
            <TextField variant='outlined' style={{ width: '90%', marginBottom: 50 }} multiline onChange={(e) => handleChange5(e.target.value)} />

          </div>

          <div style={{ marginBottom: 50 }}>
            <FormControl style={{ width: '90vw', alignItems: 'center', alignContent: 'center' }}>
              <Button disabled={question1 === '' && question2 === '' && question3 === '' && question4 === ''} style={{ backgroundColor: '#6699ff' }} variant="contained" onClick={() => sendPesquisa()}>Enviar Pesquisa</Button>
            </FormControl>
          </div>

        </div> :
        <div style={{ textAlign: 'center' }}> <h4>Pesquisa já respondida!</h4> </div>}
    </div>
  );
}


function mapStateToProps(state) {
  return {
    token: state.token,
    user: state.user,
  };
}


function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setToken: setToken,
      setUser: setUser,
    },
    dispatch
  );
}


export default connect(mapStateToProps, mapDispatchToProps)(PesquisaGeral);
