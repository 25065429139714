import React, { useEffect, useState } from "react";
import './Dashboard.css'
import api2 from "../../services/api2";

import { PowerBIEmbed } from "powerbi-client-react";
import { models } from 'powerbi-client'
import { checarPermissao, validateTokenUser, getUser, getToken } from '../../utils/utils.js'

function PowerBINecessidades(props) {

  const [token, setToken] = useState('')
  const [permitido, setPermitido] = useState(false)


  useEffect(() => {
    async function fetchMyAPI() {
      await validateTokenUser(props)
      setPermitido(await checarPermissao('powerBiNecessidades', props))

      const response_token = await api2.get("/returnToken", { params: { userId: getUser(), token: getToken() } });
      setToken(response_token.data)
    }
    fetchMyAPI();

  }, [])



  return (
    permitido === true ?
      <div>
        <div style={{display:'flex',flexDirection:'column'}}>
          <a href="https://app.powerbi.com/view?r=eyJrIjoiZjcyOTc3MGMtOWM3Ni00YTRkLWIxODItOTcxMDMwM2VjNGY5IiwidCI6Ijc1M2UyM2VlLTE1YTYtNDQ0MS05ZmQxLWNkZmFmMDQ2YjhiYSJ9&pageName=ReportSection">
            Relatório Necessidades Embalagens
          </a>
          <a href="https://app.powerbi.com/view?r=eyJrIjoiYjRmNmNkNDAtZDZhNi00MzI5LWJhNzgtOTUxOGEyMTY0YWE4IiwidCI6Ijc1M2UyM2VlLTE1YTYtNDQ0MS05ZmQxLWNkZmFmMDQ2YjhiYSJ9">
            Relatório Necessidades
          </a>
  
        </div>
        <div style={{ width: '2000', height: '2000', overflowX: 'auto' }}>
            <iframe 
            src="https://app.powerbi.com/view?r=eyJrIjoiYjRmNmNkNDAtZDZhNi00MzI5LWJhNzgtOTUxOGEyMTY0YWE4IiwidCI6Ijc1M2UyM2VlLTE1YTYtNDQ0MS05ZmQxLWNkZmFmMDQ2YjhiYSJ9" 
            title="External Page" 
            width="100%" 
            height="600px" 
            style={{ border: 'none' }}
            allowFullScreen
          />
        </div>

      </div> : <></>
  );
}

export default PowerBINecessidades;
