import React from "react"
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { TextField } from "@material-ui/core";
import Button from '@material-ui/core/Button';
import { useState, useEffect } from "react";
import api from "services/api";
import { getToken, getUser } from "utils/utils";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Loader from "react-loader-spinner";
import { Checkbox } from '@material-ui/core';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Tooltip from "@material-ui/core/Tooltip";

export default function BalancoGeral() {
    const classes = useStyles();

    // inserir produto
    const [produto, setProduto] = useState('')
    const [volume, setVolume] = useState(0)
    const [cliente, setCliente] = useState('')
    const [moeda, setMoeda] = useState('')
    const [valorVenda, setValorVenda] = useState(0)

    // options dos autocompletes
    const [produtosOptions, setProdutosOptions] = useState([])
    const [paOptions, setPaOptions] = useState([])
    const [mpOptions, setMpOptions] = useState([])
    const [clients, setClients] = useState([])

    // tabelas
    const [materiasDiarias, setMateriasDiarias] = useState('')
    const [tabelaVendas, setTabelaVendas] = useState('')
    const [tabelaResultados, setTabelaResultados] = useState('')
    const [tabelaResumo, setTabelaResumo] = useState('')

    // pesquisa por dia
    const [data, setData] = useState(new Date().toISOString().slice(0, 10)) // o default é a data atual
    const [auxData, setAuxData] = useState(new Date().toISOString().slice(0, 10))

    // misc
    const [salvando, setSalvando] = useState(false)
    const [pesquisando, setPesquisando] = useState(false)
    const [totalMateria, setTotalMateria] = useState(0)
    const dataAtual = new Date().toISOString().slice(0, 10)

    // formulação de produtos
    const [isFormulacao, setIsFormulacao] = useState(0)
    const [formulacao, setFormulacao] = useState([{ nomeMateria: '', qtd: 0 }])
    const [openFormulacao, setOpenFormulacao] = useState(false)

    // edit
    const [idEdit, setIdEdit] = useState(0)
    const [isEditing, setIsEditing] = useState(0)
    const [editObj, setEditObj] = useState({})
    const [savingEdit, setSavingEdit] = useState(false)



    useEffect(() => {
        fetchMyAPI()
    }, [])


    const fetchMyAPI = async () => {
        setPesquisando(true)
        const responseItens = await api.get('/Itens', { params: { userId: getUser(), token: getToken() } })

        let aux = []
        responseItens.data.map(obj => aux.push(obj.ItemName))
        setProdutosOptions(aux)

        let pa = responseItens.data.filter(obj => obj['itemCode'].includes('PA'))
        aux = []
        pa.map(obj => aux.push(obj.ItemName))
        setPaOptions(aux)

        let mp = responseItens.data.filter(obj => obj['itemCode'].includes('MP'))
        aux = []
        mp.map(obj => aux.push(obj.ItemName))
        setMpOptions(aux)

        const responseClientes = await api.get('/Clientes', { params: { userId: getUser(), token: getToken(), token2: '63cbse4yu21fw24f2v2'  } })
        let aux_clients = []
        responseClientes.data.map(obj => aux_clients.push(obj.CardName))
        setClients(aux_clients)

        await fetchTabelas()
        setPesquisando(false)
    }

    const fetchTabelas = async () => {
        const responseDiarios = await api.get('/get_custo_de_vendas', { params: { data, userId: getUser(), token: getToken() } })
        setMateriasDiarias(responseDiarios.data['custos_materias'])
        setTabelaResultados(responseDiarios.data['resultado_do_dia'])

        const responseTabelaClientes = await api.get('/get_tabela_clientes', { params: { data, userId: getUser(), token: getToken() } })
        setTabelaVendas(responseTabelaClientes.data['vendas'])
        setTabelaResumo(responseTabelaClientes.data['resumo'])
    }

    const salvarVenda = async () => {
        if (produto === '' || volume === '' || cliente === '' || moeda === '' || valorVenda === '') {
            alert('Preencha todos os campos antes de inserir a venda')
            return
        }
        if (isFormulacao) {
            if (1000 - totalMateria < 0) {
                alert('Digite uma quantidade válida na formulação do produto')
                return
            }
            if (totalMateria < 1000) {
                alert(`Ainda faltam ${1000 - totalMateria} na formulação do produto`)
                return
            }
        }

        setSalvando(true)
        const response = await api.get('/inserir_venda', { params: { produto, isFormulacao, formulacao: JSON.stringify(formulacao), volume, cliente, moeda, valorVenda, userId: getUser(), token: getToken() } })
        if (response.data === 'NOK') {
            alert('Erro ao salvar venda...')
        } else {
            await fetchTabelas()
        }
        setSalvando(false)
    }

    const handleEdit = (obj) => {
        setIsEditing(1)
        setIdEdit(obj.id_venda)
        setEditObj({
            'idVenda': obj.id_venda,
            'cliente': obj.cliente,
            'qtdVenda': obj.qtd_venda,
            'produto': obj.produto,
            'moeda': obj.moeda,
            'valorVenda': obj.valor_venda
        })
    }

    const handleUpdateEdit = (value, key) => {
        let aux = { ...editObj }

        aux[key] = value
        setEditObj(aux)
    }

    const handleCancelEdit = () => {
        setEditObj({})
        setIsEditing(0)
        setIdEdit(0)
    }

    const handleSaveEdit = async () => {
        setSavingEdit(true)
        await api.get('/edit_venda_balanco', { params: { idVenda: idEdit, editObj, userId: getUser(), token: getToken() } })

        const responseTabelaClientes = await api.get('/get_tabela_clientes', { params: { data, userId: getUser(), token: getToken() } })
        setTabelaVendas(responseTabelaClientes.data['vendas'])
        setTabelaResumo(responseTabelaClientes.data['resumo'])

        const responseDiarios = await api.get('/get_custo_de_vendas', { params: { data, userId: getUser(), token: getToken() } })
        setMateriasDiarias(responseDiarios.data['custos_materias'])
        setTabelaResultados(responseDiarios.data['resultado_do_dia'])

        setSavingEdit(false)
        handleCancelEdit()
    }

    const searchByDate = async (dataLocal) => {
        if (data === '') { return }
        setPesquisando(true)
        setTabelaVendas('')
        setMateriasDiarias('')
        setTabelaResultados('')
        setTabelaResumo('')
        setAuxData(dataLocal)

        const responseTabelaClientes = await api.get('/get_tabela_clientes', { params: { data: dataLocal, userId: getUser(), token: getToken() } })
        setTabelaVendas(responseTabelaClientes.data['vendas'])
        setTabelaResumo(responseTabelaClientes.data['resumo'])

        const responseDiarios = await api.get('/get_custo_de_vendas', { params: { data: dataLocal, userId: getUser(), token: getToken() } })

        setMateriasDiarias(responseDiarios.data['custos_materias'])
        setTabelaResultados(responseDiarios.data['resultado_do_dia'])

        setPesquisando(false)
    }

    const resetDate = () => {
        const current_date = new Date().toJSON().slice(0, 10)
        searchByDate(current_date)
        setData(current_date)
    }

    const addMateria = () => {
        let aux = [...formulacao]
        aux.push({ nomeMateria: '', qtd: 0 })
        setFormulacao(aux)
    }

    const removeMateria = () => {
        let aux = [...formulacao]
        if (aux.length < 2) { return }
        aux.pop()
        setFormulacao(aux)
    }

    const deleteVenda = async () => {
        setSavingEdit(true)
        await api.get('/delete_venda_balanco', { params: { idVenda: editObj.idVenda, userId: getUser(), token: getToken() } })
        fetchTabelas()
        setSavingEdit(false)
        setIsEditing(0)
    }

    const updateFormula = (value, index, key) => {
        let aux = [...formulacao]
        aux[index][key] = value
        setFormulacao(aux)
        setTotalMateria(formulacao.reduce((acumulador, obj) => acumulador + parseFloat(obj.qtd), 0))
    }

    const renderTabelaMaterias = () => {
        return (

            <TableContainer component={Paper}>
                <Table size="small" >
                    <TableHead>
                        <TableRow>
                            <TableCell >Matéria Prima</TableCell>
                            <TableCell >Venda</TableCell>
                            <TableCell >Preço U$</TableCell>
                            <TableCell >Total U$</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {materiasDiarias.map((obj, index) =>
                            <TableRow key={index}>
                                <TableCell >{obj.materia}</TableCell>
                                <TableCell >{obj.qtd}</TableCell>
                                <TableCell >{obj.preco_USD}</TableCell>
                                <TableCell >{obj.total_USD}</TableCell>
                            </TableRow>)}

                        <TableRow >
                            <TableCell component="th" scope="row" align="right" className={classes.tabCellBold}>Total</TableCell>
                            <TableCell className={classes.tabCellBold}>
                                {tabelaResultados.qtd_total_materias}
                            </TableCell>
                            <TableCell className={classes.tabCellBold}>

                            </TableCell>
                            <TableCell className={classes.tabCellBold}>
                                {tabelaResultados.total_custos}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }

    const renderTabelaVendas = () => {
        return (
            <TableContainer component={Paper}>
                <Table size="small" className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            {dataAtual === auxData ?
                                <TableCell>Edição</TableCell>
                                : null}

                            <TableCell>Cliente</TableCell>
                            <TableCell>Volume</TableCell>
                            <TableCell>Produto</TableCell>
                            <TableCell>Moeda</TableCell>
                            <TableCell>Valor</TableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tabelaVendas.map((row, index) => (
                            <TableRow key={index}>
                                {dataAtual === auxData ?
                                    <TableCell>
                                        <Button size="small" onClick={() => handleEdit(row)}>Editar</Button>
                                    </TableCell>
                                    : null}

                                <TableCell className={classes.tabCellBold}>{row.cliente}</TableCell>
                                <TableCell className={classes.tabCellBold}>{row.qtd_venda}</TableCell>
                                <TableCell className={classes.tabCellBold}>{row.produto}</TableCell>
                                <TableCell className={classes.tabCellBold}>{row.moeda}</TableCell>
                                <TableCell className={classes.tabCellBold}>{row.valor_venda}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

        )
    }

    const renderTabelaResumo = () => {
        return (
            <TableContainer component={Paper}>
                <Table size="small" className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Cliente</TableCell>
                            <TableCell>Volume</TableCell>
                            <TableCell>Produto</TableCell>
                            <TableCell>Moeda</TableCell>
                            <TableCell>Valor</TableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tabelaResumo.map((row, index) => (
                            <TableRow key={index} style={{ background: row.moeda === 'R$' ? '#F8CBAD' : '#0070C0' }}>
                                <TableCell className={classes.tabCellBold}>{row.cliente}</TableCell>
                                <TableCell className={classes.tabCellBold}>{row.qtd_venda}</TableCell>
                                <TableCell className={classes.tabCellBold}>{row.produto}</TableCell>
                                <TableCell className={classes.tabCellBold}>{row.moeda}</TableCell>
                                <TableCell className={classes.tabCellBold}>{row.valor_venda}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

        )
    }

    const renderTabelaResultados = () => {
        return (

            <TableContainer component={Paper}>
                <Table className={classes.table} size="small">
                    <TableBody>
                        <TableRow style={{ background: '#0070C0' }}>
                            <TableCell variant="head">Vendas</TableCell>
                            <TableCell>{tabelaResultados.total_venda}</TableCell>
                        </TableRow>
                        <TableRow style={{ background: '#F8CBAD' }}>
                            <TableCell variant="head">Custos</TableCell>
                            <TableCell>{tabelaResultados.total_custos}</TableCell>
                        </TableRow>
                        <TableRow style={{ background: tabelaResultados.diferenca < 0 ? '#FF0000' : '#0070C0' }}>
                            <TableCell variant="head">Resultado</TableCell>
                            <TableCell>{tabelaResultados.diferenca}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>

        )
    }

    const handleIsFormula = (value) => {
        setIsFormulacao(value)

        if (value) { setOpenFormulacao(true) }

        setProduto('')
    }

    return (
        <>
            <Grid container spacing={3} component={Paper} alignItems="center"  >
                <Dialog open={openFormulacao}>
                    <DialogTitle>Formulação do produto</DialogTitle>
                    <DialogContent>
                        <Grid container style={{ padding: 10, flexDirection: 'column' }} component={Paper} >
                            <Grid item xs={12} style={{ display: 'flex', flexDirection: 'row' }}>
                                <Autocomplete
                                    value={produto}
                                    size="small"
                                    style={{ width: '100%' }}
                                    options={paOptions}
                                    onChange={(event, value) => value !== null ? setProduto(value) : setProduto('')}
                                    renderInput={(params) => (<TextField variant='outlined' {...params} label='Produto' />)}
                                />
                            </Grid>

                            <Grid item style={{ display: 'flex', flexDirection: 'row' }}>
                                <Grid item style={{ margin: 10, marginLeft: 0 }}>
                                    <Button color="primary" variant="outlined" onClick={() => addMateria()} >Adicionar</Button>
                                </Grid >

                                <Grid item style={{ margin: 10, marginLeft: 0 }}>
                                    <Button color="secondary" onClick={() => removeMateria()} >Remover</Button>
                                </Grid >

                                <Grid item style={{ margin: 10, marginLeft: 0, width: '100%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                    {1000 - totalMateria}
                                </Grid >
                            </Grid>
                            {formulacao.map((obj, index) =>
                                <Grid key={index} item style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
                                    <Autocomplete
                                        defaultValue={formulacao[index]['nomeMateria']}
                                        size="small"
                                        style={{ width: 300 }}
                                        options={mpOptions}
                                        onChange={(event, value) => value !== null ? updateFormula(value, index, 'nomeMateria') : updateFormula('', index, 'nomeMateria')}
                                        renderInput={(params) => (<TextField variant='outlined' {...params} label='Matéria' />)}
                                    />

                                    <TextField
                                        defaultValue={formulacao[index]['qtd']}
                                        size="small" type='number' label='Quantidade' variant="outlined" onChange={(e) => updateFormula(e.target.value !== '' && e.target.value > 0 ? e.target.value : 0, index, 'qtd')} />
                                </Grid>)}
                        </Grid>

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpenFormulacao(false)}>
                            Salvar
                        </Button>
                    </DialogActions>
                </Dialog>





                <Grid container style={{ border: '1px solid lightgray', margin: 10 }} alignItems='center' justifyContent="space-evenly">
                    <Tooltip
                        title="Ativar formulação do produto"
                        placement="top"
                    >
                        <Checkbox
                            name="checkJan"
                            color="primary"
                            onChange={(e, v) => handleIsFormula(v ? 1 : 0)}
                        />
                    </Tooltip>

                    {!isFormulacao ?
                        <Grid item >
                            <Autocomplete
                                size="small"
                                style={{ width: 300 }}
                                options={produtosOptions}
                                onChange={(event, value) => value !== null ? setProduto(value) : setProduto('')}
                                renderInput={(params) => (<TextField variant='outlined' {...params} label='Produto' />)}
                            />
                        </Grid>
                        : <Button color="primary" variant="contained" onClick={() => setOpenFormulacao(true)}>
                            Formular Produto
                        </Button>}


                    <Grid item >
                        <TextField size="small" label='Volume' value={volume} type='number' variant="outlined" onChange={(e) => setVolume(e.target.value)} />
                    </Grid>

                    <Grid item >
                        <Autocomplete
                            size="small"
                            style={{ width: 300 }}
                            options={clients}
                            onChange={(event, value) => value !== null ? setCliente(value) : setCliente('')}
                            renderInput={(params) => (<TextField variant='outlined' {...params} label='Cliente' />)}
                        />
                    </Grid>

                    <Grid item >
                        <Autocomplete
                            size="small"
                            options={['R$', 'U$']}
                            onChange={(event, value) => value !== null ? setMoeda(value) : setMoeda('')}
                            renderInput={(params) => (<TextField variant='outlined' {...params} label='Moeda' />)}
                        />
                    </Grid>

                    <Grid item >
                        <TextField size="small" type='number' value={valorVenda} label='Valor de Venda' variant="outlined" onChange={(e) => setValorVenda(e.target.value)} />
                    </Grid>

                    <Grid item style={{ marginLeft: 10 }} >
                        {salvando ?
                            <Loader
                                type="Circles"
                                color="#00BFFF"
                                height={20}
                                width={20}
                                timeout={2000000} />
                            : <Button size="small" variant="contained" type="submit" color="primary" onClick={() => salvarVenda()} disabled={salvando}>Salvar</Button>
                        }
                    </Grid>

                    <Grid item style={{ border: '1px solid lightgray', margin: 10, padding: 5, alignItems: 'center' }} >
                        <TextField size="small" type="date" value={data} onChange={(e) => setData(e.target.value)} />
                        <Button size="small" onClick={() => searchByDate(data)} disabled={pesquisando}>Pesquisar</Button>
                        <Button size="small" onClick={() => resetDate()} disabled={pesquisando}>Resetar</Button>
                    </Grid>
                </Grid>
            </Grid>

            <Grid container spacing={3} component={Paper} >
                <Grid item xs={12}>
                    {materiasDiarias !== '' ?
                        renderTabelaMaterias()
                        : <Loader
                            type="Circles"
                            color="#00BFFF"
                            height={20}
                            width={20}
                            timeout={2000000}
                        />}
                </Grid>

                <Grid item xs={12}>
                    {tabelaVendas !== '' ?
                        renderTabelaVendas()
                        : <Loader
                            type="Circles"
                            color="#00BFFF"
                            height={20}
                            width={20}
                            timeout={2000000}
                        />}
                </Grid>

                <Grid item xs={12}>
                    {tabelaResumo !== '' ?
                        renderTabelaResumo()
                        : <Loader
                            type="Circles"
                            color="#00BFFF"
                            height={20}
                            width={20}
                            timeout={2000000}
                        />}
                </Grid>

                <Grid item xs={8} md={3} lg={3} xl={2}>
                    {tabelaResultados !== '' ?
                        renderTabelaResultados()
                        : <Loader
                            type="Circles"
                            color="#00BFFF"
                            height={20}
                            width={20}
                            timeout={2000000}
                        />}
                </Grid>
            </Grid>


            <Dialog open={Boolean(isEditing)}>
                <DialogTitle>Edição da Venda</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            {editObj.cliente}
                        </Grid>

                        <Grid item xs={12}>
                            <div>
                                Volume
                            </div>
                            <TextField size="small" defaultValue={editObj.qtdVenda} type='number' onChange={(e) => handleUpdateEdit(e.target.value, 'qtd_venda')} />
                        </Grid>

                        <Grid item xs={12}>
                            Produto
                            <Autocomplete
                                size="small"
                                style={{ minWidth: 300 }}
                                defaultValue={editObj.produto}
                                options={produtosOptions}
                                onChange={(event, value) => value !== null ? handleUpdateEdit(value, 'produto') : null}
                                renderInput={(params) => (<TextField {...params} />)}
                                disabled
                            />
                        </Grid>

                        <Grid item xs={12}>
                            Moeda
                            <Autocomplete
                                size="small"
                                defaultValue={editObj.moeda}
                                options={['R$', 'U$']}
                                onChange={(event, value) => value !== null ? handleUpdateEdit(value, 'moeda') : null}
                                renderInput={(params) => (<TextField  {...params} />)}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <div>
                                Valor Venda
                            </div>
                            <TextField defaultValue={editObj.valorVenda} size="small" type='number' onChange={(e) => handleUpdateEdit(e.target.value, 'valorVenda')} />
                        </Grid>

                        <Grid item xs={12}>
                            <Button disabled={savingEdit} size="small" variant='contained' color="secondary" onClick={() => deleteVenda()}>Excluir</Button>
                        </Grid>

                        {savingEdit ?
                            <Grid item>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <Loader
                                        type="Circles"
                                        color="#00BFFF"
                                        height={20}
                                        width={20}
                                        timeout={2000000} />
                                    <span style={{ marginLeft: 10 }}>
                                        Salvando...
                                    </span>
                                </div>
                            </Grid>
                            : null}

                    </Grid>
                </DialogContent>


                <DialogActions>
                    <Button onClick={() => setIsEditing(0)}>
                        Fechar
                    </Button>
                    <Button disabled={savingEdit} color="primary" variant="contained" onClick={() => handleSaveEdit()}>
                        Salvar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

const useStyles = makeStyles({
    tabCellBold: {
        fontWeight: 'bold'
    },
});