import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { setToken } from "../../flux/actions/tokenIndex";
import { setUser } from "../../flux/actions/userIndex";
import api from "../../services/api";
import { checarPermissao, validateTokenUser, getUser, getToken } from '../../utils/utils.js'
import { makeStyles } from "@material-ui/core/styles";

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Loader from "react-loader-spinner";

function DocumentosColaborador(props) {
    const [permitido, setPermitido] = useState(false)
    const [documentos, setDocumentos] = useState('')

    const [arquivos, setArquivos] = useState([])
    const [gerando, setGerando] = useState(false)
    const [sendoGerado, setSendoGerado] = useState('')

    const [nomeId, setNomeId] = useState('')
    const classes = useStyles()

    useEffect(() => {
        async function fetchMyAPI() {
            await validateTokenUser(props)
            setPermitido(await checarPermissao('documentosColaborador', props))
            const docs = await api.get('/getDocInfo', { params: { userId: getUser(), token: getToken() } })
            setDocumentos(docs.data)

            // 72 é a id da thais
            let id = JSON.parse(localStorage.getItem('permissoes'))[0].idUsuario
            if (id === 58 || id === 59 || id === 72 || id === 113 || id === 254) { // thais, maria eduarda, unity, marcio, fabio
                const lista = await api.get('/nomeIdUsuarios', { params: { userId: getUser(), token: getToken() } })
                setNomeId(lista.data)
            }
        }
        fetchMyAPI();
    }, [])


    async function gerarArquivo(idUsuario, nomeArquivo, ano, mes) {
        setGerando(true)
        setSendoGerado(`${idUsuario}-${nomeArquivo}-${ano}-${mes}`)
        const doc = await api.get('/getDocColaborador', { params: { idUsuario, nomeArquivo, ano, mes, userId: getUser(), token: getToken() } })

        if (doc.data === 'NOK') {
            alert('Erro ao gerar documento...')
            return
        }

        let aux = arquivos
        aux[`${idUsuario}-${nomeArquivo}-${ano}-${mes}`] = doc.data
        setArquivos(aux)
        setGerando(false)
    }

    return (
        permitido === true ?
            <>
                {nomeId !== '' ?
                    <Accordion style={{ marginBottom: 20, width: 520, boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px' }} >
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <p style={{ fontSize: 24 }}>Lista de Ids</p>
                        </AccordionSummary>

                        <div style={{ overflow: 'auto', height: 300, padding: 10, width: 500, boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px' }}>
                            {nomeId.map((item) => <p style={{ border: '1px solid rgba(0, 0, 0, .2)', margin: 4, paddingLeft: 4 }}>{item.nome} - {item.id}</p>)}
                        </div>

                    </Accordion> : <></>
                }

                <div style={{ background: 'white', paddingLeft: 10, border: '1px solid rgba(0, 0, 0, .2)', marginBottom: 10 }}>
                    <p style={{ fontSize: 24 }}>Documentos RH</p>
                </div>
                {documentos === '' ?
                    <div style={{ margin: 10 }}>
                        <Loader
                            type="Circles"
                            color="#00BFFF"
                            height={20}
                            width={20}
                            timeout={2000000}
                        /> Carregando...</div> : <></>}
                {documentos === 'NO-DOCS' ?
                    <div style={{ margin: 20 }}>
                        <h4>Nenhum documento inserido</h4>
                    </div> :
                    <div>
                        {
                            Object.keys(documentos).reverse().map((ano, index) =>
                                <Accordion key={index} className={classes.parentAccordion}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography>
                                            {ano}
                                        </Typography>
                                    </AccordionSummary>

                                    <AccordionDetails className={classes.parentDetails}>
                                        {Object.keys(documentos[ano]).map((mes, index2) =>

                                            <Accordion key={index2} className={classes.childAccordion}>
                                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                                    <Typography>
                                                        {mes.toUpperCase()}
                                                    </Typography>
                                                </AccordionSummary>

                                                <AccordionDetails >

                                                    <Typography component={'span'} >
                                                        {documentos[ano][mes].map((arquivo, index3) =>
                                                            <div key={index3} >

                                                                <div className={classes.divArquivo}>
                                                                    {arquivo.nomeArquivo}:

                                                                    <div style={{ marginLeft: 10 }}>
                                                                        {arquivos[`${arquivo.idUsuario}-${arquivo.nomeArquivo}-${ano}-${mes}`] === undefined && sendoGerado !== `${arquivo.idUsuario}-${arquivo.nomeArquivo}-${ano}-${mes}` ?
                                                                            <>
                                                                                <div style={{ marginRight: 5 }}>
                                                                                    <button
                                                                                        disabled={gerando && `${arquivo.idUsuario}-${arquivo.nomeArquivo}-${ano}-${mes}` !== sendoGerado}
                                                                                        onClick={() => gerarArquivo(arquivo.idUsuario, arquivo.nomeArquivo, ano, mes)}>Gerar</button>
                                                                                </div>
                                                                            </>
                                                                            : gerando && arquivos[`${arquivo.idUsuario}-${arquivo.nomeArquivo}-${ano}-${mes}`] === undefined && sendoGerado === `${arquivo.idUsuario}-${arquivo.nomeArquivo}-${ano}-${mes}` ?
                                                                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                                                    <Loader
                                                                                        type="Circles"
                                                                                        color="#00BFFF"
                                                                                        height={20}
                                                                                        width={20}
                                                                                        timeout={2000000}
                                                                                    /> &nbsp; Gerando...
                                                                                </div> :
                                                                                <>
                                                                                    <a href={arquivos[`${arquivo.idUsuario}-${arquivo.nomeArquivo}-${ano}-${mes}`]} download={arquivo.nomeArquivo} >Baixar</a>
                                                                                </>}
                                                                    </div>
                                                                </div>
                                                            </div>)}
                                                    </Typography>
                                                </AccordionDetails>
                                            </Accordion>)}
                                    </AccordionDetails>
                                </Accordion>)
                        }
                    </div>}
            </> : <></>
    )
}


const useStyles = makeStyles({
    divPrincipal: {
        background: 'white',
        padding: 10
    },

    parentAccordion: {
        margin: 10,
        boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px'
    },

    parentDetails: {
        display: 'flex',
        flexDirection: 'column'
    },

    childAccordion: {
        margin: 5,
        border: '1px solid rgba(0, 0, 0, .2)'
    },

    divArquivo: {
        border: '1px solid rgba(0, 0, 0, .2)',
        margin: 5,
        padding: 5,
        display: 'flex',
        flexDirection: 'row'
    }
})


function mapStateToProps(state) {
    return {
        token: state.token,
        user: state.user,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            setToken: setToken,
            setUser: setUser,
        },
        dispatch
    );
}


export default connect(mapStateToProps, mapDispatchToProps)(DocumentosColaborador);