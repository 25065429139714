import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles({
    topPaper: {
        padding: 20,
        marginBottom: 20
    },

    histPaper: {
        marginLeft: 20,
        padding: 20,
        marginBottom: 20,
        overflow: 'auto',
        width: '100%'
    },

    bigPaper: {
        padding: 20
    },

    reg: {
        margin: 20,
        padding: 10
    },

    table: {
        background: 'white'
    },

    backKeyframeRed: {
        animation: '$red 10s',
        animationFillMode: 'forwards',
        animationIterationCount: 'infinite'
    },

    '@keyframes red': {
        from: { background: 'red' },
        to: { background: 'white' },
    },

    backKeyframeGreen: {
        animation: '$green 10s',
        animationFillMode: 'forwards',
        animationIterationCount: 'infinite'
    },

    '@keyframes green': {
        from: { background: 'lightgreen' },
        to: { background: 'white' },
    }
})

export default useStyles