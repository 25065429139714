import React, { useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";

import Card from "components/Card/Card.js";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { setToken } from "../../flux/actions/tokenIndex";
import { setUser } from "../../flux/actions/userIndex";
import { TextField } from "@material-ui/core";

import api2 from "../../services/api2";
import { checarPermissao, validateTokenUser, getUser, getToken } from '../../utils/utils.js'
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";


const useStyles = makeStyles(styles);

function Emprestimos(props) {
    const [valorUs, setValorUs] = useState(0);
    const [vcto, setVcto] = useState("");
    const [fornecedor, setFornecedor] = useState("")
    const [emprestimos, setEmprestimos] = useState({})
    const [totals, setTotals] = useState('')
    const [changeData, setChangeData] = useState('')
    const [changeValue, setChangeValue] = useState(0)
    const classes = useStyles();
    const [permitido, setPermitido] = useState(false)

    useEffect(() => {
        async function fetchMyapi2() {
            await validateTokenUser(props)
            setPermitido(await checarPermissao('emprestimos', props))
            const response = await api2.get("/retornaDadosEmprestimos", { params: { userId: getUser(), token: getToken() } });
            setEmprestimos(response.data);
            const response_totals = await api2.get('/retornaDadosEmprestimosSum', { params: { userId: getUser(), token: getToken() } });
            setTotals(response_totals.data)
        }
        fetchMyapi2();
    }, []);

    async function fetchapi2() {
        const response_materias = await api2.get("/salvaDadosEmprestimos", {
            params: {
                fornecedor,
                data: vcto,
                valorUs,
                userId: getUser(),
                token: getToken()
            },
        });
        if (response_materias.data === 'NOK') {
            alert('Erro ao adicionar emprestimo!')
        } else {
            alert('emprestimo adicionada com sucesso!')
            window.location.reload();
        }
    }
    async function deleteDados(rowid) {
        const response_materias = await api2.get("/deleteDadosEmprestimos", {
            params: {
                rowid,
                userId: getUser(),
                token: getToken()
            },
        });
        if (response_materias.data === 'NOK') {
            alert('Erro ao deletar Emprestimo!')
        } else {
            alert('Emprestimo deletada com sucesso!')
            window.location.reload();
        }
    }
    async function modificaEmprestimo(rowid) {
        const response_materias = await api2.get("/alteraDadosEmprestimos", {
            params: {
                rowid: rowid,
                data: changeData,
                valor: changeValue,
                userId: getUser(),
                token: getToken()
            },
        });
        if (response_materias.data === 'NOK') {
            alert('Erro ao alterar Emprestimo!')
        } else {
            alert('Emprestimo modificada com sucesso!')
            window.location.reload();
        }
        setChangeData('')
        setChangeValue(0)
    }
    const alteraNDF = (e, v) => {
        if (v === 'data') {
            setChangeData(e)
        } else if (v === 'valor') {
            setChangeValue(e)
        }
    }
    return (
        permitido === true ?
            <div>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <div>
                            <h1> Empréstimos </h1>
                            <div>
                                <h4> Fornecedor </h4>
                                <TextField label={fornecedor} onChange={(e) => setFornecedor(e.target.value)} />
                            </div>
                            <div>
                                <h4> Valor U$ Empréstimo :</h4>
                                <TextField
                                    label={valorUs}
                                    onChange={(e) => setValorUs(e.target.value)}
                                />
                            </div>
                            <div>
                                <h4> Data Vencimento</h4>
                                <TextField label={vcto} onChange={(e) => setVcto(e.target.value)} />
                            </div>

                            <div style={{ marginTop: 30 }}>
                                <button onClick={() => fetchapi2()}>Salvar Dados</button>
                            </div>
                        </div>
                    </GridItem>
                    {/* <GridItem xs={12} sm={12} md={4}>
                    <Card chart>
                        <CardHeader color="warning">
                            <ChartistGraph
                                className="ct-chart"
                                data={emailsSubscriptionChart.data}
                                type="Bar"
                                options={emailsSubscriptionChart.options}
                                responsiveOptions={emailsSubscriptionChart.responsiveOptions}
                                listener={emailsSubscriptionChart.animation}
                            />
                        </CardHeader>
                        <CardBody>
                            <h4 className={classes.cardTitle}>Email Subscriptions</h4>
                            <p className={classes.cardCategory}>Last Campaign Performance</p>
                        </CardBody>
                        <CardFooter chart>
                            <div className={classes.stats}>
                                <AccessTime /> campaign sent 2 days ago
              </div>
                        </CardFooter>
                    </Card>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                    <Card chart>
                        <CardHeader color="danger">
                            <ChartistGraph
                                className="ct-chart"
                                data={completedTasksChart.data}
                                type="Line"
                                options={completedTasksChart.options}
                                listener={completedTasksChart.animation}
                            />
                        </CardHeader>
                        <CardBody>
                            <h4 className={classes.cardTitle}>Completed Tasks</h4>
                            <p className={classes.cardCategory}>Last Campaign Performance</p>
                        </CardBody>
                        <CardFooter chart>
                            <div className={classes.stats}>
                                <AccessTime /> campaign sent 2 days ago
              </div>
                        </CardFooter>
                    </Card>
                </GridItem> */}
                </GridContainer>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={8}>

                        <Card>
                            <table >
                                <thead>
                                    <tr style={{ width: 300, textAlign: "center", border: "1px solid " }}>
                                        <th style={{ width: 300, textAlign: "center", border: "1px solid " }}>
                                            Fornecedor
                                        </th>
                                        <th style={{ width: 300, textAlign: "center", border: "1px solid " }}>
                                            Valor U$
                                        </th>
                                        <th style={{ width: 300, textAlign: "center", border: "1px solid " }}>
                                            Vencimento
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Object.keys(emprestimos).map((item, index) => (
                                        <tr key={index}>
                                            <td style={{ width: 300, textAlign: "center", border: "1px solid " }}>
                                                {emprestimos[item].fornecedor}
                                            </td>

                                            <td style={{ width: 300, textAlign: "center", border: "1px solid " }}>
                                                <TextField label={emprestimos[item].valor.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,').replaceAll(',', '/').replaceAll('.', ',').replaceAll('/', '.')} onChange={(e) => alteraNDF(e.target.value, 'valor')} />
                                            </td>

                                            <td style={{ width: 300, textAlign: "center", border: "1px solid " }}>
                                                <TextField label={emprestimos[item].data} onChange={(e) => alteraNDF(e.target.value, 'data')} />
                                            </td>


                                            <td> <button onClick={() => deleteDados(emprestimos[item].rowid)}> Deleta Emprestimo</button></td>
                                            <td> <button onClick={() => modificaEmprestimo(emprestimos[item].rowid)}> Modifica Emprestimo</button></td>

                                        </tr>

                                    ))}
                                    {totals !== '' ?
                                        <tr key={56756756}>
                                            <td style={{ width: 300, textAlign: "center", border: "1px solid " }}>
                                                {'Total Emprestimos U$'}
                                            </td>

                                            <td style={{ width: 300, textAlign: "center", border: "1px solid " }}>
                                                {totals[0].totalEmprestimos.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,').replaceAll(',', '/').replaceAll('.', ',').replaceAll('/', '.')}
                                            </td>
                                        </tr> : console.log(totals)}
                                </tbody>
                            </table>
                        </Card>
                    </GridItem>

                </GridContainer>

                {/*                  */}

            </div> : <></>
    );
}
function mapStateToProps(state) {
    return {
        token: state.token,
        user: state.user,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            setToken: setToken,
            setUser: setUser,
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(Emprestimos);
