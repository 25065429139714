import React, { useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import { Checkbox } from '@material-ui/core';

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { setToken } from "../../flux/actions/tokenIndex";
import { setUser } from "../../flux/actions/userIndex";
import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import api2 from "../../services/api2";
import { checarPermissao, validateTokenUser, getUser, getToken } from '../../utils/utils.js'
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";


const useStyles = makeStyles(styles);

function NDF(props) {
    const [operacao, setOpecaracao] = useState("Compra");
    const [cambio, setCambio] = useState(0);
    const [valorUs, setValorUs] = useState(0);
    const [vcto, setVcto] = useState("");
    const [apur, setApur] = useState("");
    const [ndfs, setNdfs] = useState({})
    const [totals, setTotals] = useState('')
    const [change, setChange] = useState({})
    const [banco, setBanco] = useState("SANTANDER")
    const [totalGeral, setTotalGeral] = useState('')
    const [vetDelete, setVetDelete] = useState([])
    const classes = useStyles();
    const [permitido, setPermitido] = useState(false)

    useEffect(() => {
        async function fetchMyapi2() {
            await validateTokenUser(props)
            setPermitido(await checarPermissao('ndf', props))
            const response = await api2.get("/retornaDadosNDF", { params: { userId: getUser(), token: getToken() } });
            setNdfs(response.data);
            if (response.data.length === 0) { console.log(123) }
            else { setNdfs(response.data); }

            const response_totals = await api2.get('/retornaDadosNDFsum', { params: { userId: getUser(), token: getToken() } });
            setTotals(response_totals.data)
            if (response_totals.data === 'NOK') { console.log(123) }
            else { setTotals(response_totals.data) }

            const response_totals_geral = await api2.get('/retornaDadosTotalSum', { params: { userId: getUser(), token: getToken() } });

            if (response_totals_geral.data === 'NOK') { console.log(123) }
            else { setTotalGeral(response_totals_geral.data) }

        }
        fetchMyapi2();
    }, []);

    async function fetchapi2() {
        const response_materias = await api2.get("/salvaDadoNDFs", {
            params: {
                banco,
                operacao,
                cambio,
                valorUs,
                vcto,
                apur,
                userId: getUser(),
                token: getToken()
            },
        });
        if (response_materias.data === 'NOK') {
            alert('Erro ao adicionar ndf!')
        } else {
            alert('NDF adicionada com sucesso!')
            window.location.reload();
        }
    }


    async function deleteTudoNDF(item) {
        let auxO = ''
        Object.keys(vetDelete).forEach(
            (item) => (auxO = auxO + item + '#' + vetDelete[item] + ';')
        )

        const response_materias = await api2.get("/deleteTudoNDF", { params: { vetDelete: auxO, userId: getUser(), token: getToken() } });
        if (response_materias.data === 'NOK') {
            alert('Erro ao deletar NDF!')
        } else {
            alert('NDF deletada com sucesso!')
            window.location.reload();
        }
    }
    async function deleteDados(item) {
        const response_materias = await api2.get("/deleteDadosNdf", {
            params: {
                rowID: item.rowid,
                userId: getUser(),
                token: getToken()
            },
        });
        if (response_materias.data === 'NOK') {
            alert('Erro ao deletar NDF!')
        } else {
            alert('NDF deletada com sucesso!')
            window.location.reload();
        }
    }
    async function modificaNDFS(e) {
        const response_materias = await api2.get("/alteraDadosNdf", {
            params: {
                registro: e,
                altera: change,
                userId: getUser(),
                token: getToken()
            },
        });
        if (response_materias.data === 'NOK') {
            alert('Erro ao alterar NDF!')
        } else {
            alert('NDF modificada com sucesso!')
            window.location.reload();
        }
    }
    const alteraNDF = (e, v) => {
        console.log(e, v)
        let aux = change;
        aux[v] = e;
        setChange(aux)
        console.log(aux)
    }
    const montaDelete = (index) => {
        let aux = vetDelete
        index = parseInt(index)
        if (aux[index] === undefined) {
            aux[index] = true
        }
        else {
            aux[index] = !aux[index]
        }
        setVetDelete(aux)
        console.log(aux)
    }
    return (
        permitido === true ?
            <div>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <div>
                            <h1> Notas NDF</h1>
                            <div>

                                <h4> Banco :</h4>
                                <Autocomplete
                                    id="combo-box-demo"
                                    options={[{ name: "SANTANDER" }, { name: "DAYCOVAL" }, { name: "BANCO DO BRASIL" }]}
                                    getOptionLabel={(option) => option.name}
                                    style={{ width: 300 }}
                                    onInputChange={(e, v) => setBanco(v)}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Banco"
                                            variant="outlined"
                                        />
                                    )}
                                />
                            </div>
                            <div>


                                <h4> Compra/Venda :</h4>
                                <Autocomplete
                                    id="combo-box-demo"
                                    options={[{ name: "Compra" }, { name: "Venda" }]}
                                    getOptionLabel={(option) => option.name}
                                    style={{ width: 300 }}
                                    onInputChange={(e, v) => setOpecaracao(v)}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Compra ou Venda"
                                            variant="outlined"
                                        />
                                    )}
                                />
                            </div>
                            <div>
                                <h4> Câmbio Movimentação:</h4>
                                <TextField
                                    label={cambio}
                                    onChange={(e) => setCambio(e.target.value)}
                                />
                            </div>
                            <div>
                                <h4> Valor U$ Movimentação :</h4>
                                <TextField
                                    label={valorUs}
                                    onChange={(e) => setValorUs(e.target.value)}
                                />
                            </div>
                            <div>
                                <h4> Data Vencimento</h4>
                                <TextField label={vcto} onChange={(e) => setVcto(e.target.value)} />
                            </div>
                            <div>
                                <h4> Data Apuração</h4>
                                <TextField label={apur} onChange={(e) => setApur(e.target.value)} />
                            </div>
                            <div style={{ marginTop: 30 }}>
                                <button onClick={() => fetchapi2()}>Salvar Dados</button>
                            </div>
                        </div>
                    </GridItem>
                    {/* <GridItem xs={12} sm={12} md={4}>
                    <Card chart>
                        <CardHeader color="warning">
                            <ChartistGraph
                                className="ct-chart"
                                data={emailsSubscriptionChart.data}
                                type="Bar"
                                options={emailsSubscriptionChart.options}
                                responsiveOptions={emailsSubscriptionChart.responsiveOptions}
                                listener={emailsSubscriptionChart.animation}
                            />
                        </CardHeader>
                        <CardBody>
                            <h4 className={classes.cardTitle}>Email Subscriptions</h4>
                            <p className={classes.cardCategory}>Last Campaign Performance</p>
                        </CardBody>
                        <CardFooter chart>
                            <div className={classes.stats}>
                                <AccessTime /> campaign sent 2 days ago
              </div>
                        </CardFooter>
                    </Card>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                    <Card chart>
                        <CardHeader color="danger">
                            <ChartistGraph
                                className="ct-chart"
                                data={completedTasksChart.data}
                                type="Line"
                                options={completedTasksChart.options}
                                listener={completedTasksChart.animation}
                            />
                        </CardHeader>
                        <CardBody>
                            <h4 className={classes.cardTitle}>Completed Tasks</h4>
                            <p className={classes.cardCategory}>Last Campaign Performance</p>
                        </CardBody>
                        <CardFooter chart>
                            <div className={classes.stats}>
                                <AccessTime /> campaign sent 2 days ago
              </div>
                        </CardFooter>
                    </Card>
                </GridItem> */}
                </GridContainer>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>

                        <Card>
                            <table >
                                <thead>
                                    <tr >
                                        <th style={{ width: 300, textAlign: "center", border: "1px solid " }}>
                                            banco
                                        </th>
                                        <th style={{ width: 300, textAlign: "center", border: "1px solid " }}>
                                            operacao
                                        </th>
                                        <th style={{ width: 300, textAlign: "center", border: "1px solid " }}>cambio</th>
                                        <th style={{ width: 300, textAlign: "center", border: "1px solid " }}>
                                            valorUs
                                        </th>
                                        <th style={{ width: 300, textAlign: "center", border: "1px solid " }}>
                                            valorRs
                                        </th>
                                        <th style={{ width: 300, textAlign: "center", border: "1px solid " }}>Vencimento</th>
                                        <th style={{ width: 300, textAlign: "center", border: "1px solid " }}>Apuração</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {Object.keys(ndfs).map((item, index) => (


                                        <tr key={index}>
                                            <td style={{ width: 300, textAlign: "center", border: "1px solid " }}>
                                                {ndfs[item].banco}
                                            </td>
                                            <td style={{ width: 300, textAlign: "center", border: "1px solid " }}>
                                                {ndfs[item].operacao}
                                            </td>
                                            <td style={{ width: 300, textAlign: "center", border: "1px solid " }}>
                                                {ndfs[item].cambio}
                                            </td>
                                            <td style={{ width: 300, textAlign: "center", border: "1px solid " }}>

                                                <TextField label={ndfs[item].valorUs.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,').replaceAll(',', '/').replaceAll('.', ',').replaceAll('/', '.')} onChange={(e) => alteraNDF(e.target.value, 'valorUs')} />
                                            </td>
                                            <td style={{ width: 300, textAlign: "center", border: "1px solid " }}>
                                                {ndfs[item].valorRS.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,').replaceAll(',', '/').replaceAll('.', ',').replaceAll('/', '.')}
                                            </td>
                                            <td style={{ width: 300, textAlign: "center", border: "1px solid " }}>
                                                <TextField label={ndfs[item].dataVcto} onChange={(e) => alteraNDF(e.target.value, 'dataVcto')} />
                                            </td>
                                            <td style={{ width: 300, textAlign: "center", border: "1px solid " }}>
                                                <TextField label={ndfs[item].dataApur} onChange={(e) => alteraNDF(e.target.value, 'dataApur')} />
                                            </td>

                                            <td> <button onClick={() => deleteDados(ndfs[item])}> Deleta NDF</button></td>
                                            <td><button onClick={() => modificaNDFS(ndfs[item])}> Modifica NDF </button> </td>
                                            <td>  <Checkbox

                                                onChange={() => montaDelete(ndfs[index].rowid)}
                                                name="checkedB"
                                                color="primary"
                                            /> </td>
                                        </tr>

                                    ))}
                                    {totals !== '' ?
                                        <tr key={56756756}>
                                            <td style={{ width: 300, textAlign: "center", border: "1px solid " }}>
                                                {'Média Cambio/Totais'}
                                            </td>
                                            <td style={{ width: 300, textAlign: "center", border: "1px solid " }}>
                                                {''}
                                            </td>
                                            <td style={{ width: 300, textAlign: "center", border: "1px solid " }}>
                                                {totals[0].cambioMeanVenda}
                                            </td>

                                            <td style={{ width: 300, textAlign: "center", border: "1px solid " }}>
                                                {totals[0].totalUsVenda.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,').replaceAll(',', '/').replaceAll('.', ',').replaceAll('/', '.')}
                                            </td>
                                            <td style={{ width: 300, textAlign: "center", border: "1px solid " }}>
                                                {totals[0].totalRsVenda.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,').replaceAll(',', '/').replaceAll('.', ',').replaceAll('/', '.')}
                                            </td>
                                            <td style={{ width: 300, textAlign: "center", border: "1px solid " }}>
                                                {''}
                                            </td>

                                        </tr> : console.log(totals)}
                                </tbody>
                            </table>
                        </Card>
                    </GridItem>

                </GridContainer>
                <GridContainer>
                    <div style={{ marginLeft: 20 }}>
                        <Button style={{ backgroundColor: '#DC143C' }} onClick={() => deleteTudoNDF()}>Remover NDF's selecionadas</Button>
                    </div>
                </GridContainer>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                        <CardHeader> Total Geral NDF</CardHeader>
                        <table >
                            <thead>
                                <tr style={{ width: 300, textAlign: "center", border: "1px solid " }}>
                                    <th style={{ width: 300, textAlign: "center", border: "1px solid " }}>
                                        Média Cambio
                                    </th>
                                    <th style={{ width: 300, textAlign: "center", border: "1px solid " }}>
                                        Total Us
                                    </th>
                                    <th style={{ width: 300, textAlign: "center", border: "1px solid " }}>
                                        Total Rs
                                    </th>


                                </tr>
                            </thead>
                            <tbody>
                                {totals !== '' ?
                                    <tr key={56756756}>
                                        <td style={{ width: 300, textAlign: "center", border: "1px solid " }}>
                                            {totals[0].mediaGeral}
                                        </td>
                                        <td style={{ width: 300, textAlign: "center", border: "1px solid " }}>
                                            {totals[0].totalGeral.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,').replaceAll(',', '/').replaceAll('.', ',').replaceAll('/', '.')}
                                        </td>
                                        <td style={{ width: 300, textAlign: "center", border: "1px solid " }}>
                                            {totals[0].totalGeralRs.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,').replaceAll(',', '/').replaceAll('.', ',').replaceAll('/', '.')}
                                        </td>

                                    </tr> : console.log(totals)}

                            </tbody>
                        </table>
                    </GridItem>
                </GridContainer>

                <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                        <CardHeader> Total Geral NDF+XP </CardHeader>
                        <table >
                            <thead>
                                <tr style={{ width: 300, textAlign: "center", border: "1px solid " }}>
                                    <th style={{ width: 300, textAlign: "center", border: "1px solid " }}>
                                        Média Cambio
                                    </th>
                                    <th style={{ width: 300, textAlign: "center", border: "1px solid " }}>
                                        Total Us
                                    </th>
                                    <th style={{ width: 300, textAlign: "center", border: "1px solid " }}>
                                        Total Rs
                                    </th>


                                </tr>
                            </thead>
                            <tbody>
                                {totalGeral !== '' ?
                                    <tr key={567567356}>
                                        <td style={{ width: 300, textAlign: "center", border: "1px solid " }}>
                                            {totalGeral[0].mediaGeral}
                                        </td>
                                        <td style={{ width: 300, textAlign: "center", border: "1px solid " }}>
                                            {totalGeral[0].totalUs.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,').replaceAll(',', '/').replaceAll('.', ',').replaceAll('/', '.')}
                                        </td>
                                        <td style={{ width: 300, textAlign: "center", border: "1px solid " }}>
                                            {totalGeral[0].totalRs.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,').replaceAll(',', '/').replaceAll('.', ',').replaceAll('/', '.')}
                                        </td>

                                    </tr> : console.log(totalGeral)}

                            </tbody>
                        </table>
                    </GridItem>
                </GridContainer>
                {/*                  */}

            </div> : <></>
    );
}
function mapStateToProps(state) {
    return {
        token: state.token,
        user: state.user,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            setToken: setToken,
            setUser: setUser,
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(NDF);
