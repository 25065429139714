import React, { useState } from "react";
import AddMetas from './PlrComponents/AddMetas';
import TabelaInferiorMetas from './PlrComponents/TabelaInferiorMetas';
import CreateSetor from './PlrComponents/CreateSetor';
import { Paper, Typography, Tabs, Tab } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Resultados from "./PlrComponents/Resultados";

function ControleMetas(props) {

    const [keyUpdateFlagSetorAdded, setKeyUpdateFlagSetorAdded] = useState(0);
    const [keyUpdateFlagSaveEdit, setKeyUpdateFlagSaveEdit] = useState(0);
    const [tabValue, setTabValue] = useState(0); // For controlling the active tab

    const handleSetorAddSuccess = () => {
        setKeyUpdateFlagSetorAdded(prevKey => prevKey + 1)
    };

    const handleSaveEditSuccess = () => {
        setKeyUpdateFlagSaveEdit(prevKey => prevKey + 1)
    };

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <Grid container xs={12} spacing={4}>
            <Tabs value={tabValue} onChange={handleTabChange} centered>
                <Tab label="Atualização de metas" />
                <Tab label="Resultados PLR"/>
            </Tabs>

            {/* {tabValue === 0 && (
                <Grid item component={Paper} xs={12}>
                 
                    <CreateSetor onSetorAddSuccess={handleSetorAddSuccess} />
                </Grid>
            )} */}

            {tabValue === 1 && (
                <Grid item component={Paper} xs={12}>
                    <Resultados />
                </Grid>
            )}

            <Grid item component={Paper} xs={12} style={{ marginTop: 20 }}>
                <Typography variant="h6" style={{ marginBottom: 10 }}>
                    Atualizar Metas
                </Typography>
                <AddMetas refreshMessage={keyUpdateFlagSetorAdded} onSaveEditSuccess={handleSaveEditSuccess} />
            </Grid>

            <Grid item component={Paper} xs={12} style={{ marginTop: 20 }}>
                <Typography variant="h6" style={{ marginBottom: 10 }}>
                    Acompanhamento de Metas
                </Typography>
                <TabelaInferiorMetas refreshMessageTableInferior={keyUpdateFlagSaveEdit} />
            </Grid>
        </Grid>
    );
};

export default ControleMetas;
