import React, { useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { setToken } from "../../flux/actions/tokenIndex";
import { setUser } from "../../flux/actions/userIndex";
import api from "../../services/api";

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Button from '@material-ui/core/Button';
import { TextField } from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(3),
  },
  button: {
    margin: theme.spacing(1, 1, 0, 0),
  },
}));

function ValidadeQrCode(props) {
  const classes = useStyles();
  const [valid, setValid] = useState(false)
  const [carregamento, setCarregamento] = useState('')

  useEffect(() => {
    async function fetchMyAPI() {
      const response = await api.get("/info_qr_code_balanca", {
        params: {
          codigo: props.match.params.id,
          token: 'gerar_token_validacao_app',

        }
      });

      if ((response.data === 'NOK') || (response.data === 'NOK1') || (response.data === 'NOK2')) {
        alert('Erro ao encontrar carregamento')
        setValid(false)
      }
      else if ((response.data === 'NOK3')) {
        alert('Carregamento já finalizado')
        setValid(false)
      }
      else {
        setCarregamento(response.data)
        setValid(true)
        //setNome(response.data.nome)
        //setProdutos(response.data.produtos)
      }
    }
    fetchMyAPI();

  }, [])

  async function sendValidation() {
    const response = await api.get("/validate_qr_code", {
      params: {
        codigo: props.match.params.id,
        token: 'gerar_token_validacao_app',
      }
    });
    if (response.data === 'OK') {
      alert('Carregamento aceito')
      window.location.reload()
    }
  }



  return (
    <div style={{ background: 'white' }}>
      {valid === true ?
        <div style={{ overflowWrap: 'break-word', padding: 10, margin: 'auto', width: '90vw' }} >
          <div>
            <GridContainer >
              <GridItem style={{ width: '90vw' }}>
                <p> Placa: {carregamento[0].placa}</p>
              </GridItem>
            </GridContainer>

            <GridContainer>
              <GridItem style={{ width: '90vw' }}>
                <p> Opercao: {carregamento[0].operacao}</p>
              </GridItem>
            </GridContainer>

            <GridContainer>
              <GridItem style={{ width: '90vw' }}>
                <p> Entrou: {carregamento[0].entrouArmazem}</p>
              </GridItem>
            </GridContainer>

          </div>

          {carregamento[0].entrouArmazem === 0 ? <div style={{ marginBottom: 50 }}>
            <FormControl style={{ width: '90vw', alignItems: 'center', alignContent: 'center' }}>
              <Button style={{ backgroundColor: '#6699ff' }} variant="contained" onClick={() => sendValidation()}>Aceitar Carregamento</Button>
            </FormControl>
          </div> : <p>Carregamento Aceito</p>}

        </div> :
        <div style={{ textAlign: 'center' }}> <h4>Consultando carregamento</h4> </div>}
    </div>
  );
}


function mapStateToProps(state) {
  return {
    token: state.token,
    user: state.user,
  };
}


function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setToken: setToken,
      setUser: setUser,
    },
    dispatch
  );
}


export default connect(mapStateToProps, mapDispatchToProps)(ValidadeQrCode);
