import React, { useState, useEffect } from "react";
import { TextField, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import api from "services/api";
import Loader from "react-loader-spinner";

const useStyles = makeStyles({
    paper: {
        padding: 20,
        width: '100%',
    },
});

const CreateSetor = ({ onSetorAddSuccess }) => {
    const classes = useStyles();
    const [vinculosPlr, setVinculosPlr] = useState([]);
    const [selectedSetorMaster, setSelectedSetorMaster] = useState("");
    const [setorNome, setSetorNome] = useState("");
    const [isLoading, setIsLoading] = useState(false);


    useEffect(() => {
        returnSetorMaster();
    }, []);

    const returnSetorMaster = async () => {
        const returnSetorMaster = await api.get('/return_setores_master', { params: {} });
        setVinculosPlr(returnSetorMaster.data);
    };

    const handleAutocompleteMaster = async (setorMasteId) => {
        setSelectedSetorMaster(setorMasteId)
    }

    const handleSubmit = async () => {
        if (selectedSetorMaster) {
            if (setorNome.trim() === "") {
                alert('Por favor, preencha o nome do setor');
                setIsLoading(false);
                return
            }

            setIsLoading(true);
            const ReturnAddSetor = await api.post('/add_setor_plr', { 'idSetorMaster': selectedSetorMaster.id_setor_master, 'nomeSetor': setorNome });
            setVinculosPlr(ReturnAddSetor.data);

            if (ReturnAddSetor.data.status === "OK") {
                alert('Setor criado com sucesso, agora você pode adicionar as metas ao setor criado');
                window.location.reload()
                onSetorAddSuccess();
                setSetorNome("");
            }

            setIsLoading(false);
        } else {
            alert('Selecione um Grupo');
            setIsLoading(false)
        }
    };



    return (
        <div style={{ width: '100%' }}>
            <Paper className={classes.paper}>
                <Autocomplete
                    options={vinculosPlr}
                    disableClearable={true}
                    getOptionLabel={(option) => option.nome_setor_master}
                    renderInput={(params) => <TextField {...params} label="Selecionar Grupo" variant="outlined" />}
                    size="small"
                    onChange={(event, newValue) => {
                        handleAutocompleteMaster(newValue ? newValue : null)
                    }}
                    style={{
                        width: 300,
                        marginBottom: 10
                    }}
                />

                <TextField
                    label="Nome do setor"
                    size="small"
                    variant="outlined"
                    value={setorNome}
                    onChange={(e) => setSetorNome(e.target.value)}
                    style={{ width: 500, marginRight: 20 }}
                />

                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    disabled={isLoading}
                >
                    {isLoading ? <Loader type="Circles" color="#00BFFF" height={20} width={20} /> : 'Salvar'}
                </Button>
            </Paper>
        </div>
    );
};

export default CreateSetor;
