import React, { useState, useEffect } from "react";
import api from "services/api";
import { TextField, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Dialog, DialogTitle, DialogContent, Typography, DialogActions } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import Loader from "react-loader-spinner";
import Tooltip from '@material-ui/core/Tooltip';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';



const months = ['Meta', 'Peso', 'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];

const AddMetas = ({ refreshMessage, onSaveEditSuccess }) => {
    const classes = useStyles();
    const [editIdx, setEditIdx] = useState(-1);
    const [setorSelecionado, setSetorSelecionado] = useState(null);
    const [setoresPlr, setSetoresPlr] = useState([]);
    const [vinculosPlr, setVinculosPlr] = useState([]);
    const [editObj, setEditObj] = useState({})
    const [rowEditId, setRowEditId] = useState(0)
    const [isLoading, setIsLoading] = useState(false);
    const [isAddingRow, setIsAddingRow] = useState();
    const [isSaving, setIsSaving] = useState(false);
    const [openDialog, setOpenDialog] = useState(false)
    const keysToIgnore = ['nome_setor', 'id_meta', 'id_setor', 'valor_meta'];
    const metasOptions = [{ nomeMeta: 'Média', metaOptionId: 0 }, { nomeMeta: 'Incremental', metaOptionId: 1 }, { nomeMeta: 'Quantitativa', metaOptionId: 2 }]
    const desc = <>
        <p>Média: Faz média dos meses </p>
        <p>Incremental: Considera o último valor</p>
        <p>Quantitativa: Soma tudo e mostra a porcentagem equivalente </p></>

    useEffect(() => {
        returnaSetoresPlr();
    }, [refreshMessage]);

    const returnaSetoresPlr = async () => {
        const retornoSetoresPlr = await api.get('/return_setores_plr', { params: {} });
        setSetoresPlr(retornoSetoresPlr.data);
    };

    const handleAddRow = async () => {
        setIsAddingRow(true);
        try {
            const PostRow = await api.post('/add_row_plr', { 'setorId': setorSelecionado });
            setVinculosPlr(PostRow.data);
        } catch (err) {
            console.error(err);
        } finally {
            setIsAddingRow(false);
        }
    };

    const handleAutocomplete = async (setorId) => {
        setIsLoading(true);
        setSetorSelecionado(setorId);
        const retornoVinculosPlr = await api.get('/return_metas_plr', { params: { 'setorId': setorId } });
        console.log(retornoVinculosPlr.data)
        setVinculosPlr(retornoVinculosPlr.data);
        setIsLoading(false);
        setIsAddingRow(false);
    };

    const handleEdit = (key, value) => {
        let aux = { ...editObj }
        aux[key] = value
        setEditObj(aux)
    }

    const handleSaveEdit = async () => {
        setIsSaving(true);

        let editObjCopy = { ...editObj };
        delete editObjCopy.nome_setor;

        let aux = {
            rowId: rowEditId,
            editObj: editObjCopy
        };

        try {
            const response = await api.post('/edit_row_plr', aux)
            if (response.data === 'OK') {
                const retornoVinculosPlr = await api.get('/return_metas_plr', { params: { 'setorId': setorSelecionado } });
                setVinculosPlr(retornoVinculosPlr.data);
                setRowEditId(null);
                onSaveEditSuccess();
            } else {
                alert('Erro ao salvar edição');
            }
        } catch (err) {
            console.error(err);
        } finally {
            setIsSaving(false);
        }
    }
    const handleDelete = async (id) => {
        setIsSaving(true);

    

        let aux = {
            rowId: id,
            
        };

        try {
            const response = await api.post('/delete_row_plr', aux)
            if (response.data === 'OK') {
                const retornoVinculosPlr = await api.get('/return_metas_plr', { params: { 'setorId': setorSelecionado } });
                setVinculosPlr(retornoVinculosPlr.data);
                setRowEditId(null);
                onSaveEditSuccess();
            } else {
                alert('Erro ao deletar meta');
            }
        } catch (err) {
            console.error(err);
        } finally {
            setIsSaving(false);
        }
    }

    const handleMetaAutocompleteChange = (id) => {
        if (id === 2) {  // tipo_meta = 0 (média), 1 (incremental), 2 (quantitativa)
            setOpenDialog(true)
        }
        handleEdit('tipo_meta', id)
    }

    const tipoMetaNome = (value) => {
        let obj = metasOptions.find(i => i.metaOptionId === value)
        return obj.nomeMeta
    }

    return (
        <Paper className={classes.paper}>
            <div style={{ padding: 20 }}>

                <div>
                    <Autocomplete
                        size='small'
                        id="combo-box-demo"
                        disableClearable={true}
                        options={setoresPlr}
                        getOptionLabel={(option) => option.nome_setor}
                        onChange={(event, newValue) => {
                            handleAutocomplete(newValue ? newValue.id_setor : null)
                        }}
                        style={{ width: 300 }}
                        renderInput={(params) => <TextField value={setorSelecionado ? setorSelecionado.nome_setor : null} {...params} label="Setor" variant="outlined" />}
                    />
                </div>
                <div style={{ marginTop: 20 }}>
                    {isLoading ?
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "200px" }}>
                            <Loader
                                type="Circles"
                                color="#00BFFF"
                                height={20}
                                width={20}
                            />
                        </div>
                        :
                        <TableContainer component={Paper}>
                            <Table className={classes.table} size='small' aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Ações</TableCell>
                                        <TableCell style={{ minWidth: 250 }}>Meta</TableCell>
                                        <Tooltip title={desc} classes={{ tooltip: classes.tooltip }}>
                                            <TableCell>
                                                <div style={{ display: 'flex', alignItems: 'center', width: 90 }}>
                                                    Tipo Meta
                                                    <HelpOutlineIcon style={{ marginLeft: '5px', color: 'gray', fontSize: '18px' }} />
                                                </div>
                                            </TableCell>
                                        </Tooltip>
                                        <TableCell >Peso</TableCell>
                                        <TableCell style={{ minWidth: 60 }}>Janeiro</TableCell>
                                        <TableCell style={{ minWidth: 60 }}>Fevereiro</TableCell>
                                        <TableCell style={{ minWidth: 60 }}>Março</TableCell>
                                        <TableCell style={{ minWidth: 60 }}>Abril</TableCell>
                                        <TableCell style={{ minWidth: 60 }}>Maio</TableCell>
                                        <TableCell style={{ minWidth: 60 }}>Junho</TableCell>
                                        <TableCell style={{ minWidth: 60 }}>Julho</TableCell>
                                        <TableCell style={{ minWidth: 60 }}>Agosto</TableCell>
                                        <TableCell style={{ minWidth: 60 }}>Setembro</TableCell>
                                        <TableCell style={{ minWidth: 60 }}>Outubro</TableCell>
                                        <TableCell style={{ minWidth: 60 }}>Novembro</TableCell>
                                        <TableCell style={{ minWidth: 60 }}>Dezembro</TableCell>
                                        <TableCell style={{ minWidth: 60 }}>Total</TableCell>
                                        <TableCell style={{ minWidth: 60 }}>Deletar</TableCell>

                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {vinculosPlr.length > 0 && vinculosPlr.map((rowData, i) => (
                                        <TableRow key={i}>
                                            <TableCell>
                                                {rowData.id_meta === rowEditId ?
                                                    <Button
                                                        size='small'
                                                        style={{ backgroundColor: 'lightgreen' }}
                                                        variant="contained"
                                                        onClick={() => handleSaveEdit()}
                                                        disabled={isSaving}
                                                    >
                                                        {isSaving ? (
                                                            <Loader type="Circles" color="#00BFFF" height={20} width={20} />
                                                        ) : (
                                                            'Salvar'
                                                        )}
                                                    </Button>

                                                    : <Button
                                                        size='small'
                                                        style={{ backgroundColor: '#6699ff', color: 'white' }}
                                                        variant="contained"
                                                        onClick={() => {
                                                            setRowEditId(rowData.id_meta);
                                                            setEditObj(rowData);
                                                        }}
                                                    >
                                                        Editar
                                                    </Button>
                                                }
                                            </TableCell>
                                            {Object.keys(rowData).map((key, index) => {
                                                if (!keysToIgnore.includes(key)) {
                                                    return (
                                                        <TableCell>
                                                            {rowData.id_meta === rowEditId ?
                                                                <>
                                                                    {key === 'nome_meta' ?
                                                                        <TextField
                                                                            size="small"
                                                                            defaultValue={rowData[key]}
                                                                            onChange={(e) => handleEdit(key, e.target.value)}
                                                                            multiline
                                                                        />
                                                                        : key === 'tipo_meta' ?
                                                                            <Autocomplete
                                                                                classes={{
                                                                                    paper: classes.dropdown
                                                                                }}
                                                                                size='small'
                                                                                disableClearable={true}
                                                                                options={metasOptions}
                                                                                getOptionLabel={(item) => item.nomeMeta}
                                                                                onChange={(event, newValue) => handleMetaAutocompleteChange(newValue.metaOptionId)}
                                                                                renderInput={(params) => <TextField
                                                                                    defaultValue={metasOptions[editObj['tipo_meta']] || rowData[key]}
                                                                                    {...params} />}
                                                                                defaultValue={rowData[key]}
                                                                            />
                                                                            : <TextField
                                                                                type="number"
                                                                                size="small"
                                                                                defaultValue={rowData[key]}
                                                                                onChange={(e) => handleEdit(key, e.target.value)}
                                                                            />}
                                                                </>
                                                                :
                                                                <>
                                                                    {key === 'tipo_meta' ?
                                                                        tipoMetaNome(rowData[key])
                                                                        : key === 'nome_meta' ?
                                                                            <>
                                                                                {rowData[key]}
                                                                                {rowData['tipo_meta'] === 2 ?
                                                                                    <b><br />(Valor da meta: {rowData['valor_meta']})</b>
                                                                                    : <></>}
                                                                            </>
                                                                            : key === 'total' ? rowData[key] + '%' : rowData[key]}
                                                                </>}
                                                        </TableCell>
                                                    )
                                                }
                                            })}

                                            <TableCell>

                                                <Button
                                                    size='small'
                                                    style={{ backgroundColor: 'red' }}
                                                    variant="contained"
                                                    onClick={() => handleDelete(rowData.id_meta)}
                                                    disabled={isSaving}
                                                >
                                                    {isSaving ? (
                                                        <Loader type="Circles" color="#00BFFF" height={20} width={20} />
                                                    ) : (
                                                        'Deletar'
                                                    )}
                                                </Button>


                                            </TableCell>



                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    }
                </div>

                {setorSelecionado && (
                    <div style={{ position: 'relative', display: 'inline-block' }}>
                        <Button
                            size='small'
                            disabled={isAddingRow}
                            style={{ marginTop: 20 }}
                            variant="contained"
                            color="primary"
                            onClick={() => { handleAddRow(); setIsAddingRow(true); }}
                        >
                            Adicionar Meta
                        </Button>
                        {isAddingRow && (
                            <div
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    height: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    backgroundColor: 'rgba(255, 255, 255, 0.5)'
                                }}
                            >
                                <Loader
                                    type="Circles"
                                    color="#00BFFF"
                                    height={20}
                                    width={20}
                                />
                            </div>
                        )}
                    </div>
                )}
            </div>


            <Dialog open={openDialog} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Adicionar valor da meta.</DialogTitle>
                <DialogContent>
                    <TextField
                        onChange={(e) => handleEdit('valor_meta', e.target.value)}
                        autoFocus
                        label="Valor"
                        type="number"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDialog(false)}>
                        Cancelar
                    </Button>
                    <Button onClick={() => setOpenDialog(false)} color="primary">
                        Salvar
                    </Button>
                </DialogActions>
            </Dialog>
        </Paper>
    );
}

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    paper: {
        width: '100%',
    },
    dropdown: {
        minWidth: 200
    },
    tooltip: {
        fontSize: 12,
        background: '#222222'
    }
});

export default AddMetas;