import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { setToken } from "../../flux/actions/tokenIndex";
import { setUser } from "../../flux/actions/userIndex";
import api from "../../services/api";
import { checarPermissao, validateTokenUser, getUser, getToken } from '../../utils/utils.js'
import { makeStyles } from "@material-ui/core/styles";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Loader from "react-loader-spinner";
import Button from "components/CustomButtons/Button.js";


function PlacasChecklist(props) {
    const [permitido, setPermitido] = useState(false)

    const [armazem, setArmazem] = useState('')
    const [armazens, setArmazens] = useState('')
    const [placas, setPlacas] = useState('')
    const [wait, setWait] = useState(false)
    const [imagens, setImagens] = useState([])
    const classes = useStyles()
    const [startDate, setStartDate] = useState('2022-06-01')
    const [endDate, setEndDate] = useState('2024-01-01')

    useEffect(() => {
        async function fetchMyAPI() {
            await validateTokenUser(props)
            setPermitido(await checarPermissao('placas_checklist', props))

            const response_armazens = await api.get('/retorna_armazens_checklist', { params: { userId: getUser(), token: getToken() } })
            setArmazens(response_armazens.data)
        }

        fetchMyAPI();
    }, [])



    async function retornaPlacasChecklist() {
        const cod_arm = armazem.split('-')[0]
        const response = await api.get('/retorna_placas_all', { params: { userId: getUser(), token: getToken(), id_filial: cod_arm, startDate, endDate } })
        if (response.data === 'NOK') {
            alert('Erro retornar placas')
        } else {
            setPlacas(response.data)
        }
    }


    async function retornaImagensPlaca(id) {

        const response = await api.get('/retorna_fotos_checklist', { params: { userId: getUser(), token: getToken(), id } })
        if (response.data === 'NOK') {
            alert('Erro retornar imagens')
        } else {
            console.log(response.data)
            let aux = imagens
            aux[id] = response.data
            aux = JSON.parse(JSON.stringify(aux))
            console.log(aux)
            console.log(id)
            setImagens(aux)
        }
    }

    return (
        permitido === true ?
            <div >
                <div>
                    {armazens !== '' ?
                        <Autocomplete
                            id="combo-box-demo2"

                            options={Object.keys(armazens).map((item) => armazens[item].nome_armazem)}
                            value={armazem}
                            getOptionSelected={(option, value) => option === value || value === ''}

                            style={{ width: 400 }}
                            onChange={(e, v) => (setArmazem(v))}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label='Filial'
                                    multiline
                                />
                            )}

                        /> : <Loader
                            type="Circles"
                            color="#00BFFF"
                            height={20}
                            width={20}
                            timeout={2000000} //3 secs

                        />
                    }
                    <div style={{ marginTop: 20 }}>
                        <TextField

                            id="date"
                            label="Inicio"
                            type="date"
                            defaultValue="2022-01-01"
                            className={classes.textField}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={(e) => setStartDate(e.target.value)}
                        />
                        <TextField
                            style={{ marginLeft: 10 }}
                            id="date"
                            label="Fim"
                            type="date"
                            defaultValue="2024-01-01"
                            className={classes.textField}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={(e) => setEndDate(e.target.value)} />

                    </div>
                </div>

                {armazem !== '' ? <Button style={{ backgroundColor: '#6699ff', marginTop: 30 }} variant="contained" onClick={() => retornaPlacasChecklist()}>Pesquisar</Button> : null}

                {Object.keys(placas).map((index) =>
                    <Accordion style={{ width: '100%' }} key={index}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id={index}

                        >
                            <Typography className={classes.heading}>Placa:{placas[index].placa} Data:{placas[index].datahora}</Typography>


                        </AccordionSummary>
                        <div style={{ border: "1px solid", margin: 10 }}>
                            <AccordionDetails style={{ display: 'flex', flexDirection: 'column' }}>

                                <Button disabled={wait} style={{ backgroundColor: '#DE3163', color: '#FFFFFF' }} onClick={() => (retornaImagensPlaca(placas[index].id))}>  Retornar images</Button>

                            </AccordionDetails>

                            {(imagens[placas[index].id] !== undefined) & (imagens[placas[index].id] !== null) ? Object.keys(imagens[placas[index].id]).map((val) =>
                                <div key={val}>
                                    <img src={"data:image/png;base64," + imagens[placas[index].id][val].foto} style={{ width: 300, height: 300 }} />
                                    {/* {console.log(imagens[val])}
                                    {console.log('falcatrua',imagens[placas[index].id][val]['foto'])} */}
                                </div>) : <Loader
                                type="Circles"
                                color="#00BFFF"
                                height={20}
                                width={20}
                                timeout={2000000} //3 secs

                            />}
                        </div>

                    </Accordion>
                )}
            </div> : <></>
    )
}


const useStyles = makeStyles()


function mapStateToProps(state) {
    return {
        token: state.token,
        user: state.user,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            setToken: setToken,
            setUser: setUser,
        },
        dispatch
    );
}


export default connect(mapStateToProps, mapDispatchToProps)(PlacasChecklist);