import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { setToken } from "../../flux/actions/tokenIndex";
import { setUser } from "../../flux/actions/userIndex";
import api from "../../services/api";
import { checarPermissao, validateTokenUser, getUser, getToken } from '../../utils/utils.js'
import MuiPhoneInput from 'material-ui-phone-number';
import { makeStyles } from "@material-ui/core/styles";

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

function Pesquisas(props) {
    const [user, setUser] = useState('')
    const [permitido, setPermitido] = useState(false)
    const [pesquisaRespondida, setPesquisaRespondida] = useState([])
    const [pesquisaPendente, setPesquisaPendente] = useState([])

    const [pedidoSendoEditado, setPedidoSendoEditado] = useState('')
    const [novoNumero, setNovoNumero] = useState('')

    const classes = useStyles()

    useEffect(() => {
        let tempUser = getUser()
        setUser(tempUser)

        async function fetchMyAPI() {
            await validateTokenUser(props)
            setPermitido(await checarPermissao('pesquisas', props))

            const pesquisas = await api.get('/getPesquisas', { params: { userId: getUser(), token: getToken() } })
            const respondido = pesquisas.data.filter((obj) => obj.ativo === 0)
            const pendente = pesquisas.data.filter((obj) => obj.ativo === 1)

            setPesquisaRespondida(respondido)
            setPesquisaPendente(pendente)
        }
        fetchMyAPI();
    }, [])


    async function atualizarNumero(numPedido) {
        if (novoNumero === '') {
            alert('Preencha o campo antes de salvar!')
            return
        }
        const response = await api.get('/salvarCelularCliente', { params: { userId: getUser(), token: getToken(), 'numero': novoNumero, numPedido } })

        if (response.data === 'NOK') {
            alert('Erro ao salvar número...')
        } else {
            alert('Salvo com sucesso!')
            window.location.reload()
        }
    }

    function editar(numPedido) {
        setPedidoSendoEditado(numPedido)
        setNovoNumero('')
    }

    return (
        permitido === true ?
            <>

                <div className={classes.divRespondida}>
                    <div style={{ fontSize: 24, margin: 4 }}>Pesquisas Respondidas</div>

                    {pesquisaRespondida.map((pesquisa, index) =>
                        <Accordion key={index} style={{ marginTop: 10 }}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content">
                                <Typography className={classes.docEntry}>
                                    Pedido {pesquisa.docEntry}
                                </Typography>
                            </AccordionSummary>

                            <AccordionDetails style={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography>
                                    <div className={classes.divInfo} >
                                        <div>Cliente: {pesquisa.Cliente}</div>
                                        <div>Produtos: {pesquisa.Item}</div>
                                        <div>Cidade: {pesquisa.CidadePedido}</div>
                                        <div>Vendedor: {pesquisa.VendedorPedido}</div>
                                    </div>
                                </Typography>


                                <Typography component={'span'} >
                                    <h4>Respostas</h4>

                                    <div className={classes.divPergunta}>
                                        <div className={classes.pergunta}>
                                            1 - Como você avalia o atendimento comercial da Gen Fertilizantes?
                                        </div>

                                        <div>
                                            {pesquisa.asw1}
                                        </div>
                                    </div>

                                </Typography>

                                <Typography component={'span'} >
                                    <div className={classes.divPergunta}>
                                        <div className={classes.pergunta}>
                                            2 - Como você avalia os serviços de pós venda (programação, entrega e comunicação) da Gen Fertilizantes?
                                        </div>

                                        <div>
                                            {pesquisa.asw2}
                                        </div>
                                    </div>

                                </Typography>

                                <Typography component={'span'} >
                                    <div className={classes.divPergunta}>
                                        <div className={classes.pergunta}>
                                            3 - Como você avalia a qualidade dos produtos da Gen Fertilizantes?
                                        </div>
                                        <div>
                                            {pesquisa.asw3}
                                        </div>
                                    </div>

                                </Typography>

                                <Typography component={'span'} >
                                    <div className={classes.divPergunta}>
                                        <div className={classes.pergunta}>
                                            4 - Em uma escala de 0 a 10, o quanto você recomendaria a Gen Fertilizantes para outros agricultores?
                                        </div>

                                        <div>
                                            {pesquisa.asw4}
                                        </div>
                                    </div>

                                </Typography>

                                <Typography component={'span'} >
                                    <div className={classes.divPergunta}>
                                        <div className={classes.pergunta}>
                                            5 - Há alguma sugestão de inovação em produto ou serviço que deseja comentar?
                                        </div>

                                        <div>
                                            {pesquisa.asw5}
                                        </div>
                                    </div>

                                </Typography>
                            </AccordionDetails>
                        </Accordion>)}
                </div>


                <div className={classes.divPendente}>
                    <div style={{ fontSize: 24, margin: 4 }}>Pesquisas Pendentes</div>

                    {pesquisaPendente.map((pesquisa, index) =>
                        <Accordion key={index} style={{ marginTop: 10 }}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content">
                                <Typography className={classes.docEntry}>
                                    Pedido {pesquisa.docEntry}
                                </Typography>
                            </AccordionSummary>

                            <AccordionDetails style={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography component={'span'}>
                                    <div className={classes.divInfo}>
                                        <div>Cliente: {pesquisa.Cliente}</div>
                                        <div>Produtos: {pesquisa.Item}</div>
                                        <div>Cidade: {pesquisa.CidadePedido}</div>
                                        <div>Vendedor: {pesquisa.VendedorPedido}</div>
                                    </div>

                                    <div style={{ border: '1px solid rgba(0, 0, 0, .2)', padding: 10, width: 300, display: 'flex', flexDirection: 'column' }}>
                                        {pedidoSendoEditado === pesquisa.docEntry ?
                                            <>
                                                <div>
                                                    <MuiPhoneInput
                                                        onChange={(value) => setNovoNumero(value)}
                                                        defaultCountry='br'
                                                        style={{ width: 300, margin: 5 }}

                                                        label='Celular'
                                                    />
                                                </div>

                                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                    <div>
                                                        <button onClick={() => atualizarNumero(pesquisa.docEntry)}>Salvar</button>
                                                    </div>

                                                    <div style={{ marginLeft: 5 }}>
                                                        <button onClick={() => setPedidoSendoEditado('')}>Cancelar</button>
                                                    </div>
                                                </div>


                                            </> :
                                            <>
                                                <div>
                                                    Número do Cliente: {pesquisa.celularCliente}
                                                </div>
                                                <div>
                                                    <button onClick={() => editar(pesquisa.docEntry)}>Editar</button>
                                                </div>
                                            </>}
                                    </div>

                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    )}
                </div>
            </> : <></>
    )
}


const useStyles = makeStyles({
    divRespondida: {
        background: 'white',
        borderRadius: 8,
        padding: 20
    },

    divPendente: {
        background: 'white',
        borderRadius: 8,
        padding: 20,
        marginTop: 50
    },

    pergunta: {
        fontWeight: 'bold'
    },

    divPergunta: {
        border: '1px solid rgba(0, 0, 0, .2)',
        margin: 5,
        padding: 5
    },

    divInfo: {
        border: '1px solid rgba(0, 0, 0, .2)',
        marginBottom: 10,
        padding: 5
    },

    docEntry: {
        fontWeight: 'bold',
        border: '1px solid rgba(0, 0, 0, .4)',
        padding: 4
    }
})


function mapStateToProps(state) {
    return {
        token: state.token,
        user: state.user,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            setToken: setToken,
            setUser: setUser,
        },
        dispatch
    );
}


export default connect(mapStateToProps, mapDispatchToProps)(Pesquisas);