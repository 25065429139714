import React, { useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { setToken } from "../../flux/actions/tokenIndex";
import { setUser } from "../../flux/actions/userIndex";
import { TextField } from "@material-ui/core";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";

import CardBody from "components/Card/CardBody.js";
import api2 from "../../services/api2";
import Loader from "react-loader-spinner";

import exportFromJSON from 'export-from-json'
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import Button from "components/CustomButtons/Button.js";
import { checarPermissao, validateTokenUser, getUser, getToken } from '../../utils/utils.js'

function getPrio() {
  const userString = localStorage.getItem('Prio')
  if (userString === null) {
    return ''
  }
  else {
    return userString
  }
}


const useStyles = makeStyles(styles);
function Relatorios(props) {

  // const [token, setToken] = useState('')
  const [rel, setRel] = useState('');// relatório de saldos
  const [prio, setPrio] = useState('')
  const [compArm, setCompArm] = useState('')//relatório de comprar armazenagem
  const [consumo, setConsumo] = useState('')
  const [relEntradas, setRelEntradas] = useState('')
  const [movEstoques, setMovEstoques] = useState('')
  const [relContasReceber, setRelContasReceber] = useState('')
  const [relContasPagar, setRelContasPagar] = useState('')
  const [fatAntecipado, setFatAntecipado] = useState('')
  const [relComissao, setRelComissao] = useState('')
  const [dataIni, setDataIni] = useState('')
  const [dataFim, setDataFim] = useState('')
  const [dataIniCR, setDataIniCR] = useState('')
  const [dataFimCR, setDataFimCR] = useState('')
  const [dataIniCPagas, setDataIniCPagas] = useState('')
  const [dataFimCPagas, setDataFimCPagas] = useState('')
  const [dataIniCP, setDataIniCP] = useState('')
  const [dataFimCP, setDataFimCP] = useState('')
  const [dataIniCom, setDataIniCom] = useState('')
  const [dataFimCom, setDataFimCom] = useState('')
  const [dataIniEstoque, setdataIniEstoque] = useState('')
  const [relConsumoPorProduto, setRelConsumoPorProduto] = useState('')

  const [prest, setPrest] = useState('')
  const [genFat, setGenFat] = useState(false)
  const [contasPagasNovo, setContasPagasNovo] = useState('')

  const classes = useStyles();
  const [permitido, setPermitido] = useState(false)
  const [gerandoRelPorProduto, setGerandoRelPorProduto] = useState(false)
  const [gerandoRelSaldos, setGerandoRelSaldos] = useState(false)
  const [gerandoRelConsumo, setGerandoRelConsumo] = useState(false)
  const [gerandoRelEntradas, setGerandoRelEntradas] = useState(false)
  const [gerandoRelPrestacoes, setGerandoRelPrestacoes] = useState(false)
  const [relEstoque, setRelEstoque] = useState('')
  const [historical, setHistorical] = useState(false);
  const [historical_pagar, setHistorical_pagar] = useState(false);
  const [historical_receber, setHistorical_receber] = useState(false);
  const [historical_pagas, setHistorical_pagas] = useState(false);
  

  const [dateHist, setDateHist] = useState('');
  const [dateHistPagar, setDateHistPagar] = useState('');

  const [dateHistReceber, setDateHistReceber] = useState('');
  const [dateHistPagas, setDateHistPagas] = useState('');
  

  useEffect(() => {
    let tempUser = getUser()
    setPrio(getPrio())

    async function fetchMyAPI() {
      await validateTokenUser(props)
      setPermitido(await checarPermissao('relatorios', props))

      //const response_rel_arm = await api2.get("/retornaRelComprasArmazenagem");
      //setCompArm(response_rel_arm.data)
    }
    fetchMyAPI();

  }, [])


  async function getRelEstoques() {
    const response_mov_estoques = await api2.get("/relMovEstoques", {
      params: {
        dataIni,
        dataFim,
        userId: getUser(),
        token: getToken()
      },
    });
    setMovEstoques(response_mov_estoques.data)
  }

  async function getFatAntecipadoRel() {
    setGenFat(true)
    const response_fat_antecipado = await api2.get("/FatAnticpadoRel", { params: { userId: getUser(), token: getToken() } });
    setFatAntecipado(response_fat_antecipado.data)
    setGenFat(false)
  }
  async function getContasReceberRel() {
    const responseContasReceber = await api2.get("/contasReceberRel", {
      params: {
        dataIniCR,
        dataFimCR,
        userId: getUser(),
        token: getToken(), hist: historical_receber === true ? 1 : 0, date_hist: dateHistReceber
      },
    });
    setRelContasReceber(responseContasReceber.data)
  }

  
  async function getContasPagasRel() {
 
    const response_contas_pagas = await api2.get("/contas_pagas_gen", { params: { 'data_ini':dataIniCPagas,
      'data_fim':dataFimCPagas, userId: getUser(), token: getToken() } });
    setContasPagasNovo(response_contas_pagas.data)
    
  }
  async function getContasPagarRel() {
    const responseContasPagar = await api2.get("/contasPagarRel", {
      params: {
        dataIniCP,
        dataFimCP,
        userId: getUser(),
        token: getToken(), hist: historical_pagar === true ? 1 : 0, date_hist: dateHistPagar
      },
    });
    setRelContasPagar(responseContasPagar.data)
  }

  async function getHistoricoEstoque() {
    const responseContasPagar = await api2.get("/returnHistoricoEstoque", {
      params: {
        dataIniEstoque,
        userId: getUser(),
        token: getToken()
      },
    });
    setRelEstoque(responseContasPagar.data)
  }

  async function getRelComissao() {
    const responseComissao = await api2.get("/relComissao", {
      params: {
        dataIniCom,
        dataFimCom,
        userId: getUser(),
        token: getToken()
      },
    });
    setRelComissao(responseComissao.data)
  }

  const armazenagem = () => {
    const data = compArm
    const fileName = 'Relatório de Armazenagem'
    const exportType = exportFromJSON.types.xls

    exportFromJSON({ data, fileName, exportType })
  }
  const contasPagar = () => {
    const data = relContasPagar
    const fileName = 'Relatório de Contas a Pagar'
    const exportType = exportFromJSON.types.xls

    exportFromJSON({ data, fileName, exportType })
  }
  const saldos = () => {
    const data = rel
    const fileName = 'Relatórios de Saldos'
    const exportType = exportFromJSON.types.xls

    exportFromJSON({ data, fileName, exportType })
  }

  const movEstoquesToXls = () => {
    const data = movEstoques
    const fileName = 'Relatórios de Movimentação de Estoques'
    const exportType = exportFromJSON.types.xls

    exportFromJSON({ data, fileName, exportType })
  }

  const consumoR = () => {
    const data = consumo
    const fileName = 'Relatório Consumo'
    const exportType = exportFromJSON.types.xls

    exportFromJSON({ data, fileName, exportType })
  }

  const estoqueR = () => {
    const data = relEstoque
    const fileName = 'Histórico Estoque'
    const exportType = exportFromJSON.types.xls

    exportFromJSON({ data, fileName, exportType })
  }
  const entradas = () => {
    const data = relEntradas
    const fileName = 'Relatório de entradas'
    const exportType = exportFromJSON.types.xls

    exportFromJSON({ data, fileName, exportType })
  }

  const contasPagas = () => {
    const data = contasPagasNovo;
    const fileName = 'Relatório de Contas Pagas';
    const exportType = exportFromJSON.types.xls;
  
    exportFromJSON({ data, fileName, exportType });
  };
  
  const contasReceber = () => {
    const data = relContasReceber
    const fileName = 'Relatório de contas a Receber'
    const exportType = exportFromJSON.types.xls

    exportFromJSON({ data, fileName, exportType })
  }

  const convertfatAntecipado = () => {
    const data = fatAntecipado
    const fileName = 'Relatório de Faturamento Antecipado'
    const exportType = exportFromJSON.types.xls

    exportFromJSON({ data, fileName, exportType })
  }
  const convertRelPrest = () => {
    const data = prest
    const fileName = 'Saldo de Pedidos de venda (prestações)'
    const exportType = exportFromJSON.types.xls

    exportFromJSON({ data, fileName, exportType })
  }
  const comissao = () => {
    const data = relComissao
    const fileName = 'Relatório de Comissão'
    const exportType = exportFromJSON.types.xls

    exportFromJSON({ data, fileName, exportType })
  }

  async function getRelConsumoPorProduto() {
    setGerandoRelPorProduto(true)
    const response = await api2.get('/relConsumoPorProduto', { params: { userId: getUser(), token: getToken() } })
    setRelConsumoPorProduto(response.data)
    setGerandoRelPorProduto(false)
  }

  async function getRelSaldos() {
    setGerandoRelSaldos(true)
    const response_rel = await api2.get("/retornaRelSaldos", {
      params: {
        prio: getPrio(),
        userId: getUser(),
        token: getToken()
      },
    });
    setRel(response_rel.data)
    setGerandoRelSaldos(false)
  }

  async function getRelConsumo() {
    setGerandoRelConsumo(true)
    const response_rel_consumo = await api2.get("/relConsumo", { params: { userId: getUser(), token: getToken() } });
    setConsumo(response_rel_consumo.data)
    setGerandoRelConsumo(false)
  }

  async function getRelEntradas() {
    setGerandoRelEntradas(true)
    const response_rel_entradas = await api2.get("/relNotasEntradas", { params: { userId: getUser(), token: getToken() } });
    setRelEntradas(response_rel_entradas.data)
    setGerandoRelEntradas(false)
  }

  async function getRelPrestacoes() {
    setGerandoRelPrestacoes(true)
    const response_prest = await api2.get("/relVendasPrestacoes", { params: { userId: getUser(), token: getToken(), hist: historical === true ? 1 : 0, date_hist: dateHist } });
    setPrest(response_prest.data)
    setGerandoRelPrestacoes(false)
  }

  function baixarRelConsumoPorProduto() {
    const data = relConsumoPorProduto
    const fileName = 'Relatório de Consumo por Produto'
    const exportType = exportFromJSON.types.xls

    exportFromJSON({ data, fileName, exportType })
  }

  const handleCheckboxChange = (event) => {
    setHistorical(event.target.checked);
    if (!event.target.checked) {
      setDateHist(''); // Clear the date if historical is unchecked
    }
  };
  const handleCheckboxChangePagar = (event) => {
    setHistorical_pagar(event.target.checked);
    if (!event.target.checked) {
      setDateHistPagar(''); // Clear the date if historical is unchecked
    }
  };
  const handleCheckboxChangeReceber = (event) => {
    setHistorical_receber(event.target.checked);
    if (!event.target.checked) {
      setDateHistReceber(''); // Clear the date if historical is unchecked
    }
  };
  const handleCheckboxChangePagas = (event) => {
    setHistorical_pagas(event.target.checked);
    if (!event.target.checked) {
      setDateHistPagas(''); // Clear the date if historical is unchecked
    }
  };
  return (
    permitido === true ?
      <div>
        <div style={{ background: 'white', padding: 10, borderRadius: 4, boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px" }}>
          <div style={{ fontSize: 20, fontWeight: 'bold', marginLeft: 5, marginTop: 10, marginBottom: 10 }}>Relatórios</div>

          <div style={{ display: 'flex', flexDirection: 'row', fontFamily: 'Arial' }}>
            <div style={{ border: '1px solid rgba(0, 0, 0, .4)', borderRadius: 4, width: 300, padding: 10, margin: 5, maxHeight: 48 }}>
              <div>Relatório de Saldos</div>
              <div style={{ display: "flex", flexDirection: "row" }}>
                {rel === '' && !gerandoRelSaldos ? <button onClick={() => getRelSaldos()}>Gerar Relatório</button> : gerandoRelSaldos ?
                  <div style={{ display: 'flex', flexDirection: 'row' }}><Loader
                    type="Circles"
                    color="#00BFFF"
                    height={20}
                    width={20}
                    timeout={200000} //3 secs
                  />&nbsp;Gerando...</div> : <a>
                    <button onClick={() => saldos()}> Baixar Relatório de Saldos</button>
                  </a>}
              </div>
            </div>

            <div style={{ border: '1px solid rgba(0, 0, 0, .4)', borderRadius: 4, width: 300, padding: 10, margin: 5, maxHeight: 48 }}>
              <div>Relatório de Consumo</div>
              <div>
                {consumo === '' && !gerandoRelConsumo ? <button onClick={() => getRelConsumo()}>Gerar Relatório</button> : gerandoRelConsumo ?
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <Loader
                      type="Circles"
                      color="#00BFFF"
                      height={20}
                      width={20}
                      timeout={200000} //3 secs
                    /> &nbsp;Gerando...</div> : <a>
                    <button onClick={() => consumoR()}> Baixar Relatório de Consumo</button>
                  </a>}
              </div>
            </div>

            <div style={{ border: '1px solid rgba(0, 0, 0, .4)', borderRadius: 4, width: 300, padding: 10, margin: 5, maxHeight: 48 }}>
              <div>Relatório de Notas Fiscais de Entradas</div>
              <div>
                {relEntradas === '' && !gerandoRelEntradas ? <button onClick={() => getRelEntradas()}>Gerar Relatório</button> : gerandoRelEntradas ?
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <Loader
                      type="Circles"
                      color="#00BFFF"
                      height={20}
                      width={20}
                      timeout={200000} //3 secs
                    /> &nbsp;Gerando...</div> : <a>
                    <button onClick={() => entradas()}> Baixar Relatório de notas fiscais de Entradas</button>
                  </a>}
              </div>
            </div>
          </div>

          <div style={{ display: 'flex', flexDirection: 'row', fontFamily: 'Arial', marginBottom: 20 }}>
            <div style={{ border: '1px solid rgba(0, 0, 0, .4)', borderRadius: 4, width: 300, padding: 10, margin: 5, maxHeight: 48 }}>
              <div>Relatório de Consumo por Produto</div>
              <div>
                {relConsumoPorProduto === '' && !gerandoRelPorProduto ?
                  <button onClick={() => getRelConsumoPorProduto()}>Gerar Relatório</button> : gerandoRelPorProduto ?
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <Loader
                        type="Circles"
                        color="#00BFFF"
                        height={20}
                        width={20}
                        timeout={200000} //3 secs
                      /> &nbsp;Gerando...</div>
                    : <button onClick={() => baixarRelConsumoPorProduto()}> Baixar Relatório de Consumo por Produto</button>}
              </div>
            </div>


            <div style={{ border: '1px solid rgba(0, 0, 0, .4)', borderRadius: 4, width: 300, padding: 10, margin: 5, maxHeight: 48 }}>
              <div>Relatório de Faturamento Antecipado</div>
              <div style={{ display: "flex", flexDirection: "row" }}>
                {fatAntecipado === '' && !genFat ? <button onClick={() => getFatAntecipadoRel()}>Gerar relatório</button> : genFat ?
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <Loader
                      type="Circles"
                      color="#00BFFF"
                      height={20}
                      width={20}
                      timeout={2000000} //3 secs
                    />&nbsp;Gerando...
                  </div>
                  :
                  <a>
                    <button onClick={() => convertfatAntecipado()}> Baixar Relatório de faturamento Antecipado</button>
                  </a>}
              </div>
            </div>


            <div style={{ border: '1px solid rgba(0, 0, 0, .4)', borderRadius: 4, width: 300, padding: 10, margin: 5 }}>
              <div>Relatório de Saldo de Pedidos de Venda (prestações)</div>
              <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginBottom: 10 }}>
                <input
                  type="checkbox"
                  checked={historical}
                  onChange={handleCheckboxChange}
                /> <span>Histórico</span>
              </div>
              {historical && (
                <div style={{ marginBottom: 10 }}>
                  <input
                    type="date"
                    value={dateHist}
                    onChange={(e) => setDateHist(e.target.value)}
                    placeholder="YYYY-MM-DD"
                  />
                </div>
              )}
              <div style={{ display: "flex", flexDirection: "row" }}>
                {prest === '' && !gerandoRelPrestacoes ? <button onClick={getRelPrestacoes}>Gerar Relatório</button>
                  : gerandoRelPrestacoes ?
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      {/* Loader Component */}
                      &nbsp;Gerando...</div> : <a>
                      <button onClick={convertRelPrest}> Baixar Relatório de Saldo de Pedidos de Venda (prestações)</button>
                    </a>}
              </div>
            </div>
          </div>
        </div>

        <Card>
          <CardHeader style={{ backgroundColor: '#6699ff' }}>
            <h4 className={classes.cardTitleWhite}>Relatório Movimentação de Estoques</h4>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem>
                <TextField
                  id="dateIni"
                  label="Inciio"
                  type="date"
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => setDataIni(e.target.value)}
                />
              </GridItem>
              <GridItem>
                <TextField
                  id="dateEnd"
                  label="Fim"
                  type="date"
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => setDataFim(e.target.value)}
                />
              </GridItem>
              <GridItem>
                <Button onClick={() => getRelEstoques()} style={{ backgroundColor: '#6699ff' }}>
                  Buscar
                </Button>
              </GridItem>
            </GridContainer>
            <div style={{ marginTop: 8 }}>
              {movEstoques !== '' ? <a>
                <button onClick={() => movEstoquesToXls()}> Baixar Relatório de Movimentação de Estoques</button>
              </a> : <Loader
                type="Circles"
                color="#00BFFF"
                height={20}
                width={20}
                timeout={2000000}
              />}
            </div>
          </CardBody>

        </Card>

        <Card>
          <CardHeader style={{ backgroundColor: '#6699ff' }}>
            <h4 className={classes.cardTitleWhite}>Relatório Contas a Receber</h4>
          </CardHeader>
          <CardBody>

            <GridContainer>
              <GridItem>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginBottom: 10 }}>
                  <input
                    type="checkbox"
                    checked={historical_receber}
                    onChange={handleCheckboxChangeReceber}
                  /> <span>Histórico</span>
                </div>
                {historical_receber && (
                  <div style={{ marginBottom: 10 }}>
                    <input
                      type="date"
                      value={dateHistReceber}
                      onChange={(e) => setDateHistReceber(e.target.value)}
                      placeholder="YYYY-MM-DD"
                    />
                  </div>
                )}
              </GridItem>
              <GridItem>
                <TextField
                  id="dateIni"
                  label="Início"
                  type="date"
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => setDataIniCR(e.target.value)}
                />
              </GridItem>
              <GridItem>
                <TextField
                  id="dateEnd"
                  label="Fim"
                  type="date"
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => setDataFimCR(e.target.value)}
                />
              </GridItem>
              <GridItem>
                <Button onClick={() => getContasReceberRel()} style={{ backgroundColor: '#6699ff' }}>
                  Buscar
                </Button>
              </GridItem>

            </GridContainer>
            <div style={{ marginTop: 8 }}>
              {relContasReceber !== '' ? <a>
                <button onClick={() => contasReceber()}> Baixar Relatório de Contas a Receber</button>
              </a> : <Loader
                type="Circles"
                color="#00BFFF"
                height={20}
                width={20}
                timeout={2000000}
              />}
            </div>

          </CardBody>


        </Card>

        <Card>
          <CardHeader style={{ backgroundColor: '#35B568' }}>
            <h4 className={classes.cardTitleWhite}>Relatório Contas Pagas — 💲Financeiro</h4>
          </CardHeader>
          <CardBody>

            <GridContainer>
              <GridItem>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginBottom: 10 }}>
                  <input
                    type="checkbox"
                    checked={historical_pagas}
                    onChange={handleCheckboxChangePagas}
                  /> <span>Histórico</span>
                </div>
                {historical_pagas && (
                  <div style={{ marginBottom: 10 }}>
                    <input
                      type="date"
                      value={dateHistPagas}
                      onChange={(e) => setDateHistPagas(e.target.value)}
                      placeholder="YYYY-MM-DD"
                    />
                  </div>
                )}
              </GridItem>
              <GridItem>
                <TextField
                  id="dateIniContasPagas"
                  label="Início"
                  type="date"
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => setDataIniCPagas(e.target.value)}
                />
              </GridItem>
              <GridItem>
                <TextField
                  id="dateEndContasPagas"
                  label="Fim"
                  type="date"
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => setDataFimCPagas(e.target.value)}
                />
              </GridItem>
              <GridItem>
                <Button onClick={() => getContasPagasRel()} style={{ backgroundColor: '#35B568' }}>
                  Buscar
                </Button>
              </GridItem>

            </GridContainer>
                <div style={{ marginTop: 8 }}>
                  {contasPagasNovo !== '' ? <a>
                    <button onClick={() => contasPagas()}> Baixar Relatório de Contas Pagas</button>
                  </a> : <Loader
                    type="Circles"
                    color="#0D6642"
                    height={20}
                    width={20}
                    timeout={2000000}
                  />}
                </div>
          </CardBody>
        </Card>

        <Card>
          <CardHeader style={{ backgroundColor: '#6699ff' }}>
            <h4 className={classes.cardTitleWhite}>Relatório Contas a Pagar</h4>
          </CardHeader>
          <CardBody>

            <GridContainer>
              <GridItem>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginBottom: 10 }}>
                  <input
                    type="checkbox"
                    checked={historical_pagar}
                    onChange={handleCheckboxChangePagar}
                  /> <span>Histórico</span>
                </div>
                {historical_pagar && (
                  <div style={{ marginBottom: 10 }}>
                    <input
                      type="date"
                      value={dateHistPagar}
                      onChange={(e) => setDateHistPagar(e.target.value)}
                      placeholder="YYYY-MM-DD"
                    />
                  </div>
                )}
              </GridItem>
              <GridItem>
                <TextField
                  id="dateIni"
                  label="Início"
                  type="date"
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => setDataIniCP(e.target.value)}
                />
              </GridItem>
              <GridItem>
                <TextField
                  id="dateEnd"
                  label="Fim"
                  type="date"
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => setDataFimCP(e.target.value)}
                />
              </GridItem>
              <GridItem>
                <Button onClick={() => getContasPagarRel()} style={{ backgroundColor: '#6699ff' }}>
                  Buscar
                </Button>
              </GridItem>
            </GridContainer>

            <div style={{ marginTop: 8 }}>
              {relContasPagar !== '' ? <a>
                <button onClick={() => contasPagar()}> Baixar Relatório de Contas a Pagar</button>
              </a> : <Loader
                type="Circles"
                color="#00BFFF"
                height={20}
                width={20}
                timeout={2000000}
              />}
            </div>

          </CardBody>

        </Card>
        <Card>
          <CardHeader style={{ backgroundColor: '#6699ff' }}>
            <h4 className={classes.cardTitleWhite}>Histórico Estoque {">"} 15/01/2022</h4>

          </CardHeader>
          <p style={{ paddingLeft: 10 }}>  *Caso não seja informado filtro de data, o sistema vai gerar os valores de estoque atuais</p>
          <CardBody>

            <GridContainer>
              <GridItem>
                <TextField
                  id="dateIni"
                  label="Início"
                  type="date"
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => setdataIniEstoque(e.target.value)}
                />
              </GridItem>

              <GridItem>
                <Button onClick={() => getHistoricoEstoque()} style={{ backgroundColor: '#6699ff' }}>
                  Buscar
                </Button>
              </GridItem>
            </GridContainer>

            <div style={{ marginTop: 8 }}>
              {relEstoque !== '' ? <a>
                <button onClick={() => estoqueR()}> Baixar Histórico Estoque </button>
              </a> : <Loader
                type="Circles"
                color="#00BFFF"
                height={20}
                width={20}
                timeout={2000000}
              />}
            </div>

          </CardBody>


        </Card>



        <Card>
          <GridItem>

          </GridItem>
        </Card>

        <Card>
          <CardHeader style={{ backgroundColor: '#6699ff' }}>
            <h4 className={classes.cardTitleWhite}>Relatório de Comissão</h4>
          </CardHeader>
          <CardBody>

            <GridContainer>
              <GridItem>
                <TextField
                  id="dateIni"
                  label="Início"
                  type="date"
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => setDataIniCom(e.target.value)}
                />
              </GridItem>
              <GridItem>
                <TextField
                  id="dateEnd"
                  label="Fim"
                  type="date"
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => setDataFimCom(e.target.value)}
                />
              </GridItem>
              <GridItem>
                <Button onClick={() => getRelComissao()} style={{ backgroundColor: '#6699ff' }}>
                  Buscar
                </Button>
              </GridItem>
            </GridContainer>
            <div style={{ marginTop: 8 }}>
              {relComissao !== '' ? <a>
                <button onClick={() => comissao()}> Baixar Relatório de Comissão</button>
              </a> : <Loader
                type="Circles"
                color="#00BFFF"
                height={20}
                width={20}
                timeout={2000000}
              />}
            </div>
          </CardBody>


        </Card>
      </div> : <></>
  );
}
function mapStateToProps(state) {
  return {
    token: state.token,
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setToken: setToken,
      setUser: setUser,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Relatorios);
