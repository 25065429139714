import axios from 'axios';
let link = window.location.href
let url = ''
if (link.includes('http://localhost:3000/')) {
  url = 'https://back.macs.dev.br:5002/'
}
else {
  url = 'https://back.macs.dev.br:5002/'
}

const api = axios.create({
  baseURL: url
});
export default api;
