import React, { useEffect, useState } from "react";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { setToken } from "../../flux/actions/tokenIndex";
import { setUser } from "../../flux/actions/userIndex";
import api from "../../services/api";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { TextField } from "@material-ui/core";
import { checarPermissao, validateTokenUser, getUser, getToken } from '../../utils/utils.js'
import exportFromJSON from "export-from-json";

import Loader from "react-loader-spinner";
import Autocomplete from "@material-ui/lab/Autocomplete";






function EstadosPedidos(props) {
    const [pedidos, setPedidos] = useState('')
    const [auxPedidos, setAuxPedidos] = useState('')
    const [wait, setWait] = useState(false)
    const [permitido, setPermitido] = useState(false)
    const [update, setUpdate] = useState([])
    const [filtroTxt, setFiltroTxt] = useState('')
    const [filtroCol, setFiltroCol] = useState('')
    const [pedidos2, setPedidos2] = useState('')
    const [auxPedidos2, setAuxPedidos2] = useState('')


    useEffect(() => {
        async function fetchMyAPI() {
            await validateTokenUser(props)
            setPermitido(await checarPermissao('estados_pedidos', props))

            const response_pedidos = await api.get("/return_status_pedidos", { params: { userId: getUser(), token: getToken() } });

            const filter = response_pedidos.data.filter(item => item.status !== 'Finalizado' && item.status !== 'Saldo Restante')
            setPedidos(filter)
            setAuxPedidos(filter.slice(0, 30))
            setPedidos2(response_pedidos.data.filter(item => item.status === 'Finalizado' || item.status === 'Saldo Restante'))
            setAuxPedidos2(response_pedidos.data.filter(item => item.status === 'Finalizado' || item.status === 'Saldo Restante').slice(0, 30))
        }

        fetchMyAPI();
    }, []);

    const handleDownload = async (numPedido) => {
        try {
            const response = await api.get("/retorna_excel_contrato_venda", {
                params: { numPedido, userId: getUser(), token: getToken() },
                responseType: 'blob'  // Important for handling binary files
            });

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `pedido${numPedido}.pdf`);  // Define your desired file name here
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

        } catch (error) {
            console.error("Error downloading the file:", error);
        }
    };

    function baixarCarteira() {
        if (pedidos === '') { return }
        const data = pedidos.concat(pedidos2)

        const fileName = 'RelCarteira'
        const exportType = exportFromJSON.types.xls

        exportFromJSON({ data, fileName, exportType })
    }
    async function updatePedidos(value, doc, cod, index) {
        console.log(index)
        const response_pedidos = await api.get("/alter_status_pedido", { params: { value, doc, cod, index, userId: getUser(), token: getToken() } });

    }

    const handleFiltro = (coluna_filtro, valor_filtro) => {

        if (coluna_filtro === '' || valor_filtro === '') { return }


        valor_filtro = valor_filtro.toLowerCase().trim()
        let filtro1 = pedidos.filter(e => e[coluna_filtro].toString().toLowerCase().trim().includes(valor_filtro))
        let filtro2 = pedidos2.filter(e => e[coluna_filtro].toString().toLowerCase().trim().includes(valor_filtro))

        setAuxPedidos(filtro1)
        setAuxPedidos2(filtro2)
    };


    function clearFiltro() {
        setFiltroTxt('')
        setAuxPedidos(pedidos.slice(0, 30))
        setAuxPedidos2(pedidos2)
    }

    const filtroColOptions = [
        { label: 'Status', value: 'status' },
        { label: 'Pedido', value: 'DocPedido' },
        { label: 'Produto', value: 'CodItem' },
        { label: 'Cliente', value: 'Cliente' }]

    return (
        permitido === true ?
            <div>

                <h4>Pedidos</h4>
                {pedidos !== '' ? <button onClick={() => baixarCarteira()}> Baixar Relatório de Carteira</button> : <Loader
                    type="Circles"
                    color="#00BFFF"
                    height={20}
                    width={20}
                    timeout={2000000} //3 secs
                />}

                <div style={{ marginTop: 10, marginBottom: 10, display: 'flex', flexDirection: 'row', alignContent: 'center', alignItems: 'center' }}>
                    <TextField
                        style={{ width: 350 }}
                        value={filtroTxt}
                        onChange={(e) => e.target.value !== '' ? setFiltroTxt(e.target.value) : setFiltroTxt('')}
                        multiline
                        size='small'
                        label='Filtro'
                        variant="outlined" />

                    <Autocomplete
                        style={{ width: 200 }}
                        options={filtroColOptions}
                        getOptionLabel={(option) => option.label}
                        getOptionSelected={(option, value) => option.label === value.label}
                        onChange={(e, v) => v === null ? setFiltroCol('') : setFiltroCol(v.value)}
                        renderInput={(params) => <TextField {...params} variant='outlined' size="small" label="Coluna" />}
                    />

                    <div style={{ marginLeft: 10 }}><button onClick={() => handleFiltro(filtroCol, filtroTxt)}>Pesquisar</button> </div>
                    <div style={{ marginLeft: 5 }}><button onClick={() => clearFiltro()}>Limpar Filtro</button></div>
                </div>

                {/* primeira lista */}
                <div style={{ overflow: 'auto', maxHeight: 500, background: 'white', padding: '20px 10px 10px 10px', borderRadius: 5 }}>

                    {pedidos !== '' ?

                        Object.keys(auxPedidos).map((index) =>

                            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', marginBottom: 10 }} key={index}>


                                <Accordion style={{ width: '100%', border: '1px solid rgba(0, 0, 0, .2)' }}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id={index}>

                                        <Typography component={'span'} style={{ fontSize: 16, width: 200 }}>Numero Pedido:{auxPedidos[index].DocPedido}</Typography>
                                        <Typography component={'span'} style={{ marginLeft: 20, fontSize: 16, width: 500 }}>Produto:{auxPedidos[index].CodItem}</Typography>
                                        <Typography component={'span'} style={{ marginLeft: 20, fontSize: 16, width: 200 }}>Estado:{auxPedidos[index].status}</Typography>
                                       

                                    </AccordionSummary>
                                    <div style={{ border: "1px solid", margin: 10 }}>
                                        <AccordionDetails style={{ display: 'flex', flexDirection: 'column' }}>
                                            <Typography component={'span'} style={{ fontSize: 16 }}>Cliente:{auxPedidos[index].Cliente}</Typography>
                                            <Typography component={'span'} style={{ fontSize: 16 }}>Vendedor:{auxPedidos[index].VendedorPedido}</Typography>
                                            <Typography component={'span'} style={{ fontSize: 16 }}>Supervisor:{auxPedidos[index].SupervisorPedido}</Typography>
                                            <Typography component={'span'} style={{ fontSize: 16 }}>Quantidade Pedido:{auxPedidos[index].QtdPedido}</Typography>
                                            <Typography component={'span'} style={{ fontSize: 16 }}>Origem:{auxPedidos[index].Origem}</Typography>
                                            <Typography component={'span'} style={{ fontSize: 16 }}>Total Parcialmente liberado para logística:{auxPedidos[index].liberad_log}</Typography>

                                            <Typography component={'span'} style={{ fontSize: 16 }}>Carteira:{auxPedidos[index].Carteira}</Typography>

                                            <Typography component={'span'}>
                                                <Autocomplete
                                                    id="combo-box-demo"
                                                    options={['Aguardando Assinatura', 'Aguardando Produto',
                                                        'Aguardando Liberação do Cliente', 'Aguardando Pagamento', 'Liberado para logística',
                                                        'Saldo Restante', 'Aguardando Retirada do Produto (FOB)','Aguardando Liberação do Crédito',
                                                        'Liberado Parcialmente Logística', 'Empréstimo', 'Aguardando Desmembramento', 'Finalizado', 'Carteira 2024']}


                                                    style={{ width: 500, marginTop: 25 }}
                                                    onChange={(e, v) => updatePedidos(v, auxPedidos[index].DocPedido, auxPedidos[index].CodItem, 0)}
                                                    getOptionSelected={(option, value) => option === value || value === ''}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label='Estado pedido'
                                                        />
                                                    )}
                                                />
                                            </Typography>
                                            
                                            {auxPedidos[index].cod == 9 ?
                                                <Typography component={'span'}>
                                                    <TextField
                                                        type="number"
                                                        label="Total liberado parcialmente para logistica"
                                                        style={{ width: 500, marginTop: 25 }}
                                                        onChange={(e, v) => updatePedidos(auxPedidos[index].cod, auxPedidos[index].DocPedido, auxPedidos[index].CodItem, e.target.value)}

                                                    // You can set initial value or other props if needed
                                                    />
                                                </Typography>
                                                : null}
                                        </AccordionDetails>


                                        {auxPedidos[index].numPedidoSAP !== 0 ? <Typography component={'span'} style={{ marginLeft: 20, fontSize: 16, width: 200 }}> <button onClick={() => handleDownload(auxPedidos[index].DocPedido)}>Download Contrato Atualizado</button></Typography> : null}

                                    </div>

                                </Accordion>
                            </div>)

                        : <Loader
                            type="Circles"
                            color="#00BFFF"
                            height={20}
                            width={20}
                            timeout={2000000} //3 secs
                        />}
                </div>

                {/* segunda lista */}
                <div style={{ overflow: 'auto', marginTop: 20, maxHeight: 600, background: 'white', padding: 10, borderRadius: 5 }}>
                    <div style={{ fontSize: 20, margin: 10 }}>Pedidos "Finalizados" ou "Saldo Restante"</div>
                    {auxPedidos2 !== '' ?

                        Object.keys(auxPedidos2).map((index) =>

                            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', marginBottom: 10 }} key={index}>


                                <Accordion style={{ width: '100%', border: '1px solid rgba(0, 0, 0, .2)' }}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id={index}>

                                        <Typography component={'span'} style={{ fontSize: 16, width: 200 }}>Numero Pedido:{auxPedidos2[index].DocPedido}</Typography>
                                        <Typography component={'span'} style={{ marginLeft: 20, fontSize: 16, width: 500 }}>Produto:{auxPedidos2[index].CodItem}</Typography>
                                        <Typography component={'span'} style={{ marginLeft: 20, fontSize: 16, width: 200 }}>Estado:{auxPedidos2[index].status}</Typography>

                                    </AccordionSummary>
                                    <div style={{ border: "1px solid", margin: 10 }}>
                                        <AccordionDetails style={{ display: 'flex', flexDirection: 'column' }}>
                                            <Typography component={'span'} style={{ fontSize: 16 }}>Cliente:{auxPedidos2[index].Cliente}</Typography>
                                            <Typography component={'span'} style={{ fontSize: 16 }}>Vendedor:{auxPedidos2[index].VendedorPedido}</Typography>
                                            <Typography component={'span'} style={{ fontSize: 16 }}>Supervisor:{auxPedidos2[index].SupervisorPedido}</Typography>
                                            <Typography component={'span'} style={{ fontSize: 16 }}>Carteira:{auxPedidos2[index].Carteira}</Typography>
                                            <Typography component={'span'} style={{ fontSize: 16 }}>Origem:{auxPedidos2[index].Origem}</Typography>

                                            <Typography component={'span'}>
                                                <Autocomplete
                                                    id="combo-box-demo"
                                                    options={['Aguardando Assinatura', 'Aguardando Produto',
                                                        'Aguardando Liberação do Cliente', 'Aguardando Pagamento', 'Liberado para logística','Aguardando Liberação do Crédito',
                                                        'Saldo Restante', 'Aguardando Retirada do Produto (FOB)',
                                                        'Liberado Parcialmente Logística', 'Empréstimo', 'Aguardando Desmembramento', 'Finalizado','Carteira 2024']}


                                                    style={{ width: 500, marginTop: 25 }}
                                                    onChange={(e, v) => updatePedidos(v, auxPedidos2[index].DocPedido, auxPedidos2[index].CodItem, 0)}
                                                    getOptionSelected={(option, value) => option === value || value === ''}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label='Estado pedido'
                                                        />
                                                    )}
                                                />
                                            </Typography>


                                        </AccordionDetails>



                                    </div>

                                </Accordion>
                            </div>)

                        : <Loader
                            type="Circles"
                            color="#00BFFF"
                            height={20}
                            width={20}
                            timeout={2000000} //3 secs
                        />}
                </div>
            </div> : <></>
    );
}
function mapStateToProps(state) {
    return {
        token: state.token,
        user: state.user,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            setToken: setToken,
            setUser: setUser,
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(EstadosPedidos);
