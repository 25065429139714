import React, { useState, useEffect } from 'react';
import { AppBar, Tabs, Tab, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, makeStyles, Typography } from '@material-ui/core';
import api from 'services/api';
const months = ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'];

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}

const TabelaInferiorMetas = ({ refreshMessageTableInferior }) => {
    const metasOptions = ['Média', 'Incremental', 'Quantitativa']

    const [tabIndex, setTabIndex] = useState(0);
    const [data, setData] = useState({})
    const keys = Object.keys(data);
    const classes = useStyles()
    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue);
    }

    useEffect(() => {
        fetchApi()
    }, [refreshMessageTableInferior])

    const fetchApi = async () => {
        const response = await api.get('/return_metas_by_setor')
        setData(response.data)
        console.log(response.data)
    }

    return (
        <div>
            <AppBar position="static" className={classes.appBar}>
                <Tabs
                    value={tabIndex}
                    onChange={handleTabChange}
                    scrollButtons="auto"
                    variant="scrollable"
                >
                    {keys.map((key, index) => (
                        <Tab key={index} label={key} />
                    ))}
                </Tabs>
            </AppBar>
            {keys.map((key, index) => (
                <TabPanel value={tabIndex} index={index} >
                    {Object.entries(data[key]).map(([nomeSetor, setor]) => (
                        <div style={{ marginTop: 30 }}>
                            <Typography style={{ fontWeight: 'bold' }}>{nomeSetor.toUpperCase()}</Typography>
                            <TableContainer component={Paper} key={nomeSetor}>
                                <Table className={classes.table} size='small'>
                                    <TableHead className={classes.tableHeader}>
                                        <TableRow>
                                            <TableCell className={classes.headerCell} style={{ width: '50%', borderRight: '1px solid lightgray' }}>Meta</TableCell>
                                            <TableCell className={classes.headerCell} style={{ borderRight: '1px solid lightgray' }}>Peso</TableCell>
                                            <TableCell className={classes.headerCell} style={{ width: 110, borderRight: '1px solid lightgray' }}>Tipo</TableCell>

                                            {/* {months.map(month => (
                                                <TableCell key={month} className={classes.headerCell} style={{ borderRight: '1px solid lightgray' }}>{month}</TableCell>
                                            ))} */}
                                            <TableCell className={classes.headerCell}>Total</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {setor.metas.map(record => (
                                            <TableRow key={record.id_meta}>
                                                <TableCell component="th" scope="row">{record.nome_meta}</TableCell>
                                                <TableCell component="th" scope="row" style={{ borderRight: '1px solid lightgray' }}>{record.peso}</TableCell>
                                                <TableCell component="th" scope="row" style={{ borderRight: '1px solid lightgray' }}>{metasOptions[record.tipo_meta]} {metasOptions[record.tipo_meta] === 'Quantitativa' ? '=' + (record.total / 100 * record.valor_meta).toFixed(2) + '/' + record.valor_meta : null}</TableCell>
                                                {/* {months.map(month => (
                                                    <TableCell key={month} style={{ borderRight: '1px solid lightgray' }}>{record[month]}</TableCell>
                                                ))} */}
                                                <TableCell>{record.total}</TableCell>
                                            </TableRow>
                                        ))}

                                    </TableBody>
                                </Table>
                            </TableContainer>

                        </div>
                    ))}
                </TabPanel>
            ))}
        </div>
    );
}

const useStyles = makeStyles({
    appBar: {
        backgroundColor: 'white',
        color: 'black'
    },
    table: {
    },
    tableHeader: {
        backgroundColor: '#1976D2',
    },
    headerCell: {
        color: '#fff',
        fontWeight: 'bold',
    },
});


export default TabelaInferiorMetas;
