import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { setToken } from "../../flux/actions/tokenIndex";
import { setUser } from "../../flux/actions/userIndex";
import api2 from "../../services/api2";
import { makeStyles } from "@material-ui/core/styles";
import { TextField } from "@material-ui/core";
import { useTimer } from 'react-timer-hook';
import {
    checarPermissao,
    validateTokenUser, getUser, getToken
} from '../../utils/utils.js'


function MyTimer({ expiryTimestamp }) {
    const classes = useStyles()
    const {
        seconds,
        minutes,
        hours,
        days,
        isRunning,
        start,
        pause,
        resume,
        restart,
    } = useTimer({ expiryTimestamp, onExpire: () => console.warn('onExpire called') });

    return (
        <div style={{ display: 'flex', flexDirection: 'row', position: 'relative' }}>
            <div style={{ fontSize: '15px' }}>
                Timer: <span>{days}</span>:<span>{hours}</span>:<span>{minutes}</span>:<span>{seconds}</span>
            </div>

            {isRunning ? <div className={classes.green}></div> : <div className={classes.red}></div>}

            {/* <button onClick={start}>Start</button>
        <button onClick={pause}>Pause</button>
        <button onClick={resume}>Resume</button>
        <button onClick={() => {
          // Restarts to 5 minutes timer
          const time = new Date();
          time.setSeconds(time.getSeconds() + 300);
          restart(time)
        }}>Restart</button> */}
        </div >
    );
}

function MonitorCarregamentos(props) {
    // Valores pra criar publicação
    const [carregamentos, setCarregamentos] = useState('')
    const [permitido, setPermitido] = useState('')
    const [placa, setPlaca] = useState('')
    const [operando, setOperando] = useState(false)
    const classes = useStyles()
    useEffect(() => {
        async function fetchMyApi() {
            await validateTokenUser(props)
            setPermitido(await checarPermissao('monitorCarregamentos', props))
            const response_placas = await api2.get("/returnPlacasMonitoramento", { params: { userId: getUser(), token: getToken() } });
            setCarregamentos(response_placas.data)
            console.log(response_placas.data)
        }
        fetchMyApi()


    }, [])


    async function addPlacas(placa) {

        const response_placa = await api2.get('/inserePlacaMonitoramento', {
            params: {
                placa,
                userId: getUser(),
                token: getToken()
            },
        });
        if (response_placa.data === 'NOK') {
            alert('Erro ao adicionar placas')
        }
        else {
            alert('Placa adicionada com sucesso')
            const response_placas = await api2.get("/returnPlacasMonitoramento", { params: { userId: getUser(), token: getToken() } });
            setCarregamentos(response_placas.data)
            setPlaca('')
        }
        setOperando(false)
    }

    async function removePlaca(numeroId) {
        setOperando(true)
        const response_placa = await api2.get('/removePlacaMonitoramento', {
            params: {
                numeroId,
                userId: getUser(),
                token: getToken()
            },
        });
        if (response_placa.data === 'NOK') {
            alert('Erro ao remover placa')
        }
        else {
            alert('Placa removida com sucesso')
            const response_placas = await api2.get("/returnPlacasMonitoramento", { params: { userId: getUser(), token: getToken() } });
            setCarregamentos(response_placas.data)

        }

        setOperando(false)
    }
    async function updatePlaca(ident) {
        setOperando(true)
        const response_placa = await api2.get('/updatePlacaMonitoramento', {
            params: {
                ident,
                userId: getUser(),
                token: getToken()
            },
        });
        if (response_placa.data === 'NOK') {
            alert('Erro ao atualizar placa')
        }
        else {
            alert('Placa atualizada com sucesso')
            const response_placas = await api2.get("/returnPlacasMonitoramento", { params: { userId: getUser(), token: getToken() } });
            setCarregamentos(response_placas.data)
        }
        setOperando(false)
    }
    setInterval(function () {
        const response_placas = api2.get("/returnPlacasMonitoramento", { params: { userId: getUser(), token: getToken() } });
        setCarregamentos(response_placas.data)
    }, 15 * 1000);


    return (
        permitido === true ?
            <div >
                <div className={classes.titulo}><h3>Segurança Patrimonial - Gen Fertilizantes</h3></div>

                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div className={classes.divPrincipal}>

                        <div>
                            {carregamentos !== '' ?
                                Object.keys(carregamentos).map((index, item) =>
                                    carregamentos[index].retorno === 0 ?
                                        <div className={classes.carregamentos} key={carregamentos[index].ID}>
                                            <div>
                                                Placa : {carregamentos[index].placa}
                                            </div>

                                            Adicionada: {new Date(carregamentos[index].datahora).toLocaleString('pt-br')}

                                            <MyTimer expiryTimestamp={new Date(Date.parse(carregamentos[index].datahora) + 10 * 60000)} />

                                            <button disabled={operando} onClick={() => updatePlaca(carregamentos[index].ID)}>Atualizar status placa</button>
                                        </div> : null

                                ) : <div></div>}
                        </div>

                    </div>

                    <div className={classes.divAdd}>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            fullWidth
                            value={placa}
                            onChange={(e) => setPlaca(e.target.value)}
                            multiline
                        />

                        <button disabled={operando} onClick={() => addPlacas(placa)}> Adicionar Placa </button>
                    </div>
                </div>
                <div className={classes.titulo}><h3>Placas que já retornam</h3></div>
                <div className={classes.divPrincipal} style={{ width: 500 }}>

                    {carregamentos !== '' ?
                        Object.keys(carregamentos).map((index, item) =>
                            carregamentos[index].retorno === 1 ?
                                <div className={classes.carregamentos} key={carregamentos[index].ID}>
                                    <div>
                                        Placa : {carregamentos[index].placa}
                                    </div>
                                    <div>
                                        Adicionada: {new Date(carregamentos[index].datahora).toLocaleString('pt-br')}
                                    </div>
                                    Retornou: {new Date(carregamentos[index].datahoraRetorno).toLocaleString('pt-br')}
                                    <div style={{ display: 'flex', flexDirection: 'row', position: 'relative' }}>
                                        <div className={classes.green}></div>
                                    </div>
                                    <button disabled={operando} onClick={() => removePlaca(carregamentos[index].ID)}>Remover Placa</button>
                                </div> : null

                        ) : <div></div>}

                </div>

            </div> : <></>
    )
}
const useStyles = makeStyles({
    titulo: {
        marginLeft: 10
    },

    divPrincipal: {
        overflow: 'auto',
        width: 500,
        marginLeft: 10,
        height: 500,
        background: 'lightGrey',
        padding: 10,
        borderRadius: 6,
        border: '1px solid rgba(0, 0, 0, .4)'
    },

    placas: {
        marginTop: 5,
        border: '1px solid rgba(0, 0, 0, .5)',
        borderRadius: 8,
        background: 'white',
        padding: 5
    },

    divAdd: {
        width: 300,
        height: 100,
        background: '#ebebeb',
        border: '1px solid rgba(0, 0, 0, .4)',
        borderRadius: 8,
        padding: 10,
        marginLeft: 10,
    },

    red: {
        border: '2px solid #d40b0b',
        width: 18,
        height: 18,
        background: '#fc0f0f',
        borderRadius: '50%',
        position: 'absolute',
        top: -20,
        right: 0
    },

    green: {
        border: '2px solid #04bf1d',
        width: 18,
        height: 18,
        background: '#00fa21',
        borderRadius: '50%',
        position: 'absolute',
        top: -20,
        right: 0
    }

})
function mapStateToProps(state) {
    return {
        token: state.token,
        user: state.user,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            setToken: setToken,
            setUser: setUser,
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(MonitorCarregamentos);