import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import './agendamentoStyle.css'

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { setToken } from "../../flux/actions/tokenIndex";
import { setUser } from "../../flux/actions/userIndex";
import api from "../../services/api";
import { checarPermissao, validateTokenUser, getUser, getToken } from '../../utils/utils.js'
import Loader from "react-loader-spinner";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import PresentToAllIcon from '@material-ui/icons/PresentToAll';
import IconButton from '@material-ui/core/IconButton';
import Switch from '@material-ui/core/Switch';
import Button from "components/CustomButtons/Button.js"
import DeleteIcon from '@material-ui/icons/Delete';
import { func } from "prop-types";
import { Alert } from "@material-ui/lab";

var global_editor = false
function AgendamentoOrdenado(props) {
  const classes = useStyles()
  const [permitido, setPermitido] = useState(false)
  const [agendamento, setAgendamento] = useState('')
  const [editor, setEditor] = useState(false)


  useEffect(() => {
    async function fetchMyAPI() {
      await validateTokenUser(props)
      setPermitido(await checarPermissao('agendamento', props))
      setInterval(async function () {
        if (global_editor === false) {
          const retornoAgendamentoord = await api.get('retornaAgendaOrd', { params: { userId: getUser(), token: getToken() } })
          setAgendamento(retornoAgendamentoord.data)
        }

      }, 5000);
      // setAgendamento(retornoAgendamentoord.data.map((v,index) => ({...v, posicao: index+1})))
      // if(agendamento===''){
      //   const retornoAgendamento = await api.get('retornaAgendaDados', { params: { userId: getUser(), token: getToken() } })
      //   setAgendamento(retornoAgendamento.data)
      //   setAgendamento(retornoAgendamento.data.map((v,index) => ({...v, posicao: index+1})))
      // }
    }
    fetchMyAPI();
  }, [])


  async function deletaOrdem(id) {
    const response_deleteORD = await api.get('/deletaOrdem', { params: { 'id': id, userId: getUser(), token: getToken() } })
    if (response_deleteORD.data === 'OK') {
      alert('Agendamento Removido com Sucesso da Lista Ordenada')
      window.location.reload()
    }
    else {
      alert('Erro ao Remover agendamento, entre em contato com o time de desenvolvimento...')
    }
  }
  async function salvaOrdem() {
    let aux = '';
    // posição#id;
    Object.keys(agendamento).forEach((item) => (aux = aux + agendamento[item].ordem + '#' + agendamento[item].id + ';'));
    console.log(aux)
    const response_ordem = await api.post('/salvaOrdem', { 'ordem': aux, userId: getUser(), token: getToken() })
    if (response_ordem.data == 'OK') {
      alert('Ordem Salva')
    }
    else if (response_ordem.data == 'OK1') {
      alert('Nenhum Dado Alterado')
    }
    else {
      alert('Erro ao inserir agendamento, entre em contato com o time de desenvolvimento...')
    }
  }
  function moveComponent(direction, index) {
    // Funcao para mover os componente, fazendo a ordenação e mantendo a coluna de posicao, 
    // para depois mandar para o back usando a funcao salvaOrdem()

    let aux = agendamento
    if (direction === 'up') {

      if (index - 1 === -1) { return }
      let ord = aux[index - 1].ordem
      let prior = aux[index - 1]
      aux[index - 1].ordem = aux[index].ordem
      aux[index - 1] = aux[index]
      aux[index].ordem = ord
      aux[index] = prior
      setAgendamento(JSON.parse(JSON.stringify(aux)))
      console.log(ord)

    } else if (direction === 'down') {

      if (index + 1 === agendamento.length) { return }

      let ord = aux[index + 1].ordem
      let prior = aux[index + 1]
      aux[index + 1].ordem = aux[index].ordem
      aux[index + 1] = aux[index]
      aux[index].ordem = ord
      aux[index] = prior
      setAgendamento(JSON.parse(JSON.stringify(aux)))
    }
    console.log(agendamento)
  }

  function alteraEstados() {
    setEditor(!editor)
    global_editor = !editor
  }

  return (
    permitido === true ?
      <div style={{ height: '75%', width: '95%', position: 'Absolute', padding: 10, borderRadius: 4, boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px" }}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <b className={classes.inputh1}>Visualizar</b>
          <Switch
            style={{ color: "primary" }}
            onClick={() => { alteraEstados() }}
          />
          <b className={classes.inputh1}>Editar</b>
        </div>
        <div style={{ overflow: 'auto', height: '100%', display: 'flex', flexDirection: 'column' }}>

          {agendamento !== '' ? (

            <div >
              <TableContainer >
                <Table>
                  <TableHead style={{ backgroundColor: '#6699ff', width: '100%' }}>
                    <TableRow>
                      {editor !== true ? null :
                        <TableCell>

                        </TableCell>}
                      <TableCell>
                        <b className={classes.inputh1}>Próximos Carregamentos</b>
                      </TableCell>
                      <TableCell>
                        <b className={classes.inputh1}>Nome Motorista</b>
                      </TableCell>
                      <TableCell>
                        <b className={classes.inputh1}>Produto</b>
                      </TableCell>
                      <TableCell>
                        <b className={classes.inputh1}>Embalagem</b>
                      </TableCell>
                      <TableCell>
                        <b className={classes.inputh1}>Data</b>
                      </TableCell>
                      <TableCell>
                        <b className={classes.inputh1}>Cliente</b>
                      </TableCell>
                      
                     
                   
                     
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Object.keys(agendamento).map((item, index) => (
                      <TableRow key={index}>
                        {editor !== true ? null :
                          <TableCell>
                            <IconButton onClick={() => (moveComponent('up', index))}> < PresentToAllIcon style={{ color: 'green' }} />
                            </IconButton>
                            <IconButton onClick={() => (moveComponent('down', index))}> < PresentToAllIcon style={{ transform: 'rotate(180deg)', color: 'red' }} />
                            </IconButton>
                            <IconButton onClick={() => (deletaOrdem(agendamento[item].id))}> < DeleteIcon />
                            </IconButton>
                          </TableCell>}
                        <TableCell>
                          <b className={classes.inputh1}>{agendamento[item].ordem}</b>
                        </TableCell>
                        <TableCell>
                          <b className={classes.inputh1}>{agendamento[item].nome_motorista}</b>
                        </TableCell>
                        <TableCell>
                          <b className={classes.inputh1}>{agendamento[item].produto}</b>
                        </TableCell>
                        <TableCell>
                          <b className={classes.inputh1}>{agendamento[item].embalagem}</b>
                        </TableCell>
                        <TableCell>
                          <b className={classes.inputh1}>{agendamento[item].data}</b>
                        </TableCell>
                        <TableCell>
                          <b className={classes.inputh1}>{agendamento[item].cliente}</b>
                        </TableCell>


                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          ) : <Loader type="Circles" color="#00BFFF" height={20} width={20} timeout={2000000} />}
        </div>
        <a> <Button
          style={{ background: 'blue' }}
          onClick={() => salvaOrdem()}>Salvar Agendamento Ordenado</Button></a>

      </div> : null
  );
}





function mapStateToProps(state) {
  return {
    token: state.token,
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setToken: setToken,
      setUser: setUser,
    },
    dispatch
  );
}

const useStyles = makeStyles((theme) => ({
  tableModels: {
    marginTop: 20,
    maxWidth: '100%',
    boxShadow: '0px 17px 80px rgba(0, 0, 0, 0.07), 0px 7.10219px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 3.79717px 17.869px rgba(0, 0, 0, 0.0417275), 0px 2.12866px 10.0172px rgba(0, 0, 0, 0.035), 0px 1.13052px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 0.470434px 2.21381px rgba(0, 0, 0, 0.0196802)',
    maxHeight: 700,
    overflow: 'auto'
  },
  table: {
    minWidth: 400,
    maxWidth: '100%'
  },
  inputh1: {
    fontFamily: 'Arial',
    color: '#383838'
  }
}))


export default connect(mapStateToProps, mapDispatchToProps)(AgendamentoOrdenado);
