import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { setToken } from "../../flux/actions/tokenIndex";
import { setUser } from "../../flux/actions/userIndex";
import api from "../../services/api";
import { checarPermissao, validateTokenUser, getUser, getToken } from '../../utils/utils.js'
import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';


function ConfigurarComercial(props) {
    const [user, setUser] = useState('')
    const [permitido, setPermitido] = useState(false)

    const classes = useStyles()

    // states adicionar colaborador
    const [nome, setNome] = useState('')
    const [cargo, setCargo] = useState('')

    // lista de supervisores/vendedores
    const [colaboradores, setColaboradores] = useState([])
    const [supervisores, setSupervisores] = useState([])
    const [vendedores, setVendedores] = useState([])
    const [gerentes, setGerentes] = useState([])

    const [vendedoresDisponiveis, setVendedoresDisponiveis] = useState([])

    // atualizar nome
    const [novoNome, setNovoNome] = useState('')

    // lista de childs
    const [hierarquia, setHierarquia] = useState([])

    // state de edição
    const [editandoId, setEditandoId] = useState('')


    const [autocompleteValue, setAutocompleteValue] = useState([])
    const [atualizandoFuncao, setAtualizandoFuncao] = useState(false)


    useEffect(() => {
        async function fetchMyAPI() {
            await validateTokenUser(props)
            setPermitido(await checarPermissao('configComercial', props))

            const colab = await api.get('/getColaboradoresComercial', { params: { userId: getUser(), token: getToken() } })
            setColaboradores(colab.data)

            const auxSupervisores = colab.data.filter((item) => item.supervisor === 1)
            setSupervisores(auxSupervisores)
            const auxVendedores = colab.data.filter((item) => item.vendedor === 1)
            setVendedores(auxVendedores)
            const auxGerentes = colab.data.filter((item) => item.gerente === 1)
            setGerentes(auxGerentes)

            const childs = await api.get('/getHierarquiaComercial', { params: { userId: getUser(), token: getToken() } })
            setHierarquia(childs.data)

            listarDisponiveis(auxVendedores, childs.data)

            // criação do array que determina os valores dos autocompletes de vendedores
            let aux = []
            auxSupervisores.map(() => {
                aux.push(null)
            })
            setAutocompleteValue(aux)
        }

        fetchMyAPI();
    }, [])


    function listarDisponiveis(auxVendedores, childs) {
        let idVendedores = []
        let idChilds = []
        auxVendedores.map((item) => {
            idVendedores.push(`${item.nome} - ${item.id}`)
        })
        childs.map((item) => {
            idChilds.push(`${item.nome} - ${item.id}`)
        })
        let result = idVendedores.filter((id) => !idChilds.includes(id))
        setVendedoresDisponiveis(result)
    }

    async function addColaborador() {
        if (nome === '' || cargo === '') {
            alert('Preencha todos os campos antes de adicionar um colaborador!')
        } else {
            const response = await api.get('/addColaboradorComercial', { params: { userId: getUser(), token: getToken(), nome, cargo } })
            if (response.data === 'NOK') {
                alert('Erro ao adicionar colaborador...')
            } else {
                alert('Colaborador adicionado!')
                window.location.reload()
            }
        }
    }


    async function atualizarFuncao(funcaoComercial, id, index) {
        setAtualizandoFuncao(true)
        const response = await api.get('/atualizarFuncaoComercial', { params: { userId: getUser(), token: getToken(), id, funcaoComercial } })
        if (response.data === 'NOK') {
            alert('Erro ao atualizar função comercial...')
        } else {
            let aux = colaboradores.slice()
            if (funcaoComercial === 'supervisor') {
                aux[index]['vendedor'] = 0
                aux[index]['supervisor'] = 1
                aux[index]['gerente'] = 0
            } else if (funcaoComercial === 'vendedor') {
                aux[index]['vendedor'] = 1
                aux[index]['supervisor'] = 0
                aux[index]['gerente'] = 0
            }
            else if (funcaoComercial === 'gerente') {
                aux[index]['vendedor'] = 0
                aux[index]['supervisor'] = 0
                aux[index]['gerente'] = 1
            }
            setColaboradores(aux)
        }
        setAtualizandoFuncao(false)
    }

    async function salvarNomeEditado(id, index) {
        const response = api.get('/salvarEdicaoComercial', { params: { userId: getUser(), token: getToken(), id, novoNome } })
        if (response.data === 'NOK') {
            alert('Erro ao salvar...')
        } else {
            let aux = colaboradores.slice()
            aux[index].nome = novoNome
            setColaboradores(aux)
            setEditandoId('')
            alert('Salvo com sucesso!')
        }
    }

    function editar(id, nomeAtual) {
        setNovoNome(nomeAtual)
        setEditandoId(id)
    }

    async function salvarRelacao(idSupervisor, nomeIdVendedor, parentId, index) {

        // seta o valor do autocomplete
        let a = autocompleteValue.slice()
        a[index] = nomeIdVendedor
        setAutocompleteValue(a)

        const response = await api.get('/relacionarSupervisor', { params: { userId: getUser(), token: getToken(), idSupervisor, 'idVendedor': nomeIdVendedor } })
        if (response.data === 'NOK') {
            alert('Erro ao relacionar funções...')
        } else {
            // adiciona o objeto no hierarquia pra ele renderizar
            let aux = hierarquia.slice()
            let nomeSplit = nomeIdVendedor.split(' - ')
            let childId = nomeSplit[nomeSplit.length - 1] // ultimo elemento do array sempre é a id do vendedor
            aux.push({ nome: nomeSplit[0], id: childId, parentId: parentId })
            setHierarquia(aux)

            // remove o valor do autocomplete
            let b = autocompleteValue.slice()
            b[index] = null
            setAutocompleteValue(b)

            // remove a opção do options do autocomplete
            let aux2 = vendedoresDisponiveis.slice()
            aux2.splice(aux2.indexOf(nomeIdVendedor), 1)
            setVendedoresDisponiveis(aux2)

            alert('Relacionado com sucesso!')
        }
    }

    async function removerRelacaoComercial(childId, childNome, index) {
        const response = await api.get('/removerRelacaoComercial', { params: { userId: getUser(), token: getToken(), childId } })
        if (response.data === 'NOK') {
            alert('Erro ao deletar relação...')
        } else {
            // remove o objeto no hierarquia pra ele sumir da tela
            let aux = hierarquia.slice()
            aux.splice(index, 1)
            setHierarquia(aux)

            // adiciona a opção no options do autocomplete
            let aux2 = vendedoresDisponiveis.slice()
            aux2.push(`${childNome} - ${childId}`)
            setVendedoresDisponiveis(aux2)
            alert('Relação removida com sucesso!')
        }
    }

    return (
        permitido === true ?
            <>
                {/*TABELA DE COLABORADORES*/}
                <div className={classes.section}>
                    <h3 className={classes.titulo}>Lista de Colaboradores</h3>
                    <div className={classes.divTable}>
                        <table>
                            <thead>
                                <tr className={classes.stickyHeader}>
                                    <th className={classes.tdStyle}></th>
                                    <th className={classes.tdStyle}>Nome</th>
                                    <th className={classes.tdStyle}>ID</th>
                                    <th className={classes.tdStyle}>Função Comercial</th>
                                </tr>
                            </thead>

                            <tbody>
                                {colaboradores.map((item, index) =>
                                    <tr key={index}>

                                        {editandoId === item.id ?
                                            <>
                                                <td>
                                                    <div style={{ display: 'flex', alignContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                                                        <button onClick={() => salvarNomeEditado(item.id, index)}>Salvar</button>
                                                    </div>
                                                </td>

                                                <td style={{ paddingLeft: 6, background: '#ededed', border: '1px solid black' }}>
                                                    <TextField
                                                        multiline
                                                        onChange={(e) => setNovoNome(e.target.value)}
                                                        defaultValue={item.nome} />
                                                </td>
                                            </>
                                            :
                                            <>
                                                <td>
                                                    <div style={{ display: 'flex', alignContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                                                        {/* <button onClick={() => editar(item.id, item.nome)}>Editar</button> */}
                                                    </div>
                                                </td>

                                                <td style={{ width: 200, border: '1px solid black', paddingLeft: 6 }}>
                                                    {item.nome}
                                                </td>
                                            </>

                                        }

                                        <td style={{ width: 50, border: '1px solid black' }}>{item.id}</td>
                                        <td style={{ border: '1px solid black', pointerEvents: atualizandoFuncao ? 'none' : 'auto' }}>
                                            <RadioGroup row onChange={(event) => atualizarFuncao(event.target.value, item.id, index)}>
                                                <FormControlLabel checked={item.gerente === 1} value="gerente" control={<Radio />} label="Gerente" />
                                                <FormControlLabel checked={item.supervisor === 1} value="supervisor" control={<Radio />} label="Supervisor" />
                                                <FormControlLabel checked={item.vendedor === 1} value="vendedor" control={<Radio />} label="Vendedor" />
                                            </RadioGroup>
                                        </td>
                                    </tr>)}
                            </tbody>
                        </table>
                    </div>
                </div>



                {/*TABELA DE RELAÇÃO*/}
                <div hiddestyle={{ marginTop: 50 }}></div>
                {/* Esconder essa relação por enquanto */}
                {false === true ? <div className={classes.section}>
                    <h3 className={classes.titulo}>Tabela de Relação Comercial</h3>

                    <div className={classes.divTable}>
                        <table>
                            <thead>
                                <tr className={classes.stickyHeader}>
                                    <th className={classes.tdStyle}>Supervisor</th>
                                    <th className={classes.tdStyle}>Vendedor</th>
                                </tr>
                            </thead>

                            <tbody>
                                {supervisores.map((parent, index) =>
                                    <tr key={index}>
                                        <td className={classes.tdStyle}>{parent.nome}</td>
                                        <td className={classes.tdStyle}>
                                            <div style={{ margin: 5 }}>
                                                <Autocomplete
                                                    key={index}
                                                    value={autocompleteValue[index] || null}
                                                    size="small"
                                                    onChange={(event, value) => value !== null ? salvarRelacao(parent.id, value, parent.id, index) : null}
                                                    style={{ width: 250 }}
                                                    options={vendedoresDisponiveis}
                                                    renderInput={(params) => <TextField variant='outlined' {...params} />}
                                                />
                                            </div>

                                            {hierarquia.map((child, index) =>
                                                <div key={index}>
                                                    {child.parentId === parent.id ?
                                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', alignContent: 'center' }}>
                                                            <div style={{ textAlign: 'center', border: '1px solid black', margin: 5, padding: 5, maxWidth: 300 }}>
                                                                {child.nome}
                                                            </div>

                                                            <div>
                                                                <button onClick={() => removerRelacaoComercial(child.id, child.nome, index)}>x</button>
                                                            </div>

                                                        </div> : null}
                                                </div>)}
                                        </td>
                                    </tr>)}

                            </tbody>
                        </table>
                    </div>

                </div> : null}

                {/* <div style={{ background: 'white', borderRadius: 8 }}>
                    <h3>Adicionar Colaborador</h3>
                    <TextField
                        onChange={(event) => setNome(event.target.value)}
                        label='Nome'
                    />
                    <RadioGroup row onChange={(event) => setCargo(event.target.value)}>
                        <FormControlLabel value="Supervisor" control={<Radio />} label="Supervisor" />
                        <FormControlLabel value="Vendedor" control={<Radio />} label="Vendedor" />
                    </RadioGroup>
                    <button onClick={() => addColaborador()}>Add</button>
                </div> */}

            </> : <></>
    )
}


const useStyles = makeStyles({
    tdStyle: {
        border: '1px solid black',
        textAlign: 'center'
    },

    stickyHeader: {
        position: 'sticky',
        top: 0,
        background: 'white',
        zIndex: 2
    },

    divTable: {
        height: 600,
        overflow: 'auto',
        background: 'white'
    },

    section: {
        fontWeight: 'bold',
        background: 'white',
        padding: 20,
        borderRadius: 6
    },

    titulo: {
        border: '1px solid black',
        paddingLeft: 10
    }
})


function mapStateToProps(state) {
    return {
        token: state.token,
        user: state.user,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            setToken: setToken,
            setUser: setUser,
        },
        dispatch
    );
}


export default connect(mapStateToProps, mapDispatchToProps)(ConfigurarComercial);