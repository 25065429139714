import React, { useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";

import GridContainer from "components/Grid/GridContainer.js";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { setToken } from "../../flux/actions/tokenIndex";
import { setUser } from "../../flux/actions/userIndex";
import api from "../../services/api";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from "components/CustomButtons/Button.js";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { TextField } from "@material-ui/core";
import { checarPermissao, validateTokenUser, getUser, getToken, formatStringUniversalDateToLocal } from '../../utils/utils.js'
import exportFromJSON from "export-from-json";
import Radio from '@material-ui/core/Radio';
import Loader from "react-loader-spinner";


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
}));


function AprovaVendaLogistica(props) {
    const [pedidos, setPedidos] = useState('')
    const [wait, setWait] = useState(false)

    const [open, setOpen] = useState(false);
    const [openInfo, setOpenInfo] = useState(false);

    const [indexR, setIndexR] = useState(0)
    const [msg, setMsg] = useState('')
    const [permitido, setPermitido] = useState(false)
    const [massAprov, setMassAprov] = useState([])

    const [validadeAproval, setValidadeAproval] = useState([])
    const [statePedidos, setStatePedidos] = useState(0)
    const [salvando, setSalvando] = useState(false)

    const [historico, setHistorico] = useState('')


    useEffect(() => {
        async function fetchMyAPI() {
            await validateTokenUser(props)
            setPermitido(await checarPermissao('aprovaVendaLogistica', props))

            const response_aguardando = await api.get("/pedidosAprovacaoLogistica", { params: { userId: getUser(), token: getToken() } });
            const historico_response = await api.get('/historico_aprovacao_logistica', { params: { userId: getUser(), token: getToken() } })
            setHistorico(historico_response.data)

            setPedidos(response_aguardando.data)

            var a = new Array(response_aguardando.data.length).fill('a');
            var b = new Array(response_aguardando.data.length).fill('a');
            setMassAprov(a)
            setValidadeAproval(b)
        }

        fetchMyAPI();
    }, []);


    function baixarHistorico() {
        if (historico === '') { return }
        const data = historico

        const fileName = 'historico'
        const exportType = exportFromJSON.types.xls

        exportFromJSON({ data, fileName, exportType })
    }


    async function inserePedido(pedido, aprov) {
        setWait(true)
        const response_aprova = await api.get("/AprovaPedido", { params: { pedido, aprov, msg, userId: getUser(), token: getToken() } });

        if (response_aprova.data === 'NOK') {
            alert('Erro ao inserir pedido')
        } else if (response_aprova.data == 'OK') {
            alert('Pedido inserido com sucesso!')
            window.location.reload();
        } else if (response_aprova.data == 'OKN') {
            alert('Pedido reprovado com sucesso!')
            window.location.reload();
        }
        setWait(false)
    }


    const handleClickOpen = () => {
        setOpen(true);
    };


    const handleClose = () => {
        setOpen(false);
    };


    const handleClickOpenInfo = () => {
        setOpenInfo(true);
    };


    const handleCloseInfo = () => {
        setOpenInfo(false);
    };


    const alterState = (index, val) => {
        let aux = massAprov.slice()
        aux[index] = val
        setMassAprov(aux)
    }


    async function aprovaPedidosMassa() {
        let count = 0
        setSalvando(true)
        for (var i = 0; i < massAprov.length; i++) {
            if (massAprov[i] == 'b') {
                count += 1
                setStatePedidos(count)
                let pedido = pedidos[i].id
                const response_aprova = await api.get("/AprovaPedidoLogistica", { params: { pedido, 'aprov': '0', 'msg': '', userId: getUser(), token: getToken() } });

                if ((response_aprova.data == 'OK') || (response_aprova.data == 'OKN')) {
                    let aux = validadeAproval
                    aux[i] = true
                    setValidadeAproval(aux)
                } else {
                    let aux = validadeAproval
                    aux[i] = false
                    setValidadeAproval(aux)
                }

            }
            else if (massAprov[i] == 'c') {
                count += 1
                setStatePedidos(count)
                let pedido = pedidos[i].id
                const response_aprova = await api.get("/AprovaPedidoLogistica", { params: { pedido, 'aprov': '-1', 'msg': '', userId: getUser(), token: getToken() } });
                if (response_aprova.data == 'OKN') {
                    let aux = validadeAproval
                    aux[i] = true
                    setValidadeAproval(aux)
                } else {
                    let aux = validadeAproval
                    aux[i] = false
                    setValidadeAproval(aux)
                }

            }
        }
        setSalvando(false)
        handleClickOpenInfo()
    }

    return (
        permitido === true ?
            <div>
                <h4>Pedidos pendentes</h4>

                <div>
                    Histórico de Aprovações: <button onClick={() => baixarHistorico()}>Baixar</button>
                </div>

                <GridContainer>

                    <div style={{ display: 'flex', flexDirection: 'row', width: '20%', justifyContent: 'space-around' }}>
                        <p>Ignorar</p>
                        <p>Aprovar</p>
                        <p>Reprovar</p>
                    </div>
                    {Object.keys(pedidos).map((index) =>
                        pedidos[index].addSAP === 3 ?
                            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', marginBottom: 10 }} key={index}>

                                <div style={{ display: 'flex', flexDirection: 'row', width: '25%', justifyContent: 'space-around' }}>
                                    <Radio
                                        checked={massAprov[index] === 'a'}
                                        onChange={() => alterState(index, 'a')}
                                        value="a123"
                                        name={"gg" + index.toString()}
                                        color="default"
                                        label='123'
                                    />
                                    <Radio
                                        checked={massAprov[index] === 'b'}
                                        onChange={() => alterState(index, 'b')}
                                        value="b"
                                        name={"gg" + index.toString()}
                                        color="default"
                                    />
                                    <Radio
                                        checked={massAprov[index] === 'c'}
                                        onChange={() => alterState(index, 'c')}
                                        value="c"
                                        name={"gg" + index.toString()}
                                        color="default"
                                    />  </div>
                                <Accordion style={{ width: '100%' }}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id={index}>

                                        <Typography style={{ fontSize: 16 }}>Cliente:{pedidos[index].argsVenda.cliente}</Typography>
                                        <Typography style={{ marginLeft: 20, fontSize: 16 }}>Frete Tabela:{pedidos[index].argsVenda.valFrete}</Typography>
                                        <Typography style={{ marginLeft: 20, fontSize: 16 }}>Frete Vendido:{pedidos[index].argsVenda.valFreteVendido}</Typography>
                                        <Typography style={{ marginLeft: 20, fontSize: 16 }}>SubRegião:{pedidos[index].argsVenda.selSubReg}</Typography>
                                        <Typography style={{ marginLeft: 20, fontSize: 16 }}>Origem:{pedidos[index].argsVenda.selOrigem}</Typography>
                                        <Typography style={{ marginLeft: 20, fontSize: 16 }}>Destino:{pedidos[index].argsVenda.selDestino}</Typography>
                                    </AccordionSummary>
                                    <div style={{ border: "1px solid", margin: 10 }}>
                                        <AccordionDetails style={{ display: 'flex', flexDirection: 'column' }}>
                                            <Typography >
                                                {pedidos[index].argsVenda.desmembramento === 'true' ?
                                                    <div>Desmembramento do pedido {pedidos[index].argsVenda.pedidoMae}</div>
                                                    : null}
                                            </Typography>
                                            <Typography>
                                                - Cliente : {pedidos[index].argsVenda.cliente}
                                            </Typography>
                                            <Typography>
                                                - Observações : {pedidos[index].argsVenda.observ}
                                            </Typography>


                                        </AccordionDetails>

                                        <AccordionDetails style={{ display: 'flex', flexDirection: 'column' }}>
                                            <Typography>
                                                - Produto : {pedidos[index].argsVenda.nomeProduto}
                                            </Typography>
                                            <Typography>
                                                - Quantidade: {pedidos[index].argsVenda.qtde}
                                            </Typography>
                                            <Typography>
                                                - Preço : {pedidos[index].argsVenda.uni}{pedidos[index].argsVenda.moeda}
                                            </Typography>
                                            <Typography>
                                                - Embalagem : {pedidos[index].argsVenda.emb}
                                            </Typography>
                                            <Typography>
                                                - Total {pedidos[index].argsVenda.moeda}: {pedidos[index].argsVenda.totProd1}
                                            </Typography>
                                        </AccordionDetails>

                                        {pedidos[index].argsVenda.nomeProduto2 !== '' ?
                                            <AccordionDetails style={{ display: 'flex', flexDirection: 'column' }}>

                                                <Typography>
                                                    - Produto : {pedidos[index].argsVenda.nomeProduto2}
                                                </Typography>
                                                <Typography>
                                                    - Quantidade: {pedidos[index].argsVenda.qtde2}
                                                </Typography>
                                                <Typography>
                                                    - Preço : {pedidos[index].argsVenda.uni2} {pedidos[index].argsVenda.moeda}
                                                </Typography>
                                                <Typography>
                                                    - Embalagem : {pedidos[index].argsVenda.emb2}
                                                </Typography>
                                                <Typography>
                                                    - Total {pedidos[index].argsVenda.moeda}: {pedidos[index].argsVenda.totProd2}
                                                </Typography>
                                            </AccordionDetails> : null}
                                        {pedidos[index].argsVenda.nomeProduto3 !== '' ?
                                            <AccordionDetails style={{ display: 'flex', flexDirection: 'column' }}>

                                                <Typography>
                                                    - Produto : {pedidos[index].argsVenda.nomeProduto3}
                                                </Typography>
                                                <Typography>
                                                    - Quantidade: {pedidos[index].argsVenda.qtde3}
                                                </Typography>
                                                <Typography>
                                                    - Preço : {pedidos[index].argsVenda.uni3}{pedidos[index].argsVenda.moeda}
                                                </Typography>
                                                <Typography>
                                                    - Embalagem : {pedidos[index].argsVenda.emb3}
                                                </Typography>
                                                <Typography>
                                                    - Total {pedidos[index].argsVenda.moeda}: {pedidos[index].argsVenda.totProd3}
                                                </Typography>
                                            </AccordionDetails> : null}
                                        <div style={{ display: 'flex', flexDirection: 'row' }}>


                                            <AccordionDetails style={{ display: 'flex', flexDirection: 'column', width: '33%' }}>
                                                <Typography>
                                                    - Cidade Entrega : {pedidos[index].argsVenda.cidadeEntrega}
                                                </Typography>

                                                <Typography>
                                                    - Valor Frete Tabela: {pedidos[index].argsVenda.valFrete}
                                                </Typography>
                                                <Typography>
                                                    - Valor Frete Vendido: {pedidos[index].argsVenda.valFreteVendido}
                                                </Typography>
                                                <Typography>
                                                    - Roteiro: {pedidos[index].argsVenda.roteiro}
                                                </Typography>
                                            </AccordionDetails>

                                            <AccordionDetails style={{ display: 'flex', flexDirection: 'column', width: '33%' }}>

                                                <Typography>
                                                    - Filial : {pedidos[index].argsVenda.filial}
                                                </Typography>

                                            </AccordionDetails>
                                        </div>

                                        <Button disabled={wait} style={{ backgroundColor: '#DE3163', color: '#FFFFFF', marginLeft: 5 }} onClick={() => (handleClickOpen(), setIndexR(pedidos[index].id))}>   Reprovar Pedido</Button>

                                    </div>

                                </Accordion>
                            </div> : null)}
                    <div>

                        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                            <DialogTitle id="form-dialog-title">Reprovar Pedido</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    Descrição do motivo da reprovação da venda:
                                </DialogContentText>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="name"
                                    fullWidth
                                    onChange={(e) => setMsg(e.target.value)}
                                    multiline
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose} color="primary">
                                    Cancelar
                                </Button>
                                <Button onClick={() => (inserePedido(indexR, -1), handleClose)} color="primary">
                                    Reprovar Pedido
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                </GridContainer>
                <Button disabled={salvando || massAprov.filter(obj => obj != 'a').length == 0} style={{ backgroundColor: '#12824C', color: '#FFFFFF' }} onClick={() => aprovaPedidosMassa()}> Enviar operações</Button>
                {(salvando) ? <div> <Loader
                    type="Circles"
                    color="#00BFFF"
                    height={20}
                    width={20}
                    timeout={2000000} // 3 secs
                /> Realizando operação com pedido {statePedidos} de {massAprov.filter(obj => obj != 'a').length} </div> : <></>}
                <div >

                    <Dialog fullWidth={true}
                        maxWidth={false} open={openInfo} onClose={handleCloseInfo} aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title">Informações sobre aprovação dos pedidos selecionados</DialogTitle>
                        <DialogContent>
                            {Object.keys(pedidos).map((index) =>
                                massAprov[index] !== 'a' ?
                                    <div key={index}>
                                        <p>
                                            {pedidos[index].argsVenda.cliente} -{">"}
                                            {validadeAproval[index] === true ? 'Pedido ' + (massAprov[index] == 'b' ? 'Aprovado' : 'Reprovado') + ' com sucesso' : 'erro ao inserir pedido, entre em contato com o time de desenvolvimento, id sysgen ->' + pedidos[index].id.toString()}</p>
                                    </div> : null)}
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => window.location.reload()} color="primary">
                                OK
                            </Button>

                        </DialogActions>
                    </Dialog>
                </div>

            </div> : <></>
    );
}
function mapStateToProps(state) {
    return {
        token: state.token,
        user: state.user,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            setToken: setToken,
            setUser: setUser,
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(AprovaVendaLogistica);
