import React, { useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { setToken } from "../../flux/actions/tokenIndex";
import { setUser } from "../../flux/actions/userIndex";
import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import api2 from "../../services/api2";
import Button from "components/CustomButtons/Button.js";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";

import Loader from "react-loader-spinner";
import { checarPermissao, validateTokenUser, getUser, getToken } from '../../utils/utils.js'


const useStyles = makeStyles(styles);

function TabelaMaterias(props) {
    const [produtoA, setProdutoA] = useState('');
    const [sel, setSel] = useState('');
    const [desc, setDesc] = useState('');
    const [sum, setSum] = useState(0)
    const [list, setList] = useState([])
    const classes = useStyles();
    const [permitido, setPermitido] = useState(false)

    useEffect(() => {
        async function apicall() {
            await validateTokenUser(props)
            setPermitido(await checarPermissao('materiasPrecos', props))

            const response_produtos = await api2.get("/produtosAcabados", { params: { userId: getUser(), token: getToken() } });
            setProdutoA(response_produtos.data)
        }
        apicall()
    }, []);

    async function pesquisaFormula() {
        const response_desc = await api2.get("/descontroi", { params: { produto: sel, userId: getUser(), token: getToken() } });
        if (response_desc.data == 'NOK') {
            alert('Composição não encontrada na base SAP')
            setDesc('')
        }
        else {
            setDesc(response_desc.data)
        }
    }

    function SumAll(item, price) {
        let aux = list
        let soma = 0
        aux[item] = price
        setList(aux)
        for (var i = 0, len = aux.length; i < len; i++) {
            soma += parseFloat(aux[i]) * parseFloat(desc[i].Quantity);
        }
        setSum(soma)
    }

    return (
        permitido === true ?
            <div>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <div style={{ width: 300, padding: 10 }}>
                            {produtoA !== '' ? <Autocomplete
                                id="combo-box-demo"
                                options={produtoA}
                                getOptionLabel={(option) => option.ItemName}
                                style={{ width: 300, marginTop: 15 }}
                                onInputChange={(e, v) => (setSel(v), setDesc(''), setSum(0), setList([]))}

                                renderInput={(params) => (
                                    <TextField
                                        {...params}

                                        variant='outlined'
                                        label='Pesquisar produto'

                                    />
                                )}
                            /> : <Loader
                                type="Circles"
                                color="#00BFFF"
                                height={20}
                                width={20}
                                timeout={20000} //3 secs
                            />}
                        </div>
                    </GridItem>

                    <GridItem>
                        <h4>{sel}</h4>
                        <table >
                            <thead>
                                <tr style={{ width: 300, textAlign: "center", border: "1px solid " }}>
                                    <th style={{ width: 300, textAlign: "center", border: "1px solid " }}>
                                        Nome
                                    </th>
                                    <th style={{ width: 300, textAlign: "center", border: "1px solid " }}>
                                        Quantidade
                                    </th>
                                    <th style={{ width: 300, textAlign: "center", border: "1px solid " }}>
                                        Preço
                                    </th>

                                </tr>
                            </thead>
                            <tbody>
                                {desc !== '' ?
                                    Object.keys(desc).map((item, index) => (
                                        <tr key={index}>
                                            <td style={{ width: 300, textAlign: "center", border: "1px solid " }}>
                                                {desc[item].Nome}
                                            </td>
                                            <td style={{ width: 300, textAlign: "center", border: "1px solid " }}>
                                                {desc[item].Quantity}
                                            </td>
                                            <td style={{ width: 300, textAlign: "center", border: "1px solid " }}>
                                                <TextField onChange={(e) => SumAll(item, e.target.value)} variant="outlined" />
                                            </td>
                                        </tr>

                                    )) : <div></div>}
                            </tbody>
                        </table>
                        <h4> Total = {sum}</h4>
                    </GridItem>
                </GridContainer>
                <GridContainer>
                    <GridItem>

                        <Button style={{ backgroundColor: '#6699ff' }} variant="contained" onClick={() => pesquisaFormula()}> Pesquisar Formulação</Button>

                    </GridItem>
                </GridContainer>


            </div> : <></>
    );
}
function mapStateToProps(state) {
    return {
        token: state.token,
        user: state.user,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            setToken: setToken,
            setUser: setUser,
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(TabelaMaterias);
