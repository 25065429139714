import React from "react"
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { TextField } from "@material-ui/core";
import Button from '@material-ui/core/Button';
import { useState, useEffect } from "react";
import api from "services/api";
import { getToken, getUser } from "utils/utils";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Loader from "react-loader-spinner";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({});

export default function PrecosDeCompra() {
    const [nomeMateria, setNomeMateria] = useState('')
    const [produtosOptions, setProdutosOptions] = useState([])
    const [valorMateria, setValorMateria] = useState(0)
    const [materias, setMaterias] = useState('')
    const [idEdit, setIdEdit] = useState(0)
    const [isEditing, setIsEditing] = useState(0)
    const [editObj, setEditObj] = useState({})
    const [savingEdit, setSavingEdit] = useState(false)
    const [inserting, setInserting] = useState(false)

    const classes = useStyles();

    useEffect(() => {
        fetchMyAPI()
    }, [])

    const fetchMyAPI = async () => {
        const responseItens = await api.get('/Itens', { params: { userId: getUser(), token: getToken() } })
        let aux = []
        responseItens.data.map(obj => aux.push(obj.ItemName))
        setProdutosOptions(aux)

        const responseMaterias = await api.get('/get_precos_materias', { params: { userId: getUser(), token: getToken() } })
        setMaterias(responseMaterias.data)
    }

    const insertPrice = async () => {
        if (!saveVerification()) { return }
        setInserting(true)
        const responsePrice = await api.get('/inserir_preco_materia', { params: { isEditing, nomeMateria, valorMateria, userId: getUser(), token: getToken() } })

        if (responsePrice.data === 'NOK') {
            alert('Erro ao inserir preço de compra...')
        }
        const responseMaterias = await api.get('/get_precos_materias', { params: { userId: getUser(), token: getToken() } })
        setMaterias(responseMaterias.data)
        setInserting(false)
    }

    const handleEdit = (idMateria, valorMateria) => {
        setIsEditing(1)
        setIdEdit(idMateria)
        setEditObj({ idMateria, valorMateria })
    }

    const handleUpdateEdit = (value) => {
        let aux = { ...editObj }

        if (value === '') {
            aux['valorMateria'] = 0
        } else {
            aux['valorMateria'] = parseFloat(value)
        }
        setEditObj(aux)
    }

    const handleCancelEdit = () => {
        setEditObj({})
        setIsEditing(0)
        setIdEdit(0)
    }

    const handleSaveEdit = async (nomeMateria) => {
        setSavingEdit(true)
        const response = await api.get('/inserir_preco_materia', { params: { isEditing, nomeMateria, idMateria: editObj['idMateria'], valorMateria: editObj['valorMateria'], userId: getUser(), token: getToken() } })
        const responseMaterias = await api.get('/get_precos_materias', { params: { userId: getUser(), token: getToken() } })
        setMaterias(responseMaterias.data)
        setSavingEdit(false)
        handleCancelEdit()
    }

    const saveVerification = () => {
        if (nomeMateria === '') {
            alert('Adicione o nome da matéria antes de salvar')
            return false
        }
        return true
    }


    return (
        <>
            <Grid container spacing={3} component={Paper} alignItems="center" >

                <Grid container r style={{ border: '1px solid lightgray', margin: 10, padding: 10 }} alignItems="center" >
                    <Grid item >
                        <Autocomplete
                            size="small"
                            style={{ width: 300 }}
                            options={produtosOptions}
                            onChange={(event, value) => value !== null ? setNomeMateria(value) : setNomeMateria('')}
                            renderInput={(params) => (<TextField variant='outlined' {...params} label='Matéria' />)}
                        />
                    </Grid>

                    <Grid item >
                        <TextField size="small" type='number' label='Valor'
                            value={valorMateria} variant="outlined" onChange={(e) => e.target.value === '' ? setValorMateria(0) : setValorMateria(e.target.value)} />
                    </Grid>

                    <Grid item style={{ marginLeft: 10 }}>
                        {inserting ?
                            <Loader
                                type="Circles"
                                color="#00BFFF"
                                height={20}
                                width={20}
                                timeout={2000000}
                            />
                            : <Button size="small" variant="contained" type="submit" color="primary" onClick={() => insertPrice()}>Salvar</Button>}
                    </Grid>
                </Grid>

            </Grid>

            <Grid container spacing={3} component={Paper} >

                <Grid item xs={12}>
                    {materias === '' ? <Loader
                        type="Circles"
                        color="#00BFFF"
                        height={20}
                        width={20}
                        style={{ margin: 10 }}
                        timeout={2000000}
                    /> : <TableContainer component={Paper}>
                        <Table aria-label="simple table" size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Edição</TableCell>
                                    <TableCell>Materia</TableCell>
                                    <TableCell>Preço</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {materias.map((obj, index) =>
                                    <TableRow key={index}>
                                        <TableCell>
                                            {savingEdit && idEdit === obj.id_materia ?
                                                <Loader
                                                    type="Circles"
                                                    color="#00BFFF"
                                                    height={20}
                                                    width={20}
                                                    timeout={2000000}
                                                />
                                                :
                                                <>
                                                    {isEditing && idEdit === obj.id_materia ?
                                                        <>
                                                            <Button size="small" color="secondary" onClick={() => handleCancelEdit()}>Cancelar</Button>
                                                            <Button size="small" color="primary" onClick={() => handleSaveEdit(obj.nome_materia)}>Salvar</Button>

                                                        </>
                                                        : <Button color="primary" size="small" onClick={() => handleEdit(obj.id_materia, obj.valor_materia)}>Editar</Button>}
                                                </>}
                                        </TableCell>

                                        <TableCell>{obj.nome_materia}</TableCell>

                                        <TableCell >
                                            {idEdit === obj.id_materia ?
                                                <TextField type='number' defaultValue={obj.valor_materia} onChange={(e) => handleUpdateEdit(e.target.value)} />
                                                : obj.valor_materia}
                                        </TableCell>
                                    </TableRow>)}
                            </TableBody>
                        </Table>
                    </TableContainer>}
                </Grid>
            </Grid>
        </>
    )
}