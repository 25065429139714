import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { setToken } from "../../flux/actions/tokenIndex";
import { setUser } from "../../flux/actions/userIndex";
import api from "../../services/api";
import { validateTokenUser, getUser, getToken } from '../../utils/utils.js'

import MuiPhoneInput from 'material-ui-phone-number';
import { makeStyles } from "@material-ui/core/styles";




function PedidoFinalizado(props) {
    const [info, setInfo] = useState('')
    const [numero, setNumero] = useState('')
    const [salvo, setSalvo] = useState(false)
    const [salvando, setSalvando] = useState(false)

    const classes = useStyles()

    useEffect(() => {
        if (getUser() === '') {
            alert('Você precisa estar logado para ver as informações do pedido')
        }

        async function fetchMyAPI() {
            await validateTokenUser(props, window.location.pathname)
            const info = await api.get('/getInfoFinalizado', { params: { userId: getUser(), token: getToken(), 'numPedido': props.match.params.numPedido, 'infoToken': props.match.params.token } })

            if (info.data === 'NOK') {
                alert('Erro ao listar informações...')
            } else if (info.data === 'TOKEN-INVALIDO') {
                setInfo('TOKEN-INVALIDO')
            } else {
                setInfo(info.data)
                if (info.data.Celular !== '') {
                    setNumero(info.data.Celular)
                    setSalvo(true)
                }
            }
        }
        fetchMyAPI();
    }, [])


    async function salvarNumero() {
        setSalvando(true)
        if (numero === '') {
            alert('Preencha o campo antes de salvar!')
            return
        }
        const response = await api.get('/salvarCelularCliente', { params: { 'numPedido': props.match.params.numPedido, numero, userId: getUser(), token: getToken() } })

        if (response.data === 'NOK') {
            alert('Erro ao salvar...')
        } else {
            alert('Adicionado com sucesso!')
            setSalvo(true)
            window.location.reload()
        }
        setSalvando(false)
    }

    return (

        <div>
            {info === '' ? <div className={classes.divInfo}>Carregando...</div> :
                info === 'TOKEN-INVALIDO' ? <div className={classes.divInfo}>Pedido não encontrado...</div> :
                    <div className={classes.divInfo}>
                        <h4>Informações do Pedido</h4>

                        <div>Pedido: {info.DocPedido}</div>
                        <div>Cliente: {info.Cliente}</div>
                        <div>Cidade: {info.CidadePedido}</div>
                        <div>Produtos: {info.Produtos}</div>

                        <div style={{ marginTop: 30 }}>
                            <div>Adicionar número do cliente:</div>
                            <MuiPhoneInput
                                disabled={salvo}
                                defaultCountry='br'
                                style={{ width: 300, margin: 5 }}
                                onChange={(e) => setNumero(e)}
                                value={numero}
                                label='Celular'
                            />
                        </div>

                        {salvo === false ?

                            <div style={{ textAlign: 'center' }}>
                                {!salvando ? <button onClick={() => salvarNumero()}>Adicionar</button> : <div>Adicionando...</div>}
                            </div> : <div>Número já adicionado</div>
                        }

                    </div>
            }
        </div>
    )
}


const useStyles = makeStyles({
    divInfo: {
        background: 'white',
        margin: 'auto',
        borderRadius: 6,
        boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
        width: '90vw',
        padding: 10,
        marginTop: 100,
        marginBottom: 10
    }
})


function mapStateToProps(state) {
    return {
        token: state.token,
        user: state.user,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            setToken: setToken,
            setUser: setUser,
        },
        dispatch
    );
}


export default connect(mapStateToProps, mapDispatchToProps)(PedidoFinalizado);