import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { setToken } from "../../flux/actions/tokenIndex";
import { setUser } from "../../flux/actions/userIndex";
import api from "../../services/api";


import { makeStyles } from "@material-ui/core/styles";
import { TextField } from "@material-ui/core";
import Button from "components/CustomButtons/Button.js"
import Autocomplete from "@material-ui/lab/Autocomplete";
import Loader from "react-loader-spinner";
import exportFromJSON from "export-from-json";
import { checarPermissao, validateTokenUser, getUser, getToken } from '../../utils/utils.js'

function WorkflowComex(props) {
    // States para inserção do pedido
    const [pedidoSap, setPedidoSap] = useState('')
    const [exportador, setExportador] = useState('')
    const [produto, setProduto] = useState('')
    const [filial, setFilial] = useState('')
    const [modalidade, setModalidade] = useState('')
    const [porto, setPorto] = useState('')
    const [contrato, setContrato] = useState('')
    const [navio, setNavio] = useState('')
    const [vencimento, setVencimento] = useState('1000-01-01')
    const [eta, setEta] = useState('1000-01-01') // Data de chegada no porto
    const [etb, setEtb] = useState('1000-01-01') // Data de atracação
    const [etc, setEtc] = useState('1000-01-01') // Data de saída
    const [pago, setPago] = useState(0) // Quantidade paga do produto (em dólar)
    const [quantidade, setQuantidade] = useState(0) // Quantidade do produto comprado, em toneladas (invoice)
    const [quantidadeTotal, setQuantidadeTotal] = useState(0) // Quantidade total do navio
    const [prancha, setPrancha] = useState(0) // Desconto em dias
    const [descontoChuva, setDescontoChuva] = useState(0) // Desconto em dias
    const [demurrage, setDemurrage] = useState(0) // Demurrage por dia (demurrage é uma multa basicamente)
    const [totalNavio, setTotalNavio] = useState(0)
    const [diasDemu, setDiasDemu] = useState(0)
    const [demuPorDia, setDemuPorDia] = useState(0)
    const [vencimentoDemurrage, setVencimentoDemurrage] = useState('');

    const [valorUnit, setValorUnit] = useState(0) // Valor da unidade do produto
    const [proformaInvoice, setProformaInvoice] = useState('')
    const [numeroProformaInvoice, setNumeroProformaInvoice] = useState('')
    const [comercialInvoice, setComercialInvoice] = useState('')
    const [dataComercialInvoice, setDataComercialInvoice] = useState('1000-01-01')
    const [numeroComercialInvoice, setNumeroComercialInvoice] = useState('')
    const [coa, setCoa] = useState('')
    const [coo, setCoo] = useState('')
    const [bl, setBl] = useState('')
    const [numeroBl, setNumeroBl] = useState('')
    const [seguro, setSeguro] = useState(0)
    const [li, setLi] = useState('')
    const [numeroLi, setNumeroLi] = useState('')
    const [di, setDi] = useState('')
    const [numeroDi, setNumeroDi] = useState('')
    const [notaMae, setNotaMae] = useState('')
    const [numeroNotaMae, setNumeroNotaMae] = useState('')
    const [glme, setGlme] = useState('')
    const [cdd, setCdd] = useState('')
    const [ci, setCi] = useState('')
    const [frete, setFrete] = useState(0)
    const [desistiva, setDesistiva] = useState(0)
    const [cambio, setCambio] = useState(1)
    const [taxaAfrmm, setTaxaAfrmm] = useState(0)
    const [agentePortuario, setAgentePortuario] = useState('')
    const [operadorPortuario, setOperadorPortuario] = useState('')
    const [berco, setBerco] = useState('')
    const [armazemMic, setArmazemMic] = useState([{ armazem: '', quantidadeArmazem: 0, mic: '', descargaReal: 0 }])
    const [situacaoAfrmm, setSituacaoAfrmm] = useState('')
    const [situacaoDemurrage, setSituacaoDemurrage] = useState('')
    const [statusFinanceiro, setStatusFinanceiro] = useState('')
    const [statusLineUp, setStatusLineUp] = useState('')
    const [dataPedido, setDataPedido] = useState('1000-01-01')
    const [dataNf, setDataNf] = useState('1000-01-01')
    //statusFinanceiro = 'Pago'

    


    const [paisOrigem, setPaisOrigem] = useState('')
    const [cambioDemurrage, setCambioDemurrage] = useState(1)
    const [cambioDesistiva, setcambioDesistiva] = useState(1)
    const [cambioSaldoAfrmm, setCambioSaldoAfrmm] = useState(1)

    // Inputs pro relatório de Despesas Portuárias
    const [taxaBl, setTaxaBl] = useState(0)
    const [saldoAfrmm, setSaldoAfrmm] = useState(0)
    const [outrosCustos, setOutrosCustos] = useState([])
    const [comentario, setComentario] = useState('')
    const [despachante, setDespachante] = useState(0)
    const [taxaSiscomex, setTaxaSiscomex] = useState(0)

    // Objetos do back
    const [pedidosImport, setPedidosImport] = useState('')
    const [historicoEdicao, setHistoricoEdicao] = useState([])
    const [pedidosFormatados, setPedidosFormatados] = useState('')
    const [pedidosFormatadosArquivados, setPedidosFormatadosArquivados] = useState('')
    const [pedidosFormatadosAtivos, setPedidosFormatadosAtivos] = useState('')
    const [pedidosImportDefault, setPedidosImportDefault] = useState([])
    const [listaFiliais, setListaFiliais] = useState([])
    const [listaProdutos, setListaProdutos] = useState([])
    const [listaPedidosSap, setListaPedidosSap] = useState([])
    const [relatorioComex, setRelatorioComex] = useState('')
    const [relatorioCustos, setRelatorioCustos] = useState('')
    const [cidades, setCidades] = useState('')

    // Misc
    const [permissaoSalvarPedido, setPermissaoSalvarPedido] = useState(false)
    const [permissaoEditar, setPermissaoEditar] = useState(false)
    const [valoresEditados, setValoresEditados] = useState([])
    const [escondeArmazem, setEscondeArmazem] = useState([])
    const [escondeCusto, setEscondeCusto] = useState([])
    const [filtro, setFiltro] = useState('')
    const [filtroArquivados, setFiltroArquivados] = useState('')
    const [paraArquivar, setParaArquivar] = useState([])
    const [paraDesarquivar, setParaDesarquivar] = useState([])
    const [pedidoSendoEditado, setPedidoSendoEditado] = useState('')
    const classes = useStyles()
    const [aba, setAba] = useState(0)
    const [tab, setTab] = useState(0)
    const [subTab, setSubTab] = useState(-1)
    const [gerandoRelDespesa, setGerandoRelDespesa] = useState(false)
    const [gerandoRelCustos, setGerandoRelCustos] = useState(false)
    const [permissoesColunas, setPermissoesColunas] = useState([])

    // options dos autocompletes
    const modalidadeOptions = ['CFR', 'DPU', 'NACIONAL', 'LATAM', 'Não se aplica']
    const contratoOptions = ['Aguardando envio do fornecedor', 'Aguardando assinatura', 'Concluído', 'Não se aplica']
    const glmeOptions = ['Aguardando liberação', 'Concluído', 'Não se aplica']

    // as options dos autocompletes a baixo são todas iguais
    // comercial invoice / coa / coo / bl / li / di / cdd / ci / nota mãe
    const options1 = ['Em andamento', 'Concluído', 'Não se aplica']
    // situação AFRMM / situação demurrage
    const options2 = ['Paga', 'A pagar', 'Não se aplica']
    // Opções Status Financeiro
    const options3 = ['Aguardando Docs', 'Consignado', 'Liberado p/ Pagar', 'A prazo, Verificar Data', 'Pago']
    //Opções Status Line Up
    const options4 = ['Aguardando Docs', 'Aguardando Atracação', 'Descarregando', 'Liberado-Operação/Logística', 'Finalizado']


    // Variáveis de "fazendo alguma coisa"
    const [salvandoPedido, setSalvandoPedido] = useState(false)
    const [salvandoEdicao, setSalvandoEdicao] = useState(false)
    const [duplicando, setDuplicando] = useState(false)
    const [excluindo, setExcluindo] = useState(false)
    const [atualizando, setAtualizando] = useState(false)
    //const diasDemu = ''


    const [permitido, setPermitido] = useState(false)

    // Relatórios
    const [relFinanceiro, setRelFinanceiro] = useState('')
    const [relLineup, setRelLineup] = useState('')
    const [relDesembaraco, setRelDesembaraco] = useState('')
    const [relQuebras, setRelQuebras] = useState('')

    useEffect((props) => {
        async function fetchMyApi() {
            await validateTokenUser(props)
            setPermitido(await checarPermissao('workflowComex', props))
            const pedidos_response = await api.get('retornaPedidosImport', { params: { userId: getUser(), token: getToken(), /*varKey: varKey*/  } })

            if (pedidos_response.data !== 'NOK') {
                let pedidos = JSON.parse(pedidos_response.data.pedidos)
                let aux = pedidos.filter(item => item.arquivado === 0)
                setPedidosImport(aux)
                setPedidosImportDefault(aux)
                criaArrayEscondeArmazem(aux)
                criaArrayEscondeCusto(aux)
                setRelFinanceiro(JSON.parse(pedidos_response.data.financeiro))
                setRelLineup(JSON.parse(pedidos_response.data.lineup))
                setRelDesembaraco(JSON.parse(pedidos_response.data.desembaraco))
                setRelQuebras(JSON.parse(pedidos_response.data.relQuebras))

                let pedidosUppercase = JSON.parse(pedidos_response.data.pedidosUppercase)
                let arquivados = pedidosUppercase.filter(item => item['ARQUIVADO'] === 1)
                setPedidosFormatadosArquivados(arquivados)

                let ativos = pedidosUppercase.filter(item => item['ARQUIVADO'] === 0)
                setPedidosFormatadosAtivos(ativos)
                setPedidosFormatados(pedidosUppercase)
            }

            const filiais = await api.get('Filiais', { params: { userId: getUser(), token: getToken() } })
            setListaFiliais(filiais.data)

            const historico = await api.get('retornaHistoricoComex', { params: { userId: getUser(), token: getToken() } })
            setHistoricoEdicao(historico.data)

            const produtos = await api.get('Itens', { params: { userId: getUser(), token: getToken() } })
            setListaProdutos(produtos.data)

            const pedidosSap = await api.get('listaPedidosImportacaoSAP', { params: { userId: getUser(), token: getToken() } })
            setListaPedidosSap(pedidosSap.data)

            const idUsuario = JSON.parse(localStorage.getItem('permissoes'))[0].idUsuario
            const permissoes = await api.get('/getPermissoesWorkflow', { params: { userId: getUser(), token: getToken(), idUsuario } })
            setPermissoesColunas(permissoes.data[0])
            if (permissoes.data[0].editar === 1) {
                setPermissaoEditar(true)
            }
            if (permissoes.data[0].salvarPedido === 1) {
                setPermissaoSalvarPedido(true)
            }

            let response_cidade = await api.get('/returnDestinosInsert', { params: { userId: getUser(), token: getToken() } })
            setCidades(response_cidade.data)
        }

        fetchMyApi()

    }, [])

    async function getDadosPedidoSap(numPedido) {
        setPedidoSap(numPedido || '')
        if (numPedido !== null) {
            const dados = await api.get('retornaDadosPedidoSap', { params: { numPedido, userId: getUser(), token: getToken() } })
            autoCompletaInput(dados.data)
        }

    }

    function autoCompletaInput(dados) {
        if (dados.length > 0) {
            setQuantidade(dados[0]['Quantidade'])
            setProduto(dados[0]['produto'] || '')
            setExportador(dados[0]['fornecedor'] || '')
            setFilial(dados[0]['Filial'] || '')
            setEtb(dados[0]['etb'] || '1000-01-01')
            setEtc(dados[0]['etc'] || '1000-01-01')
        }
    }

    async function salvarPedido() {
        setSalvandoPedido(true)
        const save = await api.get('salvarPedidoImport', {
            params: {
                userId: getUser(),
                token: getToken(),
                'pedido_sap': pedidoSap,
                exportador,
                produto,
                quantidade,
                filial,
                modalidade,
                porto,
                contrato,
                vencimento,
                vencimentoDemurrage,
                navio,
                demurrage,
                pago,
                eta,
                etb,
                etc,
                quantidadeTotal,
                prancha,
                descontoChuva,
                valorUnit,
                proformaInvoice,
                comercialInvoice,
                coa,
                coo,
                bl,
                seguro,
                li,
                di,
                notaMae,
                glme,
                cdd,
                ci,
                frete,
                desistiva,
                cambio,
                taxaAfrmm,
                agentePortuario,
                operadorPortuario,
                berco,
                'armazemMic': JSON.stringify(armazemMic),
                numeroProformaInvoice,
                numeroComercialInvoice,
                numeroBl,
                numeroDi,
                numeroLi,
                numeroNotaMae,
                situacaoDemurrage,
                situacaoAfrmm,
                taxaBl,
                saldoAfrmm,
                comentario,
                despachante,
                taxaSiscomex,
                'outrosCustos': JSON.stringify(outrosCustos),
                dataPedido,
                dataNf,
                dataComercialInvoice,
                paisOrigem,
                cambioDemurrage,
                cambioDesistiva,
                cambioSaldoAfrmm,
                afrmmTotalReal: '0'

            }
        })

        if (save.data === 'OK') {
            alert('Pedido salvo com sucesso!')
            window.location.reload()
        } else {
            alert('Erro ao salvar pedido...')
        }
        setSalvandoPedido(false)
    }


    function montaUpdate(valor, id, key) {
        let aux = valoresEditados

        aux[id + '+' + key] = valor
        setValoresEditados(aux)

    }

    /*function calcularDiferencaDias(eta, etc) {
        const dataETC = new Date(etc);
        const dataETA = new Date(eta);
      
        const diferenca = (dataETC - dataETA) / (1000 * 3600 * 24);
        return diferenca;
      }*/

    async function salvarEdicao() {
        setSalvandoEdicao(true)
        let aux = ''
        Object.keys(valoresEditados).forEach(
            (key) => (aux = aux + key + '#' + valoresEditados[key] + ';')
        )

        const salvar = await api.get('/salvarEdicaoImport', {
            params: {
                userId: getUser(),
                token: getToken(),
                'edicao': aux,
                'paraArquivar': paraArquivar.join('-'),
                'paraDesarquivar': paraDesarquivar.join('-')
            }
        })

        if (salvar.data === 'OK') {
            alert('Edição salva com sucesso!')
            window.location.reload()
        } else {
            alert('Erro ao salvar edição...')
        }
        setSalvandoEdicao(false)
    }

    function removerArmazemMic() {
        let aux = [...armazemMic]
        if (aux.length > 1) {
            aux.pop()
        }

        setArmazemMic(aux)
    }

    function montaInputArmazemMic(valor, index, nome) {
        let lista = [...armazemMic]
        lista[index][nome] = valor
        setArmazemMic(lista)
    }


    function addArmazemEdicao(index) {
        let aux = JSON.parse(JSON.stringify(pedidosImport))
        aux[index].armazemMic = JSON.parse(aux[index].armazemMic)
        aux[index].armazemMic.push({ armazem: "", mic: "", quantidadeArmazem: "", descargaReal: "", idIncrement: 'add_' + aux[index].id + (aux[index].armazemMic.length + 1) })

        aux[index].armazemMic = JSON.stringify(aux[index].armazemMic)
        setPedidosImport(aux)
    }

    function removeArmazemEdicao(indexPedido, indexArmazemMic, idIncrement, idPedido) {
        let aux = [...escondeArmazem]
        aux[indexPedido][indexArmazemMic] = true

        // Esse bloquinho é pra caso o usuario adicione um armazem e coloque um valor, mas depois remove ele (ai tem que remover o valor da edição)
        // Nao deu pra usar um array auxilar pra setar o valoresEditados (eu tentei copiar ele mas sempre ficava como [])
        // Mas o operador delete meio que ignora toda a função de um setState, e altera diretamente o state
        let keyArmazem = idPedido + '+' + 'armazem-' + idIncrement
        let keyMic = idPedido + '+' + 'mic-' + idIncrement
        let keyQuantidadeArmazem = idPedido + '+' + 'quantidadeArmazem-' + idIncrement
        let keyDescargaReal = idPedido + '+' + 'descargaReal-' + idIncrement

        if (keyArmazem in valoresEditados) {
            delete valoresEditados[keyArmazem]
        }
        if (keyMic in valoresEditados) {
            delete valoresEditados[keyMic]
        }
        if (keyQuantidadeArmazem in valoresEditados) {
            delete valoresEditados[keyQuantidadeArmazem]
        }
        if (keyDescargaReal in valoresEditados) {
            delete valoresEditados[keyDescargaReal]
        }


        if (!String(idIncrement).includes('add_')) {
            montaUpdate('deletaArmazem123', idPedido, idIncrement)
        }
        setEscondeArmazem(aux)
    }

    function removerCustoEdicao(indexPedido, indexCusto, idCusto, idPedido) {
        let aux = [...escondeCusto]
        aux[indexPedido][indexCusto] = true

        let keyNomeCusto = idPedido + '+' + 'nomeCusto-' + idCusto
        let keyValor = idPedido + '+' + 'valor-' + idCusto

        if (keyNomeCusto in valoresEditados) {
            delete valoresEditados[keyNomeCusto]
        }
        if (keyValor in valoresEditados) {
            delete valoresEditados[keyValor]
        }

        if (!String(idCusto).includes('addCusto_')) {
            montaUpdate('deletaCusto123', idPedido, idCusto)
        }
        setEscondeArmazem(aux)
    }

    function addCustoPortuario(index) {
        let aux = JSON.parse(JSON.stringify(pedidosImport))

        aux[index].outrosCustosJson = JSON.parse(aux[index].outrosCustosJson)

        aux[index].outrosCustosJson.push({ nomeCusto: "", valor: "", cambioCusto: "", idCusto: 'addCusto_' + aux[index].id + (aux[index].outrosCustosJson.length + 1) })

        aux[index].outrosCustosJson = JSON.stringify(aux[index].outrosCustosJson)
        setPedidosImport(aux)
    }

    function criaArrayEscondeArmazem(pedidos) {
        let aux = []
        pedidos.map((item, index) => {
            let subArray = []
            JSON.parse(item.armazemMic).map(() => subArray.push(false))
            aux.push(subArray)
            return ''
        })
        setEscondeArmazem(aux)
    }

    function criaArrayEscondeCusto(pedidos) {
        let aux = []
        pedidos.map((item, index) => {
            let subArray = []
            JSON.parse(item.outrosCustosJson).map(() => subArray.push(false))
            aux.push(subArray)
            return ''
        })
        setEscondeCusto(aux)
    }

    function handleCancelarEdicao() {
        setPedidoSendoEditado('')
        setValoresEditados([])
        criaArrayEscondeArmazem(pedidosImport)
        criaArrayEscondeCusto(pedidosImport)
        setPedidosImport(pedidosImportDefault)
    }

    function baixarTabela() {
        const data = JSON.parse(JSON.stringify(pedidosFormatados))
        data.map(item => (delete item['ID'], delete item['COMENTARIO'], delete item['ARQUIVADO']))

        const fileName = 'ativos_arquivados'
        const exportType = exportFromJSON.types.xls

        exportFromJSON({ data, fileName, exportType })
    }

    function baixarRelFinanceiro() {
        const data = relFinanceiro

        const fileName = 'financeiro'
        const exportType = exportFromJSON.types.xls

        exportFromJSON({ data, fileName, exportType })
    }

    function baixarRelLineup() {
        const data = relLineup

        const fileName = 'lineup'
        const exportType = exportFromJSON.types.xls

        exportFromJSON({ data, fileName, exportType })
    }

    function baixarRelDesembaraco() {
        const data = relDesembaraco

        const fileName = 'desembaraço'
        const exportType = exportFromJSON.types.xls

        exportFromJSON({ data, fileName, exportType })
    }

    function baixarRelQuebras() {
        const data = relQuebras

        const fileName = 'quebras'
        const exportType = exportFromJSON.types.xls

        exportFromJSON({ data, fileName, exportType })
    }

    async function gerarRelatorioDespesaPortuaria() {
        setGerandoRelDespesa(true)
        const relatorio = await api.get('rel_despesa_portuaria', { params: { userId: getUser(), token: getToken() } })
        setRelatorioComex(relatorio.data)
    }

    async function gerarRelatorioCustos() {
        setGerandoRelCustos(true)
        const relatorio = await api.get('rel_custos_comex', { params: { userId: getUser(), token: getToken() } })
        setRelatorioCustos(relatorio.data)
    }

    async function baixarRelatorioDespesa() {
        const data = relatorioComex
        const fileName = 'relatorioDeCustos'
        const exportType = exportFromJSON.types.xls

        exportFromJSON({ data, fileName, exportType })
    }

    async function baixarRelatorioCustos() {
        const data = relatorioCustos
        const fileName = 'relatorioDeCustos'
        const exportType = exportFromJSON.types.xls

        exportFromJSON({ data, fileName, exportType })
    }

    function baixarTabelaAtivos() {
        const data = JSON.parse(JSON.stringify(pedidosFormatadosAtivos))
        data.map(item => (delete item['ID'], delete item['COMENTARIO'], delete item['ARQUIVADO']))

        const fileName = 'ativos'
        const exportType = exportFromJSON.types.xls

        exportFromJSON({ data, fileName, exportType })
    }

    function baixarTabelaArquivados() {
        const data = JSON.parse(JSON.stringify(pedidosFormatadosArquivados))
        data.map(item => (delete item['OUTROS CUSTOS'], delete item['ID'], delete item['COMENTARIO'], delete item['ARQUIVADO']))

        const fileName = 'arquivados'
        const exportType = exportFromJSON.types.xls

        exportFromJSON({ data, fileName, exportType })
    }



    function arquivar(id, checked) {
        let aux = [...paraArquivar]
        if (checked) {
            aux.push(id)
            setParaArquivar(aux)
        } else {
            let index = aux.indexOf(id)
            if (index !== -1) {
                aux.splice(index, 1)
            }
            setParaArquivar(aux)
        }
    }

    function desarquivar(id, checked) {
        let aux = [...paraDesarquivar]
        if (checked) {
            aux.push(id)
            setParaDesarquivar(aux)
        } else {
            let index = aux.indexOf(id)
            if (index !== -1) {
                aux.splice(index, 1)
            }
            setParaDesarquivar(aux)
        }
    }

    function montaInputOutrosCustos(valor, index, nome) {
        let lista = [...outrosCustos]
        lista[index][nome] = valor
        setOutrosCustos(lista)

    }

    function removerCusto() {
        let aux = [...outrosCustos]
        aux.pop()
        setOutrosCustos(aux)
    }

    async function excluirPedido(idPedido) {
        setExcluindo(true)
        const response = await api.get('excluirPedidoImport', { params: { userId: getUser(), token: getToken(), idPedido } })
        if (response.data === 'NOK') {
            alert('Erro ao excluir...')
        } else {
            alert('Pedido excluido!')
            window.location.reload()
        }
        setExcluindo(false)
    }

    async function duplicarPedido(idPedido) {
        setDuplicando(true)
        const response = await api.get('duplicarPedidoImport', { params: { userId: getUser(), token: getToken(), idPedido } })
        if (response.data === 'NOK') {
            alert('Erro ao duplicar...')
        } else {
            alert('Pedido duplicado!')
            window.location.reload()
        }
        setDuplicando(false)
    }

    async function atualizarWorkflowComex() {
        setAtualizando(true)
        const response = await api.get('atualizarWorkflowComex', { params: { userId: getUser(), token: getToken() } })
        if (response.data === 'NOK') {
            alert('Erro ao atualizar...')
        } else {
            alert('Workflow atualizado!')
            window.location.reload()
        }
        setAtualizando(false)
    }


    const [gerandoArquivo, setGerandoArquivo] = useState(false)
    async function getArquivo(numPedido, nomeArquivo, index) {
        setGerandoArquivo(true)

        const response = await api.get('getArquivosWorkflow', { params: { numPedido, nomeArquivo, userId: getUser(), token: getToken() } })


        if (response.data === 'NOK') {
            alert('Erro ao gerar arquivo...')
        } else if (response.data !== 'SEM-ARQUIVO') {
            let arquivos = response.data
            let aux = JSON.parse(JSON.stringify(pedidosImport))
            aux[index][nomeArquivo + 'Arquivos'] = arquivos

            setPedidosImport(aux)
        } else {
            alert(`Arquivo "${nomeArquivo}" do pedido ${numPedido} ainda não foi inserido...`)
        }
        setGerandoArquivo(false)

    }

    function validarArquivo(arquivos, numPedido, nomeArquivo, index) {
        return (
            <div>
                {arquivos !== undefined && arquivos !== '' ?
                    <div>
                        {arquivos.map((item, index) =>
                            <div style={{ border: '1px solid black', marginTop: 2 }}>
                                <a href={item.arquivo} download={`${nomeArquivo} ${index + 1} (${numPedido})`} >Doc {(index + 1)}</a>
                                {'\n'}
                            </div>)}
                    </div> : <button disabled={gerandoArquivo} onClick={() => getArquivo(numPedido, nomeArquivo, index)}>Gerar</button>
                }
            </div>
        )
    }

    return (
        permitido === true ?
            <div>

                {/* INPUTS DO PARA CRIAÇÃO DO PEDIDO */}
                {permissaoSalvarPedido ?
                    <div style={{ boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px' }}>

                        <div style={{ background: 'black', color: 'white', display: 'flex', flexDirection: 'row' }}>
                            <Button style={{ background: 'black', color: 'white' }} onClick={() => setAba(0)}>Primeiros Lançamentos</Button>
                            <Button style={{ background: 'black', color: 'white' }} onClick={() => setAba(1)}>Lançamento de line-up</Button>
                            <Button style={{ background: 'black', color: 'white' }} onClick={() => setAba(2)}>Processo de Desembaraço</Button>
                            <Button style={{ background: 'black', color: 'white' }} onClick={() => setAba(3)}>Dados da Logística</Button>
                            <Button style={{ background: 'black', color: 'white' }} onClick={() => setAba(4)}>Despesa Portuária</Button>

                        </div>

                        <div style={{ background: 'white' }}>
                            {
                                aba === 0 ?
                                    <div key={0} className={classes.inputDiv}>
                                        <div className={classes.divRow}>
                                            <Autocomplete
                                                value={pedidoSap || ''}
                                                onChange={(index, valor) => getDadosPedidoSap(valor)}
                                                getOptionSelected={() => true}
                                                className={classes.textField}
                                                options={listaPedidosSap.map((item) => item.numeroSAP.toString())}
                                                renderInput={(params) => <TextField variant='outlined' {...params} label='Pedido SAP' />}
                                            />

                                            <Autocomplete
                                                value={exportador}
                                                getOptionSelected={() => true}
                                                freeSolo
                                                className={classes.textField}
                                                options={['Ameropa', 'Keytrade', 'Phosagro', 'JSC-BPC', 'SQM', 'DREYMOOR', 'BULKFERT', 'INDAGRO', 'SAFTCO']}
                                                onChange={(index, valor) => setExportador(valor || '')}
                                                renderInput={(params) => <TextField variant='outlined' {...params} label='Exportador' />}
                                            />

                                            <Autocomplete
                                                getOptionSelected={() => true}
                                                value={produto}
                                                className={classes.textField}
                                                options={Object.keys(listaProdutos).map((item) => (listaProdutos[item].ItemName))}
                                                onChange={(index, valor) => setProduto(valor || '')}
                                                renderInput={(params) => <TextField variant='outlined' {...params} label='Produto' />}
                                            />
                                        </div>



                                        <div className={classes.divRow}>

                                            <Autocomplete
                                                getOptionSelected={() => true}
                                                value={porto}
                                                className={classes.textField}
                                                options={Object.keys(cidades).map((item) => (cidades[item].City_uf))}
                                                onChange={(index, valor) => setPorto(valor || '')}
                                                renderInput={(params) => <TextField variant='outlined' {...params} label='Porto' />}
                                            />

                                            <Autocomplete
                                                getOptionSelected={() => true}
                                                value={contrato}
                                                className={classes.textField}
                                                options={contratoOptions}
                                                onChange={(index, valor) => setContrato(valor || '')}
                                                renderInput={(params) => <TextField variant='outlined' {...params} label='Contrato' />}
                                            />

                                            <TextField
                                                value={vencimento}
                                                className={classes.textField}
                                                InputLabelProps={{ shrink: true }}
                                                inputProps={{ max: '9999-12-31' }}
                                                type='date'
                                                variant='outlined'
                                                onChange={(e) => setVencimento(e.target.value)} label='Vencimento'
                                            />
                                        </div>

                                        <div className={classes.divRow}>
                                            <TextField
                                                value={navio}
                                                className={classes.textField}
                                                variant='outlined'
                                                onChange={(e) => setNavio(e.target.value)}
                                                label='Navio'
                                            />

                                            <TextField
                                                value={demurrage}
                                                className={classes.textField}
                                                type='number'
                                                variant='outlined'
                                                onChange={(e) => setDemurrage(e.target.value)}
                                                label='Demurrage Diária'
                                            />

                                            <TextField
                                                value={pago}
                                                className={classes.textField}
                                                type='number'
                                                variant='outlined'
                                                onChange={(e) => setPago(e.target.value)}
                                                label='Pago'
                                            />
                                        </div>

                                        <div className={classes.divRow}>
                                            <TextField
                                                value={quantidade}
                                                className={classes.textField}
                                                type='number'
                                                variant='outlined'
                                                onChange={(e) => setQuantidade(e.target.value)}
                                                label='Quantidade'
                                            />

                                            <Autocomplete
                                                getOptionSelected={() => true}
                                                value={filial}
                                                className={classes.textField}
                                                options={Object.keys(listaFiliais).map((item) => (listaFiliais[item].BPLName.split(' ')[0]))}
                                                onChange={(index, valor) => setFilial(valor || '')}
                                                renderInput={(params) => <TextField variant='outlined' {...params} label='Filial' />}
                                            />

                                            <Autocomplete
                                                getOptionSelected={() => true}
                                                value={modalidade}
                                                className={classes.textField}
                                                options={modalidadeOptions}
                                                onChange={(index, valor) => setModalidade(valor || '')}
                                                renderInput={(params) => <TextField variant='outlined' {...params} label='Modalidade' />}
                                            />
                                        </div>

                                        <div className={classes.divRow}>
                                            <TextField
                                                value={dataPedido}
                                                className={classes.textField}
                                                InputLabelProps={{ shrink: true }}
                                                inputProps={{ max: '9999-12-31' }}
                                                type='date'
                                                variant='outlined'
                                                onChange={(e) => setDataPedido(e.target.value)} label='Data Pedido'
                                            />

                                            <TextField
                                                value={paisOrigem}
                                                className={classes.textField}

                                                variant='outlined'
                                                onChange={(e) => setPaisOrigem(e.target.value)}
                                                label='País Origem'
                                            />
                                        </div>


                                    </div>
                                    : aba === 1 ?
                                        <div key={1} className={classes.inputDiv}>
                                            <div className={classes.divRow}>
                                                <TextField
                                                    value={eta}
                                                    className={classes.textField}
                                                    InputLabelProps={{ shrink: true }}
                                                    inputProps={{ max: '9999-12-31' }}
                                                    type='date'
                                                    variant='outlined'
                                                    onChange={(e) => setEta(e.target.value)} label='ETA'
                                                />

                                                <TextField
                                                    value={etb}
                                                    className={classes.textField}
                                                    InputLabelProps={{ shrink: true }}
                                                    inputProps={{ max: '9999-12-31' }}
                                                    type='date'
                                                    variant='outlined'
                                                    onChange={(e) => setEtb(e.target.value)} label='ETB'
                                                />

                                                <TextField
                                                    value={etc}
                                                    className={classes.textField}
                                                    InputLabelProps={{ shrink: true }}
                                                    inputProps={{ max: '9999-12-31' }}
                                                    type='date'
                                                    variant='outlined'
                                                    onChange={(e) => setEtc(e.target.value)} label='ETC'
                                                />
                                            </div>

                                            <div className={classes.divRow}>
                                                <TextField
                                                    value={quantidadeTotal}
                                                    className={classes.textField}
                                                    type='number'
                                                    variant='outlined'
                                                    onChange={(e) => setQuantidadeTotal(e.target.value)}
                                                    label='Quantidade Total do Navio'
                                                />




                                            </div>

                                            <div className={classes.divRow}>
                                                <Autocomplete
                                                    getOptionSelected={() => true}
                                                    value={situacaoDemurrage}
                                                    className={classes.textField}
                                                    options={options2}
                                                    onChange={(index, valor) => setSituacaoDemurrage(valor || '')}
                                                    renderInput={(params) => <TextField variant='outlined' {...params} label='Situação Demurrage' />}
                                                />

                                                {/*<Autocomplete
                                                    getOptionSelected={() => true}
                                                    value={statusFinanceiro}
                                                    className={classes.textField}
                                                    options={options3}
                                                    onChange={(index, valor) => setStatusFinanceiro('Pago' || 'Não pago')}
                                                    renderInput={(params) => <TextField variant='outlined' {...params} label='Status Financeiro' />}
                                                />

                                                <Autocomplete
                                                    getOptionSelected={() => true}
                                                    value={statusLineUp}
                                                    className={classes.textField}
                                                    options={options4}
                                                    onChange={(index, valor) => setStatusLineUp('Descarregando' || 'Finalizado')}
                                                    renderInput={(params) => <TextField variant='outlined' {...params} label='Status Line Up' />}
                                                />*/}

                                                <Autocomplete
                                                    getOptionSelected={() => true}
                                                    value={situacaoAfrmm}
                                                    className={classes.textField}
                                                    options={options2}
                                                    onChange={(index, valor) => setSituacaoAfrmm(valor || '')}
                                                    renderInput={(params) => <TextField variant='outlined' {...params} label='Situação AFRMM' />}
                                                />
                                            </div>
                                        </div>
                                        : aba === 2 ?
                                            <div key={2} className={classes.inputDiv}>
                                                <div className={classes.divRow}>
                                                    <TextField
                                                        value={valorUnit}
                                                        className={classes.textField}
                                                        type='number'
                                                        variant='outlined'
                                                        onChange={(e) => setValorUnit(e.target.value)}
                                                        label='Valor Unidade'
                                                    />

                                                    <Autocomplete
                                                        getOptionSelected={() => true}
                                                        value={coa}
                                                        className={classes.textField}
                                                        options={options1}
                                                        onChange={(index, valor) => setCoa(valor || '')}
                                                        renderInput={(params) => <TextField variant='outlined' {...params} label='COA' />}
                                                    />

                                                    <Autocomplete
                                                        getOptionSelected={() => true}
                                                        value={coo}
                                                        className={classes.textField}
                                                        options={options1}
                                                        onChange={(index, valor) => setCoo(valor || '')}
                                                        renderInput={(params) => <TextField variant='outlined' {...params} label='COO' />}
                                                    />

                                                </div>

                                                <div className={classes.divRow}>

                                                    <TextField
                                                        value={cambio}
                                                        className={classes.textField}
                                                        type='number'
                                                        variant='outlined'
                                                        onChange={(e) => setCambio(e.target.value)}
                                                        label='Câmbio'
                                                    />



                                                    <TextField
                                                        value={seguro}
                                                        className={classes.textField}
                                                        type='number'
                                                        variant='outlined'
                                                        onChange={(e) => setSeguro(e.target.value)}
                                                        label='Seguro'
                                                    />

                                                </div>

                                                <div className={classes.divRow}>

                                                    <div className={classes.inputGroup}>
                                                        <Autocomplete
                                                            getOptionSelected={() => true}
                                                            value={li}
                                                            style={{ width: 200 }}
                                                            options={options1}
                                                            onChange={(index, valor) => setLi(valor || '')}
                                                            renderInput={(params) => <TextField variant='outlined' {...params} label='LI' />}
                                                        />
                                                        <TextField
                                                            style={{ width: 100 }}
                                                            onChange={(e) => setNumeroLi(e.target.value)}
                                                            variant='outlined'
                                                            label='Número'
                                                        />
                                                    </div>



                                                    <Autocomplete
                                                        getOptionSelected={() => true}
                                                        value={glme}
                                                        className={classes.textField}
                                                        options={glmeOptions}
                                                        onChange={(index, valor) => setGlme(valor || '')}
                                                        renderInput={(params) => <TextField variant='outlined' {...params} label='GLME' />}
                                                    />

                                                    <Autocomplete
                                                        getOptionSelected={() => true}
                                                        value={cdd}
                                                        className={classes.textField}
                                                        options={options1}
                                                        onChange={(index, valor) => setCdd(valor || '')}
                                                        renderInput={(params) => <TextField variant='outlined' {...params} label='CDD' />}
                                                    />

                                                </div>

                                                <div className={classes.divRow}>
                                                    <Autocomplete
                                                        getOptionSelected={() => true}
                                                        value={ci}
                                                        className={classes.textField}
                                                        options={options1}
                                                        onChange={(index, valor) => setCi(valor || '')}
                                                        renderInput={(params) => <TextField variant='outlined' {...params} label='CI' />}
                                                    />



                                                    <TextField
                                                        value={desistiva}
                                                        className={classes.textField}
                                                        type='number'
                                                        variant='outlined'
                                                        onChange={(e) => setDesistiva(e.target.value)}
                                                        label='Desistiva'
                                                    />
                                                </div>

                                                <div className={classes.divRow}>

                                                    <div className={classes.inputGroup}>
                                                        <Autocomplete
                                                            getOptionSelected={() => true}
                                                            value={proformaInvoice}
                                                            style={{ width: 200 }}
                                                            options={['Em andamento', 'Não se aplica', 'Concluído']}
                                                            onChange={(index, valor) => setProformaInvoice(valor || '')}
                                                            renderInput={(params) => <TextField variant='outlined' {...params} label='Proforma Invoice' />}
                                                        />
                                                        <TextField
                                                            style={{ width: 100 }}
                                                            onChange={(e) => setNumeroProformaInvoice(e.target.value)}
                                                            variant='outlined'
                                                            label='Número'
                                                        />
                                                    </div>

                                                    <div className={classes.inputGroup}>
                                                        <Autocomplete
                                                            getOptionSelected={() => true}
                                                            value={comercialInvoice}
                                                            style={{ width: 200 }}
                                                            options={options1}
                                                            onChange={(index, valor) => setComercialInvoice(valor || '')}
                                                            renderInput={(params) => <TextField variant='outlined' {...params} label='Comercial Invoice' />}
                                                        />

                                                        <TextField
                                                            style={{ width: 100 }}
                                                            onChange={(e) => setNumeroComercialInvoice(e.target.value)}
                                                            variant='outlined'
                                                            label='Número'
                                                        />
                                                    </div>

                                                    <div className={classes.inputGroup}>
                                                        <Autocomplete
                                                            getOptionSelected={() => true}
                                                            value={di}
                                                            style={{ width: 200 }}
                                                            options={options1}
                                                            onChange={(index, valor) => setDi(valor || '')}
                                                            renderInput={(params) => <TextField variant='outlined' {...params} label='DI' />}
                                                        />

                                                        <TextField
                                                            style={{ width: 100 }}
                                                            onChange={(e) => setNumeroDi(e.target.value)}
                                                            variant='outlined'
                                                            label='Número'
                                                        />
                                                    </div>



                                                </div>

                                                <div className={classes.divRow}>
                                                    <div className={classes.inputGroup}>
                                                        <Autocomplete
                                                            getOptionSelected={() => true}
                                                            value={bl}
                                                            style={{ width: 200 }}
                                                            options={options1}
                                                            onChange={(index, valor) => setBl(valor || '')}
                                                            renderInput={(params) => <TextField variant='outlined' {...params} label='BL' />}
                                                        />

                                                        <TextField
                                                            style={{ width: 100 }}
                                                            onChange={(e) => setNumeroBl(e.target.value)}
                                                            variant='outlined'
                                                            label='Número'
                                                        />
                                                    </div>

                                                    <div className={classes.inputGroup}>
                                                        <Autocomplete
                                                            getOptionSelected={() => true}
                                                            value={notaMae}
                                                            style={{ width: 200 }}
                                                            options={options1}
                                                            onChange={(index, valor) => setNotaMae(valor || '')}
                                                            renderInput={(params) => <TextField variant='outlined' {...params} label='Nota Mãe' />}
                                                        />

                                                        <TextField
                                                            style={{ width: 100 }}
                                                            onChange={(e) => setNumeroNotaMae(e.target.value)}
                                                            variant='outlined'
                                                            label='Número'
                                                        />
                                                    </div>

                                                    <TextField
                                                        value={dataNf}
                                                        className={classes.textField}
                                                        InputLabelProps={{ shrink: true }}
                                                        inputProps={{ max: '9999-12-31' }}
                                                        type='date'
                                                        variant='outlined'
                                                        onChange={(e) => setDataNf(e.target.value)} label='Data NF'
                                                    />
                                                </div>
                                                <div className={classes.divRow}>
                                                    <TextField
                                                        value={dataComercialInvoice}
                                                        className={classes.textField}
                                                        InputLabelProps={{ shrink: true }}
                                                        inputProps={{ max: '9999-12-31' }}
                                                        type='date'
                                                        variant='outlined'
                                                        onChange={(e) => setDataComercialInvoice(e.target.value)} label='Data Invoice'
                                                    />
                                                </div>
                                            </div>
                                            : aba === 3 ?
                                                <div key={3} className={classes.inputDiv}>
                                                    <div className={classes.divRow}>
                                                        <TextField
                                                            value={agentePortuario}
                                                            className={classes.textField}
                                                            variant='outlined'
                                                            onChange={(e) => setAgentePortuario(e.target.value)}
                                                            label='Agente Portuário'
                                                        />


                                                        <TextField
                                                            value={operadorPortuario}
                                                            className={classes.textField}
                                                            variant='outlined'
                                                            onChange={(e) => setOperadorPortuario(e.target.value)}
                                                            label='Operador Portuário'
                                                        />

                                                        <TextField
                                                            value={berco}
                                                            className={classes.textField}
                                                            variant='outlined'
                                                            onChange={(e) => setBerco(e.target.value)}
                                                            label='Berço'
                                                        />
                                                    </div>

                                                    <div style={{ border: 'solid 1px rgba(0,0,0, .2)', padding: 10, borderRadius: 4 }}>
                                                        <div>
                                                            <Button
                                                                onClick={() => setArmazemMic([...armazemMic, { armazem: '', quantidadeArmazem: '', mic: '', descargaReal: '' }])}>Adicionar Armazém</Button>
                                                            <Button
                                                                onClick={() => removerArmazemMic()}>Remover Armazém</Button>
                                                        </div>

                                                        {armazemMic.map((obj, index) => {
                                                            return (
                                                                <div key={index} style={{ display: 'flex', flexDirection: 'row', margin: 10, alignItems: 'center', alignContent: 'center' }}>
                                                                    <TextField
                                                                        value={obj['armazem']}
                                                                        onChange={(e) => montaInputArmazemMic(e.target.value, index, 'armazem')}
                                                                        variant='outlined'
                                                                        label='Distribuição do Armazém'
                                                                    />
                                                                    --
                                                                    <TextField
                                                                        value={obj['quantidadeArmazem']}
                                                                        onChange={(e) => montaInputArmazemMic(e.target.value, index, 'quantidadeArmazem')}
                                                                        variant='outlined'
                                                                        label='Quantidade'
                                                                        type='number'
                                                                    />
                                                                    --
                                                                    <TextField
                                                                        value={obj['mic']}
                                                                        onChange={(e) => montaInputArmazemMic(e.target.value, index, 'mic')}
                                                                        variant='outlined'
                                                                        label='MIC'
                                                                    />
                                                                    --
                                                                    <TextField
                                                                        value={obj['descargaReal']}
                                                                        onChange={(e) => montaInputArmazemMic(e.target.value, index, 'descargaReal')}
                                                                        variant='outlined'
                                                                        label='Descarga Real'
                                                                        type='number'
                                                                    />
                                                                </div>
                                                            )
                                                        })}

                                                    </div>

                                                </div>
                                                : aba === 4 ?
                                                    <div key={4} className={classes.inputDiv}>
                                                        <div className={classes.divRow}>
                                                            <TextField
                                                                value={taxaBl}
                                                                className={classes.textField}
                                                                type='number'
                                                                variant='outlined'
                                                                onChange={(e) => setTaxaBl(e.target.value)}
                                                                label='Taxa BL'
                                                            />

                                                            <TextField
                                                                value={saldoAfrmm}
                                                                className={classes.textField}
                                                                type='number'
                                                                variant='outlined'
                                                                onChange={(e) => setSaldoAfrmm(e.target.value)}
                                                                label='Saldo AFRMM'
                                                            />

                                                            <TextField
                                                                value={taxaSiscomex}
                                                                className={classes.textField}
                                                                type='number'
                                                                variant='outlined'
                                                                onChange={(e) => setTaxaSiscomex(e.target.value)}
                                                                label='Taxa Siscomex'
                                                            />

                                                        </div>

                                                        <div className={classes.divRow}>
                                                            <TextField
                                                                value={despachante}
                                                                className={classes.textField}
                                                                type='number'
                                                                variant='outlined'
                                                                onChange={(e) => setDespachante(e.target.value)}
                                                                label='Despachante'
                                                            />

                                                            <TextField
                                                                multiline
                                                                value={comentario}
                                                                className={classes.textField}
                                                                variant='outlined'
                                                                onChange={(e) => setComentario(e.target.value)}
                                                                label='Comentário'
                                                            />
                                                        </div>

                                                        <div>
                                                            <div style={{ border: 'solid 1px rgba(0,0,0, .2)', padding: 10, borderRadius: 4 }}>
                                                                <div>
                                                                    <Button
                                                                        onClick={() => setOutrosCustos([...outrosCustos, { nomeCusto: '', valor: '' }])}
                                                                    >Adicionar Custo</Button>
                                                                    <Button
                                                                        onClick={() => removerCusto()}>Remover Custo</Button>
                                                                </div>

                                                                {outrosCustos.map((obj, index) =>

                                                                    <div key={index} style={{ display: 'flex', flexDirection: 'row', margin: 10, alignItems: 'center', alignContent: 'center' }}>
                                                                        <TextField
                                                                            value={obj['nomeCusto']}
                                                                            onChange={(e) => montaInputOutrosCustos(e.target.value, index, 'nomeCusto')}
                                                                            variant='outlined'
                                                                            label='Nome do Custo'
                                                                        />
                                                                        --
                                                                        <TextField
                                                                            value={obj['valor']}
                                                                            onChange={(e) => montaInputOutrosCustos(e.target.value, index, 'valor')}
                                                                            variant='outlined'
                                                                            label='Valor'
                                                                            type='number'
                                                                        />
                                                                    </div>

                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : null
                            }

                            <div style={{ padding: 10 }}>
                                <Button disabled={salvandoPedido} onClick={() => salvarPedido()}>Salvar Pedido</Button>
                            </div>
                        </div>
                    </div> : null}

                {/* TABELAS EDITÁVEIS */}
                {pedidosImport !== '' && pedidosImport !== null && typeof (pedidosImport) !== 'undefined' && pedidosImport !== 'NOK' ?
                    <div style={{ background: 'white', marginTop: 50, height: 600, boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px' }}>

                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', alignContent: 'center' }}>
                            <div style={{
                                color: 'white', background: 'black',
                                pointerEvents: pedidoSendoEditado !== '' ? 'none' : 'auto',
                                opacity: pedidoSendoEditado !== '' ? .7 : 1
                            }}>
                                <Button style={{ background: 'black', color: 'white' }} onClick={() => setTab(0)}>Tabela Completa</Button>
                                <Button style={{ background: 'black', color: 'white' }} onClick={() => setTab(1)}>Financeiro</Button>
                                <Button style={{ background: 'black', color: 'white' }} onClick={() => setTab(2)}>Lineup</Button>
                                <Button style={{ background: 'black', color: 'white' }} onClick={() => setTab(3)}>Desembaraço</Button>
                                {permissoesColunas.despesaPortuaria === 1 ? <Button style={{ background: 'black', color: 'white' }} onClick={() => setTab(4)}>Despesa Portuária</Button> : <></>}
                            </div>
                            <div style={{ marginRight: 5, marginLeft: 5 }}>
                                <TextField
                                    size='small'
                                    variant='outlined'
                                    label='Pesquisar'
                                    onChange={(e) => setFiltro(e.target.value.toLowerCase())}
                                />
                            </div>


                        </div>

                        <div style={{ overflow: 'scroll', background: 'white' }} >
                            {
                                tab === 0 ? // TABELA COMPLETA
                                    <div key={0} className={classes.divTabela}>
                                        <table>
                                            <thead className={classes.header}>
                                                <tr>
                                                    <th style={{ position: 'sticky', left: 0, background: '#ED3237', fontSize: 14, minWidth: 94 }}>SAP</th>
                                                    <th style={{ position: 'sticky', left: 94, background: '#ED3237', fontSize: 14, minWidth: 94 }}>Exportador</th>
                                                    <th style={{ position: 'sticky', left: 188, background: '#ED3237', fontSize: 14, minWidth: 94 }}>Produto</th>
                                                    <th style={{ position: 'sticky', left: 282, background: '#ED3237', fontSize: 14, minWidth: 94 }}>Navio</th>
                                                    <th style={{ position: 'sticky', left: 376, background: '#ED3237', fontSize: 14, minWidth: 94 }}>Filial</th>
                                                    <th className={classes.thStyle}>País Origem</th>
                                                    <th className={classes.thStyle}>Data Pedido</th>
                                                    <th className={classes.thStyle}>Modalidade</th>
                                                    <th className={classes.thStyle}>Porto</th>
                                                    {permissoesColunas.contrato === 1 ? <th className={classes.thStyle}>Contrato</th> : <></>}
                                                    {permissoesColunas.vencimento === 1 ? <th className={classes.thStyle}>Vencimento</th> : <></>}
                                                    <th className={classes.thStyle}>Quantidade</th>
                                                    {permissoesColunas.valorUnit === 1 ? <th className={classes.thStyle}>Valor Unidade (U$)</th> : <></>}
                                                    {permissoesColunas.qtdPagaTons === 1 ? <th className={classes.thStyle}>Quantidade Paga (Tons)</th> : <></>}
                                                    {permissoesColunas.faltaPagarTons === 1 ? <th className={classes.thStyle}>Falta Pagar (Tons)</th> : <></>}
                                                    {permissoesColunas.valorTotal === 1 ? <th className={classes.thStyle}>Valor Total (U$)</th> : <></>}
                                                    {permissoesColunas.faltaPagar === 1 ? <th className={classes.thStyle}>Falta Pagar (U$)</th> : <></>}
                                                    {permissoesColunas.pago === 1 ? <th className={classes.thStyle}>Pago</th> : <></>}
                                                    <th className={classes.thStyle}>ETA</th>
                                                    <th className={classes.thStyle}>ETB</th>
                                                    <th className={classes.thStyle}>ETC</th>
                                                    {permissoesColunas.situacaoDemurrage === 1 ? <th className={classes.thStyle}>Situação Demurrage</th> : <></>}
                                                    <th className={classes.thStyle}>Câmbio Demurrage</th>
                                                    <th className={classes.thStyle}>Demurrage Gen (U$)</th>
                                                    <th className={classes.thStyle}>Total Navio</th>
                                                    <th className={classes.thStyle}>Dias Demu.</th>
                                                    <th className={classes.thStyle}>Demu. p/ Dia</th>
                                                    <th className={classes.thStyle}>Venc. Demu.</th>
                                                    {permissoesColunas.proformaInvoice === 1 ? <th className={classes.thStyle}>Proforma Invoice</th> : <></>}
                                                    {permissoesColunas.comercialInvoice === 1 ?
                                                        <>
                                                            <th className={classes.thStyle}>Comercial Invoice</th>
                                                            <th className={classes.thStyle}>Data Comercial Invoice</th>
                                                        </>

                                                        : <></>}
                                                    {permissoesColunas.coa === 1 ? <th className={classes.thStyle}>COA</th> : <></>}
                                                    {permissoesColunas.coo === 1 ? <th className={classes.thStyle}>COO</th> : <></>}
                                                    {permissoesColunas.bl === 1 ? <th className={classes.thStyle}>BL</th> : <></>}
                                                    {permissoesColunas.seguro === 1 ? <th className={classes.thStyle}>Câmbio Seguro</th> : <></>}
                                                    {permissoesColunas.seguro === 1 ? <th className={classes.thStyle}>Seguro (U$)</th> : <></>}
                                                    {permissoesColunas.li === 1 ? <th className={classes.thStyle}>LI</th> : <></>}
                                                    {permissoesColunas.di === 1 ? <th className={classes.thStyle}>DI</th> : <></>}
                                                  
                                                    {permissoesColunas.notaMae === 1 ? <th className={classes.thStyle}>Nota Mãe</th> : <></>}
                                                    <th className={classes.thStyle}>Data NF</th>
                                                    <th className={classes.thStyle}>Dias P/ Desembaraço</th>
                                                    <th className={classes.thStyle}>Dias P/ ETB</th>
                                                    {permissoesColunas.glme === 1 ? <th className={classes.thStyle}>GLME</th> : <></>}
                                                    {permissoesColunas.cdd === 1 ? <th className={classes.thStyle}>CDD</th> : <></>}
                                                    {permissoesColunas.ci === 1 ? <th className={classes.thStyle}>CI</th> : <></>}
                                                    <th className={classes.thStyle}>Câmbio Desistiva</th>
                                                    <th className={classes.thStyle}>Desistiva</th>
                                                    {permissoesColunas.cambio === 1 ? <th className={classes.thStyle}>Câmbio Pedido</th> : <></>}
                                                    <th className={classes.thStyle}>Câmbio Total AFRMM</th>
                                                    {permissoesColunas.afrmmTotalReal === 1 ? <th className={classes.thStyle}>AFRMM Total (R$)</th> : <></>}
                                                    {permissoesColunas.afrmmTotalDolar === 1 ? <th className={classes.thStyle}>AFRMM Total (U$)</th> : <></>}
                                                    {permissoesColunas.situacaoAfrmm === 1 ? <th className={classes.thStyle}>Situação AFRMM</th> : <></>}
                                                    <th className={classes.thStyle}>Agente Portuário</th>
                                                    <th className={classes.thStyle}>Operador Portuário</th>
                                                    <th className={classes.thStyle}>Berço</th>
                                                    <th className={classes.thStyle}>Armazém / Quantidade / MIC / Descarga Real</th>
                                                    <th className={classes.thStyle}>Qtd Descarga</th>
                                                    <th className={classes.thStyle}>Comentário</th>
                                                    <th className={classes.thStyle}>Status Financeiro</th>
                                                    <th className={classes.thStyle}>Status Line Up</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {Object.keys(pedidosImport)
                                                    .sort((a, b) => Number(b) - Number(a)) // Ordena os IDs em ordem decrescente
                                                    .map((item, index) => {
                                                        let itemString = JSON.stringify(pedidosImport[item]).toLowerCase();
                                                    return (
                                                        itemString.includes(filtro) || filtro === '' ?
                                                            <tr key={index} >
                                                                <td style={{ position: 'sticky', left: 0, background: '#e6e6e6', zIndex: 1, border: '1px solid rgba(0,0,0, .4)' }}>
                                                                    <div className={classes.cellContentFixed} >
                                                                        {pedidoSendoEditado !== pedidosImport[item].id ?
                                                                            <div>
                                                                                <div>
                                                                                    {pedidosImport[item].pedido_sap}
                                                                                </div>
                                                                                {(pedidoSendoEditado === '' || pedidoSendoEditado === pedidosImport[item].id) && permissaoEditar ?
                                                                                    <div>
                                                                                        <button onClick={() => setPedidoSendoEditado(pedidosImport[item].id)}>Editar</button>
                                                                                    </div>
                                                                                    : null}
                                                                            </div>

                                                                            : <div>
                                                                                <div >
                                                                                    <div><input type="checkbox" onChange={(e) => arquivar(pedidosImport[item].id, e.target.checked)} />Arquivar</div>
                                                                                </div>
                                                                                <div><Autocomplete
                                                                                    classes={{
                                                                                        paper: classes.paper
                                                                                    }}

                                                                                    disableClearable
                                                                                    className={classes.autoComplete}
                                                                                    getOptionSelected={() => true}
                                                                                    onChange={(index, valor) => montaUpdate(valor, pedidosImport[item].id, 'pedido_sap')}
                                                                                    defaultValue={pedidosImport[item].pedido_sap}
                                                                                    options={listaPedidosSap.map((item) => item.numeroSAP.toString())}
                                                                                    renderInput={(params) => (<TextField {...params} inputProps={{ ...params.inputProps, style: { fontSize: 12, fontWeight: 'bold' } }} />)}
                                                                                /></div>

                                                                                {duplicando || excluindo ?
                                                                                    <Loader
                                                                                        type="Circles"
                                                                                        color="#00BFFF"
                                                                                        height={20}
                                                                                        width={20}
                                                                                        timeout={2000000}
                                                                                    />
                                                                                    : <><div><button onClick={() => duplicarPedido(pedidosImport[item].id)}>Duplicar</button></div>
                                                                                        <div><button onClick={() => excluirPedido(pedidosImport[item].id)}>Excluir</button></div>
                                                                                    </>}
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </td>

                                                                <td style={{ position: 'sticky', left: 94, background: '#e6e6e6', zIndex: 1, border: '1px solid rgba(0,0,0, .4)' }}>
                                                                    <div className={classes.cellContentFixed}>
                                                                        {pedidoSendoEditado !== pedidosImport[item].id ? pedidosImport[item].exportador :
                                                                            <Autocomplete
                                                                                classes={{
                                                                                    paper: classes.paper
                                                                                }}
                                                                                freeSolo
                                                                                disableClearable
                                                                                className={classes.autoComplete}
                                                                                getOptionSelected={() => true}
                                                                                onChange={(index, valor) => montaUpdate(valor, pedidosImport[item].id, 'exportador')}
                                                                                defaultValue={pedidosImport[item].exportador}
                                                                                options={['Ameropa', 'Keytrade', 'Phosagro', 'JSC-BPC', 'SQM', 'DREYMOOR', 'BULKFERT', 'INDAGRO', 'SAFTCO']}
                                                                                renderInput={(params) => (<TextField {...params} inputProps={{ ...params.inputProps, style: { fontSize: 12, fontWeight: 'bold' } }} />)}
                                                                            />}
                                                                    </div>

                                                                </td>

                                                                <td style={{ position: 'sticky', left: 188, background: '#e6e6e6', zIndex: 1, border: '1px solid rgba(0,0,0, .4)' }}>
                                                                    <div className={classes.cellContentFixed}>
                                                                        {pedidoSendoEditado !== pedidosImport[item].id ?
                                                                            pedidosImport[item].produto :
                                                                            <Autocomplete
                                                                                classes={{
                                                                                    paper: classes.paper
                                                                                }}
                                                                                className={classes.autoComplete}
                                                                                disableClearable
                                                                                getOptionSelected={() => true}
                                                                                onChange={(index, valor) => montaUpdate(valor, pedidosImport[item].id, 'produto')}
                                                                                defaultValue={pedidosImport[item].produto}
                                                                                options={Object.keys(listaProdutos).map((item) => (listaProdutos[item].ItemName))}
                                                                                renderInput={(params) => (<TextField {...params} inputProps={{ ...params.inputProps, style: { fontSize: 12, fontWeight: 'bold' } }} />)}
                                                                            />}
                                                                    </div>
                                                                </td>

                                                                <td style={{ position: 'sticky', left: 282, background: '#e6e6e6', zIndex: 1, border: '1px solid rgba(0,0,0, .4)' }}>
                                                                    <div className={classes.cellContentFixed}>
                                                                        {pedidoSendoEditado !== pedidosImport[item].id ? pedidosImport[item].navio :
                                                                            <TextField
                                                                                inputProps={{ style: { fontSize: 12, textAlign: 'center', width: 80, fontWeight: 'bold' } }}
                                                                                defaultValue={pedidosImport[item].navio}
                                                                                onChange={(e) => montaUpdate(e.target.value, pedidosImport[item].id, 'navio')}
                                                                            />}
                                                                    </div>
                                                                </td>

                                                                <td style={{ position: 'sticky', left: 376, background: '#e6e6e6', zIndex: 1, border: '1px solid rgba(0,0,0, .4)' }}>
                                                                    <div className={classes.cellContentFixed}>
                                                                        {pedidoSendoEditado !== pedidosImport[item].id ? pedidosImport[item].filial :
                                                                            <Autocomplete
                                                                                classes={{
                                                                                    paper: classes.paper
                                                                                }}
                                                                                disableClearable
                                                                                className={classes.autoComplete}
                                                                                getOptionSelected={() => true}
                                                                                defaultValue={pedidosImport[item].filial}
                                                                                options={Object.keys(listaFiliais).map((item) => (listaFiliais[item].BPLName.split(' ')[0]))}
                                                                                onChange={(index, valor) => montaUpdate(valor, pedidosImport[item].id, 'filial')}
                                                                                renderInput={(params) => (<TextField {...params} inputProps={{ ...params.inputProps, style: { fontSize: 12, fontWeight: 'bold' } }} />)}
                                                                            />}
                                                                    </div>
                                                                </td>

                                                                <td className={classes.tdBorder}>
                                                                    <div className={classes.cellContent}>
                                                                        {pedidoSendoEditado !== pedidosImport[item].id ? pedidosImport[item].paisOrigem :
                                                                            <TextField
                                                                                inputProps={{ style: { fontSize: 12, textAlign: 'center', width: 80, fontWeight: 'bold' } }}
                                                                                defaultValue={pedidosImport[item].paisOrigem}
                                                                                onChange={(e) => montaUpdate(e.target.value, pedidosImport[item].id, 'paisOrigem')}
                                                                            />}
                                                                    </div>
                                                                </td>

                                                                <td className={classes.tdBorder}>
                                                                    <div className={classes.cellContent}>
                                                                        {pedidoSendoEditado !== pedidosImport[item].id ? pedidosImport[item].dataPedido :
                                                                            <TextField
                                                                                className={classes.dataWidth}
                                                                                defaultValue={pedidosImport[item].dataPedido.substr(6) + '-' + pedidosImport[item].dataPedido.substr(3, 2) + '-' + pedidosImport[item].dataPedido.substr(0, 2)}
                                                                                inputProps={{ max: '9999-12-31', style: { fontSize: 12, fontWeight: 'bold' } }}
                                                                                type='date'
                                                                                onChange={(e) => montaUpdate(e.target.value, pedidosImport[item].id, 'dataPedido')}
                                                                            />}
                                                                    </div>
                                                                </td>

                                                                <td className={classes.tdBorder}>
                                                                    <div className={classes.cellContent}>
                                                                        {pedidoSendoEditado !== pedidosImport[item].id ? pedidosImport[item].modalidade :
                                                                            <Autocomplete
                                                                                disableClearable
                                                                                className={classes.autoComplete}
                                                                                getOptionSelected={() => true}
                                                                                defaultValue={pedidosImport[item].modalidade}
                                                                                options={modalidadeOptions}
                                                                                onChange={(index, valor) => montaUpdate(valor, pedidosImport[item].id, 'modalidade')}
                                                                                renderInput={(params) => (<TextField {...params} inputProps={{ ...params.inputProps, style: { fontSize: 12, fontWeight: 'bold' } }} />)}
                                                                            />}
                                                                    </div>
                                                                </td>

                                                                <td className={classes.tdBorder}>
                                                                    <div className={classes.cellContent}>
                                                                        {pedidoSendoEditado !== pedidosImport[item].id ? pedidosImport[item].porto :
                                                                            <Autocomplete
                                                                                classes={{
                                                                                    paper: classes.paper
                                                                                }}
                                                                                disableClearable
                                                                                className={classes.autoComplete}
                                                                                renderInput={(params) => (<TextField {...params} inputProps={{ ...params.inputProps, style: { fontSize: 12, fontWeight: 'bold' } }} />)}
                                                                                getOptionSelected={() => true}
                                                                                defaultValue={pedidosImport[item].porto}
                                                                                options={Object.keys(cidades).map((item) => (cidades[item].City_uf))}
                                                                                onChange={(index, valor) => montaUpdate(valor, pedidosImport[item].id, 'porto')}
                                                                            />}
                                                                    </div>
                                                                </td>

                                                                {permissoesColunas.contrato === 1 ?
                                                                    <td className={classes.tdBorder}>
                                                                        <div className={classes.cellContent}>
                                                                            {pedidoSendoEditado !== pedidosImport[item].id ?
                                                                                <div>

                                                                                    {pedidosImport[item].contrato} {'\n'}

                                                                                    {pedidosImport[item].contrato === 'Concluído' ?
                                                                                        validarArquivo(pedidosImport[item].contratoArquivos, pedidosImport[item].pedido_sap, 'contrato', index)
                                                                                        : null
                                                                                    }
                                                                                </div> :
                                                                                <Autocomplete
                                                                                    classes={{
                                                                                        paper: classes.paper
                                                                                    }}
                                                                                    disableClearable
                                                                                    className={classes.autoComplete}
                                                                                    renderInput={(params) => (<TextField {...params} inputProps={{ ...params.inputProps, style: { fontSize: 12, fontWeight: 'bold' } }} />)}
                                                                                    getOptionSelected={() => true}
                                                                                    defaultValue={pedidosImport[item].contrato}
                                                                                    options={contratoOptions}
                                                                                    onChange={(index, valor) => montaUpdate(valor, pedidosImport[item].id, 'contrato')}
                                                                                />}
                                                                        </div>
                                                                    </td>
                                                                    : <></>}


                                                                {permissoesColunas.vencimento === 1 ?
                                                                    <td className={classes.tdBorder}>
                                                                        <div className={classes.cellContent}>
                                                                            {pedidoSendoEditado !== pedidosImport[item].id ? pedidosImport[item].vencimento :
                                                                                <TextField
                                                                                    className={classes.dataWidth}
                                                                                    defaultValue={pedidosImport[item].vencimento.substr(6) + '-' + pedidosImport[item].vencimento.substr(3, 2) + '-' + pedidosImport[item].vencimento.substr(0, 2)}
                                                                                    inputProps={{ max: '9999-12-31', style: { fontSize: 12, fontWeight: 'bold' } }}
                                                                                    type='date'
                                                                                    onChange={(e) => montaUpdate(e.target.value, pedidosImport[item].id, 'vencimento')}
                                                                                />}
                                                                        </div>
                                                                    </td>
                                                                    : <></>}



                                                                <td className={classes.tdBorder}>
                                                                    <div className={classes.cellContent}>
                                                                        {pedidoSendoEditado !== pedidosImport[item].id ? pedidosImport[item].quantidade :
                                                                            <TextField
                                                                                inputProps={{ style: { fontSize: 12, textAlign: 'center', width: 80, fontWeight: 'bold' } }}
                                                                                onChange={(e) => montaUpdate(e.target.value, pedidosImport[item].id, 'quantidade')}
                                                                                defaultValue={pedidosImport[item].quantidade.replace(',', '.')}
                                                                                type='number'
                                                                            />}
                                                                    </div>
                                                                </td>

                                                                {permissoesColunas.valorUnit === 1 ?
                                                                    <td className={classes.tdBorder}>
                                                                        <div className={classes.cellContent}>
                                                                            {pedidoSendoEditado !== pedidosImport[item].id ? pedidosImport[item].valorUnit.toLocaleString('en-us', { style: 'currency', currency: 'USD' }) :
                                                                                <TextField
                                                                                    inputProps={{ style: { fontSize: 12, textAlign: 'center', width: 80, fontWeight: 'bold' } }}
                                                                                    defaultValue={pedidosImport[item].valorUnit.replace(',', '.')}
                                                                                    type='number'
                                                                                    onChange={(e) => montaUpdate(e.target.value, pedidosImport[item].id, 'valorUnit')}
                                                                                />}
                                                                        </div>
                                                                    </td>
                                                                    : <></>}

                                                                {permissoesColunas.qtdPagaTons === 1 ?
                                                                    <td className={classes.tdBorder}>
                                                                        <div className={classes.cellContent}> {/* CAMPO AUTOMATICO */}
                                                                            {pedidosImport[item].quantidadePaga}
                                                                        </div>
                                                                    </td>
                                                                    : <></>}

                                                                {permissoesColunas.faltaPagarTons === 1 ?
                                                                    <td className={pedidosImport[item].faltaPagarTons === 0 ? classes.green : classes.red}>
                                                                        <div className={classes.cellContent}> {/* CAMPO AUTOMATICO */}
                                                                            {pedidosImport[item].faltaPagarTons}
                                                                        </div>
                                                                    </td>
                                                                    : <></>}

                                                                {permissoesColunas.valorTotal === 1 ?
                                                                    <td className={classes.tdBorder}>
                                                                        <div className={classes.cellContent}> {/* CAMPO AUTOMATICO */}
                                                                            {pedidosImport[item].valorTotal.toLocaleString('en-us', { style: 'currency', currency: 'USD' })}
                                                                        </div>
                                                                    </td>
                                                                    : <></>}

                                                                {permissoesColunas.faltaPagar === 1 ?
                                                                    <td className={pedidosImport[item].faltaPagarDolar === 0 ? classes.green : classes.red}>
                                                                        <div className={classes.cellContent}> {/* CAMPO AUTOMATICO */}
                                                                            {pedidosImport[item].faltaPagarDolar.toLocaleString('en-us', { style: 'currency', currency: 'USD' })}
                                                                        </div>
                                                                    </td>

                                                                    : <></>}

                                                                {permissoesColunas.pago === 1 ?
                                                                    <td className={pedidosImport[item].pago === pedidosImport[item].valorTotal ? classes.green : classes.red}>
                                                                        <div className={classes.cellContent}>
                                                                            {pedidoSendoEditado !== pedidosImport[item].id ? pedidosImport[item].pago.toLocaleString('en-us', { style: 'currency', currency: 'USD' }) :
                                                                                <TextField
                                                                                    inputProps={{ style: { fontSize: 12, textAlign: 'center', width: 80, fontWeight: 'bold' } }}
                                                                                    defaultValue={pedidosImport[item].pago.replace(',', '.')}
                                                                                    type='number'
                                                                                    onChange={(e) => montaUpdate(e.target.value, pedidosImport[item].id, 'pago')}
                                                                                />}
                                                                        </div>
                                                                    </td>
                                                                    : <></>}


                                                                <td className={classes.tdBorder}>
                                                                    <div className={classes.cellContent}>
                                                                        {pedidoSendoEditado !== pedidosImport[item].id ? pedidosImport[item].eta :
                                                                            <TextField
                                                                                className={classes.dataWidth}
                                                                                defaultValue={pedidosImport[item].eta.substr(6) + '-' + pedidosImport[item].eta.substr(3, 2) + '-' + pedidosImport[item].eta.substr(0, 2)}
                                                                                inputProps={{ max: '9999-12-31', style: { fontSize: 12, fontWeight: 'bold' } }}
                                                                                type='date'
                                                                                onChange={(e) => montaUpdate(e.target.value, pedidosImport[item].id, 'eta')}
                                                                            />}
                                                                    </div>
                                                                </td>

                                                                <td className={classes.tdBorder}>
                                                                    <div className={classes.cellContent}>
                                                                        {pedidoSendoEditado !== pedidosImport[item].id ? pedidosImport[item].etb :
                                                                            <TextField
                                                                                className={classes.dataWidth}
                                                                                defaultValue={pedidosImport[item].etb.substr(6) + '-' + pedidosImport[item].etb.substr(3, 2) + '-' + pedidosImport[item].etb.substr(0, 2)}
                                                                                inputProps={{ max: '9999-12-31', style: { fontSize: 12, fontWeight: 'bold' } }}
                                                                                type='date'
                                                                                onChange={(e) => montaUpdate(e.target.value, pedidosImport[item].id, 'etb')}
                                                                            />}
                                                                    </div>
                                                                </td>

                                                                <td className={classes.tdBorder}>
                                                                    <div className={classes.cellContent}>
                                                                        {pedidoSendoEditado !== pedidosImport[item].id ? pedidosImport[item].etc :
                                                                            <TextField
                                                                                className={classes.dataWidth}
                                                                                defaultValue={pedidosImport[item].etc.substr(6) + '-' + pedidosImport[item].etc.substr(3, 2) + '-' + pedidosImport[item].etc.substr(0, 2)}
                                                                                inputProps={{ max: '9999-12-31', style: { fontSize: 12, fontWeight: 'bold' } }}
                                                                                type='date'
                                                                                onChange={(e) => montaUpdate(e.target.value, pedidosImport[item].id, 'etc')}
                                                                            />}
                                                                    </div>
                                                                </td>

                                                                {permissoesColunas.situacaoDemurrage === 1 ?
                                                                    <td className={pedidosImport[item].situacaoDemurrage === 'Paga' ? classes.green : classes.red}>
                                                                        <div className={classes.cellContent}>
                                                                            {pedidoSendoEditado !== pedidosImport[item].id ? pedidosImport[item].situacaoDemurrage :
                                                                                <Autocomplete
                                                                                    classes={{
                                                                                        paper: classes.paper
                                                                                    }}
                                                                                    className={classes.autoComplete}
                                                                                    disableClearable
                                                                                    getOptionSelected={() => true}
                                                                                    defaultValue={pedidosImport[item].situacaoDemurrage}
                                                                                    options={options2}
                                                                                    onChange={(index, valor) => montaUpdate(valor, pedidosImport[item].id, 'situacaoDemurrage')}
                                                                                    renderInput={(params) => (<TextField {...params} inputProps={{ ...params.inputProps, style: { fontSize: 12, fontWeight: 'bold' } }} />)}
                                                                                />
                                                                            }
                                                                        </div>
                                                                    </td>
                                                                    : <></>}

                                                                <td className={classes.tdBorder}>
                                                                    <div className={classes.cellContent}>
                                                                        {pedidoSendoEditado !== pedidosImport[item].id ?
                                                                            pedidosImport[item].cambioDemurrage :
                                                                            <TextField
                                                                                inputProps={{ style: { fontSize: 12, textAlign: 'center', width: 80, fontWeight: 'bold' } }}
                                                                                defaultValue={pedidosImport[item].cambioDemurrage.replace(',', '.')}
                                                                                type='number'
                                                                                onChange={(e) => montaUpdate(e.target.value, pedidosImport[item].id, 'cambioDemurrage')}
                                                                            />}
                                                                    </div>
                                                                </td>

                                                                <td className={classes.tdBorder}>
                                                                    <div className={classes.cellContent}>
                                                                        {pedidoSendoEditado !== pedidosImport[item].id ?
                                                                            pedidosImport[item].demurrageGenDolar.toLocaleString('en-us', { style: 'currency', currency: 'USD' }) :
                                                                            <TextField
                                                                                inputProps={{ style: { fontSize: 12, textAlign: 'center', width: 80, fontWeight: 'bold' } }}
                                                                                defaultValue={pedidosImport[item].demurrageGenDolar.replace(',', '.')}
                                                                                type='number'
                                                                                onChange={(e) => montaUpdate(e.target.value, pedidosImport[item].id, 'demurrageGenDolar')}
                                                                            />}
                                                                    </div>
                                                                </td>

                                                                <td className={classes.tdBorder}>
                                                                    <div className={classes.cellContent}>
                                                                        {pedidoSendoEditado !== pedidosImport[item].id ? (
                                                                            pedidosImport[item].totalNavio
                                                                        ) : (
                                                                            <TextField
                                                                                inputProps={{ style: { fontSize: 12, textAlign: 'center', width: 80, fontWeight: 'bold' } }}
                                                                                defaultValue={pedidosImport[item].totalNavio}
                                                                                type="number"
                                                                                onChange={(e) => montaUpdate(e.target.value, pedidosImport[item].id, 'totalNavio')}
                                                                            />
                                                                        )}
                                                                    </div>
                                                                </td>

                                                                <td className={classes.tdBorder}>
                                                                    <div className={classes.cellContent}>
                                                                        {pedidoSendoEditado !== pedidosImport[item].id ? (
                                                                            // Verifique se as datas ETA e ETC existem
                                                                            pedidosImport[item].eta && pedidosImport[item].etc ? (
                                                                                (() => {

                                                                                    // Converter datas de DD/MM/YYYY para YYYY-MM-DD
                                                                                    const formatDate = (dateStr) => {
                                                                                        const [day, month, year] = dateStr.split('/');
                                                                                        return `${year}-${month}-${day}`;
                                                                                    };

                                                                                    const etaString = formatDate(pedidosImport[item].eta);
                                                                                    const etcString = formatDate(pedidosImport[item].etc);

                                                                                    // Criar objetos de data
                                                                                    const etaDate = new Date(etaString);
                                                                                    const etcDate = new Date(etcString);

                                                                                    // Verificar se as datas foram convertidas corretamente
                                                                                    if (isNaN(etaDate.getTime()) || isNaN(etcDate.getTime())) {
                                                                                        console.log('Data inválida:', { etaDate, etcDate });
                                                                                        return '-'; // Caso as datas não sejam válidas
                                                                                    }

                                                                                    // Calcular a diferença entre as datas em milissegundos
                                                                                    const diffTime = etcDate - etaDate;

                                                                                    // Converter milissegundos para dias
                                                                                    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

                                                                                    // Exibir a quantidade de dias
                                                                                    return `${diffDays} dias`;
                                                                                })()
                                                                            ) : (
                                                                                '-' // Caso não tenha as datas, mostre um '-'
                                                                            )
                                                                        ) : (
                                                                            (() => {
                                                                                // Recalcular os dias para exibir no campo de edição
                                                                                let diffDays = 0;

                                                                                if (pedidosImport[item].eta && pedidosImport[item].etc) {
                                                                                    const formatDate = (dateStr) => {
                                                                                        const [day, month, year] = dateStr.split('/');
                                                                                        return `${year}-${month}-${day}`;
                                                                                    };

                                                                                    const etaString = formatDate(pedidosImport[item].eta);
                                                                                    const etcString = formatDate(pedidosImport[item].etc);

                                                                                    const etaDate = new Date(etaString);
                                                                                    const etcDate = new Date(etcString);

                                                                                    if (!isNaN(etaDate.getTime()) && !isNaN(etcDate.getTime())) {
                                                                                        const diffTime = etcDate - etaDate;
                                                                                        diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

                                                                                    }
                                                                                }

                                                                                return (
                                                                                    <TextField
                                                                                        className={classes.autoComplete}
                                                                                        type="number"
                                                                                        defaultValue={diffDays} // Exibir o cálculo realizado no modo de edição
                                                                                        onChange={(event) => montaUpdate(event.target.value, pedidosImport[item].id, 'diasDemurrage')}
                                                                                        inputProps={{
                                                                                            style: { fontSize: 12, fontWeight: 'bold' }
                                                                                        }}
                                                                                    />
                                                                                );
                                                                            })()
                                                                        )}
                                                                    </div>
                                                                </td>

                                                                <td className={classes.tdBorder}>
                                                                    <div className={classes.cellContent}>
                                                                        {pedidoSendoEditado !== pedidosImport[item].id ? (
                                                                            pedidosImport[item].demuPorDia
                                                                        ) : (
                                                                            <TextField
                                                                                inputProps={{ style: { fontSize: 12, textAlign: 'center', width: 80, fontWeight: 'bold' } }}
                                                                                defaultValue={pedidosImport[item].demuPorDia}
                                                                                type="number"
                                                                                onChange={(e) => montaUpdate(e.target.value, pedidosImport[item].id, 'demuPorDia')}
                                                                            />
                                                                        )}
                                                                    </div>
                                                                </td>

                                                                <td className={classes.tdBorder}>
                                                                    <div className={classes.cellContent}>
                                                                        {pedidoSendoEditado !== pedidosImport[item].id ? (
                                                                            (() => {
                                                                                const vencimento = pedidosImport[item].vencimentoDemurrage;

                                                                                if (vencimento) {
                                                                                    // Se o valor for um número (timestamp), converte para Date
                                                                                    if (typeof vencimento === 'number') {
                                                                                        const data = new Date(vencimento);
                                                                                        return data.toLocaleDateString('pt-BR', {
                                                                                            year: 'numeric',
                                                                                            month: '2-digit',
                                                                                            day: '2-digit',
                                                                                        });
                                                                                    }

                                                                                    // Se o valor for uma string (YYYY-MM-DD), divide e formata
                                                                                    if (typeof vencimento === 'string') {
                                                                                        const [ano, mes, dia] = vencimento.split('-');
                                                                                        return `${dia}/${mes}/${ano}`;
                                                                                    }
                                                                                }

                                                                                // Retorna '-' se o valor for nulo, indefinido ou inválido
                                                                                return '-';
                                                                            })()
                                                                        ) : (
                                                                            <TextField
                                                                                className={classes.dataWidth}
                                                                                defaultValue={
                                                                                    pedidosImport[item].vencimentoDemurrage
                                                                                        ? (typeof pedidosImport[item].vencimentoDemurrage === 'number'
                                                                                            ? new Date(pedidosImport[item].vencimentoDemurrage).toISOString().split('T')[0]
                                                                                            : pedidosImport[item].vencimentoDemurrage)
                                                                                        : '' // Mostra em branco se não houver valor
                                                                                }
                                                                                placeholder={pedidosImport[item].vencimentoDemurrage ? '' : new Date().toISOString().split('T')[0]} // Exibe a data atual como placeholder
                                                                                inputProps={{
                                                                                    max: '9999-12-31',
                                                                                    style: { fontSize: 12, fontWeight: 'bold' },
                                                                                }}
                                                                                type="date"
                                                                                onChange={(e) =>
                                                                                    montaUpdate(e.target.value, pedidosImport[item].id, 'vencimentoDemurrage')
                                                                                }
                                                                            />
                                                                        )}
                                                                    </div>
                                                                </td>
                                                                    {permissoesColunas.proformaInvoice === 1 ?
                                                                    <td className={pedidosImport[item].proformaInvoice === 'Não se aplica' ? {} : pedidosImport[item].proformaInvoice === 'Concluído' ? classes.green : classes.red}>
                                                                        <div className={classes.cellContent}>
                                                                            {pedidoSendoEditado !== pedidosImport[item].id ?
                                                                                <div>
                                                                                    {pedidosImport[item].proformaInvoice} {'\n'}
                                                                                    {pedidosImport[item].numeroProformaInvoice} {'\n'}
                                                                                    {pedidosImport[item].proformaInvoice === 'Concluído' ?
                                                                                        validarArquivo(pedidosImport[item]['proforma invoiceArquivos'], pedidosImport[item].pedido_sap, 'proforma invoice', index)
                                                                                        : null
                                                                                    }
                                                                                </div> :
                                                                                <span>
                                                                                    <Autocomplete
                                                                                        classes={{
                                                                                            paper: classes.paper
                                                                                        }}
                                                                                        disableClearable
                                                                                        className={classes.autoComplete}
                                                                                        renderInput={(params) => (<TextField {...params} inputProps={{ ...params.inputProps, style: { fontSize: 12, fontWeight: 'bold' } }} />)}
                                                                                        getOptionSelected={() => true}
                                                                                        defaultValue={pedidosImport[item].proformaInvoice}
                                                                                        options={['Em andamento', 'Não se aplica', 'Concluído']}
                                                                                        onChange={(index, valor) => montaUpdate(valor, pedidosImport[item].id, 'proformaInvoice')}
                                                                                    /> {'\n'}
                                                                                    <TextField
                                                                                        inputProps={{ style: { fontSize: 12, textAlign: 'center', width: 80, fontWeight: 'bold' } }}
                                                                                        defaultValue={pedidosImport[item].numeroProformaInvoice}
                                                                                        onChange={(e) => montaUpdate(e.target.value, pedidosImport[item].id, 'numeroProformaInvoice')}
                                                                                    />
                                                                                </span>
                                                                            }
                                                                        </div>
                                                                    </td>
                                                                    : <></>}

                                                                {permissoesColunas.comercialInvoice === 1 ?
                                                                    <>
                                                                        <td className={pedidosImport[item].comercialInvoice === 'Concluído' ? classes.green : classes.red}>
                                                                            <div className={classes.cellContent}>
                                                                                {pedidoSendoEditado !== pedidosImport[item].id ?
                                                                                    <div>
                                                                                        {pedidosImport[item].comercialInvoice} {'\n'}
                                                                                        {pedidosImport[item].numeroComercialInvoice} {'\n'}
                                                                                        {pedidosImport[item].comercialInvoice === 'Concluído' ?
                                                                                            validarArquivo(pedidosImport[item]['comercial invoiceArquivos'], pedidosImport[item].pedido_sap, 'comercial invoice', index)
                                                                                            : null
                                                                                        }
                                                                                    </div> :
                                                                                    <span>
                                                                                        <Autocomplete
                                                                                            classes={{
                                                                                                paper: classes.paper
                                                                                            }}
                                                                                            disableClearable
                                                                                            className={classes.autoComplete}
                                                                                            renderInput={(params) => (<TextField {...params} inputProps={{ ...params.inputProps, style: { fontSize: 12, fontWeight: 'bold' } }} />)}
                                                                                            getOptionSelected={() => true}
                                                                                            defaultValue={pedidosImport[item].comercialInvoice}
                                                                                            options={options1}
                                                                                            onChange={(index, valor) => montaUpdate(valor, pedidosImport[item].id, 'comercialInvoice')}
                                                                                        /> {'\n'}
                                                                                        <TextField
                                                                                            inputProps={{ style: { fontSize: 12, textAlign: 'center', width: 80, fontWeight: 'bold' } }}
                                                                                            defaultValue={pedidosImport[item].numeroComercialInvoice}
                                                                                            onChange={(e) => montaUpdate(e.target.value, pedidosImport[item].id, 'numeroComercialInvoice')}
                                                                                        />
                                                                                    </span>
                                                                                }
                                                                            </div>
                                                                        </td>

                                                                        <td className={classes.tdBorder}>
                                                                            <div className={classes.cellContent}>
                                                                                {pedidoSendoEditado !== pedidosImport[item].id ? pedidosImport[item].dataComercialInvoice :
                                                                                    <TextField
                                                                                        className={classes.dataWidth}
                                                                                        defaultValue={pedidosImport[item].dataComercialInvoice.substr(6) + '-' + pedidosImport[item].dataComercialInvoice.substr(3, 2) + '-' + pedidosImport[item].dataComercialInvoice.substr(0, 2)}
                                                                                        inputProps={{ max: '9999-12-31', style: { fontSize: 12, fontWeight: 'bold' } }}
                                                                                        type='date'
                                                                                        onChange={(e) => montaUpdate(e.target.value, pedidosImport[item].id, 'dataComercialInvoice')}
                                                                                    />}
                                                                            </div>
                                                                        </td>
                                                                    </> : <></>}

                                                                {permissoesColunas.coa === 1 ?
                                                                    <td className={pedidosImport[item].coa === 'Concluído' ? classes.green : classes.red}>
                                                                        <div className={classes.cellContent}>
                                                                            {pedidoSendoEditado !== pedidosImport[item].id ?
                                                                                <div>
                                                                                    {pedidosImport[item].coa} {'\n'}
                                                                                    {pedidosImport[item].coa === 'Concluído' ?
                                                                                        validarArquivo(pedidosImport[item].coaArquivos, pedidosImport[item].pedido_sap, 'coa', index)
                                                                                        : null
                                                                                    }

                                                                                </div> :
                                                                                <Autocomplete
                                                                                    classes={{
                                                                                        paper: classes.paper
                                                                                    }}
                                                                                    disableClearable
                                                                                    className={classes.autoComplete}
                                                                                    renderInput={(params) => (<TextField {...params} inputProps={{ ...params.inputProps, style: { fontSize: 12, fontWeight: 'bold' } }} />)}
                                                                                    getOptionSelected={() => true}
                                                                                    defaultValue={pedidosImport[item].coa}
                                                                                    options={options1}
                                                                                    onChange={(index, valor) => montaUpdate(valor, pedidosImport[item].id, 'coa')}
                                                                                />}
                                                                        </div>
                                                                    </td>
                                                                    : <></>}

                                                                {permissoesColunas.coo === 1 ?
                                                                    <td className={pedidosImport[item].coo === 'Concluído' ? classes.green : classes.red}>
                                                                        <div className={classes.cellContent}>
                                                                            {pedidoSendoEditado !== pedidosImport[item].id ?
                                                                                <div>
                                                                                    {pedidosImport[item].coo} {'\n'}
                                                                                    {pedidosImport[item].coo === 'Concluído' ?
                                                                                        validarArquivo(pedidosImport[item].cooArquivos, pedidosImport[item].pedido_sap, 'coo', index)
                                                                                        : null
                                                                                    }
                                                                                </div> :
                                                                                <Autocomplete
                                                                                    classes={{
                                                                                        paper: classes.paper
                                                                                    }}
                                                                                    disableClearable
                                                                                    className={classes.autoComplete}
                                                                                    renderInput={(params) => (<TextField {...params} inputProps={{ ...params.inputProps, style: { fontSize: 12, fontWeight: 'bold' } }} />)}
                                                                                    getOptionSelected={() => true}
                                                                                    defaultValue={pedidosImport[item].coo}
                                                                                    options={options1}
                                                                                    onChange={(index, valor) => montaUpdate(valor, pedidosImport[item].id, 'coo')}
                                                                                />}
                                                                        </div>
                                                                    </td>
                                                                    : <></>}

                                                                {permissoesColunas.bl === 1 ?
                                                                    <td className={pedidosImport[item].bl === 'Concluído' ? classes.green : classes.red}>
                                                                        <div className={classes.cellContent}>
                                                                            {pedidoSendoEditado !== pedidosImport[item].id ?
                                                                                <div>
                                                                                    {pedidosImport[item].bl} {'\n'}
                                                                                    {pedidosImport[item].numeroBl} {'\n'}
                                                                                    {pedidosImport[item].bl === 'Concluído' ?
                                                                                        validarArquivo(pedidosImport[item].blArquivos, pedidosImport[item].pedido_sap, 'bl', index)
                                                                                        : null
                                                                                    }
                                                                                </div>
                                                                                :
                                                                                <span>
                                                                                    <Autocomplete
                                                                                        classes={{
                                                                                            paper: classes.paper
                                                                                        }}
                                                                                        disableClearable
                                                                                        className={classes.autoComplete}
                                                                                        renderInput={(params) => (<TextField {...params} inputProps={{ ...params.inputProps, style: { fontSize: 12, fontWeight: 'bold' } }} />)}
                                                                                        getOptionSelected={() => true}
                                                                                        defaultValue={pedidosImport[item].bl}
                                                                                        options={options1}
                                                                                        onChange={(index, valor) => montaUpdate(valor, pedidosImport[item].id, 'bl')}
                                                                                    /> {'\n'}

                                                                                    <TextField
                                                                                        inputProps={{ style: { fontSize: 12, textAlign: 'center', width: 80, fontWeight: 'bold' } }}
                                                                                        defaultValue={pedidosImport[item].numeroBl}
                                                                                        onChange={(e) => montaUpdate(e.target.value, pedidosImport[item].id, 'numeroBl')}
                                                                                    />
                                                                                </span>
                                                                            }
                                                                        </div>
                                                                    </td>
                                                                    : <></>}


                                                                {permissoesColunas.seguro === 1 ?
                                                                    <td className={classes.tdBorder}>
                                                                        <div className={classes.cellContent}>
                                                                            {pedidoSendoEditado !== pedidosImport[item].id ?
                                                                                pedidosImport[item].cambioSeguro :
                                                                                <TextField
                                                                                    inputProps={{ style: { fontSize: 12, textAlign: 'center', width: 80, fontWeight: 'bold' } }}
                                                                                    defaultValue={pedidosImport[item].cambioSeguro.replace(',', '.')}
                                                                                    type='number'
                                                                                    onChange={(e) => montaUpdate(e.target.value, pedidosImport[item].id, 'cambioSeguro')}
                                                                                />}
                                                                        </div>
                                                                    </td> : null}

                                                                {permissoesColunas.seguro === 1 ?
                                                                    <td className={pedidosImport[item].seguro > 0 ? classes.green : classes.red}>
                                                                        <div className={classes.cellContent}>
                                                                            {pedidoSendoEditado !== pedidosImport[item].id ? pedidosImport[item].seguro.toLocaleString('en-us', { style: 'currency', currency: 'USD' }) :
                                                                                <TextField
                                                                                    inputProps={{ style: { fontSize: 12, textAlign: 'center', width: 80, fontWeight: 'bold' } }}
                                                                                    defaultValue={pedidosImport[item].seguro}
                                                                                    type='number'
                                                                                    onChange={(e) => montaUpdate(e.target.value, pedidosImport[item].id, 'seguro')}
                                                                                />}
                                                                        </div>
                                                                    </td>
                                                                    : <></>}


                                                                {permissoesColunas.li === 1 ?
                                                                    <td className={pedidosImport[item].li === 'Concluído' ? classes.green : classes.red}>
                                                                        <div className={classes.cellContent}>
                                                                            {pedidoSendoEditado !== pedidosImport[item].id ?
                                                                                <div>
                                                                                    {pedidosImport[item].li} {'\n'}
                                                                                    {pedidosImport[item].numeroLi} {'\n'}
                                                                                    {pedidosImport[item].li === 'Concluído' ?
                                                                                        validarArquivo(pedidosImport[item].liArquivos, pedidosImport[item].pedido_sap, 'li', index)
                                                                                        : null
                                                                                    }
                                                                                </div> :
                                                                                <span>
                                                                                    <Autocomplete
                                                                                        classes={{
                                                                                            paper: classes.paper
                                                                                        }}
                                                                                        disableClearable
                                                                                        className={classes.autoComplete}
                                                                                        renderInput={(params) => (<TextField {...params} inputProps={{ ...params.inputProps, style: { fontSize: 12, fontWeight: 'bold' } }} />)}
                                                                                        getOptionSelected={() => true}
                                                                                        defaultValue={pedidosImport[item].li}
                                                                                        options={options1}
                                                                                        onChange={(index, valor) => montaUpdate(valor, pedidosImport[item].id, 'li')}
                                                                                    />
                                                                                    <TextField
                                                                                        inputProps={{ style: { fontSize: 12, textAlign: 'center', width: 80, fontWeight: 'bold' } }}
                                                                                        defaultValue={pedidosImport[item].numeroLi}
                                                                                        onChange={(e) => montaUpdate(e.target.value, pedidosImport[item].id, 'numeroLi')}
                                                                                    />
                                                                                </span>
                                                                            }

                                                                        </div>
                                                                    </td>
                                                                    : <></>}

                                                                {permissoesColunas.di === 1 ?
                                                                    <td className={pedidosImport[item].di === 'Concluído' ? classes.green : classes.red}>
                                                                        <div className={classes.cellContent}>
                                                                            {pedidoSendoEditado !== pedidosImport[item].id ?
                                                                                <div>
                                                                                    {pedidosImport[item].di} {'\n'}
                                                                                    {pedidosImport[item].numeroDi} {'\n'}
                                                                                    {pedidosImport[item].dataDi} {'\n'}
                                                                                    {pedidosImport[item].di === 'Concluído' ?
                                                                                        validarArquivo(pedidosImport[item].diArquivos, pedidosImport[item].pedido_sap, 'di', index)
                                                                                        : null
                                                                                    }
                                                                                </div>
                                                                                :
                                                                                <span>
                                                                                    <Autocomplete
                                                                                        classes={{
                                                                                            paper: classes.paper
                                                                                        }}
                                                                                        disableClearable
                                                                                        className={classes.autoComplete}
                                                                                        renderInput={(params) => (<TextField {...params} inputProps={{ ...params.inputProps, style: { fontSize: 12, fontWeight: 'bold' } }} />)}
                                                                                        getOptionSelected={() => true}
                                                                                        defaultValue={pedidosImport[item].di}
                                                                                        options={options1}
                                                                                        onChange={(index, valor) => montaUpdate(valor, pedidosImport[item].id, 'di')}
                                                                                    /> {'\n'}

                                                                                    <TextField
                                                                                        inputProps={{ style: { fontSize: 12, textAlign: 'center', width: 80, fontWeight: 'bold' } }}
                                                                                        defaultValue={pedidosImport[item].numeroDi}
                                                                                        onChange={(e) => montaUpdate(e.target.value, pedidosImport[item].id, 'numeroDi')}
                                                                                    />
                                                                                    <TextField
                                                                                        className={classes.dataWidth}
                                                                                        defaultValue={pedidosImport[item].dataDi !== '' ? pedidosImport[item].dataDi.substr(6) + '-' + pedidosImport[item].dataDi.substr(3, 2) + '-' + pedidosImport[item].dataDi.substr(0, 2) : ''}
                                                                                        inputProps={{ max: '9999-12-31', style: { fontSize: 12, fontWeight: 'bold' } }}
                                                                                        type='date'
                                                                                        onChange={(e) => montaUpdate(e.target.value, pedidosImport[item].id, 'dataDi')}
                                                                                    />
                                                                                </span>
                                                                            }
                                                                        </div>
                                                                    </td>
                                                                    : <></>}

                                                                {permissoesColunas.notaMae === 1 ?
                                                                    <>
                                                                        <td className={pedidosImport[item].notaMae === 'Concluído' ? classes.green : classes.red}>
                                                                            <div className={classes.cellContent}>
                                                                                {pedidoSendoEditado !== pedidosImport[item].id ?
                                                                                    <div>
                                                                                        {pedidosImport[item].notaMae} {'\n'}
                                                                                        {pedidosImport[item].numeroNotaMae} {'\n'}
                                                                                        {pedidosImport[item].notaMae === 'Concluído' ?
                                                                                            validarArquivo(pedidosImport[item]['nota maeArquivos'], pedidosImport[item].pedido_sap, 'nota mae', index)
                                                                                            : null
                                                                                        }
                                                                                    </div> :
                                                                                    <span>
                                                                                        <Autocomplete
                                                                                            classes={{
                                                                                                paper: classes.paper
                                                                                            }}
                                                                                            disableClearable
                                                                                            className={classes.autoComplete}
                                                                                            renderInput={(params) => (<TextField {...params} inputProps={{ ...params.inputProps, style: { fontSize: 12, fontWeight: 'bold' } }} />)}
                                                                                            getOptionSelected={() => true}
                                                                                            defaultValue={pedidosImport[item].notaMae}
                                                                                            options={options1}
                                                                                            onChange={(index, valor) => montaUpdate(valor, pedidosImport[item].id, 'notaMae')}
                                                                                        />

                                                                                        <TextField
                                                                                            inputProps={{ style: { fontSize: 12, textAlign: 'center', width: 80, fontWeight: 'bold' } }}
                                                                                            defaultValue={pedidosImport[item].numeroNotaMae}
                                                                                            onChange={(e) => montaUpdate(e.target.value, pedidosImport[item].id, 'numeroNotaMae')}
                                                                                        />
                                                                                    </span>
                                                                                }
                                                                            </div>
                                                                        </td>
                                                                        <td className={classes.tdBorder}>
                                                                            <div className={classes.cellContent}>
                                                                                {pedidoSendoEditado !== pedidosImport[item].id ? pedidosImport[item].dataNf :
                                                                                    <TextField
                                                                                        className={classes.dataWidth}
                                                                                        defaultValue={pedidosImport[item].dataNf.substr(6) + '-' + pedidosImport[item].dataNf.substr(3, 2) + '-' + pedidosImport[item].dataNf.substr(0, 2)}
                                                                                        inputProps={{ max: '9999-12-31', style: { fontSize: 12, fontWeight: 'bold' } }}
                                                                                        type='date'
                                                                                        onChange={(e) => montaUpdate(e.target.value, pedidosImport[item].id, 'dataNf')}
                                                                                    />}
                                                                            </div>
                                                                        </td>
                                                                    </>
                                                                    : <></>}

                                                                <td className={classes.tdBorder}>
                                                                    <div className={classes.cellContent}> {/* CAMPO AUTOMATICO */}
                                                                        {pedidosImport[item].diasParaDesembaraco}
                                                                    </div>
                                                                </td>

                                                                <td className={classes.tdBorder}>
                                                                    <div className={classes.cellContent}> {/* CAMPO AUTOMATICO */}
                                                                        {pedidosImport[item].diasParaEtb}
                                                                    </div>
                                                                </td>

                                                                {permissoesColunas.glme === 1 ?
                                                                    <td className={pedidosImport[item].glme === 'Concluído' ? classes.green : classes.red}>
                                                                        <div className={classes.cellContent}>
                                                                            {pedidoSendoEditado !== pedidosImport[item].id ?
                                                                                <div>
                                                                                    {pedidosImport[item].glme} {'\n'}
                                                                                    {pedidosImport[item].glme === 'Concluído' ?
                                                                                        validarArquivo(pedidosImport[item].glmeArquivos, pedidosImport[item].pedido_sap, 'glme', index)
                                                                                        : null
                                                                                    }
                                                                                </div> :
                                                                                <Autocomplete
                                                                                    classes={{
                                                                                        paper: classes.paper
                                                                                    }}
                                                                                    disableClearable
                                                                                    className={classes.autoComplete}
                                                                                    renderInput={(params) => (<TextField {...params} inputProps={{ ...params.inputProps, style: { fontSize: 12, fontWeight: 'bold' } }} />)}
                                                                                    getOptionSelected={() => true}
                                                                                    defaultValue={pedidosImport[item].glme}
                                                                                    options={glmeOptions}
                                                                                    onChange={(index, valor) => montaUpdate(valor, pedidosImport[item].id, 'glme')}
                                                                                />}
                                                                        </div>
                                                                    </td>
                                                                    : <></>}

                                                                {permissoesColunas.cdd === 1 ?
                                                                    <td className={pedidosImport[item].cdd === 'Concluído' ? classes.green : classes.red}>
                                                                        <div className={classes.cellContent}>
                                                                            {pedidoSendoEditado !== pedidosImport[item].id ?
                                                                                <div>
                                                                                    {pedidosImport[item].cdd} {'\n'}
                                                                                    {pedidosImport[item].cdd === 'Concluído' ?
                                                                                        validarArquivo(pedidosImport[item].cddArquivos, pedidosImport[item].pedido_sap, 'cdd', index)
                                                                                        : null
                                                                                    }
                                                                                </div> :

                                                                                <Autocomplete
                                                                                    classes={{
                                                                                        paper: classes.paper
                                                                                    }}
                                                                                    disableClearable
                                                                                    className={classes.autoComplete}
                                                                                    renderInput={(params) => (<TextField {...params} inputProps={{ ...params.inputProps, style: { fontSize: 12, fontWeight: 'bold' } }} />)}
                                                                                    getOptionSelected={() => true}
                                                                                    defaultValue={pedidosImport[item].cdd}
                                                                                    options={options1}
                                                                                    onChange={(index, valor) => montaUpdate(valor, pedidosImport[item].id, 'cdd')}
                                                                                />}
                                                                        </div>
                                                                    </td>
                                                                    : <></>}


                                                                {permissoesColunas.ci === 1 ?
                                                                    <td className={pedidosImport[item].ci === 'Concluído' ? classes.green : classes.red}>
                                                                        <div className={classes.cellContent}>
                                                                            {pedidoSendoEditado !== pedidosImport[item].id ?
                                                                                <div>
                                                                                    {pedidosImport[item].ci} {'\n'}
                                                                                    {pedidosImport[item].ci === 'Concluído' ?
                                                                                        validarArquivo(pedidosImport[item].ciArquivos, pedidosImport[item].pedido_sap, 'ci', index)
                                                                                        : null
                                                                                    }
                                                                                </div> :
                                                                                <Autocomplete
                                                                                    classes={{
                                                                                        paper: classes.paper
                                                                                    }}
                                                                                    disableClearable
                                                                                    className={classes.autoComplete}
                                                                                    renderInput={(params) => (<TextField {...params} inputProps={{ ...params.inputProps, style: { fontSize: 12, fontWeight: 'bold' } }} />)}
                                                                                    getOptionSelected={() => true}
                                                                                    defaultValue={pedidosImport[item].ci}
                                                                                    options={options1}
                                                                                    onChange={(index, valor) => montaUpdate(valor, pedidosImport[item].id, 'ci')}
                                                                                />}
                                                                        </div>
                                                                    </td>
                                                                    : <></>}

                                                                <td className={classes.tdBorder}>
                                                                    <div className={classes.cellContent}>
                                                                        {pedidoSendoEditado !== pedidosImport[item].id ?
                                                                            pedidosImport[item].cambioDesistiva :
                                                                            <TextField
                                                                                inputProps={{ style: { fontSize: 12, textAlign: 'center', width: 80, fontWeight: 'bold' } }}
                                                                                defaultValue={pedidosImport[item].cambioDesistiva.replace(',', '.')}
                                                                                type='number'
                                                                                onChange={(e) => montaUpdate(e.target.value, pedidosImport[item].id, 'cambioDesistiva')}
                                                                            />}
                                                                    </div>
                                                                </td>

                                                                <td className={classes.tdBorder}>
                                                                    <div className={classes.cellContent}>
                                                                        {pedidoSendoEditado !== pedidosImport[item].id ?
                                                                            pedidosImport[item].desistiva.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) :
                                                                            <TextField
                                                                                inputProps={{ style: { fontSize: 12, textAlign: 'center', width: 80, fontWeight: 'bold' } }}
                                                                                defaultValue={pedidosImport[item].desistiva.replace(',', '.')}
                                                                                type='number'
                                                                                onChange={(e) => montaUpdate(e.target.value, pedidosImport[item].id, 'desistiva')}
                                                                            />}
                                                                    </div>
                                                                </td>

                                                                {permissoesColunas.cambio === 1 ?
                                                                    <td className={classes.tdBorder}>
                                                                        <div className={classes.cellContent}>
                                                                            {pedidoSendoEditado !== pedidosImport[item].id ?
                                                                                pedidosImport[item].cambio :
                                                                                <TextField
                                                                                    inputProps={{ style: { fontSize: 12, textAlign: 'center', width: 80, fontWeight: 'bold' } }}
                                                                                    defaultValue={pedidosImport[item].cambio.replace(',', '.')}
                                                                                    type='number'
                                                                                    onChange={(e) => montaUpdate(e.target.value, pedidosImport[item].id, 'cambio')}
                                                                                />}
                                                                        </div>
                                                                    </td>
                                                                    : <></>}


                                                                <td className={classes.tdBorder}>
                                                                    <div className={classes.cellContent}>
                                                                        {pedidoSendoEditado !== pedidosImport[item].id ?
                                                                            pedidosImport[item].cambioTotalAfrmm :
                                                                            <TextField
                                                                                inputProps={{ style: { fontSize: 12, textAlign: 'center', width: 80, fontWeight: 'bold' } }}
                                                                                defaultValue={pedidosImport[item].cambioTotalAfrmm.replace(',', '.')}
                                                                                type='number'
                                                                                onChange={(e) => montaUpdate(e.target.value, pedidosImport[item].id, 'cambioTotalAfrmm')}
                                                                            />}
                                                                    </div>
                                                                </td>


                                                                <td className={classes.tdBorder}>
                                                                    <div className={classes.cellContent}>
                                                                        {pedidoSendoEditado !== pedidosImport[item].id ?
                                                                            pedidosImport[item].afrmmTotalReal :
                                                                            <TextField
                                                                                inputProps={{ style: { fontSize: 12, textAlign: 'center', width: 80, fontWeight: 'bold' } }}
                                                                                defaultValue={pedidosImport[item].afrmmTotalReal.replace(',', '.')}
                                                                                type='number'
                                                                                onChange={(e) => montaUpdate(e.target.value, pedidosImport[item].id, 'afrmmTotalReal')}
                                                                            />}
                                                                    </div>
                                                                </td>

                                                                {permissoesColunas.afrmmTotalDolar === 1 ?
                                                                    <td className={classes.tdBorder}>
                                                                        <div className={classes.cellContent}> {/* CAMPO AUTOMATICO */}
                                                                            {pedidosImport[item].afrmmTotalDolar.toLocaleString('en-us', { style: 'currency', currency: 'USD' })}
                                                                        </div>
                                                                    </td> : <></>}

                                                                {permissoesColunas.situacaoAfrmm === 1 ?
                                                                    <td className={pedidosImport[item].situacaoAfrmm === 'Paga' ? classes.green : classes.red}>
                                                                        <div className={classes.cellContent}>
                                                                            {pedidoSendoEditado !== pedidosImport[item].id ?
                                                                                pedidosImport[item].situacaoAfrmm :
                                                                                <Autocomplete
                                                                                    classes={{
                                                                                        paper: classes.paper
                                                                                    }}
                                                                                    className={classes.autoComplete}
                                                                                    disableClearable
                                                                                    getOptionSelected={() => true}
                                                                                    defaultValue={pedidosImport[item].situacaoAfrmm}
                                                                                    options={options2}
                                                                                    onChange={(index, valor) => montaUpdate(valor, pedidosImport[item].id, 'situacaoAfrmm')}
                                                                                    renderInput={(params) => (<TextField {...params} inputProps={{ ...params.inputProps, style: { fontSize: 12, fontWeight: 'bold' } }} />)}
                                                                                />}
                                                                        </div>
                                                                    </td>
                                                                    : <></>}


                                                                <td className={classes.tdBorder}>
                                                                    <div className={classes.cellContent}>
                                                                        {pedidoSendoEditado !== pedidosImport[item].id ?
                                                                            pedidosImport[item].agentePortuario :
                                                                            <TextField
                                                                                inputProps={{ style: { fontSize: 12, textAlign: 'center', width: 80, fontWeight: 'bold' } }}
                                                                                defaultValue={pedidosImport[item].agentePortuario}
                                                                                onChange={(e) => montaUpdate(e.target.value, pedidosImport[item].id, 'agentePortuario')}
                                                                            />}
                                                                    </div>
                                                                </td>

                                                                <td className={classes.tdBorder}>
                                                                    <div className={classes.cellContent}>
                                                                        {pedidoSendoEditado !== pedidosImport[item].id ?
                                                                            pedidosImport[item].operadorPortuario :
                                                                            <TextField
                                                                                inputProps={{ style: { fontSize: 12, textAlign: 'center', width: 80, fontWeight: 'bold' } }}
                                                                                defaultValue={pedidosImport[item].operadorPortuario}
                                                                                onChange={(e) => montaUpdate(e.target.value, pedidosImport[item].id, 'operadorPortuario')}
                                                                            />}
                                                                    </div>
                                                                </td>

                                                                <td className={classes.tdBorder}>
                                                                    <div className={classes.cellContent}>
                                                                        {pedidoSendoEditado !== pedidosImport[item].id ?
                                                                            pedidosImport[item].berco :
                                                                            <TextField
                                                                                inputProps={{ style: { fontSize: 12, textAlign: 'center', width: 80, fontWeight: 'bold' } }}
                                                                                defaultValue={pedidosImport[item].berco}
                                                                                onChange={(e) => montaUpdate(e.target.value, pedidosImport[item].id, 'berco')}
                                                                            />}
                                                                    </div>
                                                                </td>

                                                                <td className={classes.tdBorder}>

                                                                    <div className={classes.cellContentLeft}>
                                                                        {pedidoSendoEditado !== pedidosImport[item].id ?
                                                                            Object.keys(JSON.parse(pedidosImport[item].armazemMic)).map((el, ind1) => {
                                                                                return (
                                                                                    <div key={ind1} style={{ fontSize: 13, border: '1px solid rgba(0, 0, 0, .5)', marginLeft: 5, minWidth: 150, textAlign: 'center' }}>
                                                                                        <div>Az: {JSON.parse(pedidosImport[item].armazemMic)[el].armazem}</div>
                                                                                        <div>Qtd: {JSON.parse(pedidosImport[item].armazemMic)[el].quantidadeArmazem}</div>
                                                                                        <div>Mic: {JSON.parse(pedidosImport[item].armazemMic)[el].mic}</div>
                                                                                        <div>DescReal: {JSON.parse(pedidosImport[item].armazemMic)[el].descargaReal}</div>
                                                                                        <div>Saldo: {JSON.parse(pedidosImport[item].armazemMic)[el].saldo}</div>
                                                                                    </div>
                                                                                )
                                                                            }) :
                                                                            <div style={{ display: 'flex', flexDirection: 'row', alignContent: 'center', alignItems: 'center' }}>
                                                                                <button style={{ height: 25 }} onClick={() => addArmazemEdicao(index)}>Add</button>
                                                                                {Object.keys(JSON.parse(pedidosImport[item].armazemMic)).map((indexArmazem) => {
                                                                                    return (
                                                                                        <div key={indexArmazem} style={{ width: 80, border: '1px solid rgba(0, 0, 0, .5)', margin: 2, padding: 4, flexDirection: 'column', display: escondeArmazem[index][indexArmazem] ? 'none' : 'block' }}>
                                                                                            <button
                                                                                                onClick={() => removeArmazemEdicao(index, indexArmazem, JSON.parse(pedidosImport[item].armazemMic)[indexArmazem].idIncrement, pedidosImport[item].id)}
                                                                                            >Remover</button>
                                                                                            <TextField
                                                                                                onChange={(e) => montaUpdate(e.target.value, pedidosImport[item].id, 'armazem-' + JSON.parse(pedidosImport[item].armazemMic)[indexArmazem].idIncrement)}
                                                                                                inputProps={{ style: { fontSize: 12, textAlign: 'center', width: 80, fontWeight: 'bold' } }}
                                                                                                defaultValue={JSON.parse(pedidosImport[item].armazemMic)[indexArmazem].armazem}
                                                                                            />

                                                                                            <TextField
                                                                                                type='number'
                                                                                                onChange={(e) => montaUpdate(e.target.value, pedidosImport[item].id, 'quantidadeArmazem-' + JSON.parse(pedidosImport[item].armazemMic)[indexArmazem].idIncrement)}
                                                                                                inputProps={{ style: { fontSize: 12, textAlign: 'center', width: 80, fontWeight: 'bold' } }}
                                                                                                defaultValue={JSON.parse(pedidosImport[item].armazemMic)[indexArmazem].quantidadeArmazem}
                                                                                            />

                                                                                            <TextField
                                                                                                onChange={(e) => montaUpdate(e.target.value, pedidosImport[item].id, 'mic-' + JSON.parse(pedidosImport[item].armazemMic)[indexArmazem].idIncrement)}
                                                                                                inputProps={{ style: { fontSize: 12, textAlign: 'center', width: 80, fontWeight: 'bold' } }}
                                                                                                defaultValue={JSON.parse(pedidosImport[item].armazemMic)[indexArmazem].mic}
                                                                                            />

                                                                                            <TextField
                                                                                                type='number'
                                                                                                onChange={(e) => montaUpdate(e.target.value, pedidosImport[item].id, 'descargaReal-' + JSON.parse(pedidosImport[item].armazemMic)[indexArmazem].idIncrement)}
                                                                                                inputProps={{ style: { fontSize: 12, textAlign: 'center', width: 80, fontWeight: 'bold' } }}
                                                                                                defaultValue={JSON.parse(pedidosImport[item].armazemMic)[indexArmazem].descargaReal}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                })}
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </td>

                                                                <td className={classes.tdBorder}>
                                                                    <div className={classes.cellContent}> {/* CAMPO AUTOMATICO */}
                                                                        {pedidosImport[item].qtdDescarga}
                                                                    </div>
                                                                </td>

                                                                <td className={classes.tdBorder}>
                                                                    <div className={classes.cellContentBig}>
                                                                        {pedidoSendoEditado !== pedidosImport[item].id ?
                                                                            pedidosImport[item].comentarioPedido :
                                                                            <TextField
                                                                                multiline
                                                                                inputProps={{ style: { fontSize: 12, textAlign: 'center', width: 80, fontWeight: 'bold' } }}
                                                                                defaultValue={pedidosImport[item].comentarioPedido}
                                                                                onChange={(e) => montaUpdate(e.target.value, pedidosImport[item].id, 'comentarioPedido')}
                                                                            />}
                                                                    </div>
                                                                </td>

                                                                <td className={classes.tdBorder}>
                                                                    <div className={classes.cellContentBig}>
                                                                    {pedidoSendoEditado !== pedidosImport[item].id ?
                                                                        (pedidosImport[item].statusFinanceiro || '-') :
                                                                        <Autocomplete
                                                                            classes={{
                                                                                paper: classes.paper
                                                                            }}
                                                                            className={classes.autoComplete}
                                                                            disableClearable
                                                                            getOptionSelected={() => true}
                                                                            defaultValue={pedidosImport[item].statusFinanceiro || '-'}
                                                                            options={options3}
                                                                            onChange={(index, valor) => montaUpdate(valor, pedidosImport[item].id, 'statusFinanceiro')}
                                                                            renderInput={(params) => (
                                                                                <TextField
                                                                                    {...params}
                                                                                    inputProps={{
                                                                                        ...params.inputProps,
                                                                                        style: { fontSize: 12, fontWeight: 'bold' }
                                                                                    }}
                                                                                />
                                                                            )}
                                                                        />
                                                                    }
                                                                    </div>
                                                                </td>
                                                                <td className={classes.tdBorder}>
                                                                    <div className={classes.cellContentBig}>
                                                                        {pedidoSendoEditado !== pedidosImport[item].id ?
                                                                            (pedidosImport[item].statusLineUp || '-') :
                                                                            <Autocomplete
                                                                                classes={{
                                                                                    paper: classes.paper
                                                                                }}
                                                                                className={classes.autoComplete}
                                                                                disableClearable
                                                                                getOptionSelected={() => true}
                                                                                defaultValue={pedidosImport[item].statusLineUp || '-'}
                                                                                options={options4}
                                                                                onChange={(index, valor) => montaUpdate(valor, pedidosImport[item].id, 'statusLineUp')}
                                                                                renderInput={(params) => (
                                                                                    <TextField
                                                                                        {...params}
                                                                                        inputProps={{
                                                                                            ...params.inputProps,
                                                                                            style: { fontSize: 12, fontWeight: 'bold' }
                                                                                        }}
                                                                                    />
                                                                                )}
                                                                            />
                                                                        }
                                                                    </div>
                                                                </td>               

                                                            </tr> : '')
                                                })}


                                            </tbody>
                                        </table>

                                    </div> :
                                    tab === 1 ? // FINANCEIRO
                                        <div key={1} className={classes.divTabela}>
                                            <table>
                                                <thead className={classes.header}>
                                                    <tr>
                                                        <th style={{ position: 'sticky', left: 0, background: '#3d42e0', fontSize: 14, minWidth: 94 }}>SAP</th>
                                                        <th style={{ position: 'sticky', left: 94, background: '#3d42e0', fontSize: 14, minWidth: 94 }}>Exportador</th>
                                                        <th style={{ position: 'sticky', left: 188, background: '#3d42e0', fontSize: 14, minWidth: 94 }}>Produto</th>
                                                        <th style={{ position: 'sticky', left: 282, background: '#3d42e0', fontSize: 14, minWidth: 94 }}>Navio</th>
                                                        <th style={{ position: 'sticky', left: 376, background: '#3d42e0', fontSize: 14, minWidth: 94 }}>Filial</th>
                                                        {permissoesColunas.vencimento === 1 ? <th className={classes.thFinanceiro}>Vencimento</th> : <></>}
                                                        <th className={classes.thFinanceiro}>Quantidade</th>
                                                        {permissoesColunas.valorUnit === 1 ? <th className={classes.thFinanceiro}>Valor Unidade (U$)</th> : <></>}
                                                        {permissoesColunas.qtdPagaTons === 1 ? <th className={classes.thFinanceiro}>Quantidade Paga (Tons)</th> : <></>}

                                                        {permissoesColunas.faltaPagarTons === 1 ? <th className={classes.thFinanceiro}>Falta Pagar (Tons)</th> : <></>}

                                                        {permissoesColunas.valorTotal === 1 ? <th className={classes.thFinanceiro}>Valor Total (U$)</th> : <></>}

                                                        {permissoesColunas.faltaPagar === 1 ? <th className={classes.thFinanceiro}>Falta Pagar (U$)</th> : <></>}

                                                        {permissoesColunas.pago === 1 ? <th className={classes.thFinanceiro}>Pago</th> : <></>}



                                                        {permissoesColunas.situacaoDemurrage === 1 ? <th className={classes.thFinanceiro}>Situação Demurrage</th> : <></>}

                                                        <th className={classes.thFinanceiro}>Demurrage Gen (U$)</th>

                                                        {permissoesColunas.afrmmTotalReal === 1 ? <th className={classes.thFinanceiro}>AFRMM Total (R$)</th> : <></>}

                                                        {permissoesColunas.afrmmTotalDolar === 1 ? <th className={classes.thFinanceiro}>AFRMM Total (U$)</th> : <></>}

                                                        {permissoesColunas.situacaoAfrmm === 1 ? <th className={classes.thFinanceiro}>Situação AFRMM</th> : <></>}

                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    {Object.keys(pedidosImport).map((item, index) => {
                                                        let itemString = JSON.stringify(pedidosImport[item]).toLowerCase()
                                                        return (
                                                            itemString.includes(filtro) || filtro === '' ?
                                                                <tr key={index}>
                                                                    <td style={{ position: 'sticky', left: 0, background: '#e6e6e6', zIndex: 1, border: '1px solid rgba(0,0,0, .4)' }}>
                                                                        <div className={classes.cellContentFixed} >
                                                                            {pedidoSendoEditado !== pedidosImport[item].id ?

                                                                                <div>
                                                                                    <div>
                                                                                        {pedidosImport[item].pedido_sap}
                                                                                    </div>
                                                                                    {(pedidoSendoEditado === '' || pedidoSendoEditado === pedidosImport[item].id) && permissaoEditar ?
                                                                                        <div>
                                                                                            <button onClick={() => setPedidoSendoEditado(pedidosImport[item].id)}>Editar</button>
                                                                                        </div>
                                                                                        : null}
                                                                                </div>

                                                                                : <div>
                                                                                    <div >
                                                                                        <div><input type="checkbox" onChange={(e) => arquivar(pedidosImport[item].id, e.target.checked)} />Arquivar</div>
                                                                                    </div>
                                                                                    <div><Autocomplete
                                                                                        classes={{
                                                                                            paper: classes.paper
                                                                                        }}

                                                                                        disableClearable
                                                                                        className={classes.autoComplete}
                                                                                        getOptionSelected={() => true}
                                                                                        onChange={(index, valor) => montaUpdate(valor, pedidosImport[item].id, 'pedido_sap')}
                                                                                        defaultValue={pedidosImport[item].pedido_sap}
                                                                                        options={listaPedidosSap.map((item) => item.numeroSAP.toString())}
                                                                                        renderInput={(params) => (<TextField {...params} inputProps={{ ...params.inputProps, style: { fontSize: 12, fontWeight: 'bold' } }} />)}
                                                                                    /></div>

                                                                                    {duplicando || excluindo ?
                                                                                        <Loader
                                                                                            type="Circles"
                                                                                            color="#00BFFF"
                                                                                            height={20}
                                                                                            width={20}
                                                                                            timeout={2000000}
                                                                                        />
                                                                                        : <><div><button onClick={() => duplicarPedido(pedidosImport[item].id)}>Duplicar</button></div>
                                                                                            <div><button onClick={() => excluirPedido(pedidosImport[item].id)}>Excluir</button></div>
                                                                                        </>}
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    </td>

                                                                    <td style={{ position: 'sticky', left: 94, background: '#e6e6e6', zIndex: 1, border: '1px solid rgba(0,0,0, .4)' }}>
                                                                        <div className={classes.cellContentFixed}>
                                                                            {pedidoSendoEditado !== pedidosImport[item].id ? pedidosImport[item].exportador :
                                                                                <Autocomplete
                                                                                    classes={{
                                                                                        paper: classes.paper
                                                                                    }}
                                                                                    freeSolo
                                                                                    disableClearable
                                                                                    className={classes.autoComplete}
                                                                                    getOptionSelected={() => true}
                                                                                    onChange={(index, valor) => montaUpdate(valor, pedidosImport[item].id, 'exportador')}
                                                                                    defaultValue={pedidosImport[item].exportador}
                                                                                    options={['Ameropa', 'Keytrade', 'Phosagro', 'JSC-BPC', 'SQM', 'DREYMOOR', 'BULKFERT', 'INDAGRO', 'SAFTCO']}
                                                                                    renderInput={(params) => (<TextField {...params} inputProps={{ ...params.inputProps, style: { fontSize: 12, fontWeight: 'bold' } }} />)}
                                                                                />}
                                                                        </div>

                                                                    </td>

                                                                    <td style={{ position: 'sticky', left: 188, background: '#e6e6e6', zIndex: 1, border: '1px solid rgba(0,0,0, .4)' }}>
                                                                        <div className={classes.cellContentFixed}>
                                                                            {pedidoSendoEditado !== pedidosImport[item].id ?
                                                                                pedidosImport[item].produto :
                                                                                <Autocomplete
                                                                                    classes={{
                                                                                        paper: classes.paper
                                                                                    }}
                                                                                    className={classes.autoComplete}
                                                                                    disableClearable
                                                                                    getOptionSelected={() => true}
                                                                                    onChange={(index, valor) => montaUpdate(valor, pedidosImport[item].id, 'produto')}
                                                                                    defaultValue={pedidosImport[item].produto}
                                                                                    options={Object.keys(listaProdutos).map((item) => (listaProdutos[item].ItemName))}
                                                                                    renderInput={(params) => (<TextField {...params} inputProps={{ ...params.inputProps, style: { fontSize: 12, fontWeight: 'bold' } }} />)}
                                                                                />}
                                                                        </div>
                                                                    </td>

                                                                    <td style={{ position: 'sticky', left: 282, background: '#e6e6e6', zIndex: 1, border: '1px solid rgba(0,0,0, .4)' }}>
                                                                        <div className={classes.cellContentFixed}>
                                                                            {pedidoSendoEditado !== pedidosImport[item].id ? pedidosImport[item].navio :
                                                                                <TextField
                                                                                    inputProps={{ style: { fontSize: 12, textAlign: 'center', width: 80, fontWeight: 'bold' } }}
                                                                                    defaultValue={pedidosImport[item].navio}
                                                                                    onChange={(e) => montaUpdate(e.target.value, pedidosImport[item].id, 'navio')}
                                                                                />}
                                                                        </div>
                                                                    </td>

                                                                    <td style={{ position: 'sticky', left: 376, background: '#e6e6e6', zIndex: 1, border: '1px solid rgba(0,0,0, .4)' }}>
                                                                        <div className={classes.cellContentFixed}>
                                                                            {pedidoSendoEditado !== pedidosImport[item].id ? pedidosImport[item].filial :
                                                                                <Autocomplete
                                                                                    classes={{
                                                                                        paper: classes.paper
                                                                                    }}
                                                                                    disableClearable
                                                                                    className={classes.autoComplete}
                                                                                    getOptionSelected={() => true}
                                                                                    defaultValue={pedidosImport[item].filial}
                                                                                    options={Object.keys(listaFiliais).map((item) => (listaFiliais[item].BPLName.split(' ')[0]))}
                                                                                    onChange={(index, valor) => montaUpdate(valor, pedidosImport[item].id, 'filial')}
                                                                                    renderInput={(params) => (<TextField {...params} inputProps={{ ...params.inputProps, style: { fontSize: 12, fontWeight: 'bold' } }} />)}
                                                                                />}
                                                                        </div>

                                                                    </td>

                                                                    {permissoesColunas.vencimento === 1 ?
                                                                        <td className={classes.tdBorder}>
                                                                            <div className={classes.cellContent}>
                                                                                {pedidoSendoEditado !== pedidosImport[item].id ? pedidosImport[item].vencimento :
                                                                                    <TextField
                                                                                        className={classes.dataWidth}
                                                                                        defaultValue={pedidosImport[item].vencimento.substr(6) + '-' + pedidosImport[item].vencimento.substr(3, 2) + '-' + pedidosImport[item].vencimento.substr(0, 2)}
                                                                                        inputProps={{ max: '9999-12-31', style: { fontSize: 12, fontWeight: 'bold' } }}
                                                                                        type='date'
                                                                                        onChange={(e) => montaUpdate(e.target.value, pedidosImport[item].id, 'vencimento')}
                                                                                    />}
                                                                            </div>
                                                                        </td>
                                                                        : <></>}


                                                                    <td className={classes.tdBorder}>
                                                                        <div className={classes.cellContent}>
                                                                            {pedidoSendoEditado !== pedidosImport[item].id ? pedidosImport[item].quantidade :
                                                                                <TextField
                                                                                    inputProps={{ style: { fontSize: 12, textAlign: 'center', width: 80, fontWeight: 'bold' } }}
                                                                                    onChange={(e) => montaUpdate(e.target.value, pedidosImport[item].id, 'quantidade')}
                                                                                    defaultValue={pedidosImport[item].quantidade.replace(',', '.')}
                                                                                    type='number'
                                                                                />}
                                                                        </div>
                                                                    </td>

                                                                    {permissoesColunas.valorUnit === 1 ?
                                                                        <td className={classes.tdBorder}>
                                                                            <div className={classes.cellContent}>
                                                                                {pedidoSendoEditado !== pedidosImport[item].id ? pedidosImport[item].valorUnit.toLocaleString('en-us', { style: 'currency', currency: 'USD' }) :
                                                                                    <TextField
                                                                                        inputProps={{ style: { fontSize: 12, textAlign: 'center', width: 80, fontWeight: 'bold' } }}
                                                                                        defaultValue={pedidosImport[item].valorUnit.replace(',', '.')}
                                                                                        type='number'
                                                                                        onChange={(e) => montaUpdate(e.target.value, pedidosImport[item].id, 'valorUnit')}
                                                                                    />}
                                                                            </div>
                                                                        </td>
                                                                        : <></>}


                                                                    {permissoesColunas.qtdPagaTons === 1 ?
                                                                        <td className={classes.tdBorder}>
                                                                            <div className={classes.cellContent}> {/* CAMPO AUTOMATICO */}
                                                                                {pedidosImport[item].quantidadePaga}
                                                                            </div>
                                                                        </td>
                                                                        : <></>}

                                                                    {permissoesColunas.faltaPagarTons === 1 ?
                                                                        <td className={pedidosImport[item].faltaPagarTons === 0 ? classes.green : classes.red}>
                                                                            <div className={classes.cellContent}> {/* CAMPO AUTOMATICO */}
                                                                                {pedidosImport[item].faltaPagarTons}
                                                                            </div>
                                                                        </td>
                                                                        : <></>}

                                                                    {permissoesColunas.valorTotal === 1 ?
                                                                        <td className={classes.tdBorder}>
                                                                            <div className={classes.cellContent}> {/* CAMPO AUTOMATICO */}
                                                                                {pedidosImport[item].valorTotal.toLocaleString('en-us', { style: 'currency', currency: 'USD' })}
                                                                            </div>
                                                                        </td>
                                                                        : <></>}

                                                                    {permissoesColunas.faltaPagar === 1 ?
                                                                        <td className={pedidosImport[item].faltaPagarDolar === 0 ? classes.green : classes.red}>
                                                                            <div className={classes.cellContent}> {/* CAMPO AUTOMATICO */}
                                                                                {pedidosImport[item].faltaPagarDolar.toLocaleString('en-us', { style: 'currency', currency: 'USD' })}
                                                                            </div>
                                                                        </td>
                                                                        : <></>}

                                                                    {permissoesColunas.pago === 1 ?
                                                                        <td className={pedidosImport[item].pago === pedidosImport[item].valorTotal ? classes.green : classes.red}>
                                                                            <div className={classes.cellContent}>
                                                                                {pedidoSendoEditado !== pedidosImport[item].id ? pedidosImport[item].pago.toLocaleString('en-us', { style: 'currency', currency: 'USD' }) :
                                                                                    <TextField
                                                                                        inputProps={{ style: { fontSize: 12, textAlign: 'center', width: 80, fontWeight: 'bold' } }}
                                                                                        defaultValue={pedidosImport[item].pago.replace(',', '.')}
                                                                                        type='number'
                                                                                        onChange={(e) => montaUpdate(e.target.value, pedidosImport[item].id, 'pago')}
                                                                                    />}
                                                                            </div>
                                                                        </td>
                                                                        : <></>}




                                                                    {permissoesColunas.situacaoDemurrage === 1 ?
                                                                        <td className={pedidosImport[item].situacaoDemurrage === 'Paga' ? classes.green : classes.red}>
                                                                            <div className={classes.cellContent}>
                                                                                {pedidoSendoEditado !== pedidosImport[item].id ? pedidosImport[item].situacaoDemurrage :
                                                                                    <Autocomplete
                                                                                        classes={{
                                                                                            paper: classes.paper
                                                                                        }}
                                                                                        className={classes.autoComplete}
                                                                                        disableClearable
                                                                                        getOptionSelected={() => true}
                                                                                        defaultValue={pedidosImport[item].situacaoDemurrage}
                                                                                        options={options2}
                                                                                        onChange={(index, valor) => montaUpdate(valor, pedidosImport[item].id, 'situacaoDemurrage')}
                                                                                        renderInput={(params) => (<TextField {...params} inputProps={{ ...params.inputProps, style: { fontSize: 12, fontWeight: 'bold' } }} />)}
                                                                                    />
                                                                                }
                                                                            </div>
                                                                        </td>
                                                                        : <></>}


                                                                    <td className={classes.tdBorder}>
                                                                        <div className={classes.cellContent}>
                                                                            {pedidoSendoEditado !== pedidosImport[item].id ?
                                                                                pedidosImport[item].demurrageGenDolar.toLocaleString('en-us', { style: 'currency', currency: 'USD' }) :
                                                                                <TextField
                                                                                    inputProps={{ style: { fontSize: 12, textAlign: 'center', width: 80, fontWeight: 'bold' } }}
                                                                                    defaultValue={pedidosImport[item].demurrageGenDolar.replace(',', '.')}
                                                                                    type='number'
                                                                                    onChange={(e) => montaUpdate(e.target.value, pedidosImport[item].id, 'demurrageGenDolar')}
                                                                                />}
                                                                        </div>
                                                                    </td>

                                                                    {permissoesColunas.afrmmTotalReal === 1 ?
                                                                        <td className={classes.tdBorder}>
                                                                            <div className={classes.cellContent}> {/* CAMPO AUTOMATICO */}
                                                                                {pedidosImport[item].afrmmTotalReal.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                                                            </div>
                                                                        </td>
                                                                        : <></>}


                                                                    {permissoesColunas.afrmmTotalDolar === 1 ?
                                                                        <td className={classes.tdBorder}>
                                                                            <div className={classes.cellContent}> {/* CAMPO AUTOMATICO */}
                                                                                {pedidosImport[item].afrmmTotalDolar.toLocaleString('en-us', { style: 'currency', currency: 'USD' })}
                                                                            </div>
                                                                        </td>
                                                                        : <></>}


                                                                    {permissoesColunas.situacaoAfrmm === 1 ?
                                                                        <td className={pedidosImport[item].situacaoAfrmm === 'Paga' ? classes.green : classes.red}>
                                                                            <div className={classes.cellContent}>
                                                                                {pedidoSendoEditado !== pedidosImport[item].id ?
                                                                                    pedidosImport[item].situacaoAfrmm :
                                                                                    <Autocomplete
                                                                                        classes={{
                                                                                            paper: classes.paper
                                                                                        }}
                                                                                        className={classes.autoComplete}
                                                                                        disableClearable
                                                                                        getOptionSelected={() => true}
                                                                                        defaultValue={pedidosImport[item].situacaoAfrmm}
                                                                                        options={options2}
                                                                                        onChange={(index, valor) => montaUpdate(valor, pedidosImport[item].id, 'situacaoAfrmm')}
                                                                                        renderInput={(params) => (<TextField {...params} inputProps={{ ...params.inputProps, style: { fontSize: 12, fontWeight: 'bold' } }} />)}
                                                                                    />}
                                                                            </div>
                                                                        </td>
                                                                        : <></>}

                                                                </tr> : ''
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                        </div> :
                                        tab === 2 ? // LINEUP
                                            <div key={2} className={classes.divTabela}>
                                                <table>
                                                    <thead className={classes.header}>
                                                        <tr>
                                                            <th style={{ position: 'sticky', left: 0, background: '#36c416', fontSize: 14, minWidth: 94 }}>SAP</th>
                                                            <th style={{ position: 'sticky', left: 94, background: '#36c416', fontSize: 14, minWidth: 94 }}>Exportador</th>
                                                            <th style={{ position: 'sticky', left: 188, background: '#36c416', fontSize: 14, minWidth: 94 }}>Produto</th>
                                                            <th style={{ position: 'sticky', left: 282, background: '#36c416', fontSize: 14, minWidth: 94 }}>Navio</th>
                                                            <th style={{ position: 'sticky', left: 376, background: '#36c416', fontSize: 14, minWidth: 94 }}>Filial</th>
                                                            <th className={classes.thLineup}>Modalidade</th>
                                                            <th className={classes.thLineup}>Porto</th>

                                                            <th className={classes.thLineup}>Quantidade</th>

                                                            <th className={classes.thLineup}>ETA</th>
                                                            <th className={classes.thLineup}>ETB</th>
                                                            <th className={classes.thLineup}>ETC</th>


                                                            <th className={classes.thLineup}>Agente Portuário</th>
                                                            <th className={classes.thLineup}>Operador Portuário</th>

                                                            <th className={classes.thLineup}>Armazém / Quantidade / MIC / Descarga Real</th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        {Object.keys(pedidosImport).map((item, index) => {
                                                            let itemString = JSON.stringify(pedidosImport[item]).toLowerCase()
                                                            return (
                                                                itemString.includes(filtro) || filtro === '' ?
                                                                    <tr key={index}>
                                                                        <td style={{ position: 'sticky', left: 0, background: '#e6e6e6', zIndex: 1, border: '1px solid rgba(0,0,0, .4)' }}>
                                                                            <div className={classes.cellContentFixed} >
                                                                                {pedidoSendoEditado !== pedidosImport[item].id ?

                                                                                    <div>
                                                                                        <div>
                                                                                            {pedidosImport[item].pedido_sap}
                                                                                        </div>
                                                                                        {(pedidoSendoEditado === '' || pedidoSendoEditado === pedidosImport[item].id) && permissaoEditar ?
                                                                                            <div>
                                                                                                <button onClick={() => setPedidoSendoEditado(pedidosImport[item].id)}>Editar</button>
                                                                                            </div>
                                                                                            : null}
                                                                                    </div>

                                                                                    : <div>
                                                                                        <div >
                                                                                            <div><input type="checkbox" onChange={(e) => arquivar(pedidosImport[item].id, e.target.checked)} />Arquivar</div>
                                                                                        </div>
                                                                                        <div><Autocomplete
                                                                                            classes={{
                                                                                                paper: classes.paper
                                                                                            }}

                                                                                            disableClearable
                                                                                            className={classes.autoComplete}
                                                                                            getOptionSelected={() => true}
                                                                                            onChange={(index, valor) => montaUpdate(valor, pedidosImport[item].id, 'pedido_sap')}
                                                                                            defaultValue={pedidosImport[item].pedido_sap}
                                                                                            options={listaPedidosSap.map((item) => item.numeroSAP.toString())}
                                                                                            renderInput={(params) => (<TextField {...params} inputProps={{ ...params.inputProps, style: { fontSize: 12, fontWeight: 'bold' } }} />)}
                                                                                        /></div>

                                                                                        {duplicando || excluindo ?
                                                                                            <Loader
                                                                                                type="Circles"
                                                                                                color="#00BFFF"
                                                                                                height={20}
                                                                                                width={20}
                                                                                                timeout={2000000}
                                                                                            />
                                                                                            : <><div><button onClick={() => duplicarPedido(pedidosImport[item].id)}>Duplicar</button></div>
                                                                                                <div><button onClick={() => excluirPedido(pedidosImport[item].id)}>Excluir</button></div>
                                                                                            </>}
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                        </td>

                                                                        <td style={{ position: 'sticky', left: 94, background: '#e6e6e6', zIndex: 1, border: '1px solid rgba(0,0,0, .4)' }}>
                                                                            <div className={classes.cellContentFixed}>
                                                                                {pedidoSendoEditado !== pedidosImport[item].id ? pedidosImport[item].exportador :
                                                                                    <Autocomplete
                                                                                        classes={{
                                                                                            paper: classes.paper
                                                                                        }}
                                                                                        freeSolo
                                                                                        disableClearable
                                                                                        className={classes.autoComplete}
                                                                                        getOptionSelected={() => true}
                                                                                        onChange={(index, valor) => montaUpdate(valor, pedidosImport[item].id, 'exportador')}
                                                                                        defaultValue={pedidosImport[item].exportador}
                                                                                        options={['Ameropa', 'Keytrade', 'Phosagro', 'JSC-BPC', 'SQM', 'DREYMOOR', 'BULKFERT', 'INDAGRO', 'SAFTCO']}
                                                                                        renderInput={(params) => (<TextField {...params} inputProps={{ ...params.inputProps, style: { fontSize: 12, fontWeight: 'bold' } }} />)}
                                                                                    />}
                                                                            </div>

                                                                        </td>

                                                                        <td style={{ position: 'sticky', left: 188, background: '#e6e6e6', zIndex: 1, border: '1px solid rgba(0,0,0, .4)' }}>
                                                                            <div className={classes.cellContentFixed}>
                                                                                {pedidoSendoEditado !== pedidosImport[item].id ?
                                                                                    pedidosImport[item].produto :
                                                                                    <Autocomplete
                                                                                        classes={{
                                                                                            paper: classes.paper
                                                                                        }}
                                                                                        className={classes.autoComplete}
                                                                                        disableClearable
                                                                                        getOptionSelected={() => true}
                                                                                        onChange={(index, valor) => montaUpdate(valor, pedidosImport[item].id, 'produto')}
                                                                                        defaultValue={pedidosImport[item].produto}
                                                                                        options={Object.keys(listaProdutos).map((item) => (listaProdutos[item].ItemName))}
                                                                                        renderInput={(params) => (<TextField {...params} inputProps={{ ...params.inputProps, style: { fontSize: 12, fontWeight: 'bold' } }} />)}
                                                                                    />}
                                                                            </div>
                                                                        </td>

                                                                        <td style={{ position: 'sticky', left: 282, background: '#e6e6e6', zIndex: 1, border: '1px solid rgba(0,0,0, .4)' }}>
                                                                            <div className={classes.cellContentFixed}>
                                                                                {pedidoSendoEditado !== pedidosImport[item].id ? pedidosImport[item].navio :
                                                                                    <TextField
                                                                                        inputProps={{ style: { fontSize: 12, textAlign: 'center', width: 80, fontWeight: 'bold' } }}
                                                                                        defaultValue={pedidosImport[item].navio}
                                                                                        onChange={(e) => montaUpdate(e.target.value, pedidosImport[item].id, 'navio')}
                                                                                    />}
                                                                            </div>
                                                                        </td>

                                                                        <td style={{ position: 'sticky', left: 376, background: '#e6e6e6', zIndex: 1, border: '1px solid rgba(0,0,0, .4)' }}>
                                                                            <div className={classes.cellContentFixed}>
                                                                                {pedidoSendoEditado !== pedidosImport[item].id ? pedidosImport[item].filial :
                                                                                    <Autocomplete
                                                                                        classes={{
                                                                                            paper: classes.paper
                                                                                        }}
                                                                                        disableClearable
                                                                                        className={classes.autoComplete}
                                                                                        getOptionSelected={() => true}
                                                                                        defaultValue={pedidosImport[item].filial}
                                                                                        options={Object.keys(listaFiliais).map((item) => (listaFiliais[item].BPLName.split(' ')[0]))}
                                                                                        onChange={(index, valor) => montaUpdate(valor, pedidosImport[item].id, 'filial')}
                                                                                        renderInput={(params) => (<TextField {...params} inputProps={{ ...params.inputProps, style: { fontSize: 12, fontWeight: 'bold' } }} />)}
                                                                                    />}
                                                                            </div>

                                                                        </td>

                                                                        <td className={classes.tdBorder}>
                                                                            <div className={classes.cellContent}>
                                                                                {pedidoSendoEditado !== pedidosImport[item].id ? pedidosImport[item].modalidade :
                                                                                    <Autocomplete
                                                                                        disableClearable
                                                                                        className={classes.autoComplete}
                                                                                        getOptionSelected={() => true}
                                                                                        defaultValue={pedidosImport[item].modalidade}
                                                                                        options={modalidadeOptions}
                                                                                        onChange={(index, valor) => montaUpdate(valor, pedidosImport[item].id, 'modalidade')}
                                                                                        renderInput={(params) => (<TextField {...params} inputProps={{ ...params.inputProps, style: { fontSize: 12, fontWeight: 'bold' } }} />)}
                                                                                    />}
                                                                            </div>
                                                                        </td>

                                                                        <td className={classes.tdBorder}>
                                                                            <div className={classes.cellContent}>
                                                                                {pedidoSendoEditado !== pedidosImport[item].id ? pedidosImport[item].porto :
                                                                                    <Autocomplete
                                                                                        classes={{
                                                                                            paper: classes.paper
                                                                                        }}
                                                                                        disableClearable
                                                                                        className={classes.autoComplete}
                                                                                        renderInput={(params) => (<TextField {...params} inputProps={{ ...params.inputProps, style: { fontSize: 12, fontWeight: 'bold' } }} />)}
                                                                                        getOptionSelected={() => true}
                                                                                        defaultValue={pedidosImport[item].porto}
                                                                                        options={Object.keys(cidades).map((item) => (cidades[item].City_uf))}
                                                                                        onChange={(index, valor) => montaUpdate(valor, pedidosImport[item].id, 'porto')}
                                                                                    />}
                                                                            </div>
                                                                        </td>

                                                                        <td className={classes.tdBorder}>
                                                                            <div className={classes.cellContent}>
                                                                                {pedidoSendoEditado !== pedidosImport[item].id ? pedidosImport[item].quantidade :
                                                                                    <TextField
                                                                                        inputProps={{ style: { fontSize: 12, textAlign: 'center', width: 80, fontWeight: 'bold' } }}
                                                                                        onChange={(e) => montaUpdate(e.target.value, pedidosImport[item].id, 'quantidade')}
                                                                                        defaultValue={pedidosImport[item].quantidade.replace(',', '.')}
                                                                                        type='number'
                                                                                    />}
                                                                            </div>
                                                                        </td>

                                                                        <td className={classes.tdBorder}>
                                                                            <div className={classes.cellContent}>
                                                                                {pedidoSendoEditado !== pedidosImport[item].id ? pedidosImport[item].eta :
                                                                                    <TextField
                                                                                        className={classes.dataWidth}
                                                                                        defaultValue={pedidosImport[item].eta.substr(6) + '-' + pedidosImport[item].eta.substr(3, 2) + '-' + pedidosImport[item].eta.substr(0, 2)}
                                                                                        inputProps={{ max: '9999-12-31', style: { fontSize: 12, fontWeight: 'bold' } }}
                                                                                        type='date'
                                                                                        onChange={(e) => montaUpdate(e.target.value, pedidosImport[item].id, 'eta')}
                                                                                    />}
                                                                            </div>
                                                                        </td>

                                                                        <td className={classes.tdBorder}>
                                                                            <div className={classes.cellContent}>
                                                                                {pedidoSendoEditado !== pedidosImport[item].id ? pedidosImport[item].etb :
                                                                                    <TextField
                                                                                        className={classes.dataWidth}
                                                                                        defaultValue={pedidosImport[item].etb.substr(6) + '-' + pedidosImport[item].etb.substr(3, 2) + '-' + pedidosImport[item].etb.substr(0, 2)}
                                                                                        inputProps={{ max: '9999-12-31', style: { fontSize: 12, fontWeight: 'bold' } }}
                                                                                        type='date'
                                                                                        onChange={(e) => montaUpdate(e.target.value, pedidosImport[item].id, 'etb')}
                                                                                    />}
                                                                            </div>
                                                                        </td>

                                                                        <td className={classes.tdBorder}>
                                                                            <div className={classes.cellContent}>
                                                                                {pedidoSendoEditado !== pedidosImport[item].id ? pedidosImport[item].etc :
                                                                                    <TextField
                                                                                        className={classes.dataWidth}
                                                                                        defaultValue={pedidosImport[item].etc.substr(6) + '-' + pedidosImport[item].etc.substr(3, 2) + '-' + pedidosImport[item].etc.substr(0, 2)}
                                                                                        inputProps={{ max: '9999-12-31', style: { fontSize: 12, fontWeight: 'bold' } }}
                                                                                        type='date'
                                                                                        onChange={(e) => montaUpdate(e.target.value, pedidosImport[item].id, 'etc')}
                                                                                    />}
                                                                            </div>
                                                                        </td>

                                                                        <td className={classes.tdBorder}>
                                                                            <div className={classes.cellContent}>
                                                                                {pedidoSendoEditado !== pedidosImport[item].id ?
                                                                                    pedidosImport[item].agentePortuario :
                                                                                    <TextField
                                                                                        inputProps={{ style: { fontSize: 12, textAlign: 'center', width: 80, fontWeight: 'bold' } }}
                                                                                        defaultValue={pedidosImport[item].agentePortuario}
                                                                                        onChange={(e) => montaUpdate(e.target.value, pedidosImport[item].id, 'agentePortuario')}
                                                                                    />}
                                                                            </div>
                                                                        </td>

                                                                        <td className={classes.tdBorder}>
                                                                            <div className={classes.cellContent}>
                                                                                {pedidoSendoEditado !== pedidosImport[item].id ?
                                                                                    pedidosImport[item].operadorPortuario :
                                                                                    <TextField
                                                                                        inputProps={{ style: { fontSize: 12, textAlign: 'center', width: 80, fontWeight: 'bold' } }}
                                                                                        defaultValue={pedidosImport[item].operadorPortuario}
                                                                                        onChange={(e) => montaUpdate(e.target.value, pedidosImport[item].id, 'operadorPortuario')}
                                                                                    />}
                                                                            </div>
                                                                        </td>

                                                                        <td className={classes.tdBorder}>

                                                                            <div className={classes.cellContentLeft}>
                                                                                {pedidoSendoEditado !== pedidosImport[item].id ?
                                                                                    Object.keys(JSON.parse(pedidosImport[item].armazemMic)).map((el, ind1) => {
                                                                                        return (
                                                                                            <div key={ind1} style={{ fontSize: 13, border: '1px solid rgba(0, 0, 0, .5)', marginLeft: 5, minWidth: 150, textAlign: 'center' }}>
                                                                                                <div>Az: {JSON.parse(pedidosImport[item].armazemMic)[el].armazem}</div>
                                                                                                <div>Qtd: {JSON.parse(pedidosImport[item].armazemMic)[el].quantidadeArmazem}</div>
                                                                                                <div>Mic: {JSON.parse(pedidosImport[item].armazemMic)[el].mic}</div>
                                                                                                <div>DescReal: {JSON.parse(pedidosImport[item].armazemMic)[el].descargaReal}</div>
                                                                                                <div>Saldo: {JSON.parse(pedidosImport[item].armazemMic)[el].saldo}</div>
                                                                                            </div>
                                                                                        )
                                                                                    }) :
                                                                                    <div style={{ display: 'flex', flexDirection: 'row', alignContent: 'center', alignItems: 'center' }}>
                                                                                        <button style={{ height: 25 }} onClick={() => addArmazemEdicao(index)}>Add</button>
                                                                                        {Object.keys(JSON.parse(pedidosImport[item].armazemMic)).map((indexArmazem) => {
                                                                                            return (
                                                                                                <div key={indexArmazem} style={{ width: 80, border: '1px solid rgba(0, 0, 0, .5)', margin: 2, padding: 4, flexDirection: 'column', display: escondeArmazem[index][indexArmazem] ? 'none' : 'block' }}>
                                                                                                    <button
                                                                                                        onClick={() => removeArmazemEdicao(index, indexArmazem, JSON.parse(pedidosImport[item].armazemMic)[indexArmazem].idIncrement, pedidosImport[item].id)}
                                                                                                    >Remover</button>
                                                                                                    <TextField
                                                                                                        onChange={(e) => montaUpdate(e.target.value, pedidosImport[item].id, 'armazem-' + JSON.parse(pedidosImport[item].armazemMic)[indexArmazem].idIncrement)}
                                                                                                        inputProps={{ style: { fontSize: 12, textAlign: 'center', width: 80, fontWeight: 'bold' } }}
                                                                                                        defaultValue={JSON.parse(pedidosImport[item].armazemMic)[indexArmazem].armazem}
                                                                                                    />

                                                                                                    <TextField
                                                                                                        type='number'
                                                                                                        onChange={(e) => montaUpdate(e.target.value, pedidosImport[item].id, 'quantidadeArmazem-' + JSON.parse(pedidosImport[item].armazemMic)[indexArmazem].idIncrement)}
                                                                                                        inputProps={{ style: { fontSize: 12, textAlign: 'center', width: 80, fontWeight: 'bold' } }}
                                                                                                        defaultValue={JSON.parse(pedidosImport[item].armazemMic)[indexArmazem].quantidadeArmazem}
                                                                                                    />

                                                                                                    <TextField
                                                                                                        onChange={(e) => montaUpdate(e.target.value, pedidosImport[item].id, 'mic-' + JSON.parse(pedidosImport[item].armazemMic)[indexArmazem].idIncrement)}
                                                                                                        inputProps={{ style: { fontSize: 12, textAlign: 'center', width: 80, fontWeight: 'bold' } }}
                                                                                                        defaultValue={JSON.parse(pedidosImport[item].armazemMic)[indexArmazem].mic}
                                                                                                    />

                                                                                                    <TextField
                                                                                                        type='number'
                                                                                                        onChange={(e) => montaUpdate(e.target.value, pedidosImport[item].id, 'descargaReal-' + JSON.parse(pedidosImport[item].armazemMic)[indexArmazem].idIncrement)}
                                                                                                        inputProps={{ style: { fontSize: 12, textAlign: 'center', width: 80, fontWeight: 'bold' } }}
                                                                                                        defaultValue={JSON.parse(pedidosImport[item].armazemMic)[indexArmazem]}
                                                                                                    />
                                                                                                </div>
                                                                                            )
                                                                                        })}
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                        </td>

                                                                    </tr> : ''
                                                            )
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div> :
                                            tab === 3 ? // DESEMBARAÇO
                                                <div key={3} className={classes.divTabela}>
                                                    <table>
                                                        <thead className={classes.header}>
                                                            <tr>
                                                                <th style={{ position: 'sticky', left: 0, background: '#383838', fontSize: 14, minWidth: 94 }}>SAP</th>
                                                                <th style={{ position: 'sticky', left: 94, background: '#383838', fontSize: 14, minWidth: 94 }}>Exportador</th>
                                                                <th style={{ position: 'sticky', left: 188, background: '#383838', fontSize: 14, minWidth: 94 }}>Produto</th>
                                                                <th style={{ position: 'sticky', left: 282, background: '#383838', fontSize: 14, minWidth: 94 }}>Navio</th>
                                                                <th style={{ position: 'sticky', left: 376, background: '#383838', fontSize: 14, minWidth: 94 }}>Filial</th>
                                                                {permissoesColunas.contrato === 1 ? <th className={classes.thDesembaraço}>Contrato</th> : <></>}
                                                                <th className={classes.thDesembaraço}>Quantidade</th>
                                                                {permissoesColunas.valorUnit === 1 ? <th className={classes.thDesembaraço}>Valor Unidade (U$)</th> : <></>}
                                                                {permissoesColunas.proformaInvoice === 1 ? <th className={classes.thDesembaraço}>Proforma Invoice</th> : <></>}
                                                                {permissoesColunas.comercialInvoice === 1 ?
                                                                    <>
                                                                        <th className={classes.thDesembaraço}>Comercial Invoice</th>
                                                                        <th className={classes.thDesembaraço}>Data Comercial Invoice</th>
                                                                    </>
                                                                    : <></>}
                                                                {permissoesColunas.coa === 1 ? <th className={classes.thDesembaraço}>COA</th> : <></>}
                                                                {permissoesColunas.coo === 1 ? <th className={classes.thDesembaraço}>COO</th> : <></>}
                                                                {permissoesColunas.bl === 1 ? <th className={classes.thDesembaraço}>BL</th> : <></>}
                                                                {permissoesColunas.seguro === 1 ? <th className={classes.thDesembaraço}>Seguro</th> : <></>}
                                                                {permissoesColunas.li === 1 ? <th className={classes.thDesembaraço}>LI</th> : <></>}
                                                                {permissoesColunas.di === 1 ? <th className={classes.thDesembaraço}>DI</th> : <></>}
                                                                {permissoesColunas.notaMae === 1 ? <th className={classes.thDesembaraço}>Nota Mãe</th> : <></>}
                                                                {permissoesColunas.glme === 1 ? <th className={classes.thDesembaraço}>GLME</th> : <></>}
                                                                {permissoesColunas.cdd === 1 ? <th className={classes.thDesembaraço}>CDD</th> : <></>}
                                                                {permissoesColunas.ci === 1 ? <th className={classes.thDesembaraço}>CI</th> : <></>}
                                                                {permissoesColunas.afrmmTotalReal === 1 ? <th className={classes.thDesembaraço}>AFRMM Total (R$)</th> : <></>}
                                                                {permissoesColunas.afrmmTotalDolar === 1 ? <th className={classes.thDesembaraço}>AFRMM Total (U$)</th> : <></>}
                                                                {permissoesColunas.situacaoAfrmm === 1 ? <th className={classes.thDesembaraço}>Situação AFRMM</th> : <></>}
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {Object.keys(pedidosImport).map((item, index) => {
                                                                let itemString = JSON.stringify(pedidosImport[item]).toLowerCase()
                                                                return (
                                                                    itemString.includes(filtro) || filtro === '' ?
                                                                        <tr key={index}>
                                                                            <td style={{ position: 'sticky', left: 0, background: '#e6e6e6', zIndex: 1, border: '1px solid rgba(0,0,0, .4)' }}>
                                                                                <div className={classes.cellContentFixed} >
                                                                                    {pedidoSendoEditado !== pedidosImport[item].id ?

                                                                                        <div>
                                                                                            <div>
                                                                                                {pedidosImport[item].pedido_sap}
                                                                                            </div>
                                                                                            {(pedidoSendoEditado === '' || pedidoSendoEditado === pedidosImport[item].id) && permissaoEditar ?
                                                                                                <div>
                                                                                                    <button onClick={() => setPedidoSendoEditado(pedidosImport[item].id)}>Editar</button>
                                                                                                </div>
                                                                                                : null}
                                                                                        </div>

                                                                                        : <div>
                                                                                            <div >
                                                                                                <div><input type="checkbox" onChange={(e) => arquivar(pedidosImport[item].id, e.target.checked)} />Arquivar</div>
                                                                                            </div>
                                                                                            <div><Autocomplete
                                                                                                classes={{
                                                                                                    paper: classes.paper
                                                                                                }}

                                                                                                disableClearable
                                                                                                className={classes.autoComplete}
                                                                                                getOptionSelected={() => true}
                                                                                                onChange={(index, valor) => montaUpdate(valor, pedidosImport[item].id, 'pedido_sap')}
                                                                                                defaultValue={pedidosImport[item].pedido_sap}
                                                                                                options={listaPedidosSap.map((item) => item.numeroSAP.toString())}
                                                                                                renderInput={(params) => (<TextField {...params} inputProps={{ ...params.inputProps, style: { fontSize: 12, fontWeight: 'bold' } }} />)}
                                                                                            /></div>

                                                                                            {duplicando || excluindo ?
                                                                                                <Loader
                                                                                                    type="Circles"
                                                                                                    color="#00BFFF"
                                                                                                    height={20}
                                                                                                    width={20}
                                                                                                    timeout={2000000}
                                                                                                />
                                                                                                : <><div><button onClick={() => duplicarPedido(pedidosImport[item].id)}>Duplicar</button></div>
                                                                                                    <div><button onClick={() => excluirPedido(pedidosImport[item].id)}>Excluir</button></div>
                                                                                                </>}
                                                                                        </div>
                                                                                    }
                                                                                </div>
                                                                            </td>

                                                                            <td style={{ position: 'sticky', left: 94, background: '#e6e6e6', zIndex: 1, border: '1px solid rgba(0,0,0, .4)' }}>
                                                                                <div className={classes.cellContentFixed}>
                                                                                    {pedidoSendoEditado !== pedidosImport[item].id ? pedidosImport[item].exportador :
                                                                                        <Autocomplete
                                                                                            classes={{
                                                                                                paper: classes.paper
                                                                                            }}
                                                                                            freeSolo
                                                                                            disableClearable
                                                                                            className={classes.autoComplete}
                                                                                            getOptionSelected={() => true}
                                                                                            onChange={(index, valor) => montaUpdate(valor, pedidosImport[item].id, 'exportador')}
                                                                                            defaultValue={pedidosImport[item].exportador}
                                                                                            options={['Ameropa', 'Keytrade', 'Phosagro', 'JSC-BPC', 'SQM', 'DREYMOOR', 'BULKFERT', 'INDAGRO', 'SAFTCO']}
                                                                                            renderInput={(params) => (<TextField {...params} inputProps={{ ...params.inputProps, style: { fontSize: 12, fontWeight: 'bold' } }} />)}
                                                                                        />}
                                                                                </div>

                                                                            </td>

                                                                            <td style={{ position: 'sticky', left: 188, background: '#e6e6e6', zIndex: 1, border: '1px solid rgba(0,0,0, .4)' }}>
                                                                                <div className={classes.cellContentFixed}>
                                                                                    {pedidoSendoEditado !== pedidosImport[item].id ?
                                                                                        pedidosImport[item].produto :
                                                                                        <Autocomplete
                                                                                            classes={{
                                                                                                paper: classes.paper
                                                                                            }}
                                                                                            className={classes.autoComplete}
                                                                                            disableClearable
                                                                                            getOptionSelected={() => true}
                                                                                            onChange={(index, valor) => montaUpdate(valor, pedidosImport[item].id, 'produto')}
                                                                                            defaultValue={pedidosImport[item].produto}
                                                                                            options={Object.keys(listaProdutos).map((item) => (listaProdutos[item].ItemName))}
                                                                                            renderInput={(params) => (<TextField {...params} inputProps={{ ...params.inputProps, style: { fontSize: 12, fontWeight: 'bold' } }} />)}
                                                                                        />}
                                                                                </div>
                                                                            </td>

                                                                            <td style={{ position: 'sticky', left: 282, background: '#e6e6e6', zIndex: 1, border: '1px solid rgba(0,0,0, .4)' }}>
                                                                                <div className={classes.cellContentFixed}>
                                                                                    {pedidoSendoEditado !== pedidosImport[item].id ? pedidosImport[item].navio :
                                                                                        <TextField
                                                                                            inputProps={{ style: { fontSize: 12, textAlign: 'center', width: 80, fontWeight: 'bold' } }}
                                                                                            defaultValue={pedidosImport[item].navio}
                                                                                            onChange={(e) => montaUpdate(e.target.value, pedidosImport[item].id, 'navio')}
                                                                                        />}
                                                                                </div>
                                                                            </td>

                                                                            <td style={{ position: 'sticky', left: 376, background: '#e6e6e6', zIndex: 1, border: '1px solid rgba(0,0,0, .4)' }}>
                                                                                <div className={classes.cellContentFixed}>
                                                                                    {pedidoSendoEditado !== pedidosImport[item].id ? pedidosImport[item].filial :
                                                                                        <Autocomplete
                                                                                            classes={{
                                                                                                paper: classes.paper
                                                                                            }}
                                                                                            disableClearable
                                                                                            className={classes.autoComplete}
                                                                                            getOptionSelected={() => true}
                                                                                            defaultValue={pedidosImport[item].filial}
                                                                                            options={Object.keys(listaFiliais).map((item) => (listaFiliais[item].BPLName.split(' ')[0]))}
                                                                                            onChange={(index, valor) => montaUpdate(valor, pedidosImport[item].id, 'filial')}
                                                                                            renderInput={(params) => (<TextField {...params} inputProps={{ ...params.inputProps, style: { fontSize: 12, fontWeight: 'bold' } }} />)}
                                                                                        />}
                                                                                </div>

                                                                            </td>

                                                                            {permissoesColunas.contrato === 1 ?
                                                                                <td className={classes.tdBorder}>
                                                                                    <div className={classes.cellContent}>
                                                                                        {pedidoSendoEditado !== pedidosImport[item].id ?
                                                                                            <div>
                                                                                                {pedidosImport[item].contrato}  {'\n'}

                                                                                                {pedidosImport[item].contrato === 'Concluído' ?
                                                                                                    validarArquivo(pedidosImport[item].contratoArquivos, pedidosImport[item].pedido_sap, 'contrato', index)
                                                                                                    : null
                                                                                                }
                                                                                            </div> :
                                                                                            <Autocomplete
                                                                                                classes={{
                                                                                                    paper: classes.paper
                                                                                                }}
                                                                                                disableClearable
                                                                                                className={classes.autoComplete}
                                                                                                renderInput={(params) => (<TextField {...params} inputProps={{ ...params.inputProps, style: { fontSize: 12, fontWeight: 'bold' } }} />)}
                                                                                                getOptionSelected={() => true}
                                                                                                defaultValue={pedidosImport[item].contrato}
                                                                                                options={contratoOptions}
                                                                                                onChange={(index, valor) => montaUpdate(valor, pedidosImport[item].id, 'contrato')}
                                                                                            />}
                                                                                    </div>
                                                                                </td>
                                                                                : <></>}


                                                                            <td className={classes.tdBorder}>
                                                                                <div className={classes.cellContent}>
                                                                                    {pedidoSendoEditado !== pedidosImport[item].id ? pedidosImport[item].quantidade :
                                                                                        <TextField
                                                                                            inputProps={{ style: { fontSize: 12, textAlign: 'center', width: 80, fontWeight: 'bold' } }}
                                                                                            onChange={(e) => montaUpdate(e.target.value, pedidosImport[item].id, 'quantidade')}
                                                                                            defaultValue={pedidosImport[item].quantidade.replace(',', '.')}
                                                                                            type='number'
                                                                                        />}
                                                                                </div>
                                                                            </td>

                                                                            {permissoesColunas.valorUnit === 1 ?
                                                                                <td className={classes.tdBorder}>
                                                                                    <div className={classes.cellContent}>
                                                                                        {pedidoSendoEditado !== pedidosImport[item].id ? pedidosImport[item].valorUnit.toLocaleString('en-us', { style: 'currency', currency: 'USD' }) :
                                                                                            <TextField
                                                                                                inputProps={{ style: { fontSize: 12, textAlign: 'center', width: 80, fontWeight: 'bold' } }}
                                                                                                defaultValue={pedidosImport[item].valorUnit.replace(',', '.')}
                                                                                                type='number'
                                                                                                onChange={(e) => montaUpdate(e.target.value, pedidosImport[item].id, 'valorUnit')}
                                                                                            />}
                                                                                    </div>
                                                                                </td>
                                                                                : <></>}


                                                                            {permissoesColunas.proformaInvoice === 1 ?
                                                                                <td className={pedidosImport[item].proformaInvoice === 'Não se aplica' ? {} : pedidosImport[item].proformaInvoice === 'Concluído' ? classes.green : classes.red}>
                                                                                    <div className={classes.cellContent}>
                                                                                        {pedidoSendoEditado !== pedidosImport[item].id ?
                                                                                            <div>
                                                                                                {pedidosImport[item].proformaInvoice} {'\n'}
                                                                                                {pedidosImport[item].numeroProformaInvoice} {'\n'}
                                                                                                {pedidosImport[item].proformaInvoice === 'Concluído' ?
                                                                                                    validarArquivo(pedidosImport[item]['proforma invoiceArquivos'], pedidosImport[item].pedido_sap, 'proforma invoice', index)
                                                                                                    : null
                                                                                                }
                                                                                            </div> :
                                                                                            <span>
                                                                                                <Autocomplete
                                                                                                    classes={{
                                                                                                        paper: classes.paper
                                                                                                    }}
                                                                                                    disableClearable
                                                                                                    className={classes.autoComplete}
                                                                                                    renderInput={(params) => (<TextField {...params} inputProps={{ ...params.inputProps, style: { fontSize: 12, fontWeight: 'bold' } }} />)}
                                                                                                    getOptionSelected={() => true}
                                                                                                    defaultValue={pedidosImport[item].proformaInvoice}
                                                                                                    options={['Em andamento', 'Não se aplica', 'Concluído']}
                                                                                                    onChange={(index, valor) => montaUpdate(valor, pedidosImport[item].id, 'proformaInvoice')}
                                                                                                /> {'\n'}
                                                                                                <TextField
                                                                                                    inputProps={{ style: { fontSize: 12, textAlign: 'center', width: 80, fontWeight: 'bold' } }}
                                                                                                    defaultValue={pedidosImport[item].numeroProformaInvoice}
                                                                                                    onChange={(e) => montaUpdate(e.target.value, pedidosImport[item].id, 'numeroProformaInvoice')}
                                                                                                />
                                                                                            </span>
                                                                                        }
                                                                                    </div>
                                                                                </td>
                                                                                : <></>}

                                                                            {permissoesColunas.comercialInvoice === 1 ?
                                                                                <>
                                                                                    <td className={pedidosImport[item].comercialInvoice === 'Concluído' ? classes.green : classes.red}>
                                                                                        <div className={classes.cellContent}>
                                                                                            {pedidoSendoEditado !== pedidosImport[item].id ?
                                                                                                <div>
                                                                                                    {pedidosImport[item].comercialInvoice} {'\n'}
                                                                                                    {pedidosImport[item].numeroComercialInvoice} {'\n'}
                                                                                                    {pedidosImport[item].comercialInvoice === 'Concluído' ?
                                                                                                        validarArquivo(pedidosImport[item]['comercial invoiceArquivos'], pedidosImport[item].pedido_sap, 'comercial invoice', index)
                                                                                                        : null
                                                                                                    }
                                                                                                </div> :
                                                                                                <span>
                                                                                                    <Autocomplete
                                                                                                        classes={{
                                                                                                            paper: classes.paper
                                                                                                        }}
                                                                                                        disableClearable
                                                                                                        className={classes.autoComplete}
                                                                                                        renderInput={(params) => (<TextField {...params} inputProps={{ ...params.inputProps, style: { fontSize: 12, fontWeight: 'bold' } }} />)}
                                                                                                        getOptionSelected={() => true}
                                                                                                        defaultValue={pedidosImport[item].comercialInvoice}
                                                                                                        options={options1}
                                                                                                        onChange={(index, valor) => montaUpdate(valor, pedidosImport[item].id, 'comercialInvoice')}
                                                                                                    /> {'\n'}
                                                                                                    <TextField
                                                                                                        inputProps={{ style: { fontSize: 12, textAlign: 'center', width: 80, fontWeight: 'bold' } }}
                                                                                                        defaultValue={pedidosImport[item].numeroComercialInvoice}
                                                                                                        onChange={(e) => montaUpdate(e.target.value, pedidosImport[item].id, 'numeroComercialInvoice')}
                                                                                                    />
                                                                                                </span>
                                                                                            }
                                                                                        </div>

                                                                                    </td>
                                                                                    <td className={classes.tdBorder}>
                                                                                        <div className={classes.cellContent}>
                                                                                            {pedidoSendoEditado !== pedidosImport[item].id ? pedidosImport[item].dataComercialInvoice :
                                                                                                <TextField
                                                                                                    className={classes.dataWidth}
                                                                                                    defaultValue={pedidosImport[item].dataComercialInvoice.substr(6) + '-' + pedidosImport[item].dataComercialInvoice.substr(3, 2) + '-' + pedidosImport[item].dataComercialInvoice.substr(0, 2)}
                                                                                                    inputProps={{ max: '9999-12-31', style: { fontSize: 12, fontWeight: 'bold' } }}
                                                                                                    type='date'
                                                                                                    onChange={(e) => montaUpdate(e.target.value, pedidosImport[item].id, 'dataComercialInvoice')}
                                                                                                />}
                                                                                        </div>
                                                                                    </td>
                                                                                </>


                                                                                : <></>}

                                                                            {permissoesColunas.coa === 1 ?
                                                                                <td className={pedidosImport[item].coa === 'Concluído' ? classes.green : classes.red}>
                                                                                    <div className={classes.cellContent}>
                                                                                        {pedidoSendoEditado !== pedidosImport[item].id ?
                                                                                            <div>
                                                                                                {pedidosImport[item].coa} {'\n'}
                                                                                                {pedidosImport[item].coa === 'Concluído' ?
                                                                                                    validarArquivo(pedidosImport[item].coaArquivos, pedidosImport[item].pedido_sap, 'coa', index)
                                                                                                    : null
                                                                                                }
                                                                                            </div> :
                                                                                            <Autocomplete
                                                                                                classes={{
                                                                                                    paper: classes.paper
                                                                                                }}
                                                                                                disableClearable
                                                                                                className={classes.autoComplete}
                                                                                                renderInput={(params) => (<TextField {...params} inputProps={{ ...params.inputProps, style: { fontSize: 12, fontWeight: 'bold' } }} />)}
                                                                                                getOptionSelected={() => true}
                                                                                                defaultValue={pedidosImport[item].coa}
                                                                                                options={options1}
                                                                                                onChange={(index, valor) => montaUpdate(valor, pedidosImport[item].id, 'coa')}
                                                                                            />}
                                                                                    </div>
                                                                                </td>
                                                                                : <></>}

                                                                            {permissoesColunas.coo === 1 ?
                                                                                <td className={pedidosImport[item].coo === 'Concluído' ? classes.green : classes.red}>
                                                                                    <div className={classes.cellContent}>
                                                                                        {pedidoSendoEditado !== pedidosImport[item].id ?
                                                                                            <div>
                                                                                                {pedidosImport[item].coo} {'\n'}
                                                                                                {pedidosImport[item].coo === 'Concluído' ?
                                                                                                    validarArquivo(pedidosImport[item].cooArquivos, pedidosImport[item].pedido_sap, 'coo', index)
                                                                                                    : null
                                                                                                }
                                                                                            </div> :
                                                                                            <Autocomplete
                                                                                                classes={{
                                                                                                    paper: classes.paper
                                                                                                }}
                                                                                                disableClearable
                                                                                                className={classes.autoComplete}
                                                                                                renderInput={(params) => (<TextField {...params} inputProps={{ ...params.inputProps, style: { fontSize: 12, fontWeight: 'bold' } }} />)}
                                                                                                getOptionSelected={() => true}
                                                                                                defaultValue={pedidosImport[item].coo}
                                                                                                options={options1}
                                                                                                onChange={(index, valor) => montaUpdate(valor, pedidosImport[item].id, 'coo')}
                                                                                            />}
                                                                                    </div>
                                                                                </td>
                                                                                : <></>}


                                                                            {permissoesColunas.bl === 1 ?
                                                                                <td className={pedidosImport[item].bl === 'Concluído' ? classes.green : classes.red}>
                                                                                    <div className={classes.cellContent}>
                                                                                        {pedidoSendoEditado !== pedidosImport[item].id ?
                                                                                            <div>
                                                                                                {pedidosImport[item].bl} {'\n'}
                                                                                                {pedidosImport[item].numeroBl} {'\n'}
                                                                                                {pedidosImport[item].bl === 'Concluído' ?
                                                                                                    validarArquivo(pedidosImport[item].blArquivos, pedidosImport[item].pedido_sap, 'bl', index)
                                                                                                    : null
                                                                                                }
                                                                                            </div>
                                                                                            :
                                                                                            <span>
                                                                                                <Autocomplete
                                                                                                    classes={{
                                                                                                        paper: classes.paper
                                                                                                    }}
                                                                                                    disableClearable
                                                                                                    className={classes.autoComplete}
                                                                                                    renderInput={(params) => (<TextField {...params} inputProps={{ ...params.inputProps, style: { fontSize: 12, fontWeight: 'bold' } }} />)}
                                                                                                    getOptionSelected={() => true}
                                                                                                    defaultValue={pedidosImport[item].bl}
                                                                                                    options={options1}
                                                                                                    onChange={(index, valor) => montaUpdate(valor, pedidosImport[item].id, 'bl')}
                                                                                                /> {'\n'}

                                                                                                <TextField
                                                                                                    inputProps={{ style: { fontSize: 12, textAlign: 'center', width: 80, fontWeight: 'bold' } }}
                                                                                                    defaultValue={pedidosImport[item].numeroBl}
                                                                                                    onChange={(e) => montaUpdate(e.target.value, pedidosImport[item].id, 'numeroBl')}
                                                                                                />
                                                                                            </span>
                                                                                        }
                                                                                    </div>
                                                                                </td>
                                                                                : <></>}

                                                                            {permissoesColunas.seguro === 1 ?
                                                                                <td className={pedidosImport[item].seguro > 0 ? classes.green : classes.red}>
                                                                                    <div className={classes.cellContent}>
                                                                                        {pedidoSendoEditado !== pedidosImport[item].id ? pedidosImport[item].seguro.toLocaleString('en-us', { style: 'currency', currency: 'USD' }) :
                                                                                            <TextField
                                                                                                inputProps={{ style: { fontSize: 12, textAlign: 'center', width: 80, fontWeight: 'bold' } }}
                                                                                                defaultValue={pedidosImport[item].seguro.replace(',', '.')}
                                                                                                type='number'
                                                                                                onChange={(e) => montaUpdate(e.target.value, pedidosImport[item].id, 'seguro')}
                                                                                            />}
                                                                                    </div>
                                                                                </td>
                                                                                : <></>}


                                                                            {permissoesColunas.li === 1 ?
                                                                                <td className={pedidosImport[item].li === 'Concluído' ? classes.green : classes.red}>
                                                                                    <div className={classes.cellContent}>
                                                                                        {pedidoSendoEditado !== pedidosImport[item].id ?
                                                                                            <div>
                                                                                                {pedidosImport[item].li} {'\n'}
                                                                                                {pedidosImport[item].numeroLi} {'\n'}
                                                                                                {pedidosImport[item].li === 'Concluído' ?
                                                                                                    validarArquivo(pedidosImport[item].liArquivos, pedidosImport[item].pedido_sap, 'li', index)
                                                                                                    : null
                                                                                                }
                                                                                            </div> :
                                                                                            <span>
                                                                                                <Autocomplete
                                                                                                    classes={{
                                                                                                        paper: classes.paper
                                                                                                    }}
                                                                                                    disableClearable
                                                                                                    className={classes.autoComplete}
                                                                                                    renderInput={(params) => (<TextField {...params} inputProps={{ ...params.inputProps, style: { fontSize: 12, fontWeight: 'bold' } }} />)}
                                                                                                    getOptionSelected={() => true}
                                                                                                    defaultValue={pedidosImport[item].li}
                                                                                                    options={options1}
                                                                                                    onChange={(index, valor) => montaUpdate(valor, pedidosImport[item].id, 'li')}
                                                                                                />
                                                                                                <TextField
                                                                                                    inputProps={{ style: { fontSize: 12, textAlign: 'center', width: 80, fontWeight: 'bold' } }}
                                                                                                    defaultValue={pedidosImport[item].numeroLi}
                                                                                                    onChange={(e) => montaUpdate(e.target.value, pedidosImport[item].id, 'numeroLi')}
                                                                                                />
                                                                                            </span>
                                                                                        }

                                                                                    </div>
                                                                                </td>
                                                                                : <></>}

                                                                            {permissoesColunas.di === 1 ?
                                                                                <td className={pedidosImport[item].di === 'Concluído' ? classes.green : classes.red}>
                                                                                    <div className={classes.cellContent}>
                                                                                        {pedidoSendoEditado !== pedidosImport[item].id ?
                                                                                            <div>
                                                                                                {pedidosImport[item].di} {'\n'}
                                                                                                {pedidosImport[item].numeroDi} {'\n'}
                                                                                                {pedidosImport[item].di === 'Concluído' ?
                                                                                                    validarArquivo(pedidosImport[item].diArquivos, pedidosImport[item].pedido_sap, 'di', index)
                                                                                                    : null
                                                                                                }
                                                                                            </div>
                                                                                            :
                                                                                            <span>
                                                                                                <Autocomplete
                                                                                                    classes={{
                                                                                                        paper: classes.paper
                                                                                                    }}
                                                                                                    disableClearable
                                                                                                    className={classes.autoComplete}
                                                                                                    renderInput={(params) => (<TextField {...params} inputProps={{ ...params.inputProps, style: { fontSize: 12, fontWeight: 'bold' } }} />)}
                                                                                                    getOptionSelected={() => true}
                                                                                                    defaultValue={pedidosImport[item].di}
                                                                                                    options={options1}
                                                                                                    onChange={(index, valor) => montaUpdate(valor, pedidosImport[item].id, 'di')}
                                                                                                /> {'\n'}

                                                                                                <TextField
                                                                                                    inputProps={{ style: { fontSize: 12, textAlign: 'center', width: 80, fontWeight: 'bold' } }}
                                                                                                    defaultValue={pedidosImport[item].numeroDi}
                                                                                                    onChange={(e) => montaUpdate(e.target.value, pedidosImport[item].id, 'numeroDi')}
                                                                                                />
                                                                                            </span>
                                                                                        }
                                                                                    </div>
                                                                                </td>
                                                                                : <></>}


                                                                            {permissoesColunas.notaMae === 1 ?
                                                                                <td className={pedidosImport[item].notaMae === 'Concluído' ? classes.green : classes.red}>
                                                                                    <div className={classes.cellContent}>
                                                                                        {pedidoSendoEditado !== pedidosImport[item].id ?
                                                                                            <div>
                                                                                                {pedidosImport[item].notaMae} {'\n'}
                                                                                                {pedidosImport[item].numeroNotaMae} {'\n'}
                                                                                                {pedidosImport[item].notaMae === 'Concluído' ?
                                                                                                    validarArquivo(pedidosImport[item]['nota maeArquivos'], pedidosImport[item].pedido_sap, 'nota mae', index)
                                                                                                    : null
                                                                                                }
                                                                                            </div> :
                                                                                            <span>
                                                                                                <Autocomplete
                                                                                                    classes={{
                                                                                                        paper: classes.paper
                                                                                                    }}
                                                                                                    disableClearable
                                                                                                    className={classes.autoComplete}
                                                                                                    renderInput={(params) => (<TextField {...params} inputProps={{ ...params.inputProps, style: { fontSize: 12, fontWeight: 'bold' } }} />)}
                                                                                                    getOptionSelected={() => true}
                                                                                                    defaultValue={pedidosImport[item].notaMae}
                                                                                                    options={options1}
                                                                                                    onChange={(index, valor) => montaUpdate(valor, pedidosImport[item].id, 'notaMae')}
                                                                                                />

                                                                                                <TextField
                                                                                                    inputProps={{ style: { fontSize: 12, textAlign: 'center', width: 80, fontWeight: 'bold' } }}
                                                                                                    defaultValue={pedidosImport[item].numeroNotaMae}
                                                                                                    onChange={(e) => montaUpdate(e.target.value, pedidosImport[item].id, 'numeroNotaMae')}
                                                                                                />
                                                                                            </span>
                                                                                        }
                                                                                    </div>
                                                                                </td>
                                                                                : <></>}

                                                                            {permissoesColunas.glme === 1 ?
                                                                                <td className={pedidosImport[item].glme === 'Concluído' ? classes.green : classes.red}>
                                                                                    <div className={classes.cellContent}>
                                                                                        {pedidoSendoEditado !== pedidosImport[item].id ?
                                                                                            <div>
                                                                                                {pedidosImport[item].glme} {'\n'}
                                                                                                {pedidosImport[item].glme === 'Concluído' ?
                                                                                                    validarArquivo(pedidosImport[item].glmeArquivos, pedidosImport[item].pedido_sap, 'glme', index)
                                                                                                    : null
                                                                                                }
                                                                                            </div> :
                                                                                            <Autocomplete
                                                                                                classes={{
                                                                                                    paper: classes.paper
                                                                                                }}
                                                                                                disableClearable
                                                                                                className={classes.autoComplete}
                                                                                                renderInput={(params) => (<TextField {...params} inputProps={{ ...params.inputProps, style: { fontSize: 12, fontWeight: 'bold' } }} />)}
                                                                                                getOptionSelected={() => true}
                                                                                                defaultValue={pedidosImport[item].glme}
                                                                                                options={glmeOptions}
                                                                                                onChange={(index, valor) => montaUpdate(valor, pedidosImport[item].id, 'glme')}
                                                                                            />}
                                                                                    </div>
                                                                                </td>
                                                                                : <></>}

                                                                            {permissoesColunas.cdd === 1 ?
                                                                                <td className={pedidosImport[item].cdd === 'Concluído' ? classes.green : classes.red}>
                                                                                    <div className={classes.cellContent}>
                                                                                        {pedidoSendoEditado !== pedidosImport[item].id ?
                                                                                            <div>
                                                                                                {pedidosImport[item].cdd} {'\n'}
                                                                                                {pedidosImport[item].cdd === 'Concluído' ?
                                                                                                    validarArquivo(pedidosImport[item].cddArquivos, pedidosImport[item].pedido_sap, 'cdd', index)
                                                                                                    : null
                                                                                                }
                                                                                            </div> :
                                                                                            <Autocomplete
                                                                                                classes={{
                                                                                                    paper: classes.paper
                                                                                                }}
                                                                                                disableClearable
                                                                                                className={classes.autoComplete}
                                                                                                renderInput={(params) => (<TextField {...params} inputProps={{ ...params.inputProps, style: { fontSize: 12, fontWeight: 'bold' } }} />)}
                                                                                                getOptionSelected={() => true}
                                                                                                defaultValue={pedidosImport[item].cdd}
                                                                                                options={options1}
                                                                                                onChange={(index, valor) => montaUpdate(valor, pedidosImport[item].id, 'cdd')}
                                                                                            />}
                                                                                    </div>
                                                                                </td>
                                                                                : <></>}

                                                                            {permissoesColunas.ci === 1 ?
                                                                                <td className={pedidosImport[item].ci === 'Concluído' ? classes.green : classes.red}>
                                                                                    <div className={classes.cellContent}>
                                                                                        {pedidoSendoEditado !== pedidosImport[item].id ?
                                                                                            <div>
                                                                                                {pedidosImport[item].ci} {'\n'}
                                                                                                {pedidosImport[item].ci === 'Concluído' ?
                                                                                                    validarArquivo(pedidosImport[item].ciArquivos, pedidosImport[item].pedido_sap, 'ci', index)
                                                                                                    : null
                                                                                                }
                                                                                            </div> :
                                                                                            <Autocomplete
                                                                                                classes={{
                                                                                                    paper: classes.paper
                                                                                                }}
                                                                                                disableClearable
                                                                                                className={classes.autoComplete}
                                                                                                renderInput={(params) => (<TextField {...params} inputProps={{ ...params.inputProps, style: { fontSize: 12, fontWeight: 'bold' } }} />)}
                                                                                                getOptionSelected={() => true}
                                                                                                defaultValue={pedidosImport[item].ci}
                                                                                                options={options1}
                                                                                                onChange={(index, valor) => montaUpdate(valor, pedidosImport[item].id, 'ci')}
                                                                                            />}
                                                                                    </div>
                                                                                </td>
                                                                                : <></>}

                                                                            {permissoesColunas.afrmmTotalReal === 1 ?
                                                                                <td className={classes.tdBorder}>
                                                                                    <div className={classes.cellContent}> {/* CAMPO AUTOMATICO */}
                                                                                        {pedidosImport[item].afrmmTotalReal.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                                                                    </div>
                                                                                </td>
                                                                                : <></>}

                                                                            {permissoesColunas.afrmmTotalDolar === 1 ?
                                                                                <td className={classes.tdBorder}>
                                                                                    <div className={classes.cellContent}> {/* CAMPO AUTOMATICO */}
                                                                                        {pedidosImport[item].afrmmTotalDolar.toLocaleString('en-us', { style: 'currency', currency: 'USD' })}
                                                                                    </div>
                                                                                </td>
                                                                                : <></>}

                                                                            {permissoesColunas.situacaoAfrmm === 1 ?
                                                                                <td className={pedidosImport[item].situacaoAfrmm === 'Paga' ? classes.green : classes.red}>
                                                                                    <div className={classes.cellContent}>
                                                                                        {pedidoSendoEditado !== pedidosImport[item].id ?
                                                                                            pedidosImport[item].situacaoAfrmm :
                                                                                            <Autocomplete
                                                                                                classes={{
                                                                                                    paper: classes.paper
                                                                                                }}
                                                                                                className={classes.autoComplete}
                                                                                                disableClearable
                                                                                                getOptionSelected={() => true}
                                                                                                defaultValue={pedidosImport[item].situacaoAfrmm}
                                                                                                options={options2}
                                                                                                onChange={(index, valor) => montaUpdate(valor, pedidosImport[item].id, 'situacaoAfrmm')}
                                                                                                renderInput={(params) => (<TextField {...params} inputProps={{ ...params.inputProps, style: { fontSize: 12, fontWeight: 'bold' } }} />)}
                                                                                            />}
                                                                                    </div>
                                                                                </td>
                                                                                : <></>}


                                                                        </tr> : ''
                                                                )
                                                            })}
                                                        </tbody>
                                                    </table>
                                                </div>
                                                : tab === 4 ? // DESPESA PORTUÁRIA
                                                    <div key={4} className={classes.divTabela}>
                                                        <table>
                                                            <thead className={classes.header}>
                                                                <tr>
                                                                    <th style={{ position: 'sticky', left: 0, background: '#3c1854', fontSize: 14, minWidth: 94 }}>SAP</th>
                                                                    <th style={{ position: 'sticky', left: 94, background: '#3c1854', fontSize: 14, minWidth: 94 }}>Exportador</th>
                                                                    <th style={{ position: 'sticky', left: 188, background: '#3c1854', fontSize: 14, minWidth: 94 }}>Produto</th>
                                                                    <th style={{ position: 'sticky', left: 282, background: '#3c1854', fontSize: 14, minWidth: 94 }}>Navio</th>
                                                                    <th style={{ position: 'sticky', left: 376, background: '#3c1854', fontSize: 14, minWidth: 94 }}>Filial</th>

                                                                    <th className={classes.thDespesa}>Quantidade</th>
                                                                    <th className={classes.thDespesa}>Valor Unidade</th>
                                                                    <th className={classes.thDespesa}>Seguro</th>
                                                                    <th className={classes.thDespesa}>Taxa Marinha Mercante</th>

                                                                    <th className={classes.thDespesa}>Câmbio (Siscomex, Despachante, BL)</th>
                                                                    <th className={classes.thDespesa}>Taxa Siscomex (R$)</th>
                                                                    <th className={classes.thDespesa}>Taxa Siscomex (U$)</th>
                                                                    <th className={classes.thDespesa}>Despachante (R$)</th>
                                                                    <th className={classes.thDespesa}>Despachante (U$)</th>
                                                                    <th className={classes.thDespesa}>Taxa BL (R$)</th>
                                                                    <th className={classes.thDespesa}>Taxa BL (U$)</th>
                                                                    <th className={classes.thDespesa}>Câmbio Saldo AFRMM</th>
                                                                    <th className={classes.thDespesa}>Saldo AFRMM</th>
                                                                    <th className={classes.thDespesa}>Câmbio ICMS</th>
                                                                    <th className={classes.thDespesa}>ICMS (R$)</th>

                                                                    <th className={classes.thDespesa}>Outros Custos</th>
                                                                    <th className={classes.thDespesa}>Total Outros Custos</th>
                                                                    <th className={classes.thDespesa}>Comentário</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {Object.keys(pedidosImport).map((item, index) => {
                                                                    let itemString = JSON.stringify(pedidosImport[item]).toLowerCase()
                                                                    return (
                                                                        itemString.includes(filtro) || filtro === '' ?
                                                                            <tr key={index}>
                                                                                <td style={{ position: 'sticky', left: 0, background: '#e6e6e6', zIndex: 1, border: '1px solid rgba(0,0,0, .4)' }}>
                                                                                    <div className={classes.cellContentFixed} >
                                                                                        {pedidoSendoEditado !== pedidosImport[item].id ?

                                                                                            <div>
                                                                                                <div>
                                                                                                    {pedidosImport[item].pedido_sap}
                                                                                                </div>
                                                                                                {(pedidoSendoEditado === '' || pedidoSendoEditado === pedidosImport[item].id) && permissaoEditar ?
                                                                                                    <div>
                                                                                                        <button onClick={() => setPedidoSendoEditado(pedidosImport[item].id)}>Editar</button>
                                                                                                    </div>
                                                                                                    : null}
                                                                                            </div>

                                                                                            : <div>
                                                                                                <div >
                                                                                                    <div><input type="checkbox" onChange={(e) => arquivar(pedidosImport[item].id, e.target.checked)} />Arquivar</div>
                                                                                                </div>
                                                                                                <div><Autocomplete
                                                                                                    classes={{
                                                                                                        paper: classes.paper
                                                                                                    }}

                                                                                                    disableClearable
                                                                                                    className={classes.autoComplete}
                                                                                                    getOptionSelected={() => true}
                                                                                                    onChange={(index, valor) => montaUpdate(valor, pedidosImport[item].id, 'pedido_sap')}
                                                                                                    defaultValue={pedidosImport[item].pedido_sap}
                                                                                                    options={listaPedidosSap.map((item) => item.numeroSAP.toString())}
                                                                                                    renderInput={(params) => (<TextField {...params} inputProps={{ ...params.inputProps, style: { fontSize: 12, fontWeight: 'bold' } }} />)}
                                                                                                /></div>

                                                                                                {duplicando || excluindo ?
                                                                                                    <Loader
                                                                                                        type="Circles"
                                                                                                        color="#00BFFF"
                                                                                                        height={20}
                                                                                                        width={20}
                                                                                                        timeout={2000000}
                                                                                                    />
                                                                                                    : <><div><button onClick={() => duplicarPedido(pedidosImport[item].id)}>Duplicar</button></div>
                                                                                                        <div><button onClick={() => excluirPedido(pedidosImport[item].id)}>Excluir</button></div>
                                                                                                    </>}
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                </td>

                                                                                <td style={{ position: 'sticky', left: 94, background: '#e6e6e6', zIndex: 1, border: '1px solid rgba(0,0,0, .4)' }}>
                                                                                    <div className={classes.cellContentFixed}>
                                                                                        {pedidoSendoEditado !== pedidosImport[item].id ? pedidosImport[item].exportador :
                                                                                            <Autocomplete
                                                                                                classes={{
                                                                                                    paper: classes.paper
                                                                                                }}
                                                                                                freeSolo
                                                                                                disableClearable
                                                                                                className={classes.autoComplete}
                                                                                                getOptionSelected={() => true}
                                                                                                onChange={(index, valor) => montaUpdate(valor, pedidosImport[item].id, 'exportador')}
                                                                                                defaultValue={pedidosImport[item].exportador}
                                                                                                options={['Ameropa', 'Keytrade', 'Phosagro', 'JSC-BPC', 'SQM', 'DREYMOOR', 'BULKFERT', 'INDAGRO', 'SAFTCO']}
                                                                                                renderInput={(params) => (<TextField {...params} inputProps={{ ...params.inputProps, style: { fontSize: 12, fontWeight: 'bold' } }} />)}
                                                                                            />}
                                                                                    </div>

                                                                                </td>

                                                                                <td style={{ position: 'sticky', left: 188, background: '#e6e6e6', zIndex: 1, border: '1px solid rgba(0,0,0, .4)' }}>
                                                                                    <div className={classes.cellContentFixed}>
                                                                                        {pedidoSendoEditado !== pedidosImport[item].id ?
                                                                                            pedidosImport[item].produto :
                                                                                            <Autocomplete
                                                                                                classes={{
                                                                                                    paper: classes.paper
                                                                                                }}
                                                                                                className={classes.autoComplete}
                                                                                                disableClearable
                                                                                                getOptionSelected={() => true}
                                                                                                onChange={(index, valor) => montaUpdate(valor, pedidosImport[item].id, 'produto')}
                                                                                                defaultValue={pedidosImport[item].produto}
                                                                                                options={Object.keys(listaProdutos).map((item) => (listaProdutos[item].ItemName))}
                                                                                                renderInput={(params) => (<TextField {...params} inputProps={{ ...params.inputProps, style: { fontSize: 12, fontWeight: 'bold' } }} />)}
                                                                                            />}
                                                                                    </div>
                                                                                </td>

                                                                                <td style={{ position: 'sticky', left: 282, background: '#e6e6e6', zIndex: 1, border: '1px solid rgba(0,0,0, .4)' }}>
                                                                                    <div className={classes.cellContentFixed}>
                                                                                        {pedidoSendoEditado !== pedidosImport[item].id ? pedidosImport[item].navio :
                                                                                            <TextField
                                                                                                inputProps={{ style: { fontSize: 12, textAlign: 'center', width: 80, fontWeight: 'bold' } }}
                                                                                                defaultValue={pedidosImport[item].navio}
                                                                                                onChange={(e) => montaUpdate(e.target.value, pedidosImport[item].id, 'navio')}
                                                                                            />}
                                                                                    </div>
                                                                                </td>

                                                                                <td style={{ position: 'sticky', left: 376, background: '#e6e6e6', zIndex: 1, border: '1px solid rgba(0,0,0, .4)' }}>
                                                                                    <div className={classes.cellContentFixed}>
                                                                                        {pedidoSendoEditado !== pedidosImport[item].id ? pedidosImport[item].filial :
                                                                                            <Autocomplete
                                                                                                classes={{
                                                                                                    paper: classes.paper
                                                                                                }}
                                                                                                disableClearable
                                                                                                className={classes.autoComplete}
                                                                                                getOptionSelected={() => true}
                                                                                                defaultValue={pedidosImport[item].filial}
                                                                                                options={Object.keys(listaFiliais).map((item) => (listaFiliais[item].BPLName.split(' ')[0]))}
                                                                                                onChange={(index, valor) => montaUpdate(valor, pedidosImport[item].id, 'filial')}
                                                                                                renderInput={(params) => (<TextField {...params} inputProps={{ ...params.inputProps, style: { fontSize: 12, fontWeight: 'bold' } }} />)}
                                                                                            />}
                                                                                    </div>

                                                                                </td>

                                                                                <td className={classes.tdBorder}>
                                                                                    <div className={classes.cellContent}>
                                                                                        {pedidoSendoEditado !== pedidosImport[item].id ? pedidosImport[item].quantidade :
                                                                                            <TextField
                                                                                                inputProps={{ style: { fontSize: 12, textAlign: 'center', width: 80, fontWeight: 'bold' } }}
                                                                                                onChange={(e) => montaUpdate(e.target.value, pedidosImport[item].id, 'quantidade')}
                                                                                                defaultValue={pedidosImport[item].quantidade.replace(',', '.')}
                                                                                                type='number'
                                                                                            />}
                                                                                    </div>
                                                                                </td>


                                                                                <td className={classes.tdBorder}>
                                                                                    <div className={classes.cellContent}>
                                                                                        {pedidoSendoEditado !== pedidosImport[item].id ? pedidosImport[item].valorUnit.toLocaleString('en-us', { style: 'currency', currency: 'USD' }) :
                                                                                            <TextField
                                                                                                inputProps={{ style: { fontSize: 12, textAlign: 'center', width: 80, fontWeight: 'bold' } }}
                                                                                                defaultValue={pedidosImport[item].valorUnit.replace(',', '.')}
                                                                                                type='number'
                                                                                                onChange={(e) => montaUpdate(e.target.value, pedidosImport[item].id, 'valorUnit')}
                                                                                            />}
                                                                                    </div>
                                                                                </td>


                                                                                <td className={pedidosImport[item].seguro > 0 ? classes.green : classes.red}>
                                                                                    <div className={classes.cellContent}>
                                                                                        {pedidoSendoEditado !== pedidosImport[item].id ? pedidosImport[item].seguro.toLocaleString('en-us', { style: 'currency', currency: 'USD' }) :
                                                                                            <TextField
                                                                                                inputProps={{ style: { fontSize: 12, textAlign: 'center', width: 80, fontWeight: 'bold' } }}
                                                                                                defaultValue={pedidosImport[item].seguro.replace(',', '.')}
                                                                                                type='number'
                                                                                                onChange={(e) => montaUpdate(e.target.value, pedidosImport[item].id, 'seguro')}
                                                                                            />}
                                                                                    </div>
                                                                                </td>


                                                                                <td className={classes.tdBorder}>
                                                                                    <div className={classes.cellContent}> {/* CAMPO AUTOMATICO */}
                                                                                        {pedidosImport[item].afrmmTotalReal.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                                                                    </div>
                                                                                </td>

                                                                                <td className={classes.tdBorder}>
                                                                                    <div className={classes.cellContent}>
                                                                                        {pedidoSendoEditado !== pedidosImport[item].id ? pedidosImport[item].cambioDespachante :
                                                                                            <TextField
                                                                                                inputProps={{ style: { fontSize: 12, textAlign: 'center', width: 80, fontWeight: 'bold' } }}
                                                                                                onChange={(e) => montaUpdate(e.target.value, pedidosImport[item].id, 'cambioDespachante')}
                                                                                                defaultValue={pedidosImport[item].cambioDespachante.replace(',', '.')}
                                                                                                type='number'
                                                                                            />}
                                                                                    </div>
                                                                                </td>

                                                                                <td className={classes.tdBorder}>
                                                                                    <div className={classes.cellContent}>
                                                                                        {pedidoSendoEditado !== pedidosImport[item].id ? pedidosImport[item].taxaSiscomex.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) :
                                                                                            <TextField
                                                                                                inputProps={{ style: { fontSize: 12, textAlign: 'center', width: 80, fontWeight: 'bold' } }}
                                                                                                onChange={(e) => montaUpdate(e.target.value, pedidosImport[item].id, 'taxaSiscomex')}
                                                                                                defaultValue={pedidosImport[item].taxaSiscomex.replace(',', '.')}
                                                                                                type='number'
                                                                                            />}
                                                                                    </div>
                                                                                </td>

                                                                                <td className={classes.tdBorder}>
                                                                                    <div className={classes.cellContent}> {/* CAMPO AUTOMATICO */}
                                                                                        {pedidosImport[item].taxaSiscomexDolar.toLocaleString('en-us', { style: 'currency', currency: 'USD' })}
                                                                                    </div>
                                                                                </td>


                                                                                <td className={classes.tdBorder}>
                                                                                    <div className={classes.cellContent}>
                                                                                        {pedidoSendoEditado !== pedidosImport[item].id ? pedidosImport[item].despachante.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) :
                                                                                            <TextField
                                                                                                inputProps={{ style: { fontSize: 12, textAlign: 'center', width: 80, fontWeight: 'bold' } }}
                                                                                                onChange={(e) => montaUpdate(e.target.value, pedidosImport[item].id, 'despachante')}
                                                                                                defaultValue={pedidosImport[item].despachante.replace(',', '.')}
                                                                                                type='number'
                                                                                            />}
                                                                                    </div>
                                                                                </td>

                                                                                <td className={classes.tdBorder}>
                                                                                    <div className={classes.cellContent}> {/* CAMPO AUTOMATICO */}
                                                                                        {pedidosImport[item].despachanteDolar.toLocaleString('en-us', { style: 'currency', currency: 'USD' })}
                                                                                    </div>
                                                                                </td>

                                                                                <td className={classes.tdBorder}>
                                                                                    <div className={classes.cellContent}>
                                                                                        {pedidoSendoEditado !== pedidosImport[item].id ? pedidosImport[item].taxaBl.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) :
                                                                                            <TextField
                                                                                                inputProps={{ style: { fontSize: 12, textAlign: 'center', width: 80, fontWeight: 'bold' } }}
                                                                                                onChange={(e) => montaUpdate(e.target.value, pedidosImport[item].id, 'taxaBl')}
                                                                                                defaultValue={pedidosImport[item].taxaBl.replace(',', '.')}
                                                                                                type='number'
                                                                                            />}
                                                                                    </div>
                                                                                </td>

                                                                                <td className={classes.tdBorder}>
                                                                                    <div className={classes.cellContent}> {/* CAMPO AUTOMATICO */}
                                                                                        {pedidosImport[item].taxaBlDolar.toLocaleString('en-us', { style: 'currency', currency: 'USD' })}
                                                                                    </div>
                                                                                </td>

                                                                                <td className={classes.tdBorder}>
                                                                                    <div className={classes.cellContent}>
                                                                                        {pedidoSendoEditado !== pedidosImport[item].id ? pedidosImport[item].cambioSaldoAfrmm :
                                                                                            <TextField
                                                                                                inputProps={{ style: { fontSize: 12, textAlign: 'center', width: 80, fontWeight: 'bold' } }}
                                                                                                onChange={(e) => montaUpdate(e.target.value, pedidosImport[item].id, 'cambioSaldoAfrmm')}
                                                                                                defaultValue={pedidosImport[item].cambioSaldoAfrmm.replace(',', '.')}
                                                                                                type='number'
                                                                                            />}
                                                                                    </div>
                                                                                </td>

                                                                                <td className={classes.tdBorder}>
                                                                                    <div className={classes.cellContent}>
                                                                                        {pedidoSendoEditado !== pedidosImport[item].id ? pedidosImport[item].saldoAfrmm.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) :
                                                                                            <TextField
                                                                                                inputProps={{ style: { fontSize: 12, textAlign: 'center', width: 80, fontWeight: 'bold' } }}
                                                                                                onChange={(e) => montaUpdate(e.target.value, pedidosImport[item].id, 'saldoAfrmm')}
                                                                                                defaultValue={pedidosImport[item].saldoAfrmm.replace(',', '.')}
                                                                                                type='number'
                                                                                            />}
                                                                                    </div>
                                                                                </td>

                                                                                <td className={classes.tdBorder}>
                                                                                    <div className={classes.cellContent}>
                                                                                        {pedidoSendoEditado !== pedidosImport[item].id ? pedidosImport[item].cambioIcms :
                                                                                            <TextField
                                                                                                inputProps={{ style: { fontSize: 12, textAlign: 'center', width: 80, fontWeight: 'bold' } }}
                                                                                                onChange={(e) => montaUpdate(e.target.value, pedidosImport[item].id, 'cambioIcms')}
                                                                                                defaultValue={pedidosImport[item].cambioIcms.replace(',', '.')}
                                                                                                type='number'
                                                                                            />}
                                                                                    </div>
                                                                                </td>

                                                                                <td className={classes.tdBorder}>
                                                                                    <div className={classes.cellContent}>
                                                                                        {pedidoSendoEditado !== pedidosImport[item].id ? pedidosImport[item].icmsReal :
                                                                                            <TextField
                                                                                                inputProps={{ style: { fontSize: 12, textAlign: 'center', width: 80, fontWeight: 'bold' } }}
                                                                                                onChange={(e) => montaUpdate(e.target.value, pedidosImport[item].id, 'icmsReal')}
                                                                                                defaultValue={pedidosImport[item].icmsReal.replace(',', '.')}
                                                                                                type='number'
                                                                                            />}
                                                                                    </div>
                                                                                </td>

                                                                                <td className={classes.tdBorder}>

                                                                                    <div className={classes.cellContentLeft}>
                                                                                        {pedidoSendoEditado !== pedidosImport[item].id ?
                                                                                            Object.keys(JSON.parse(pedidosImport[item].outrosCustosJson)).map((el, ind1) => {
                                                                                                return (
                                                                                                    <div key={ind1} style={{ fontSize: 13, border: '1px solid rgba(0, 0, 0, .5)', marginLeft: 5, padding: 5, textAlign: 'center' }}>
                                                                                                        <div>{JSON.parse(pedidosImport[item].outrosCustosJson)[el].nomeCusto}</div>
                                                                                                        <div>{JSON.parse(pedidosImport[item].outrosCustosJson)[el].valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</div>
                                                                                                        <div>{JSON.parse(pedidosImport[item].outrosCustosJson)[el].cambioCusto}</div>
                                                                                                    </div>
                                                                                                )
                                                                                            }) :
                                                                                            <div style={{ display: 'flex', flexDirection: 'row', alignContent: 'center', alignItems: 'center' }}>
                                                                                                <button style={{ height: 25 }} onClick={() => addCustoPortuario(index)}>Add</button>
                                                                                                {Object.keys(JSON.parse(pedidosImport[item].outrosCustosJson)).map((indexCusto) => {
                                                                                                    return (
                                                                                                        <div key={indexCusto} style={{ width: 80, border: '1px solid rgba(0, 0, 0, .5)', margin: 2, padding: 4, flexDirection: 'column', display: escondeCusto[index][indexCusto] ? 'none' : 'block' }}>
                                                                                                            <button
                                                                                                                onClick={() => removerCustoEdicao(index, indexCusto, JSON.parse(pedidosImport[item].outrosCustosJson)[indexCusto].idCusto, pedidosImport[item].id)}
                                                                                                            >Remover</button>

                                                                                                            <TextField
                                                                                                                label="nome"
                                                                                                                onChange={(e) => montaUpdate(e.target.value, pedidosImport[item].id, 'nomeCusto-' + JSON.parse(pedidosImport[item].outrosCustosJson)[indexCusto].idCusto)}
                                                                                                                inputProps={{ style: { fontSize: 12, textAlign: 'center', width: 80, fontWeight: 'bold' } }}
                                                                                                                defaultValue={JSON.parse(pedidosImport[item].outrosCustosJson)[indexCusto].nomeCusto}
                                                                                                            />

                                                                                                            <TextField
                                                                                                                label="valor"
                                                                                                                type='number'
                                                                                                                onChange={(e) => montaUpdate(e.target.value, pedidosImport[item].id, 'valor-' + JSON.parse(pedidosImport[item].outrosCustosJson)[indexCusto].idCusto)}
                                                                                                                inputProps={{ style: { fontSize: 12, textAlign: 'center', width: 80, fontWeight: 'bold' } }}
                                                                                                                defaultValue={JSON.parse(pedidosImport[item].outrosCustosJson)[indexCusto].valor}
                                                                                                            />

                                                                                                            <TextField
                                                                                                                label="câmbio"
                                                                                                                type='number'
                                                                                                                onChange={(e) => montaUpdate(e.target.value, pedidosImport[item].id, 'cambioCusto-' + JSON.parse(pedidosImport[item].outrosCustosJson)[indexCusto].idCusto)}
                                                                                                                inputProps={{ style: { fontSize: 12, textAlign: 'center', width: 80, fontWeight: 'bold' } }}
                                                                                                                defaultValue={JSON.parse(pedidosImport[item].outrosCustosJson)[indexCusto].cambioCusto}
                                                                                                            />
                                                                                                        </div>
                                                                                                    )
                                                                                                })}
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                </td>

                                                                                <td className={classes.tdBorder}>
                                                                                    <div className={classes.cellContentBig}>
                                                                                        {pedidosImport[item].totalOutrosCustos}
                                                                                    </div>
                                                                                </td>

                                                                                <td className={classes.tdBorder}>
                                                                                    <div className={classes.cellContentBig}>
                                                                                        {pedidoSendoEditado !== pedidosImport[item].id ? pedidosImport[item].comentario :
                                                                                            <TextField
                                                                                                multiline
                                                                                                inputProps={{ style: { fontSize: 12, textAlign: 'center', width: 80, fontWeight: 'bold' } }}
                                                                                                onChange={(e) => montaUpdate(e.target.value, pedidosImport[item].id, 'comentario')}
                                                                                                defaultValue={pedidosImport[item].comentario}
                                                                                            />}
                                                                                    </div>
                                                                                </td>
                                                                            </tr> : ''
                                                                    )
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    : null
                            }
                        </div>

                        {
                            pedidoSendoEditado !== '' ?
                                <div style={{ marginLeft: 5, display: 'flex', flexDirection: 'row', alignContent: 'center', alignItems: 'center' }}>

                                    {salvandoEdicao ? <div style={{ margin: 10 }}><Loader
                                        type="Circles"
                                        color="#00BFFF"
                                        height={20}
                                        width={20}
                                        timeout={2000000}
                                    /> </div> :
                                        <div >
                                            <Button style={{ maxHeight: 30 }} onClick={() => handleCancelarEdicao()}>Cancelar</Button>
                                            <Button style={{ maxHeight: 30 }} disabled={salvandoEdicao} onClick={() => salvarEdicao()}>Salvar Edição</Button>
                                        </div>}

                                </div>
                                : null
                        }

                    </div>
                    : null}

                {/* TABELA ARQUIVADOS */}
                <div style={{ height: 600, marginTop: 150 }}>
                    <div style={{ background: 'black', color: 'white', display: 'flex', flexDirection: 'row' }}>
                        {permissoesColunas.arquivados === 1 ? <Button style={{ background: 'black', color: 'white' }} onClick={() => setSubTab(0)}>Arquivados</Button> : <></>}
                        {permissoesColunas.historico === 1 ? <Button style={{ background: 'black', color: 'white' }} onClick={() => setSubTab(1)}>Histórico</Button> : <></>}
                        {permissoesColunas.downloads === 1 ? <Button style={{ background: 'black', color: 'white' }} onClick={() => setSubTab(2)}>Relatórios</Button> : <></>}

                    </div>

                    {
                        subTab === 0 ?
                            <div style={{ background: 'white' }}>
                                {pedidosFormatadosArquivados !== '' && pedidosFormatadosArquivados !== 'NOK' ?
                                    < div key={0} style={{ padding: 10 }}>
                                        {
                                            pedidosFormatados.length > 0 ?
                                                <div >
                                                    <div style={{ margin: 5, display: 'flex', flexDirection: 'row', textAlign: 'center', alignItems: 'center', alignContent: 'center' }}>
                                                        <TextField
                                                            size='small'
                                                            variant='outlined'
                                                            label='Pesquisar'
                                                            onChange={(e) => setFiltroArquivados(e.target.value.toLowerCase())}
                                                        />

                                                        <Button
                                                            disabled={salvandoEdicao}
                                                            style={{ display: paraDesarquivar.length > 0 ? '' : 'none', marginLeft: 10 }}
                                                            onClick={() => salvarEdicao()}
                                                        >Desarquivar Pedidos Selecionados</Button>
                                                    </div>
                                                    <div hidden={!salvandoEdicao}>
                                                        <Loader
                                                            type="Circles"
                                                            color="#00BFFF"
                                                            height={20}
                                                            width={20}
                                                            timeout={2000000}
                                                        />
                                                    </div>

                                                    <div style={{ overflow: 'auto', height: 500 }}>

                                                        <table>
                                                            <thead style={{ position: 'sticky', top: 0, zIndex: 1 }}>
                                                                <tr style={{ background: '#666666', color: 'white' }}>
                                                                    {permissaoEditar ?
                                                                        <th style={{ position: 'sticky', left: 0, background: '#666666', fontSize: 12, padding: 5 }}>
                                                                            DESARQUIVAR
                                                                        </th>
                                                                        : null}

                                                                    {Object.keys(pedidosFormatados[0]).map((item, index) => {
                                                                        return (
                                                                            item !== 'ARQUIVADO' && item !== 'ID' && item !== 'AUX' && item !== 'OUTROS CUSTOS' ?
                                                                                <th key={index} className={classes.thArquivados}>
                                                                                    {item}
                                                                                </th> : null
                                                                        )
                                                                    })}
                                                                </tr>
                                                            </thead>


                                                            <tbody>
                                                                {
                                                                    Object.keys(pedidosFormatadosArquivados).map((item, index) => {
                                                                        let stringzona = JSON.stringify(pedidosFormatadosArquivados[item]).toLowerCase()
                                                                        if (stringzona.includes(filtroArquivados) || filtroArquivados === '') {
                                                                            return (
                                                                                <tr key={index} className={classes.cellContentArquivado}>
                                                                                    {permissaoEditar ?
                                                                                        <td key={index} style={{ position: 'sticky', left: 0, background: '#545454' }}>
                                                                                            <div style={{ textAlign: 'center' }}>
                                                                                                <input type="checkbox" onChange={(e) => desarquivar(pedidosFormatadosArquivados[item]['ID'], e.target.checked)} />
                                                                                            </div>
                                                                                        </td>
                                                                                        : null}

                                                                                    {Object.keys(pedidosFormatadosArquivados[item]).map((key, index) => {
                                                                                        return (
                                                                                            key !== 'ARQUIVADO' && key !== 'ARMAZEM MIC' && key !== 'ID' && key !== 'AUX' && key !== 'OUTROS CUSTOS' ?
                                                                                                <td key={index}>
                                                                                                    <div className={classes.cellContent}>
                                                                                                        {pedidosFormatadosArquivados[item][key]}
                                                                                                    </div>
                                                                                                </td>
                                                                                                : key === 'ARMAZEM MIC' ?
                                                                                                    <td key={index}>
                                                                                                        <div style={{ width: 500, fontWeight: 'bold', padding: 5 }}>
                                                                                                            {pedidosFormatadosArquivados[item][key]}
                                                                                                        </div>
                                                                                                    </td> : null)
                                                                                    })}
                                                                                </tr>
                                                                            )
                                                                        }

                                                                    })
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                : null
                                        }


                                    </div>
                                    : null}
                            </div>
                            : subTab === 1 ?
                                <div key={1} style={{ background: 'white' }}>
                                    <div>
                                        <div style={{ overflow: 'auto', height: 500, fontWeight: 'bold', border: '5px solid rgba(0, 0, 0, .6)' }}>

                                            <table style={{ background: 'white', width: '100%', padding: 20 }}>
                                                <thead>
                                                    <tr>
                                                        <th className={classes.thHistorico}>Data</th>
                                                        <th className={classes.thHistorico}>Usuário</th>
                                                        <th className={classes.thHistorico}>Pedido Sap</th>
                                                        <th className={classes.thHistorico}>Alteração (Valor anterior =&gt; Novo valor)</th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    {Object.keys(historicoEdicao).map((item, index) => {
                                                        return (
                                                            <tr key={index} style={{ background: index % 2 === 0 ? '#ededed' : 'white' }}>
                                                                <td style={{ padding: 5 }}>{historicoEdicao[item].data}</td>
                                                                <td style={{ padding: 5 }}>{historicoEdicao[item].usuario}</td>
                                                                <td style={{ padding: 5 }}>{historicoEdicao[item].pedido}</td>
                                                                <td style={{ padding: 5 }}>{historicoEdicao[item].alteracao}</td>
                                                            </tr>
                                                        )
                                                    })}

                                                </tbody>
                                            </table>

                                        </div>
                                    </div>
                                </div>

                                : subTab === 2 ?
                                    <div key={2} style={{ display: 'flex', flexDirection: 'column', background: 'white', padding: 15 }}>

                                        <div style={{ marginBottom: 10, display: 'flex', flexDirection: 'row' }}>
                                            {pedidosFormatadosAtivos === '' ? <div style={{ margin: 10 }}>
                                                <Loader
                                                    type="Circles"
                                                    color="#00BFFF"
                                                    height={20}
                                                    width={20}
                                                    timeout={2000000}
                                                />
                                            </div> : <div className={classes.divRelatorio}>
                                                <div>Tabela de Ativos</div>
                                                <button onClick={() => baixarTabelaAtivos()}>Baixar</button>
                                            </div>}

                                            {pedidosFormatadosArquivados === '' ? <div style={{ margin: 10 }}>
                                                <Loader
                                                    type="Circles"
                                                    color="#00BFFF"
                                                    height={20}
                                                    width={20}
                                                    timeout={2000000}
                                                />
                                            </div> : <div className={classes.divRelatorio}>
                                                <div>Tabela de Arquivados</div>
                                                <button onClick={() => baixarTabelaArquivados()}>Baixar</button>
                                            </div>}

                                            {pedidosFormatados === '' ? <div style={{ margin: 10 }}>
                                                <Loader
                                                    type="Circles"
                                                    color="#00BFFF"
                                                    height={20}
                                                    width={20}
                                                    timeout={2000000}
                                                />
                                            </div> : <div className={classes.divRelatorio}>
                                                <div>Tabela Completa (Ativos/Arquivados)</div>
                                                <button onClick={() => baixarTabela()}>Baixar</button>
                                            </div>}
                                        </div>

                                        <div style={{ marginBottom: 10, display: 'flex', flexDirection: 'row' }}>
                                            {pedidosFormatadosAtivos === '' ? <div style={{ margin: 10 }}>
                                                <Loader
                                                    type="Circles"
                                                    color="#00BFFF"
                                                    height={20}
                                                    width={20}
                                                    timeout={2000000}
                                                />
                                            </div> :
                                                <div className={classes.divRelatorio}>
                                                    <div>Tabela Financeiro</div>
                                                    <button onClick={() => baixarRelFinanceiro()}>Baixar</button>
                                                </div>}

                                            {pedidosFormatadosAtivos === '' ? <div style={{ margin: 10 }}>
                                                <Loader
                                                    type="Circles"
                                                    color="#00BFFF"
                                                    height={20}
                                                    width={20}
                                                    timeout={2000000}
                                                />
                                            </div> :
                                                <div className={classes.divRelatorio}>
                                                    <div>Tabela Lineup</div>
                                                    <button onClick={() => baixarRelLineup()}>Baixar</button>
                                                </div>}

                                            {pedidosFormatadosAtivos === '' ? <div style={{ margin: 10 }}>
                                                <Loader
                                                    type="Circles"
                                                    color="#00BFFF"
                                                    height={20}
                                                    width={20}
                                                    timeout={2000000}
                                                />
                                            </div> :
                                                <div className={classes.divRelatorio}>
                                                    <div>Tabela Desembaraço</div>
                                                    <button onClick={() => baixarRelDesembaraco()}>Baixar</button>
                                                </div>}
                                        </div>

                                        <div style={{ marginBottom: 10, display: 'flex', flexDirection: 'row' }}>
                                            {pedidosFormatadosAtivos === '' ? <div style={{ margin: 10 }}>
                                                <Loader
                                                    type="Circles"
                                                    color="#00BFFF"
                                                    height={20}
                                                    width={20}
                                                    timeout={2000000}
                                                />
                                            </div> :
                                                <div className={classes.divRelatorio}>
                                                    <div>Relatório de Quebras</div>
                                                    <button onClick={() => baixarRelQuebras()}>Baixar</button>
                                                </div>}


                                            {permissoesColunas.despesaPortuaria === 1 ?
                                                <div className={classes.divRelatorio}>
                                                    <div >
                                                        Tabela Despesa Portuária
                                                    </div>
                                                    <div hidden={relatorioComex !== ''}>
                                                        {gerandoRelDespesa === true ? <div style={{ margin: 5 }}><Loader
                                                            type="Circles"
                                                            color="#00BFFF"
                                                            height={20}
                                                            width={20}
                                                            timeout={2000000}
                                                        /></div> : <div style={{ marginBottom: 10 }}><button onClick={() => gerarRelatorioDespesaPortuaria()}>Gerar Relatório</button></div>}

                                                    </div>

                                                    {relatorioComex === '' ? null : <div style={{ marginBottom: 10 }}><button onClick={() => baixarRelatorioDespesa()}>Baixar</button></div>}

                                                </div>
                                                : <></>}

                                            {permissoesColunas.despesaPortuaria === 1 ?
                                                <div className={classes.divRelatorio}>
                                                    <div >
                                                        Relatório Custos
                                                    </div>
                                                    <div hidden={relatorioCustos !== ''}>
                                                        {gerandoRelCustos === true ? <div style={{ margin: 5 }}><Loader
                                                            type="Circles"
                                                            color="#00BFFF"
                                                            height={20}
                                                            width={20}
                                                            timeout={2000000}
                                                        /></div> : <div style={{ marginBottom: 10 }}><button onClick={() => gerarRelatorioCustos()}>Gerar Relatório</button></div>}

                                                    </div>

                                                    {relatorioCustos === '' ? null : <div style={{ marginBottom: 10 }}><button onClick={() => baixarRelatorioCustos()}>Baixar</button></div>}

                                                </div>
                                                : <></>}
                                        </div>
                                    </div>
                                    : null
                    }
                </div>
            </div >
            : null
    )
}

const useStyles = makeStyles({
    inputGroup: {
        display: 'flex',
        flexDirection: 'row',
        margin: 10
    },

    tdBorder: {
        border: '1px solid rgba(0,0,0, .4)'
    },

    paper: {
        width: 300
    },

    divRow: {
        display: 'flex',
        flexDirection: 'row'
    },

    inputDiv: {
        background: 'white',
        padding: 20,
    },

    textField: {
        width: 300,
        margin: 10
    },

    divTabela: {
        height: 600,
    },

    header: {
        color: 'white',
        position: 'sticky',
        zIndex: 2,
        top: 0
    },

    thStyle: {
        padding: 5,
        fontSize: 14,
        background: '#ED3237',
        whiteSpace: 'nowrap',
        fontWeight: 'bold'
    },

    thArquivados: {
        background: '#666666',
        fontSize: 12,
        padding: 5,
        whiteSpace: 'nowrap'
    },

    thFinanceiro: {
        padding: 5,
        fontSize: 14,
        background: '#3d42e0',
        whiteSpace: 'nowrap',
        fontWeight: 'bold'

    },

    thLineup: {
        padding: 5,
        fontSize: 14,
        background: '#36c416',
        whiteSpace: 'nowrap',
        fontWeight: 'bold'

    },

    thDesembaraço: {
        padding: 5,
        fontSize: 14,
        background: '#383838',
        whiteSpace: 'nowrap',
        fontWeight: 'bold'

    },

    thDespesa: {
        padding: 5,
        fontSize: 14,
        background: '#3c1854',
        whiteSpace: 'nowrap',
        fontWeight: 'bold'

    },

    thHistorico: {
        padding: 5,
        fontSize: 14,
        background: '#cccbca'
    },

    cellContent: {
        fontWeight: 'bold',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        fontSize: 14,
        padding: 5
    },

    cellContentBig: {
        minWidth: 100,
        fontWeight: 'bold',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        fontSize: 14,
        padding: 5
    },

    cellContentLeft: {
        fontWeight: 'bold',
        fontSize: 14,
        padding: 5,
        display: 'flex',
        flexDirection: 'row'
    },

    cellContentArquivado: {
        background: 'lightgray',
        fontSize: 12
    },

    cellContentFixed: {
        width: 90,
        fontWeight: 'bold',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        fontSize: 14
    },

    autoComplete: {
        width: 80,
    },

    red: { background: 'rgba(255, 77, 77, .8)' },

    green: { background: 'rgba(255, 214, 99, .8)' },

    dataWidth: {
        width: 113
    },

    divRelatorio: {
        border: '1px solid rgba(0, 0, 0, .4)',
        borderRadius: 4,
        width: 300,
        padding: 10,
        margin: 5,
        maxHeight: 48
    }
})

function mapStateToProps(state) {
    return {
        token: state.token,
        user: state.user,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            setToken: setToken,
            setUser: setUser,
        },
        dispatch
    );
}


export default connect(mapStateToProps, mapDispatchToProps)(WorkflowComex);