import React, { useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { setToken } from "../../flux/actions/tokenIndex";
import { setUser } from "../../flux/actions/userIndex";
import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import api2 from "../../services/api2";
import Button from "components/CustomButtons/Button.js";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import Slider from '@material-ui/core/Slider';
import exportFromJSON from 'export-from-json'
import Loader from "react-loader-spinner";
import { Checkbox } from '@material-ui/core';

import { checarPermissao, validateTokenUser, getUser, getToken } from '../../utils/utils.js'


const useStyles = makeStyles(styles);

function AtualizaFretes(props) {
    const [fretes, setFretes] = useState('');
    const [val, setVal] = useState(0);

    const [vet, setVet] = useState([])
    const [origem, setOrigem] = useState('')
    const [destino, setDestino] = useState('')


    const [U_Jan, setU_Jan] = useState(0)
    const [U_Fev, setU_Fev] = useState(0)
    const [U_Mar, setU_Mar] = useState(0)
    const [U_Abr, setU_Abr] = useState(0)
    const [U_Mai, setU_Mai] = useState(0)
    const [U_Jun, setU_Jun] = useState(0)
    const [U_Jul, setU_Jul] = useState(0)
    const [U_Ago, setU_Ago] = useState(0)
    const [U_Set, seU_Set] = useState(0)
    const [U_Out, seU_Out] = useState(0)
    const [U_Nov, setU_Nov] = useState(0)
    const [U_Dez, setU_Dez] = useState(0)

    const [C_Jan, setC_Jan] = useState(true)
    const [C_Fev, setC_Fev] = useState(true)
    const [C_Mar, setC_Mar] = useState(true)
    const [C_Abr, setC_Abr] = useState(true)
    const [C_Mai, setC_Mai] = useState(true)
    const [C_Jun, setC_Jun] = useState(true)
    const [C_Jul, setC_Jul] = useState(true)
    const [C_Ago, setC_Ago] = useState(true)
    const [C_Set, setC_Set] = useState(true)
    const [C_Out, setC_Out] = useState(true)
    const [C_Nov, setC_Nov] = useState(true)
    const [C_Dez, setC_Dez] = useState(true)

    const [origems, setOrigems] = useState('')
    const [destinos, setDestinos] = useState('')
    const [origemF, setOrigemF] = useState('')
    const [destinoF, setDestinoF] = useState('')
    const [destinosInsert, setDestinosInsert] = useState('')
    const [valFiltro, setValFiltro] = useState(0)
    const [value, setValue] = useState([0, 100]);
    const [fretesAux, setFretesAux] = useState('')
    const [max, setMax] = useState(0)
    const [wait, setWait] = useState(false)
    const classes = useStyles();
    const [permitido, setPermitido] = useState(false)
    const [statusUpdate, setStatusUpdate] = useState('')
    useEffect(() => {
        async function apicall() {
            try {
                await validateTokenUser(props)
                setPermitido(await checarPermissao('tabelaFretes', props))

                const respose_dest = await api2.get("/returnOr", { params: { userId: getUser(), token: getToken() } })
                setOrigems(respose_dest.data)

                const respose_dest2 = await api2.get("/returnDes", { params: { userId: getUser(), token: getToken() } })
                setDestinos(respose_dest2.data)

                const response_fretes = await api2.get("/returnFretes", { params: { origens: 'no', destinos: 'no', userId: getUser(), token: getToken(), 'app': 1 } });
                setFretes(response_fretes.data)
                setFretesAux(response_fretes.data)

                const maxDay = response_fretes.data.length > 0 && response_fretes.data[0].maxDay ? response_fretes.data[0].maxDay : 0;
                setMax(maxDay)
                setValue([0, maxDay])

                const respose_destinos_insert = await api2.get("/returnDestinosInsert", { params: { userId: getUser(), token: getToken() } })
                setDestinosInsert(respose_destinos_insert.data)

                const response_status_atualizacao = await api2.get("/statusAtualizacaoFretes", { params: { userId: getUser(), token: getToken() } })
                setStatusUpdate(response_status_atualizacao.data)
                console.log(response_status_atualizacao.data)
            } catch (error) {
                console.error("Error during API call:", error)
                // Handle the error accordingly, e.g., show a notification to the user
            }
        }
        apicall()
    }, []);
    const fretesFF = () => {
        const data = fretes
        const fileName = 'Fretes'
        const exportType = exportFromJSON.types.xls

        exportFromJSON({ data, fileName, exportType })
    }
    async function ModificaFretesMassa() {
        let auxO = ''
        Object.keys(origemF).forEach(
            (item) => (auxO = auxO + item + '#' + origemF[item] + ';')
        )
        let auxD = ''
        Object.keys(destinoF).forEach(
            (item) => (auxD = auxD + item + '#' + destinoF[item] + ';')
        )
        if (auxO === '') { auxO = 'no' }
        if (auxD === '') { auxD = 'no' }
        alert('Atualizando fretes!')
        const response_fretes = await api2.get("/updateAll", {
            params: {
                val, origens: auxO, destinos: auxD, C_Jan,
                C_Fev,
                C_Mar,
                C_Abr,
                C_Mai,
                C_Jun,
                C_Jul,
                C_Ago,
                C_Set,
                C_Out,
                C_Nov,
                C_Dez,
                userId: getUser(),
                token: getToken()
            }
        });

        if (response_fretes.data === 'NOK') {
            alert('Falha ao atualizar fretes')
        } else {
            alert('Fretes atualizados com sucesso')
            const response_fretes = await api2.get("/returnFretes", { params: { origens: auxO, destinos: auxD, userId: getUser(), token: getToken(), 'app': 1 } });
            setFretes(response_fretes.data)
        }
    }

    function montaUpdate(val, coluna, numero, or, des) {
        let aux = vet

        aux[numero + '+' + coluna + '+' + or + '+' + des] = val
        setVet(aux)


    }

    async function atualizaPdfFretes() {
        const response_PDF = await api2.get("/genPdfFretes", { params: { userId: getUser(), token: getToken() } });
        if (response_PDF.data === 'NOK') {
            alert('Falha ao atualizar PDF')
        } else {
            alert('PDF atualizado com sucesso')
        }
    }

    async function AtualizaPontual() {
        setWait(true)
        alert('Atualizando fretes!')
        let aux = ''
        Object.keys(vet).forEach(
            (item) => (aux = aux + item + '#' + vet[item] + ';')
        )

        const response_fretes = await api2.get("/atualizaPontual", { params: { vet: aux, userId: getUser(), token: getToken() } })
        if (response_fretes.data === 'ok') {
            alert('Fretes atualizados com sucesso')
            window.location.reload();
        } else if (response_fretes.data === 'NOK') {
            alert('Erro ao atualizar fretes')
        }
        setWait(false)

    }
    async function ModificaFretesFiltro() {
        setWait(true)
        alert('Atualizando fretes utilizando filtros origens->' + origemF + ' e destinos -> ' + destinoF)
        const response_fretes = await api2.get("/updateAllFiltro", {
            params: {
                valFiltro, origens: origemF !== '' ? origemF.join() : '', destinos: destinoF !== '' ? destinoF.join() : '',
                C_Jan,
                C_Fev,
                C_Mar,
                C_Abr,
                C_Mai,
                C_Jun,
                C_Jul,
                C_Ago,
                C_Set,
                C_Out,
                C_Nov,
                C_Dez,
                userId: getUser(),
                token: getToken()
            }
        });
        if (response_fretes.data === 'OK') {
            alert('Fretes atualizados com sucesso')
            window.location.reload();
        } else if (response_fretes.data === 'NOK') {
            alert('Erro ao atualizar fretes')
        }
        setWait(false)
    }


    async function AdicionaFrete() {
        setWait(true)
        const response_fretes = await api2.get("/AdicionaFrete", {
            params: {

                U_Origem: origem,
                U_Destino: destino,

                U_Jan,
                U_Fev,
                U_Mar,
                U_Abr,
                U_Mai,
                U_Jun,
                U_Jul,
                U_Ago,
                U_Set,
                U_Out,
                U_Nov,
                U_Dez,
                userId: getUser(),
                token: getToken()
            }
        });

        if (response_fretes.data === 'NOK') {
            alert('Falha ao atualizar fretes')
        } else {
            alert('Fretes adicionados com sucesso')
            window.location.reload();
        }
        setWait(false)
    }
    async function PesquisaRotasFiltro() {
        setWait(true)
        let auxO = ''
        Object.keys(origemF).forEach(
            (item) => (auxO = auxO + item + '#' + origemF[item] + ';')
        )
        let auxD = ''
        Object.keys(destinoF).forEach(
            (item) => (auxD = auxD + item + '#' + destinoF[item] + ';')
        )
        if (auxO === '') { auxO = 'no' }
        if (auxD === '') { auxD = 'no' }
        const response_fretes = await api2.get("/returnFretes", { params: { origens: auxO, destinos: auxD, userId: getUser(), token: getToken(), 'app': 1 } });
        setFretes(response_fretes.data)
        setWait(false)
    }

    async function limpaFiltro() {
        setFretes(fretesAux)
        setOrigemF('')
        setDestinoF('')
        setValue([0, max])
    }

    async function deletaRota(or, ds) {
        const response_delete = await api2.get("/deletaRota", { params: { origem: or, destino: ds, userId: getUser(), token: getToken() } });

        if (response_delete.data === 'NOK') {
            alert('Falha ao remover frete')
        } else {
            alert('Fretes removido com sucesso')
            window.location.reload();
        }
    }


    const handleChange = (event, newValue) => {
        setValue(newValue);
        setFretes(fretes.filter(function (e) {
            return e.daysUpdate >= newValue[0] &&
                e.daysUpdate <= newValue[1]

        }))
    };
    const handleChangeOrigin = (newValue) => {
        setOrigemF(newValue)
        setFretes(fretes.filter(function (e) {
            return newValue.includes(e.U_Origem)
        }))
    };

    const handleChangeDestino = (newValue) => {
        setDestinoF(newValue)

        setFretes(fretes.filter(function (e) {
            return newValue.includes(e.U_Destino)
        }))
    };
    async function atualizaStatus() {
        const response_status_atualizacao = await api2.get("/statusAtualizacaoFretes", { params: { userId: getUser(), token: getToken() } })
        setStatusUpdate(response_status_atualizacao.data)
    }
    return (
        permitido === true ?
            <div>
                <div>
                    {statusUpdate !== '' ? <p>Status : {statusUpdate[0].status == '0'
                        ? 'Nenhuma operação sendo realizada'
                        : 'Atualizando fretes ' + statusUpdate[0].atual + '/' + statusUpdate[0].quantidade}</p>
                        : <Loader
                            type="Circles"
                            color="#00BFFF"
                            height={20}
                            width={20}
                            timeout={20000} //3 secs
                        />}
                    <Button style={{ backgroundColor: '#6699ff' }} variant="contained" onClick={() => atualizaStatus()}>Atualizar status</Button>

                </div>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={3}>
                        {origems !== '' ? <Autocomplete
                            multiple
                            id="combo-box-demo"
                            options={origems}
                            getOptionLabel={(option) => option}
                            style={{ width: 200, marginTop: 36 }}
                            onChange={(e, v) => handleChangeOrigin(v)}
                            value={origemF === '' ? [] : origemF}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Filtro Origem"
                                />
                            )}
                        /> : <Loader
                            type="Circles"
                            color="#00BFFF"
                            height={20}
                            width={20}
                            timeout={20000} //3 secs
                        />}

                    </GridItem>
                    <GridItem xs={12} sm={12} md={3}>
                        {destinos !== '' ? <Autocomplete
                            multiple
                            id="combo-box-demo"
                            options={destinos}
                            getOptionLabel={(option) => option}
                            style={{ width: 200, marginTop: 36 }}
                            onChange={(e, v) => handleChangeDestino(v)}
                            value={destinoF === '' ? [] : destinoF}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Filtro Destino"
                                />
                            )}
                        /> : <Loader
                            type="Circles"
                            color="#00BFFF"
                            height={20}
                            width={20}
                            timeout={20000} //3 secs
                        />}

                    </GridItem>
                    <GridItem xs={12} sm={12} md={3}>
                        {value[1] !== 100 ? <Slider
                            style={{ width: 200, marginTop: 36 }}
                            value={value}
                            onChange={handleChange}
                            aria-labelledby="range-slider"
                            valueLabelDisplay="on"
                            max={max}
                            step={1}
                        /> : <Loader
                            type="Circles"
                            color="#00BFFF"
                            height={20}
                            width={20}
                            timeout={20000} //3 secs
                        />}
                    </GridItem>
                    <GridItem>
                        <Button style={{ backgroundColor: '#6699ff' }} variant="contained" onClick={async () => await limpaFiltro()}>Limpar Filtros</Button>
                    </GridItem>

                </GridContainer>
                <GridContainer >
                    <GridItem xs={12} sm={12} md={12}>
                        <div style={{ marginTop: 20, overflow: 'scroll', height: 700, borderStyle: 'solid' }} >
                            {fretes !== '' ? <table id="table-to-xls" >
                                <thead>
                                    <tr style={{ textAlign: "center", border: "1px solid " }}>
                                        <th style={{ textAlign: "center", border: "1px solid " }}>

                                            Origem
                                        </th>
                                        <th style={{ textAlign: "center", border: "1px solid " }}>
                                            Destino
                                        </th>
                                        <th style={{ textAlign: "center", border: "1px solid " }}>
                                            SubRegiao (Raio - KM)
                                        </th>
                                        <th style={{ textAlign: "center", border: "1px solid " }}>
                                            <Checkbox
                                                checked={C_Jan}
                                                onChange={() => setC_Jan(!C_Jan)}
                                                name="checkJan"
                                                color="primary"
                                            />
                                            Janeiro
                                        </th>
                                        <th style={{ textAlign: "center", border: "1px solid " }}>
                                            <Checkbox
                                                checked={C_Fev}
                                                onChange={() => setC_Fev(!C_Fev)}
                                                name="checkFev"
                                                color="primary"
                                            />
                                            Feveiro
                                        </th>
                                        <th style={{ textAlign: "center", border: "1px solid " }}>
                                            <Checkbox
                                                checked={C_Mar}
                                                onChange={() => setC_Mar(!C_Mar)}
                                                name="checkMar"
                                                color="primary"
                                            />
                                            Março
                                        </th>
                                        <th style={{ textAlign: "center", border: "1px solid " }}>
                                            <Checkbox
                                                checked={C_Abr}
                                                onChange={() => setC_Abr(!C_Abr)}
                                                name="checkAbr"
                                                color="primary"
                                            />
                                            Abril
                                        </th>
                                        <th style={{ textAlign: "center", border: "1px solid " }}>
                                            <Checkbox
                                                checked={C_Mai}

                                                onChange={() => setC_Mai(!C_Mai)}
                                                name="checkMai"
                                                color="primary"
                                            />
                                            Maio
                                        </th>
                                        <th style={{ textAlign: "center", border: "1px solid " }}>
                                            <Checkbox
                                                checked={C_Jun}

                                                onChange={() => setC_Jun(!C_Jun)}
                                                name="checkJun"
                                                color="primary"
                                            />
                                            Junho
                                        </th>
                                        <th style={{ textAlign: "center", border: "1px solid " }}>
                                            <Checkbox
                                                checked={C_Jul}

                                                onChange={() => setC_Jul(!C_Jul)}
                                                name="checkJul"
                                                color="primary"
                                            />
                                            Julho
                                        </th>
                                        <th style={{ textAlign: "center", border: "1px solid " }}>
                                            <Checkbox
                                                checked={C_Ago}

                                                onChange={() => setC_Ago(!C_Ago)}
                                                name="checkAgo"
                                                color="primary"
                                            />
                                            Agosto
                                        </th>
                                        <th style={{ textAlign: "center", border: "1px solid " }}>
                                            <Checkbox
                                                checked={C_Set}

                                                onChange={() => setC_Set(!C_Set)}
                                                name="checkSet"
                                                color="primary"
                                            />
                                            Setembro
                                        </th>
                                        <th style={{ textAlign: "center", border: "1px solid " }}>
                                            <Checkbox
                                                checked={C_Out}

                                                onChange={() => setC_Out(!C_Out)}
                                                name="checkOut"
                                                color="primary"
                                            />
                                            Outubro
                                        </th>
                                        <th style={{ textAlign: "center", border: "1px solid " }}>
                                            <Checkbox
                                                checked={C_Nov}

                                                onChange={() => setC_Nov(!C_Nov)}
                                                name="checkNov"
                                                color="primary"
                                            />
                                            Novembro
                                        </th>
                                        <th style={{ textAlign: "center", border: "1px solid " }}>
                                            <Checkbox
                                                checked={C_Dez}

                                                onChange={() => setC_Dez(!C_Dez)}
                                                name="checkDez"
                                                color="primary"
                                            />
                                            Dezembro
                                        </th>
                                        <th style={{ textAlign: "center", border: "1px solid " }}>
                                            Ultima Atualização
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {fretes !== '' ?
                                        Object.keys(fretes.slice(0, 50)).map((item, index) => (

                                            <tr key={index}>
                                                <td style={{ width: 300, textAlign: "center", border: "1px solid " }}>

                                                    {fretes[item].U_Origem + '-' + fretes[item].U_UF_Origem}

                                                </td>
                                                <td style={{ width: 300, textAlign: "center", border: "1px solid " }}>
                                                    {fretes[item].U_Destino + '-' + fretes[item].U_UF_Destino}
                                                </td>
                                                <td style={{ width: 300, textAlign: "center", border: "1px solid " }}>
                                                    {fretes[item].U_SubRegiao}
                                                </td>
                                                <td style={{ width: 300, textAlign: "center", border: "1px solid " }}>
                                                    {fretes[item].U_SubRegiao === 25 ?
                                                        <TextField type='number' label={fretes[item].U_Jan} onChange={(e) => montaUpdate(e.target.value, 'U_Jan', fretes[item].cod, fretes[item].U_Origem + '-' + fretes[item].U_UF_Origem, fretes[item].U_Destino + '-' + fretes[item].U_UF_Destino)} />
                                                        : <p>{fretes[item].U_Jan} </p>}

                                                </td>
                                                <td style={{ width: 300, textAlign: "center", border: "1px solid " }}>
                                                    {fretes[item].U_SubRegiao === 25 ?
                                                        <TextField type='number' label={fretes[item].U_Fev} onChange={(e) => montaUpdate(e.target.value, 'U_Fev', fretes[item].cod, fretes[item].U_Origem + '-' + fretes[item].U_UF_Origem, fretes[item].U_Destino + '-' + fretes[item].U_UF_Destino)} />
                                                        : <p>{fretes[item].U_Fev} </p>}

                                                </td>
                                                <td style={{ width: 300, textAlign: "center", border: "1px solid " }}>
                                                    {fretes[item].U_SubRegiao === 25 ?
                                                        <TextField type='number' label={fretes[item].U_Mar} onChange={(e) => montaUpdate(e.target.value, 'U_Mar', fretes[item].cod, fretes[item].U_Origem + '-' + fretes[item].U_UF_Origem, fretes[item].U_Destino + '-' + fretes[item].U_UF_Destino)} />
                                                        : <p>{fretes[item].U_Mar} </p>}

                                                </td>
                                                <td style={{ width: 300, textAlign: "center", border: "1px solid " }}>
                                                    {fretes[item].U_SubRegiao === 25 ?
                                                        <TextField type='number' label={fretes[item].U_Abr} onChange={(e) => montaUpdate(e.target.value, 'U_Abr', fretes[item].cod, fretes[item].U_Origem + '-' + fretes[item].U_UF_Origem, fretes[item].U_Destino + '-' + fretes[item].U_UF_Destino)} />
                                                        : <p>{fretes[item].U_Abr} </p>}

                                                </td>
                                                <td style={{ width: 300, textAlign: "center", border: "1px solid " }}>
                                                    {fretes[item].U_SubRegiao === 25 ?
                                                        <TextField type='number' label={fretes[item].U_Mai} onChange={(e) => montaUpdate(e.target.value, 'U_Mai', fretes[item].cod, fretes[item].U_Origem + '-' + fretes[item].U_UF_Origem, fretes[item].U_Destino + '-' + fretes[item].U_UF_Destino)} />
                                                        : <p>{fretes[item].U_Mai} </p>}

                                                </td>
                                                <td style={{ width: 300, textAlign: "center", border: "1px solid " }}>
                                                    {fretes[item].U_SubRegiao === 25 ?
                                                        <TextField type='number' label={fretes[item].U_Jun} onChange={(e) => montaUpdate(e.target.value, 'U_Jun', fretes[item].cod, fretes[item].U_Origem + '-' + fretes[item].U_UF_Origem, fretes[item].U_Destino + '-' + fretes[item].U_UF_Destino)} />
                                                        : <p>{fretes[item].U_Jun} </p>}

                                                </td>
                                                <td style={{ width: 300, textAlign: "center", border: "1px solid " }}>
                                                    {fretes[item].U_SubRegiao === 25 ?
                                                        <TextField type='number' label={fretes[item].U_Jul} onChange={(e) => montaUpdate(e.target.value, 'U_Jul', fretes[item].cod, fretes[item].U_Origem + '-' + fretes[item].U_UF_Origem, fretes[item].U_Destino + '-' + fretes[item].U_UF_Destino)} />
                                                        : <p>{fretes[item].U_Jul} </p>}

                                                </td>
                                                <td style={{ width: 300, textAlign: "center", border: "1px solid " }}>
                                                    {fretes[item].U_SubRegiao === 25 ?
                                                        <TextField type='number' label={fretes[item].U_Ago} onChange={(e) => montaUpdate(e.target.value, 'U_Ago', fretes[item].cod, fretes[item].U_Origem + '-' + fretes[item].U_UF_Origem, fretes[item].U_Destino + '-' + fretes[item].U_UF_Destino)} />
                                                        : <p>{fretes[item].U_Ago} </p>}

                                                </td>
                                                <td style={{ width: 300, textAlign: "center", border: "1px solid " }}>
                                                    {fretes[item].U_SubRegiao === 25 ?
                                                        <TextField type='number' label={fretes[item].U_Set} onChange={(e) => montaUpdate(e.target.value, 'U_Set', fretes[item].cod, fretes[item].U_Origem + '-' + fretes[item].U_UF_Origem, fretes[item].U_Destino + '-' + fretes[item].U_UF_Destino)} />
                                                        : <p>{fretes[item].U_Set} </p>}

                                                </td>
                                                <td style={{ width: 300, textAlign: "center", border: "1px solid " }}>
                                                    {fretes[item].U_SubRegiao === 25 ?
                                                        <TextField type='number' label={fretes[item].U_Out} onChange={(e) => montaUpdate(e.target.value, 'U_Out', fretes[item].cod, fretes[item].U_Origem + '-' + fretes[item].U_UF_Origem, fretes[item].U_Destino + '-' + fretes[item].U_UF_Destino)} />
                                                        : <p>{fretes[item].U_Out} </p>}

                                                </td>
                                                <td style={{ width: 300, textAlign: "center", border: "1px solid " }}>
                                                    {fretes[item].U_SubRegiao === 25 ?
                                                        <TextField type='number' label={fretes[item].U_Nov} onChange={(e) => montaUpdate(e.target.value, 'U_Nov', fretes[item].cod, fretes[item].U_Origem + '-' + fretes[item].U_UF_Origem, fretes[item].U_Destino + '-' + fretes[item].U_UF_Destino)} />
                                                        : <p>{fretes[item].U_Nov} </p>}

                                                </td>
                                                <td style={{ width: 300, textAlign: "center", border: "1px solid " }}>
                                                    {fretes[item].U_SubRegiao === 25 ?
                                                        <TextField type='number' label={fretes[item].U_Dez} onChange={(e) => montaUpdate(e.target.value, 'U_Dez', fretes[item].cod, fretes[item].U_Origem + '-' + fretes[item].U_UF_Origem, fretes[item].U_Destino + '-' + fretes[item].U_UF_Destino)} />
                                                        : <p>{fretes[item].U_Dez} </p>}

                                                </td>



                                                <td style={{ width: 300, textAlign: "center", border: "1px solid " }}>
                                                    {fretes[item].daysUpdate}

                                                </td>
                                                <td style={{ width: 300, textAlign: "center", border: "1px solid " }}>
                                                    {fretes[item].U_SubRegiao === 25 ?
                                                        <Button style={{ backgroundColor: '#6699ff' }} variant="contained" onClick={() => deletaRota(fretes[item].U_Origem, fretes[item].U_Destino)}>Remover Rota</Button>
                                                        : <p> </p>}

                                                </td>
                                            </tr>

                                        )) : <div></div>}
                                </tbody>
                            </table> : <Loader
                                type="Circles"
                                color="#00BFFF"
                                height={20}
                                width={20}
                                timeout={20000} //3 secs
                            />}

                        </div>

                        <GridItem>
                            <Button disabled={wait} style={{ backgroundColor: '#6699ff' }} variant="contained" onClick={() => AtualizaPontual()}>Atualizar</Button>
                        </GridItem>
                    </GridItem>
                    <GridItem>
                        <TextField type="number" style={{ width: 250, marginTop: 20 }} onChange={(e) => setVal(e.target.value)} />
                    </GridItem>
                    <GridItem>
                        <Button disabled={wait} style={{ backgroundColor: '#6699ff' }} variant="contained" onClick={() => ModificaFretesMassa()}>Atualizar Toda a Tabela</Button>
                    </GridItem>

                </GridContainer>
                <GridContainer>
                    <GridItem>
                        <TextField type="number" style={{ width: 250, marginTop: 20 }} onChange={(e) => setValFiltro(e.target.value)} />
                    </GridItem>
                    <GridItem>
                        <Button disabled={wait} style={{ backgroundColor: '#6699ff' }} variant="contained" onClick={() => ModificaFretesFiltro()}>Atualizar com base nos filtros</Button>
                        <p>Filtro Origens = {origemF === '' ? '' : origemF.join(',')}</p>
                        <p>Filtro Destinos = {destinoF === '' ? '' : destinoF.join(',')}</p>
                    </GridItem>
                </GridContainer>
                <GridContainer>
                    <div style={{ marginTop: 30 }} >
                        <table >
                            <thead>
                                <tr style={{ textAlign: "center", border: "1px solid " }}>
                                    <th style={{ textAlign: "center", border: "1px solid " }}>
                                        Origem
                                    </th>
                                    <th style={{ textAlign: "center", border: "1px solid " }}>
                                        Destino
                                    </th>

                                    <th style={{ textAlign: "center", border: "1px solid " }}>
                                        Janeiro
                                    </th>
                                    <th style={{ textAlign: "center", border: "1px solid " }}>
                                        Feveiro
                                    </th>
                                    <th style={{ textAlign: "center", border: "1px solid " }}>
                                        Março
                                    </th>
                                    <th style={{ textAlign: "center", border: "1px solid " }}>
                                        Abril
                                    </th>
                                    <th style={{ textAlign: "center", border: "1px solid " }}>
                                        Maio
                                    </th>
                                    <th style={{ textAlign: "center", border: "1px solid " }}>
                                        Junho
                                    </th>
                                    <th style={{ textAlign: "center", border: "1px solid " }}>
                                        Julho
                                    </th>
                                    <th style={{ textAlign: "center", border: "1px solid " }}>
                                        Agosto
                                    </th>
                                    <th style={{ textAlign: "center", border: "1px solid " }}>
                                        Setembro
                                    </th>
                                    <th style={{ textAlign: "center", border: "1px solid " }}>
                                        Outubro
                                    </th>
                                    <th style={{ textAlign: "center", border: "1px solid " }}>
                                        Novembro
                                    </th>
                                    <th style={{ textAlign: "center", border: "1px solid " }}>
                                        Dezembro
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style={{ width: 200, textAlign: "center", border: "1px solid " }}>
                                        {destinosInsert !== '' ? <Autocomplete

                                            id="combo-box-demo"
                                            options={destinosInsert}
                                            getOptionLabel={(option) => option.City_uf}
                                            style={{ width: 200, marginTop: 36 }}

                                            onChange={(e, v) => setOrigem(v)}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Origem"

                                                />
                                            )}
                                        /> : <Loader
                                            type="Circles"
                                            color="#00BFFF"
                                            height={20}
                                            width={20}
                                            timeout={20000} //3 secs
                                        />}

                                    </td>

                                    <td style={{ width: 200, textAlign: "center", border: "1px solid " }}>
                                        {destinosInsert !== '' ? <Autocomplete

                                            id="combo-box-demo"
                                            options={destinosInsert}
                                            getOptionLabel={(option) => option.City_uf}
                                            style={{ width: 200, marginTop: 36 }}

                                            onChange={(e, v) => setDestino(v)}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Destino"

                                                />
                                            )}
                                        /> : <Loader
                                            type="Circles"
                                            color="#00BFFF"
                                            height={20}
                                            width={20}
                                            timeout={20000} //3 secs
                                        />}
                                    </td>
                                    <td style={{ width: 100, textAlign: "center", border: "1px solid " }}>

                                        <TextField type='number' onChange={(e) => setU_Jan(e.target.value)} />
                                    </td>
                                    <td style={{ width: 100, textAlign: "center", border: "1px solid " }}>

                                        <TextField type='number' onChange={(e) => setU_Fev(e.target.value)} />
                                    </td>
                                    <td style={{ width: 100, textAlign: "center", border: "1px solid " }}>

                                        <TextField type='number' onChange={(e) => setU_Mar(e.target.value)} />
                                    </td>
                                    <td style={{ width: 100, textAlign: "center", border: "1px solid " }}>

                                        <TextField type='number' onChange={(e) => setU_Abr(e.target.value)} />
                                    </td>
                                    <td style={{ width: 100, textAlign: "center", border: "1px solid " }}>

                                        <TextField type='number' onChange={(e) => setU_Mai(e.target.value)} />
                                    </td>
                                    <td style={{ width: 100, textAlign: "center", border: "1px solid " }}>

                                        <TextField type='number' onChange={(e) => setU_Jun(e.target.value)} />
                                    </td>
                                    <td style={{ width: 100, textAlign: "center", border: "1px solid " }}>

                                        <TextField type='number' onChange={(e) => setU_Jul(e.target.value)} />
                                    </td>
                                    <td style={{ width: 100, textAlign: "center", border: "1px solid " }}>

                                        <TextField type='number' onChange={(e) => setU_Ago(e.target.value)} />
                                    </td>
                                    <td style={{ width: 100, textAlign: "center", border: "1px solid " }}>

                                        <TextField type='number' onChange={(e) => seU_Set(e.target.value)} />
                                    </td>
                                    <td style={{ width: 100, textAlign: "center", border: "1px solid " }}>

                                        <TextField type='number' onChange={(e) => seU_Out(e.target.value)} />
                                    </td>
                                    <td style={{ width: 100, textAlign: "center", border: "1px solid " }}>

                                        <TextField type='number' onChange={(e) => setU_Nov(e.target.value)} />
                                    </td>
                                    <td style={{ width: 100, textAlign: "center", border: "1px solid " }}>

                                        <TextField type='number' onChange={(e) => setU_Dez(e.target.value)} />
                                    </td>


                                </tr>


                            </tbody>
                        </table>
                        <GridItem>
                            <Button style={{ backgroundColor: '#6699ff' }} variant="contained" onClick={() => AdicionaFrete()}>Adiciona Frete</Button>
                        </GridItem>

                    </div>
                </GridContainer>
                <GridContainer>
                    <GridItem>
                        <a href='https://back.macs.dev.br/fretesPDFLista/out.pdf' download>PDF com Fretes</a>
                    </GridItem>
                    <GridItem>
                        {fretes !== '' ? <a>
                            <button onClick={() => fretesFF()}> Baixar Excel Fretes</button>
                        </a> : <Loader
                            type="Circles"
                            color="#00BFFF"
                            height={20}
                            width={20}
                            timeout={20000} //3 secs
                        />}
                    </GridItem>

                    <GridItem>
                        <Button style={{ backgroundColor: '#6699ff' }} variant="contained" onClick={() => atualizaPdfFretes()}>Atualizar Lista PDF</Button>
                    </GridItem>
                </GridContainer>
            </div > : <></>
    );
}
function mapStateToProps(state) {
    return {
        token: state.token,
        user: state.user,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            setToken: setToken,
            setUser: setUser,
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(AtualizaFretes);
