import React, { useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import GridItem from "components/Grid/GridItem.js";
import { Checkbox } from '@material-ui/core';
import GridContainer from "components/Grid/GridContainer.js";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { setToken } from "../../flux/actions/tokenIndex";
import { setUser } from "../../flux/actions/userIndex";
import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import api2 from "../../services/api2";
import { checarPermissao, validateTokenUser, getUser, getToken } from '../../utils/utils.js'
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";

const useStyles = makeStyles(styles);

function Notas(props) {
    const [operacao, setOpecaracao] = useState("Compra");
    const [cambio, setCambio] = useState(0);
    const [valorUs, setValorUs] = useState(0);
    const [efet, setEfet] = useState("");
    const [notasXp, setNotasXp] = useState('')
    const [totals, setTotals] = useState('')
    const [change, setChange] = useState({})
    const [vetDelete, setVetDelete] = useState([])
    const [permitido, setPermitido] = useState(false)
    const classes = useStyles();

    useEffect(() => {
        async function fetchMyapi() {
            await validateTokenUser(props)
            setPermitido(await checarPermissao('notas', props))
            const response = await api2.get("/retornaDados", { params: { userId: getUser(), token: getToken() } });

            if (response.data.length === 0) { console.log(123) }
            else { setNotasXp(response.data); }


            const response_totals = await api2.get('/retornaDadosSum', { params: { userId: getUser(), token: getToken() } });
            if (response_totals.data === 'NOK') { console.log(123) }
            else { setTotals(response_totals.data) }


        }
        fetchMyapi();
    }, []);


    async function deleteDados(item) {
        const response_materias = await api2.get("/deleteDados", {
            params: {
                operacao: item.operacao,
                cambio: item.cambio,
                valorUs: item.valorUs,
                efet: item.efet,
                userId: getUser(),
                token: getToken()
            },
        });
        if (response_materias.data === 'NOK') {
            alert('Erro ao deletar nota!')
        } else {
            alert('Nota deletada com sucesso!')
            window.location.reload();
        }
    }
    async function fetchapi2() {
        const response_materias = await api2.get("/salvaDados", {
            params: {
                operacao,
                cambio,
                valorUs,
                efet,
                userId: getUser(),
                token: getToken()
            },
        });
        if (response_materias.data === 'NOK') {
            alert('Erro ao adicionar nota!')
        } else {
            alert('Nota adicionada com sucesso!')
            window.location.reload();
        }
    }
    async function modificaNota(e) {
        const response_materias = await api2.get("/alteraDadosNotas", {
            params: {
                registro: e,
                altera: change,
                userId: getUser(),
                token: getToken()
            },
        });
        if (response_materias.data === 'NOK') {
            alert('Erro ao alterar nota')
        } else {
            alert('nota alterada com sucesso!')
            window.location.reload();
        }
    }
    const alteraNota = (e, v) => {
        console.log(e, v)
        let aux = change;
        aux[v] = e;
        setChange(aux)
        console.log(aux)
    }

    const montaDelete = (index) => {
        let aux = vetDelete
        index = parseInt(index)
        if (aux[index] === undefined) {
            aux[index] = true
        }
        else {
            aux[index] = !aux[index]
        }
        setVetDelete(aux)
        console.log(aux)
    }

    async function deleteTudoNotas(item) {
        let auxO = ''
        Object.keys(vetDelete).forEach(
            (item) => (auxO = auxO + item + '#' + vetDelete[item] + ';')
        )

        const response_materias = await api2.get("/deleteTudoNotas", { params: { vetDelete: auxO, userId: getUser(), token: getToken() } });
        if (response_materias.data === 'NOK') {
            alert('Erro ao deletar NDF!')
        } else {
            alert('NDF deletada com sucesso!')
            window.location.reload();
        }
    }
    return (
        permitido === true ?
            <div>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <div
                        >
                            <div>

                                <h4> Compra/Venda :</h4>
                                <Autocomplete
                                    id="combo-box-demo"
                                    options={[{ name: "Compra" }, { name: "Venda" }]}
                                    getOptionLabel={(option) => option.name}
                                    style={{ width: 300 }}
                                    onInputChange={(e, v) => setOpecaracao(v)}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Compra ou Venda"
                                            variant="outlined"
                                        />
                                    )}
                                />
                            </div>
                            <div>
                                <h4> Câmbio Movimentação:</h4>
                                <TextField
                                    label={cambio}
                                    onChange={(e) => setCambio(e.target.value)}
                                />
                            </div>
                            <div>
                                <h4> Valor U$ Movimentação :</h4>
                                <TextField
                                    label={valorUs}
                                    onChange={(e) => setValorUs(e.target.value)}
                                />
                            </div>

                            <div>
                                <h4> Data Efetivação Movimentação</h4>
                                <TextField label={efet} onChange={(e) => setEfet(e.target.value)} />
                            </div>
                            <div style={{ marginTop: 30 }}>
                                <button onClick={() => fetchapi2()}>Salvar Dados</button>
                            </div>
                        </div>
                    </GridItem>
                    {/* <GridItem xs={12} sm={12} md={4}>
                    <Card chart>
                        <CardHeader color="warning">
                            <ChartistGraph
                                className="ct-chart"
                                data={emailsSubscriptionChart.data}
                                type="Bar"
                                options={emailsSubscriptionChart.options}
                                responsiveOptions={emailsSubscriptionChart.responsiveOptions}
                                listener={emailsSubscriptionChart.animation}
                            />
                        </CardHeader>
                        <CardBody>
                            <h4 className={classes.cardTitle}>Email Subscriptions</h4>
                            <p className={classes.cardCategory}>Last Campaign Performance</p>
                        </CardBody>
                        <CardFooter chart>
                            <div className={classes.stats}>
                                <AccessTime /> campaign sent 2 days ago
              </div>
                        </CardFooter>
                    </Card>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                    <Card chart>
                        <CardHeader color="danger">
                            <ChartistGraph
                                className="ct-chart"
                                data={completedTasksChart.data}
                                type="Line"
                                options={completedTasksChart.options}
                                listener={completedTasksChart.animation}
                            />
                        </CardHeader>
                        <CardBody>
                            <h4 className={classes.cardTitle}>Completed Tasks</h4>
                            <p className={classes.cardCategory}>Last Campaign Performance</p>
                        </CardBody>
                        <CardFooter chart>
                            <div className={classes.stats}>
                                <AccessTime /> campaign sent 2 days ago
              </div>
                        </CardFooter>
                    </Card>
                </GridItem> */}
                </GridContainer>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={10}>
                        <CardHeader >Compras </CardHeader>
                        <Card>
                            {notasXp !== '' ?
                                <table style={{
                                    borderCollapse: "collapse",
                                    borderSpacing: 0,
                                }}>
                                    <thead>
                                        <tr >
                                            <th style={{ width: 300, textAlign: "center", border: "1px solid " }}>
                                                operacao
                                            </th>
                                            <th style={{ width: 300, textAlign: "center", border: "1px solid " }}>cambio</th>
                                            <th style={{ width: 300, textAlign: "center", border: "1px solid " }}>
                                                valorUs
                                            </th>
                                            <th style={{ width: 300, textAlign: "center", border: "1px solid " }}>
                                                valorRs
                                            </th>
                                            <th style={{ width: 300, textAlign: "center", border: "1px solid " }}>efet</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Object.keys(notasXp).map((item, index) => (

                                            notasXp[item].operacao === 'Compra' ?
                                                <tr key={index} >
                                                    <td style={{ width: 300, textAlign: "center", border: "1px solid " }}>
                                                        {notasXp[item].operacao}
                                                    </td>
                                                    <td style={{ width: 300, textAlign: "center", border: "1px solid " }}>
                                                        {notasXp[item].cambio}
                                                    </td>
                                                    <td style={{ width: 300, textAlign: "center", border: "1px solid " }}>

                                                        <TextField label={notasXp[item].valorUs.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,').replaceAll(',', '/').replaceAll('.', ',').replaceAll('/', '.')} onChange={(e) => alteraNota(e.target.value, 'valorUs')} />

                                                    </td>
                                                    <td style={{ width: 300, textAlign: "center", border: "1px solid " }}>
                                                        {notasXp[item].valorRs.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,').replaceAll(',', '/').replaceAll('.', ',').replaceAll('/', '.')}
                                                    </td>
                                                    <td style={{ width: 300, textAlign: "center", border: "1px solid " }}>
                                                        <TextField label={notasXp[item].efet} onChange={(e) => alteraNota(e.target.value, 'efet')} />

                                                    </td>
                                                    <td> <button onClick={() => deleteDados(notasXp[item])}> Deleta Nota</button></td>
                                                    <td> <button onClick={() => modificaNota(notasXp[item])}> Altera Nota</button></td>
                                                    <td>  <Checkbox

                                                        onChange={() => montaDelete(notasXp[index].rowid)}
                                                        name="checkedB"
                                                        color="primary"
                                                    /> </td>
                                                </tr> : console.log(1)

                                        ))}
                                        {totals !== '' ?
                                            <tr key={56756756}>
                                                <td style={{ width: 300, textAlign: "center", border: "1px solid " }}>
                                                    {'Média Cambio/Totais'}
                                                </td>
                                                <td style={{ width: 300, textAlign: "center", border: "1px solid " }}>
                                                    {totals[0].cambioMean}
                                                </td>
                                                <td style={{ width: 300, textAlign: "center", border: "1px solid " }}>
                                                    {totals[0].totalUs.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,').replaceAll(',', '/').replaceAll('.', ',').replaceAll('/', '.')}
                                                </td>
                                                <td style={{ width: 300, textAlign: "center", border: "1px solid " }}>
                                                    {totals[0].totalRs.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,').replaceAll(',', '/').replaceAll('.', ',').replaceAll('/', '.')}
                                                </td>
                                                <td style={{ width: 300, textAlign: "center", border: "1px solid " }}>
                                                    {''}
                                                </td>

                                            </tr> : console.log(totals)}

                                    </tbody>
                                </table>
                                : <div></div>}
                        </Card>
                    </GridItem>

                </GridContainer>

                <GridContainer>
                    <GridItem xs={12} sm={12} md={10}>
                        <CardHeader >Vendas </CardHeader>
                        <Card>
                            <table style={{
                                borderCollapse: "collapse",
                                borderSpacing: 0,
                            }}>
                                <thead>
                                    <tr >
                                        <th style={{ width: 300, textAlign: "center", border: "1px solid " }}>
                                            operacao
                                        </th>
                                        <th style={{ width: 300, textAlign: "center", border: "1px solid " }}>cambio</th>
                                        <th style={{ width: 300, textAlign: "center", border: "1px solid " }}>
                                            valorUs
                                        </th>
                                        <th style={{ width: 300, textAlign: "center", border: "1px solid " }}>
                                            valorRs
                                        </th>
                                        <th style={{ width: 300, textAlign: "center", border: "1px solid " }}>efet</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {Object.keys(notasXp).map((item, index) => (

                                        notasXp[item].operacao === 'Venda' ?
                                            <tr key={index} >
                                                <td style={{ width: 300, textAlign: "center", border: "1px solid " }}>
                                                    {notasXp[item].operacao}
                                                </td>
                                                <td style={{ width: 300, textAlign: "center", border: "1px solid " }}>
                                                    {notasXp[item].cambio}
                                                </td>
                                                <td style={{ width: 300, textAlign: "center", border: "1px solid " }}>

                                                    <TextField label={notasXp[item].valorUs.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,').replaceAll(',', '/').replaceAll('.', ',').replaceAll('/', '.')} onChange={(e) => alteraNota(e.target.value, 'valorUs')} />

                                                </td>
                                                <td style={{ width: 300, textAlign: "center", border: "1px solid " }}>
                                                    {notasXp[item].valorRs.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,').replaceAll(',', '/').replaceAll('.', ',').replaceAll('/', '.')}
                                                </td>
                                                <td style={{ width: 300, textAlign: "center", border: "1px solid " }}>
                                                    <TextField label={notasXp[item].efet} onChange={(e) => alteraNota(e.target.value, 'efet')} />

                                                </td>
                                                <td> <button onClick={() => deleteDados(notasXp[item])}> Deleta Nota</button></td>
                                                <td> <button onClick={() => modificaNota(notasXp[item])}> Altera Nota</button></td>
                                                <td>  <Checkbox

                                                    onChange={() => montaDelete(notasXp[index].rowid)}
                                                    name="checkedB"
                                                    color="primary"
                                                /> </td>
                                            </tr> : console.log(1)

                                    ))}
                                    {totals !== '' ?
                                        <tr key={56756756}>
                                            <td style={{ width: 300, textAlign: "center", border: "1px solid " }}>
                                                {'Média Cambio/Totais'}
                                            </td>
                                            <td style={{ width: 300, textAlign: "center", border: "1px solid " }}>
                                                {totals[0].cambioMean}
                                            </td>
                                            <td style={{ width: 300, textAlign: "center", border: "1px solid " }}>
                                                {totals[0].totalUs.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,').replaceAll(',', '/').replaceAll('.', ',').replaceAll('/', '.')}
                                            </td>
                                            <td style={{ width: 300, textAlign: "center", border: "1px solid " }}>
                                                {totals[0].totalRs.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,').replaceAll(',', '/').replaceAll('.', ',').replaceAll('/', '.')}
                                            </td>
                                            <td style={{ width: 300, textAlign: "center", border: "1px solid " }}>
                                                {''}
                                            </td>

                                        </tr> : console.log(totals)}

                                </tbody>
                            </table>
                        </Card>
                    </GridItem>
                </GridContainer>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                        <Button style={{ backgroundColor: '#DC143C' }} onClick={() => deleteTudoNotas()}>Remover Operações Selecionadas</Button>
                    </GridItem>
                </GridContainer>

                <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                        <h2> Total Geral </h2>
                        <table style={{ width: 300, textAlign: "center", border: "1px solid " }}>
                            <thead>
                                <tr >
                                    <th style={{ width: 300, textAlign: "center", border: "1px solid " }}>
                                        Média Cambio
                                    </th>
                                    <th style={{ width: 300, textAlign: "center", border: "1px solid " }}>
                                        Total Us
                                    </th>
                                    <th style={{ width: 300, textAlign: "center", border: "1px solid " }}>
                                        Total Rs
                                    </th>


                                </tr>
                            </thead>
                            <tbody>
                                {totals !== '' ?
                                    <tr key={56756756}>
                                        <td style={{ width: 300, textAlign: "center", border: "1px solid " }}>
                                            {totals[0].mediaGeral}
                                        </td>
                                        <td style={{ width: 300, textAlign: "center", border: "1px solid " }}>
                                            {totals[0].totalGeral.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,').replaceAll(',', '/').replaceAll('.', ',').replaceAll('/', '.')}
                                        </td>
                                        <td style={{ width: 300, textAlign: "center", border: "1px solid " }}>
                                            {totals[0].totalGeralRs.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,').replaceAll(',', '/').replaceAll('.', ',').replaceAll('/', '.')}
                                        </td>

                                    </tr> : console.log(totals)}

                            </tbody>
                        </table>
                    </GridItem>
                </GridContainer>
                {/*                  */}

            </div> : <></>
    );
}
function mapStateToProps(state) {
    return {
        token: state.token,
        user: state.user,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            setToken: setToken,
            setUser: setUser,
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(Notas);
