import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { setToken } from "../../flux/actions/tokenIndex";
import { setUser } from "../../flux/actions/userIndex";
import api2 from "../../services/api2";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import GridItem from "components/Grid/GridItem.js";

import { makeStyles } from "@material-ui/core/styles";
import { TextField } from "@material-ui/core";
import Button from "components/CustomButtons/Button.js"

import GridContainer from "components/Grid/GridContainer.js";

import Checkbox from "@material-ui/core/Checkbox";
import {
    checarPermissao,
    validateTokenUser, getUser, getToken
} from '../../utils/utils.js'


function AutoFaturamento(props) {
    // Valores pra criar publicação
    const [user, setUser] = useState('')
    const [agendamentos, setAgendamentos] = useState('')
    const [agFilter, setAgFitler] = useState('')
    const classes = useStyles();
    const [permitido, setPermitido] = useState(false)
    const [emitidos, setEmitidos] = useState(false)
    const [iniDate, setIniDate] = useState((new Date()).toLocaleDateString("pt-BR").replaceAll('/', '-'))
    const [endDate, setEndDate] = useState('')
    useEffect(() => {
        async function fetchMyApi() {
            await validateTokenUser(props)
            setPermitido(await checarPermissao('autoFat', props))
            const response_placas = await api2.get("/retornaPlacas", { params: { userId: getUser(), token: getToken() } });
            setAgendamentos(response_placas.data)
            setAgFitler(response_placas.data)
            console.log(response_placas)
        }
        fetchMyApi()


    }, [])

    const handleChange = (event, type) => {
        console.log(event)
        if (type === 'ini') {
            if (endDate === '') { setEndDate(event) }
            setAgFitler(agendamentos.filter(function (e) {
                return e.dataA >= event &&
                    e.dataA <= endDate && e.nf_emitida == emitidos

            }))
        } else if (type === 'end') {
            if (iniDate === '') { setIniDate(event) }
            setAgFitler(agendamentos.filter(function (e) {
                return e.dataA >= iniDate &&
                    e.dataA <= event && e.nf_emitida == emitidos

            }))
        }


    };
    const handleChangeFound = (newValue) => {
        let filterCheck = newValue === true ? 1 : 0
        console.log(filterCheck)
        setEmitidos(newValue)
        setAgFitler(agendamentos.filter(function (e) {
            return e.dataA >= iniDate &&
                e.dataA <= endDate && e.nf_emitida == filterCheck
        }))


    };
    async function enviarEmail(id, id_placas, produto, placa, dataA, numeroPedido) {
        const response_email = await api2.get("/EnviaEmailTransportadora", {
            params: {
                id,
                userId: getUser(),
                token: getToken(),
                id_placas, produto,
                placa,
                dataA,
                numeroPedido
            }
        });
        if (response_email.data === 'OK') {
            alert('Email Enviado com sucesso')
            window.location.reload();
        } else if (response_email.data === 'NOK') {
            alert('Erro ao enviar email')
        }
    }

    return (
        permitido === true ?
            <div className={classes.tudoBackground}>
                <div style={{ border: "1px solid " }}>
                    <GridContainer style={{ padding: 10 }}>
                        <GridItem>
                            <p> Data Inicio</p>

                            <TextField
                                value={iniDate}
                                style={{ width: 250 }}
                                type='date'
                                onChange={(e) => (setIniDate(e.target.value), handleChange(e.target.value, 'ini'))}
                            />
                        </GridItem>
                        <GridItem>
                            <p> Data Fim</p>

                            <TextField
                                value={endDate}
                                style={{ width: 250 }}
                                type='date'
                                onChange={(e) => (setEndDate(e.target.value), handleChange(e.target.value, 'end'))}
                            />
                        </GridItem>
                    </GridContainer>


                    <GridItem>
                        <p>Filtrar Notas fiscais</p>
                        <p> Checkado = Notas Emitidas</p>
                        <p> Não checkado = Notas Fiscais não encontradas no banco de dados</p>

                        <Checkbox
                            defaultValue={emitidos}
                            onChange={() => handleChangeFound(!emitidos)}
                            name="Check Emitidos"
                            color="primary"
                        />
                    </GridItem>
                </div>
                {Object.keys(agFilter).map((index) =>
                    <Accordion style={{ width: '100%' }} key={index}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id={index}

                        >
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <Typography style={{ width: 300 }} className={classes.heading}> Numero Pedido GeN : {agFilter[index].pedFatCliente} </Typography>
                                    <Typography style={{ width: 300 }} className={classes.heading}> Data Agendado : {agFilter[index].dataA} </Typography>
                                </div>
                                <Typography className={classes.heading}> Status: {agFilter[index].nf_emitida == 1 ? 'Nota fiscal emitida' : 'Nota fiscal não encontrada no banco de dados'}</Typography>
                                <Typography className={classes.heading}>  Placa : {agFilter[index].placa} |  Emitido : {agFilter[index].emissao_nota} </Typography>
                                <Typography className={classes.heading}>  Transportadora : {agFilter[index].transportadora}  </Typography>



                            </div>
                        </AccordionSummary>
                        <div style={{ border: "1px solid", margin: 10 }}>
                            <AccordionDetails style={{ display: 'flex', flexDirection: 'column' }}>

                                <Typography>
                                    Produto : {agFilter[index].produto}
                                </Typography>
                                <Typography>
                                    Quantidade: {agFilter[index].quantidade}
                                </Typography>
                                <Typography>
                                    Cliente : {agFilter[index].cliente}
                                </Typography>
                                <Typography>
                                    Placa : {agFilter[index].placa}
                                </Typography>
                                {agFilter[index].nf_emitida == 0 && agFilter[index].nf_solicitada == 0 ?
                                    <Button style={{ backgroundColor: '#6699ff', marginTop: 30, width: 400 }} variant="contained" onClick={() => enviarEmail(agFilter[index].id, agFilter[index].id_placas, agFilter[index].produto, agFilter[index].placa, agFilter[index].dataA, agFilter[index].pedFatCliente)}> Enviar Email Solicitando a Emissão da nota</Button>
                                    : agFilter[index].nf_emitida == 0 && agFilter[index].nf_solicitada == 1 ? <p> Emails de solicitação já enviado</p> : null}
                            </AccordionDetails>
                        </div>

                    </Accordion>
                )}
            </div > : <></>
    )
}





const useStyles = makeStyles({
    editBackground: {
        border: 'solid 1px blue',
        borderRadius: 5,
        boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
        padding: '30px',
        background: 'white',
    },

    postBackground: {
        borderRadius: 5,
        boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .5)',
        padding: '30px',
        marginTop: 40,
        background: 'white',
        margin: 'auto',
        position: 'relative',
        width: 900
    },

    tudoBackground: {
        background: 'rgb(250, 250, 250)',
        borderRadius: 5,
        boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .5)',
    },

    text: {
        width: 800
    },

    headerInput: {
        width: 500
    },

    image: {
        maxWidth: 500,
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: 500
    },

    header: {

        fontWeight: 'bold',
        color: 'black',
        fontFamily: 'Arial',
        margin: 0,
        padding: 0,

    },

    texto: {
        fontWeight: 'bold',
        whiteSpace: 'pre-wrap'
    },

    buttonDeletar: {
        marginLeft: 800,
        background: '#ED3237',
        '&:hover': {
            background: '#ED3237'
        }
    },

    buttonEditar: {
        position: 'absolute',
        top: 0,
        right: 0,
        margin: 10,
        background: 'linear-gradient(45deg, #2196F3 10%, #21CBF3 90%)'
    },

    buttonPublicar: {
        background: 'linear-gradient(45deg, #2196F3 10%, #21CBF3 90%)'
    },

    borderHeader: {
        content: '',
        display: 'block',
        borderBottom: '1px solid black'
    },

    buttonImagem: {
        background: 'linear-gradient(45deg, #2196F3 10%, #21CBF3 90%)'
    },

    divImagem: {
        marginTop: 10
    },

    buttonFixar: {
        background: 'linear-gradient(45deg, #2196F3 10%, #21CBF3 90%)'
    },

    postFixadoBackground: {
        border: 'solid 1px yellow',
        borderRadius: 5,
        boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .5)',
        padding: '30px',
        marginTop: 40,
        background: 'white',
        position: 'relative',
        margin: 'auto',
        width: 900
    },

    buttonSalvar: {
        background: '#4bcc5a',
        '&:hover': {
            background: '#4bcc5a'
        }
    },

    divTextEdit: {
        marginTop: 10
    },

    divTitulo: {
        marginTop: 10,
        marginBottom: 10
    },

    divFontes: {
        marginTop: 10
    }
});

function mapStateToProps(state) {
    return {
        token: state.token,
        user: state.user,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            setToken: setToken,
            setUser: setUser,
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(AutoFaturamento);