import React, { useEffect, useState } from "react";
import { Button, Grid, Paper, Dialog, Box } from '@material-ui/core';
import EditorCusto from './EditorCusto';
import EditorPreco from './EditorPreco';
import api from 'services/api.js';
import { getUser, getToken } from 'utils/utils.js'

export default function Edicao({ materias, setMaterias, vendedor }) {
    const [openCusto, setOpenCusto] = useState(false)
    const [openPreco, setOpenPreco] = useState(false)
    const [itens, SetItens] = useState('');
    const [itensBarcarena, setItensBarcarena] = useState('');
    const [itensParangua, setItensParangua] = useState('');

    useEffect(() => {
        FetchMyApi();
    }, [openCusto]);

    const FetchMyApi = async () => {
        const response_custos = await api.get("retorna_custos_fixos", {
            params: { userId: getUser(), token: getToken() },
        });
        SetItens(response_custos.data)
        const response_custos_parangua = await api.get("retorna_custos_fixos_parangua", {
            params: { userId: getUser(), token: getToken() },
        });
        setItensParangua(response_custos_parangua.data)
        const response_custosBarcarena = await api.get("retorna_custos_fixos_barcarena", {
            params: { userId: getUser(), token: getToken() },
        });
        setItensBarcarena(response_custosBarcarena.data)
    }

    const updateItens = (newItens) => {
        SetItens(newItens);
    }
    const updateItensBarcarena = (newItens) => {
        setItensBarcarena(newItens);
    }

    const updateItensParangua = (newItens) => {
        setItensParangua(newItens);
    }


    const handleCloseCusto = () => {
        setOpenCusto(false)
    }
    const handleClosePreco = () => setOpenPreco(false)

    return (
        <Box style={{ display: 'flex', justifyContent: 'space-between', padding: 10, borderRadius: 5, alignItems: 'center' }} component={Paper}>
            {vendedor === false ?
                <div>
                    <span>Edição / Cadastro </span>
                    <Button variant='contained' size="small" onClick={() => setOpenCusto(true)}>
                        Editar Custos
                    </Button><p></p>
                    <Button variant='contained' size="small" onClick={() => setOpenPreco(true)}>
                        Editar preços
                    </Button>

                    <Dialog open={openCusto} onClose={handleCloseCusto} maxWidth="md" fullWidth>
                        <EditorCusto
                            itens={itens}
                            setItens={updateItens}
                            close={handleCloseCusto}
                            itensBarcarena={itensBarcarena}
                            setItensBarcarena={updateItensBarcarena}
                            itensParangua={itensParangua}
                            setItensParangua={updateItensParangua}
                        />
                    </Dialog>

                    <Dialog open={openPreco} onClose={handleClosePreco} maxWidth="lg" fullWidth>
                        <EditorPreco
                            materias={materias}
                            setMaterias={setMaterias}
                            close={handleClosePreco}
                            vendedor={false}
                        />
                    </Dialog>
                </div>

                : <div>
                    <Button variant='contained' size="small" onClick={() => setOpenPreco(true)}>
                        Visualizar preços
                    </Button>
                    <Dialog open={openPreco} onClose={handleClosePreco} maxWidth="lg" fullWidth>
                        <EditorPreco
                            materias={materias}
                            setMaterias={setMaterias}
                            close={handleClosePreco}
                            vendedor={true}
                        />
                    </Dialog></div>}

                    <div> 
                        
                        <Button variant = 'contained' size = 'small' onClick={() => window.location.href = 'https://fun-simply-giraffe.ngrok-free.app/cadastro_formulador'}  style={{ marginLeft: '6px' }}>
                            Cadastrar Material
                        </Button>
                    </div>
        </Box>
    )
}